const DTToast = ({
  theme,
  title,
  message,
  isShown,
  onToastClick,
  onToastMessageClick,
}) => {
  return (
    <div
      className={`ToastWrapper${isShown ? " active" : ""} ${
        theme ? theme : " primary"
      }`}
    >
      <div className="ToastContent">
        <div className="ToastIcon" />
        <div>
          {title ? <div className="ToastTitle">{title}</div> : ""}
          {message ? (
            <span
              className="ToastMessage"
              onClick={() => onToastMessageClick()}
            >
              {message}
            </span>
          ) : (
            ""
          )}
        </div>
        {onToastClick ? (
          <div className="ToastButton" onClick={() => onToastClick()} />
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default DTToast
