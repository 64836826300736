import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '../../../../Icon'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../../../redux/slices/globalSlice'
import { parkChargeFunctions } from '../../../../../helper/parkChargeFunctions'
import { Alert } from '../../../../../helper/alert'
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner'
import { Capacitor } from '@capacitor/core'

const StartChargeButton = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {activeSession, usageAccount} = useSelector(state => state.userSlice)
    const [isScanActive, setScanActive] = useState(false)
  const startScan = () => {
    if(Capacitor.isNativePlatform()) {
      setScanActive(true)
      BarcodeScanner.scan()
      .then((barcodeData) => {
        if (!barcodeData.cancelled) {
          startCharge(barcodeData.text)
        }
      })
      .finally(() => setScanActive(false))
    }
  };

  const startCharge = (codeId) => {
    dispatch(setLoading({ status: true, message: t("starting-session") }))
    parkChargeFunctions
      .startCharge("", activeSession?.park_session?.vehicle?.id, true, codeId, usageAccount)
      .then(({ data }) => {
        if (data.success) {
          parkChargeFunctions.getActiveSession()
        } else {
          Alert.error(
            `${data.message[0].toUpperCase() + data.message.slice(1)}!`
          );
        }
      })
      .finally(() => dispatch(setLoading({status: false, message: ""})));
    
  }

  return (
    <button className='StartChargeButton' onClick={() => !isScanActive ? startScan() : Alert.warning(t("scanner-active"))}>
      <Icon
      name="charge-start"
      size={28}
      />
      {t("start-charge")}
    </button>
  )
}

export default StartChargeButton