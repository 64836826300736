const ParkChargeIcon = ({station}) => {
    return (
        <svg
              width="34"
              height="43"
              viewBox="0 0 34 43"
              fill="none"
              className="ResultsSliderIcon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.97851 5.2003C11.8639 -1.94228 23.0784 -1.72714 29.6752 5.84571C35.8597 12.9022 35.2825 23.9603 28.7682 30.7156L20.1923 41.6446C18.4607 43.4518 15.6158 43.4518 13.8842 41.6446L4.97851 30.3714C-1.6595 23.444 -1.6595 12.1708 4.97851 5.2003Z"
                fill={`${
                  station.TYPE === "parking"
                    ? "#5E53E1"
                    : station.TYPE === "station"
                    ? "#34D298"
                    : station.TYPE === "fast_charging"
                    ? "#00C2CE"
                    : "#B11D94"
                }`}
              />
              {station.TYPE === "parking" ? (
                <path
                  d="M12.1431 24.3424H15.8001V20.1448H17.6499C20.7328 20.1448 23.0716 18.2815 23.0716 15.4762C23.0716 12.6096 20.7328 10.8281 17.6499 10.8281H12.1431V24.3424ZM15.8001 17.0734V13.8996H17.6711C18.6917 13.8996 19.3508 14.5343 19.3508 15.4762C19.3508 16.4386 18.6917 17.0734 17.6711 17.0734H15.8001Z"
                  fill="white"
                />
              ) : station.TYPE === "station" ? (
                  <path
                    transform='translate(9.5, 10.75)'
                    fillRule='evenodd'
                    clipRule='evenodd'
                    fill='white'
                    d='M4.67928 15.6207C4.67952 15.7466 4.83816 15.8021 4.91678 15.7037L11.827 7.05477C11.8967 6.96745 11.8346 6.83821 11.7228 6.83821H7.9987C7.63102 6.83821 7.33275 6.54052 7.33204 6.17284L7.32073 0.379283C7.32048 0.253337 7.16185 0.197918 7.08323 0.296314L0.173038 8.94471C0.103272 9.03202 0.16544 9.16127 0.277203 9.16127H4.0013C4.36898 9.16127 4.66725 9.45896 4.66797 9.82664L4.67928 15.6207Z'
                  />
              ) : station.TYPE === "fast_charging" ? (
                <>
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.4591 27.6472C11.4594 27.7701 11.6117 27.8272 11.6927 27.7349L19.8058 18.4871C19.8814 18.4009 19.8202 18.2658 19.7055 18.2658H15.2207C14.853 18.2658 14.5548 17.9682 14.554 17.6006L14.5406 11.3532C14.5404 11.2303 14.3881 11.1732 14.3071 11.2656L6.19401 20.5128C6.11841 20.599 6.1796 20.7341 6.29424 20.7341H10.7791C11.1467 20.7341 11.445 21.0317 11.4458 21.3993L11.4591 27.6472Z"
                    fill="#FFFEFE"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.5088 20.4313C21.5092 20.6202 21.7471 20.7033 21.865 20.5557L26.7403 14.4537C26.845 14.3227 26.7517 14.1289 26.5841 14.1289H23.6995C23.5892 14.1289 23.4997 14.0396 23.4995 13.9293L23.491 9.56914C23.4906 9.38022 23.2527 9.29709 23.1347 9.44469L18.2594 15.5463C18.1548 15.6773 18.248 15.8712 18.4157 15.8712H21.3003C21.4106 15.8712 21.5001 15.9605 21.5003 16.0708L21.5088 20.4313Z"
                    fill="#FFFEFE"
                  />
                </>
              ) : (
                <>
                  <path
                    d="M14.5464 21.6925H16.7836V18.8846H17.9152C19.8012 18.8846 21.232 17.6381 21.232 15.7616C21.232 13.844 19.8012 12.6523 17.9152 12.6523H14.5464V21.6925ZM16.7836 16.83V14.7069H17.9283C18.5526 14.7069 18.9558 15.1315 18.9558 15.7616C18.9558 16.4054 18.5526 16.83 17.9283 16.83H16.7836Z"
                    fill="white"
                  />
                  <path
                    d="M17.0601 6.5C13.9973 6.5 11.2526 7.89406 9.38175 10.0849C8.98015 10.5552 9.17823 11.2462 9.70897 11.4797C10.0873 11.6462 10.5164 11.533 10.7736 11.2354C12.3207 9.44553 14.5659 8.31973 17.0601 8.31973C21.6914 8.31973 25.4842 12.2007 25.4842 16.9999C25.4842 21.7991 21.6914 25.6801 17.0601 25.6801C13.0292 25.6801 9.63351 22.7402 8.82086 18.8137C9.80735 18.3863 10.4998 17.3824 10.4998 16.2121V14.6318H9.48383L9.48375 12.7294C9.48375 12.5024 9.30488 12.3184 9.08416 12.3184C8.86344 12.3184 8.68457 12.5024 8.68457 12.7294V14.6318H6.82856L6.82848 12.7294C6.82848 12.5024 6.6496 12.3184 6.42889 12.3184C6.20817 12.3184 6.0293 12.5024 6.0293 12.7294V14.6318H5.01367V16.2121C5.01367 17.5051 5.85901 18.595 7.01223 18.9287C7.89536 23.792 12.0547 27.5 17.0603 27.5C22.7097 27.5 27.2813 22.7766 27.2813 16.9999C27.2813 11.2232 22.7096 6.5 17.0601 6.5Z"
                    fill="white"
                  />
                </>
              )}
            </svg>
    );
}

export default ParkChargeIcon;
