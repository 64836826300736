import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import CardSliderItem from "./CardSliderItem";
import {useSelector} from "react-redux";

const CardSlider = ({addNewHandler}) => {
    const {paymentMethods} = useSelector((store) => store.userSlice);

    return (
        <>
            <Swiper
                modules={[Pagination]}
                pagination={{type: "bullets"}}
                slidesPerView={1.5}
                centeredSlides={true}
                className="CardSwiper"
            >
                {
                    paymentMethods?.map((card) => {
                        return (
                            <SwiperSlide className="CardSlide" key={card.id}>
                                <CardSliderItem card={card}/>
                            </SwiperSlide>
                        );
                    })
                }
                <SwiperSlide className="CardSlide">
                    <CardSliderItem addSlide={true} addNewHandler={addNewHandler} />
                </SwiperSlide>
            </Swiper>
        </>
    );
};
export default CardSlider;
