function UnitPriceTooltip({ price }) {


  return (
    <div>
      <svg
        width="71"
        height="30"
        viewBox="0 0 71 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="5.00012" width="70" height="25" rx="6" fill="#353355" />
        <rect
          x="30"
          y="5.82825"
          width="8"
          height="8"
          rx="2"
          transform="rotate(-45 30 5.82825)"
          fill="#353355"
        />
        <text x="10%" y="70%" fill="#FFFFFF" className="text-[12px]">
          {price}
        </text>
      </svg>
    </div>
  )
}

export default UnitPriceTooltip
