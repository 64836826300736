import { IonSlide, IonContent, useIonRouter } from "@ionic/react";
import { t } from "i18next";
import Button from "../Button";
import Text from "../Text";
import { useSelector } from "react-redux";
import { useState } from "react";
import { api } from "../../helper/api";
import { store } from "../../redux/store";
import { setAlert, setLoading } from "../../redux/slices/globalSlice";
import Icon from "../Icon";

const SetBusinessCar = ({
  setBusinessSlideModalOpen,
  businessId,
  currentBusinessAccount,
  comingFrom,
  getBusinessAccounts,
  getBusinessDetail,
}) => {
  const { vehicles } = useSelector((state) => state.userSlice);
  const [choosenCar, setChoosenCar] = useState();
  const router = useIonRouter();

  const setCompanyVehicle = async () => {
    store.dispatch(setLoading({ status: true, message: "" }));
    await api
      .setCompanyVehicle(businessId, choosenCar)
      .then(({ data }) => {
        if (data.success) {
          if (comingFrom === "business-detail") getBusinessDetail();
          else getBusinessAccounts();
          setBusinessSlideModalOpen(false);
          router.push(`/my-business-accounts/detail/${businessId}`, "forward");
        } else {
          let vehicle = vehicles.find((v) => choosenCar === v.id);
          store.dispatch(
            setAlert({
              status: true,
              message: t("plate-cant-added", vehicle?.plate),
              type: "error",
            })
          );
        }
      })
      .finally(() =>
        store.dispatch(setLoading({ status: false, message: "" }))
      );
  };

  return (
    <IonSlide>
      <div className="SetBussinessCarWrapper">
        <IonContent scrollY={true} className="CarSlideContent">
          <div className="CarList">
            {vehicles.length > 0 ? (
              vehicles.map((car) => (
                  <label
                    className="BusinessRadioWrapper"
                    key={car.id}
                    htmlFor={car.id}
                    onClick={() => setChoosenCar(car.id)}
                  >
                    <input
                      type="radio"
                      id={car.id}
                      name="vehicles[]"
                      defaultChecked={
                        currentBusinessAccount.vehicle &&
                        car.id === currentBusinessAccount.vehicle?.id
                      }
                    />
                    <div className="BusinessRadio">
                      <div className="CarLogo">
                        <img src={car.logo} alt={car.brand_name} />
                      </div>
                      <div className="AddCarCheckboxContent text-left">
                        <Text size="20px" weight="700">
                          {car.brand_name}
                        </Text>
                        <Text size="16px" weight="500" margin="6px 0 0">
                          {car.model_name}
                        </Text>
                        <div className="AddCarCheckboxPlate">{car.plate}</div>
                      </div>
                    </div>
                  </label>
              ))
            ) : (
              <div className="NoItemsWrapper">
                <div className="NoItems -no-icon">
                  <Icon
                    name={"no-car"}
                    width={49}
                    height={34}
                    fill="rgba(190, 190, 217, 0.18)"
                  />
                </div>
                <Text size="22px" weight="600" margin="12px 0 0">
                  {t("oops")}...
                </Text>
                <Text size="14px" weight="400" margin="12px 0 auto">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: t("no-car-yet"),
                    }}
                  ></div>
                </Text>
              </div>
            )}
          </div>
          <div className="SetBussinessCarButtons">
            <Text
              size={16}
              weight={700}
              color={"var(--dt-pink)"}
              dangerouslySetInnerHTML={{ __html: t("add-new-car") }}
              onClick={async () => {
                await setBusinessSlideModalOpen(false);
                router.push("/add-new-car", "forward");
              }}
            />
            {vehicles.length > 0 && (
              <Button
                margin={"28px 0 50px"}
                onClick={() => {
                  setCompanyVehicle();
                }}
              >
                {t("set-my-business-car")}
              </Button>
            )}
          </div>
        </IonContent>
      </div>
    </IonSlide>
  );
};

export default SetBusinessCar;
