import {useEffect, useRef, useState} from "react"
import {Alert} from "../helper/alert";
import {useSelector} from "react-redux";

const DTAlert = () => {
  const {alert} = useSelector(state => state.globalSlice)

  useEffect(() => {
    if (alert.status) {
      const timeout = setTimeout(() => Alert.close(), 4000)
      return () => clearTimeout(timeout)
    }
  }, [alert])

  const [pressed, setPressed] = useState(false)
  const ref = useRef()
  const onMouseMove = (e) => pressed && e.movementY > 2 && Alert.close()

  return (
    <div
      className={`DTAlert -${alert.type ?? ""} ${alert.status && " open"}`}
      onMouseMove={onMouseMove}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      onTouchMove={onMouseMove}
      onTouchStart={() => setPressed(true)}
      onTouchEnd={() => setPressed(false)}
      ref={ref}
    >
      <span className="DTAlertIcon" />
      <div dangerouslySetInnerHTML={{ __html: alert.message }}/>
    </div>
  )
}

export default DTAlert
