// Ionic & React
import { IonContent, IonModal } from "@ionic/react"
import { useEffect, useState, memo } from "react"

import "./SearchModal.css"

// Components
import PageToolbar from "../components/PageToolbar"
import SearchResultsFab from "../components/SearchResultsFab"
import ParkingSpace from "../components/SearchStations/SearchFilters/ParkingSpace"
import ParkingType from "../components/SearchStations/SearchFilters/ParkingType"
import ChargeFilters from "../components/SearchStations/SearchFilters/ChargeFilters"
import ShowOnlyAvailableToggle from "../components/SearchStations/SearchFilters/ShowOnlyAvailableToggle"

import {
  setStationFilter
} from "../redux/slices/filterStationSlice"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Operators from "../components/SearchStations/SearchFilters/Operators"
import ParkingPrice from "../components/SearchStations/SearchFilters/ParkingPrice"
import ChargingSpeed from "../components/SearchStations/SearchFilters/ChargingSpeed"
import ZoneCode from "../components/SearchStations/SearchFilters/ZoneCode"
import {setFilterModal} from "../redux/slices/modalSlice";

const SearchFilterModal = ({
  setCurrentStation,
  setChargingSped,
  setStationDetailOpen,
  bottomToolbarRef,
  setFilterMenu,
  className,
  ...props
}) => {

  const { t } = useTranslation()

  const {filterModal} = useSelector((state) => state.modalSlice);

  const dispatch = useDispatch()
  const [choosenParkOperator, setChoosenParkOperator] = useState({})
  const [maxParkingPrice, setMaxParkingPrice] = useState(0)


  useEffect(() => {
    dispatch(setStationFilter({
        parking_max_price: maxParkingPrice !== 0 ?  maxParkingPrice : null,
        park_operator: choosenParkOperator && choosenParkOperator?.id,
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxParkingPrice, choosenParkOperator]);

  return (
    <IonModal
      isOpen={filterModal.status}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      canDismiss={filterModal.canDismiss}
      className={`FilterModal${className ? " " + className : ""}`}
      onDidDismiss={() => dispatch(setFilterModal(false))}
      {...props}
    >
      <PageToolbar
        title={t("filter")}
        leftButtonType="back"
        leftButtonOnClick={() => {
          dispatch(setFilterModal(false))
        }}
        backgroundColor="#fff"
        iosSafeArea={false}
      />

      <IonContent className="no-padding">
        <div className="FilterWrapper">
          <Operators title={t("parking-operators")} setChoosenOperator={setChoosenParkOperator} choosenOperator={choosenParkOperator}/>
          <ParkingPrice parkingPrice={maxParkingPrice} setParkingPrice={setMaxParkingPrice}/>
        </div>
        <SearchResultsFab
          setFilterMenu={setFilterMenu}
          onSearchResultsFabClick={() => {
            dispatch(setFilterModal(false))
          }}
          bottomToolbarRef={bottomToolbarRef}
        />
      </IonContent>
    </IonModal>
  )
}

export default memo(SearchFilterModal)
