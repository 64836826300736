//Components
import Icon from "../Icon";
//Helpers
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setAddSiteCodeModal } from "../../redux/slices/modalSlice";
const AddSiteCodeButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <button
      className="AddSiteCodeButton"
      onClick={() =>
        dispatch(setAddSiteCodeModal({ status: true, back: false }))
      }
    >
      <Icon name={"add-white"} fill="#fff" size={15} />
      {t("add-new")}
    </button>
  );
};

export default AddSiteCodeButton;
