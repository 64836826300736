//Ionic
import { IonSelect, IonSelectOption } from '@ionic/react';
//Redux
import { setDefaultVehicle } from '../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
//Helpers
import { useTranslation } from 'react-i18next';
import { api } from '../helper/api';
//Components
import Icon from './Icon';
import Text from './Text';


const VehicleSelector = ({ setVehicle, vehicleInUse }) => {

  const { vehicles } = useSelector((store) => store.userSlice);
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const interfaceOptions = {
    header: t("select-vehicle"),
    cssClass: "VehicleSelectorAlert"
  }

  const setDefaultV = async (id) => {
    const response = await api.setDefaultCar({
      options: {
        parameters: {
          vehicle_id: id,
        },
      },
    });

    if(response.status === 200 && response.data.success) {
      dispatch(setDefaultVehicle(response.data.response))
    }
  }

  return (
    <div className="w-full flex justify-center items-center">
      <Text color="#fff" weight={400} size={14} cssClass="capitalize">
        {t("plate")}:
      </Text>
      <IonSelect
        name="VehicleSelector"
        className={`VehicleSelector`}
        onIonChange={(e) => {
          e.preventDefault();
          setVehicle(e.target.value);
          setDefaultV(e.target.value)
        }}
        value={vehicleInUse}
        interfaceOptions={interfaceOptions}
      >
        {vehicles?.map((car) => (
          <IonSelectOption value={car.id} key={car.id}>
            <Text>{car.plate.toUpperCase()}</Text>
          </IonSelectOption>
        ))}
      </IonSelect>
      <Icon name="select-blue" width={10} height={16} color={"#353355"} />
    </div>
  );
};

export default VehicleSelector;
