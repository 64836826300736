// Ionic & React
import { memo, useState, useEffect, useRef } from "react"
import {
  IonPage,
  IonContent,
  useIonRouter,
  useIonAlert,
} from "@ionic/react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { setToken } from "../redux/slices/userSlice"

// Plugins
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input"
import { SmsRetriever } from "@awesome-cordova-plugins/sms-retriever"
import OtpInput from 'react18-input-otp';

// Components
import PageToolbar from "./../components/PageToolbar"
import Text from "../components/Text"
import Button from "../components/Button"

// Helper
import {api} from "./../helper/api"
import {useTranslation} from "react-i18next";
import {getConfigFromApi, getPerson} from "../helper/globalFunctions";
import { Capacitor } from "@capacitor/core"
import {clearLoader, setLoading} from "../redux/slices/globalSlice";

const EnterSMSCode = () => {
  const [count, setCount] = useState(180)
  const [border, setBorder] = useState("")
  const [isCountEnd, setCountEnd] = useState(false)
  const [SMSCode, setSMSCode] = useState("")

  const [ isTimeOut, setIsTimeOut ] = useState(false)

  const router = useIonRouter()
  const dispatch = useDispatch()
  const [showAlert] = useIonAlert()

  const { token, phone } = useSelector((state) => state.userSlice)

  const submitRef = useRef(null)
  const SMSInputRef = useRef(null)
  const {t} = useTranslation()

  const registerPhone = async (phone) => {
    dispatch(setLoading({status: true, message: t("sending")+"..."}))
    setSMSCode("")

    await api.registerPhone({ phone: phone })
      .then((response) => {
        if (response.data.success === true) {
          dispatch(clearLoader())
          setCount(180)
          SMSInputRef.current.focusInput(0)
        } else {
          dispatch(clearLoader())
          showAlert({
            message: `Invalid phone number`,
          })
        }
      }).catch(() => {
        dispatch(clearLoader())
        setBorder("shake")
        setTimeout(() => setBorder(""), 400)
      })
  }

  const activatePhone = (phone, code) => {
    api.activatePhone({ code: code, phone: phone }).then((response) => {
        if (response.data.id > 0) {
          dispatch(setToken(response.data.token))

          getPerson().then((person) => {
            getConfigFromApi().then(()=>{
              if (!!person.first_name && !!person.last_name && !!person.email) {
                router.push("/map", "forward")
              } else {
                router.push("/set-up-profile", "forward")
              }
            }).finally(() => {
              dispatch(clearLoader())
            })
          })
        } else {
          setBorder("shake")
          dispatch(clearLoader())
          showAlert({
            message: `Invalid SMS code`,
          })
        }
      })
      .catch(() => {
        dispatch(clearLoader())
      })
  }

  const startWatching = () => {
    SmsRetriever.startWatching().then((res) => {
      let message = res.Message
      let code = message.match("(|^)\\d{4}")[0]
      setSMSCode(code)
    })
  }

  const handleSubmit = async (e, code) => {
    if (e && e !== "") e.preventDefault()

    if (!code ? SMSCode.length === 4 : !isCountEnd) {
      dispatch(setLoading({status: true, message: t("confirming")+"..."}))

      activatePhone(phone, code ? code : SMSCode)
    }
  }

  const handleSMSCodeChange = (e) => {
    setSMSCode(e)
    if (e.length === 4) {
      handleSubmit("", e)
    }
  }

  const secondsToTime = (e) => {
    let m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0")

    return m + ":" + s
  }

  useEffect(() => {
    if(count > 0){
      setTimeout(() => setCount(count - 1), 1000)
      setIsTimeOut(true)
    }else if(count === 0) setCountEnd(true)
    return () => {
      if(isTimeOut){
        clearTimeout()
        setIsTimeOut(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  useEffect(() => {
    token && router.push('/map', 'redirect')
    let isMounted = true
    if (isMounted) {
      if (SMSInputRef.current) {
        setTimeout(() => SMSInputRef.current?.focusInput(0), 100)
        setIsTimeOut(true)
      }
      if(Capacitor.isNativePlatform() && Capacitor.getPlatform()==="android") {
        // have this condition bc of cordova not available error
        startWatching()
      } 
    }

    return () => {
      isMounted = false
      isTimeOut && clearTimeout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <IonPage>
      {phone && isValidPhoneNumber(phone) && (
        <PageToolbar leftButtonType="back" onClick={() => router.goBack()}/>
      )}

      <IonContent scrollEvents={false}>
        <div className="Landing">
          <form className="LandingWrapper" onSubmit={handleSubmit}>
            {phone && isValidPhoneNumber(phone) ? (
              <>
                <Text
                  select="none"
                  color="var(--dt-primary)"
                  margin="0 auto"
                  align="center"
                  size="22px"
                  weight="600"
                >
                  {t("a-verification-code-is-sent-to")}
                </Text>

                <Text
                  select="none"
                  margin="0 auto"
                  align="center"
                  size="18px"
                  weight="500"
                >
                  <h2>{formatPhoneNumberIntl(phone)}</h2>
                </Text>

                <Button
                  theme="link"
                  margin="0 auto"
                  onClick={() => router.push("/enter-phone-number", "back")}
                >
                  {t("change-number")}
                </Button>

                <div
                  className="EditPhoneNumberForSMS"
                  onClick={() => {
                    setSMSCode("")
                    router.push("/enter-phone-number", "back")
                  }}
                ></div>

                <OtpInput
                  value={SMSCode}
                  onChange={handleSMSCodeChange}
                  numInputs={4}
                  containerStyle={`SMSCodeInputWrapper${
                    border && " " + border
                  }`}
                  inputStyle="SMSCodeInput"
                  autoComplete="one-time-code"
                  focusStyle="SMSCodeInputFocused"
                  shouldAutoFocus={true}
                  isInputNum={true}
                  ref={SMSInputRef}
                />

                <button
                  type="submit"
                  style={{ display: "none" }}
                  ref={submitRef}
                ></button>

                <Text
                  margin="40px 0 0"
                  size="14px"
                  weight="500"
                  color="var(--dt-purple)"
                >
                  ({secondsToTime(count)})
                </Text>

                <Text
                  margin="4px 0 0"
                  size="12px"
                  weight="500"
                  color="var(--dt-purple)"
                  align="center"
                >
                  {t("didnt-receive-code")}
                  {" "}
                  <span
                    className={`link${!isCountEnd && " disabled"}`}
                    onClick={() => registerPhone(phone)}
                  >
                    {t("resend-code")}
                  </span>
                </Text>

                <Button type="submit" theme="primary" margin="40px 0 0">
                  {t("verify-and-create-account")}
                </Button>
              </>
            ) : (
              <>
                <Text
                  select="none"
                  cssClass="title"
                  color="var(--dt-primary)"
                  align="center"
                  margin="auto auto 0"
                >
                  {t("please-provide-a-valid-phone-number")}
                </Text>
                <Button
                  theme="link"
                  routerLink="/enter-phone-number"
                  routerDirection="back"
                  margin="26px auto auto"
                  align="center"
                >
                  {t("back")}
                </Button>
              </>
            )}
          </form>
        </div>

      </IonContent>
    </IonPage>
  )
}

export default memo(EnterSMSCode)

