import React, {memo} from "react"
import { IonRouterOutlet} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  EnterSMSCode,
  Map,
  NotFound,
  Onboarding,
  RegisterSetup,
  SetUpProfile,
  Inbox,
  Invoice,
  PlanAndPayments,
  AddNewCar,
  InvoiceDetail,
  Tickets,
  TicketDetail
  /*
  TODO::Temp Removed
  PaymentWithSepa,
   */
} from "./pages";
import { useSelector } from "react-redux";
import PaymentMethodPage from "./pages/PaymentMethodPage";
import AddNewPaymentMethodPage from "./pages/AddNewPaymentPage";
import MyBusinessAccount from "./pages/MyBusinessAccount";
import MySiteCodes from "./pages/MySiteCodes"
import { BusinessDetail } from "./components/MyBusinessAccount";
import MyDrifterCard from "./pages/MyDrifterCard";
import EditProfile from "./pages/EditProfile";
import SiteCodeDetail from "./components/MySiteCodes/SiteCodeDetail";
import AddDisablePermit from "./pages/AddDisablePermit";

const GuardedRoute = ({ path, render, ...props }) => {
  const {token, pin} = useSelector((state) => state.userSlice);

  return <Route {...props} path={path} render={() => {
    return !token || (token && pin) ? <Redirect to="/register-setup"/> : render()
  }}/>;
};
const Routes = () => {
	const {isFirstTime} = useSelector((state) => state.userSlice);

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        <Switch>
          <Route exact path="/" render={() => isFirstTime ? <Onboarding/> : <Redirect to={"/map"}/>}/>
          <Route exact path="/register-setup" render={() => <RegisterSetup />}/>
          <Route exact path="/enter-phone-number" render={() => <RegisterSetup />}/>
          <Route exact path="/enter-sms-code" render={() => <EnterSMSCode />} />

          <GuardedRoute path="/inbox" render={() => <Inbox/>}/>
          <GuardedRoute path="/set-up-profile" render={() => (<SetUpProfile/>)}/>
          <GuardedRoute path="/map" render={() => (<Map/>)}/>
          <GuardedRoute path="/plan-and-payments" render={() => <PlanAndPayments/>}/>
          <GuardedRoute exact={true} path="/invoices" render={() => <Invoice/>}/>
          <GuardedRoute exact path="/invoices/detail/:invoiceId" render={() => <InvoiceDetail />} />
          {/*
          TODO::Temp Removed
            <GuardedRoute exact path="/pay-with-sepa/:invoiceId" render={() => <PaymentWithSepa/>}/>
          */}
          <GuardedRoute path="/add-new-car" render={() => <AddNewCar />} />
          <GuardedRoute path="/my-drifter-card" render={() => <MyDrifterCard />}/>
          <GuardedRoute path="/payment-methods" render={() => <PaymentMethodPage/>}/>
          <GuardedRoute path="/add-payment-method" render={() => <AddNewPaymentMethodPage/>}/>
          <GuardedRoute exact path="/my-business-accounts" render={() => <MyBusinessAccount/>}/>
          <GuardedRoute exact path="/my-site-codes" render={() => <MySiteCodes/>}/>
          <GuardedRoute exact path="/my-site-codes/:id" render={() => <SiteCodeDetail/>}/>
          <GuardedRoute exact path="/my-business-accounts/detail/:businessId" render={() => <BusinessDetail/>}/>
          <GuardedRoute exact path="/edit-profile" render={() => <EditProfile />}/>
          <GuardedRoute exact path="/add-disable-permit" render={() => <AddDisablePermit />}/>
          <GuardedRoute exact path="/tickets" render={() => <Tickets />}/>
          <GuardedRoute exact path="/tickets/:ticket_id" render={() => <TicketDetail />}/>
          <Route path={"*"} render={() => <NotFound />} />
        </Switch>
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default memo(Routes);
