import React, { useRef, useState } from 'react'

import onboarding_park from "../assets/images/vectors/onboarding_park_area.svg";
import onboardingParkCar_last from "../assets/images/car_secondSlide-min.png";
import ParkingLights from "../assets/images/vectors/parking_lights.svg"
import ChargeIcon from "../assets/images/vectors/ChargeIcon.svg";
import {useTranslation} from "react-i18next";


const OnboardingSecondSlide = () => {
  const { t } = useTranslation();
  
  const [animEnd, setAnimEnd] = useState(true)

  const conditionalAnimationRef = useRef();

  if (conditionalAnimationRef.current) {
    conditionalAnimationRef.current.addEventListener("animationend", () => {
      setAnimEnd(false);
    });
  }

  return (
    <>
      <div className="OnboardingSlideContent">
        <div className="OnboardingPark">
          <img
            className="onboardingPark"
            src={onboarding_park}
            alt="Park and charge"
            draggable={false}
          />
          <div className={`onboardingParkChargeIcon ${animEnd && "animate"}`}>
            <img src={ChargeIcon} alt="" />
          </div>
          <div className="OnboardingChargingWave OnboardingChargingWaveAnimation" />
          <div className="OnboardingChargingWaveInner OnboardingChargingWaveAnimationInner" />
          <img
            ref={conditionalAnimationRef}
            className={`onboardingParkCar ${animEnd && "animate"}`}
            src={onboardingParkCar_last}
            alt="Park and charge"
          />
          <img
            className={`onboardingParkLights ${animEnd && "animate"}`}
            src={ParkingLights}
            alt="ParkingLight"
          />
        </div>
      </div>
      <div className="OnboardingSlideBottom">
        <div className="OnboardingTitle">{t("onboarding-second-title")}</div>
        <div
          className="OnboardingDesc"
          dangerouslySetInnerHTML={{
            __html: t("onboarding-second-description"),
          }}
        ></div>
      </div>
    </>
  );
}

export default OnboardingSecondSlide