//Helpers
import { useTranslation } from 'react-i18next';
//Components
import DTInput from '../../DTInput';

const ParkingPrice = ({parkingPrice, setParkingPrice}) => {
    const setPrice = (value) => {
        let newValue = value.charAt(0)===0 ? value.substring(1) : value;
        setParkingPrice(newValue?newValue:0)
    }

    const {t} = useTranslation()
    return (
        <div className="FilterRow">
            <span className="FilterTitle">{t("max-parking-price")}</span>
            <div className="FilterItems">
                <DTInput
                type={"number"}
                value={parkingPrice}
                onChange={e => setPrice(e.detail.value)}
                />
            </div>
        </div>
    );
}

export default ParkingPrice;
