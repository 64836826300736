// Ionic & React
import {IonContent, IonModal, IonPage, useIonRouter,} from "@ionic/react";
import {useEffect, useRef, useState} from "react";

import "./Map.css";

// Redux
import {useDispatch, useSelector} from "react-redux";

// Components
import Toolbar from "../components/Toolbar";
import BottomToolbar from "./../components/BottomToolbar";
import GoogleMapComponent from "../components/GoogleMapComponent";
import {Alert} from "../helper/alert";

// Pages
import SearchModal from "./SearchModal";
import SearchFilterModal from "./SearchFilterModal";
import ManagePermissionsModal from "./ManagePermissionsModal";
import StationDetailModal from "./StationDetailModal";

// Google Maps
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import {LocationAccuracy} from "@awesome-cordova-plugins/location-accuracy";
import {AndroidPermissions} from "@awesome-cordova-plugins/android-permissions";

// Capacitor & Cordova
import {Capacitor} from "@capacitor/core";
import {Geolocation} from "@capacitor/geolocation";
import {Diagnostic} from "@awesome-cordova-plugins/diagnostic";
import {DeviceOrientation} from "@awesome-cordova-plugins/device-orientation";


// Helper
import {api} from "../helper/api";
import {
  clusteringIcons,
  getConfig,
  getConfigFromApi,
  getPerson,
  isModalsOpened,
  markerIcons
} from "../helper/globalFunctions";
import {setMapFilter, setStationFilter} from "../redux/slices/filterStationSlice";
import i18next from "i18next";
import LocationAlertModal from "../components/LocationAlertModal";
import StartChargingModal from "../components/StartChargingModal";
import StartParkingOnlyModal from "../components/StartParkingOnlyModal";
import {setLocations, setRememberedPos} from "../redux/slices/userSlice";
import Notifications from "../components/Notifications";
import Modals from "../components/Modals";
import {setCurrentStation} from "../redux/slices/propSlice";
import {
  setChargeSessionModal,
  setMenuModal,
  setMyCarsModal,
  setMyProfileModal,
  setParkChargeSessionModal,
  setParkSessionModal,
  setStationDetailModal,
} from "../redux/slices/modalSlice";
import { setLoading } from "../redux/slices/globalSlice";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const Map = () => {
  const {t} = useTranslation()
  const { user,rememberedPos } = useSelector((state) => state.userSlice);
  const { mapFilter } = useSelector((state) => state.filterStationSlice);
  const {filterModal } = useSelector((state) => state.modalSlice);
  const [pos, setPos] = useState(rememberedPos ? rememberedPos : {lat: 59.34466854390898, long: 16.264559906928714});

  const prevMarkersRef = useRef([]);
  const clustersRef = useRef([]);

  const [zoom, setZoom] = useState(12);
  const [center, setCenter] = useState({lat: pos.lat, long: pos.long})
  const map = null;
  const maps = null;
  const [directionCoords, setDirectionCoords] = useState({lat: 0, long: 0, type: null});
  const [isGPSAvailable] = useState(false);
  //const [isEmailActivated, setIsEmailActivated] = useState(0);
  const [chargingSpeed, setChargingSped] = useState("");
  const [gpsAsked, setGpsAsked] = useState(false);
  const [isManagePermissionsModalOpen, setManagePermissionsModalOpen] = useState(false);
  const [isEmailToastShown, setEmailToastShown] = useState(false);

  const [isStationsLoading, setStationsLoading] = useState(false)
  const [stations, setStations] = useState([]);

  const [isLocationAlertModalOpen, setLocationAlertModalOpen] = useState(false);

  const [mapFnc, setMapFnc] = useState({});
  const [routeForMapRender, setRouteForMapRender] = useState();

  const [getCoords, setGetCoords] = useState(false);
  const [isLocationExist, setIsLocationExist] = useState(false);
  const [heading, setHeading] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [locationButton, setLocationButton] = useState(false);
  const [parkVipCode, setParkVipCode] = useState("")

  const watchId = useRef(null);
  const mailIntervalRef = useRef(null);
  const [isWatchIdSetted, setIsWatchIdSetted] = useState(false);

  const [isStartSessionModal, setStartSessionModal] = useState(false);

  const [value, setValue] = useState(0);

  const [breakpoint, setBreakpoint] = useState(0.9)

  const [markersInitType, setMarkersInitType] = useState("init")

  const {stationFilter} = useSelector((state) => state.filterStationSlice);
  const {activeSession} = useSelector((state) => state.userSlice);
  const {currentStation} = useSelector((state) => state.propSlice);
  const {stationDetailModal} = useSelector((state) => state.modalSlice);

  const dispatch = useDispatch();
  const router = useIonRouter();

  const goRoute = (path, direction) => router.push(path, direction);

  const watchGps = () => {
    if (!isWatchIdSetted) {
      Geolocation.watchPosition({timeout: 1000}, (pos, err) =>
        !isModalsOpened() && watchPositionCallback(pos, err)
      ).then((id) => {
        if (id) {
          watchId.current = id
          setIsWatchIdSetted(true)
        }
      });
    }
  };

  // Open Modals when the user came back from the menu items
  const history = useHistory()
  const {menuModal, myCarsModal, myProfileModal} = history?.location?.state || {menuModal: false, myCarsModal: false}

  useEffect(() => {
    menuModal && dispatch(setMenuModal(true));
    myCarsModal && dispatch(setMyCarsModal(true));
    myProfileModal && dispatch(setMyProfileModal(true));

    return history.push(history.location.pathname, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuModal, myCarsModal, myProfileModal])

  useEffect(() => {
    if (isEmailToastShown) {
      if (!mailIntervalRef.current && !isModalsOpened()) {
        mailIntervalRef.current = setInterval(() => {
          toggleCheckMail();
        }, 15000);
      }
    } else {
      clearInterval(mailIntervalRef.current);
      mailIntervalRef.current = null;
    }

    return () => {
      mailIntervalRef && clearInterval(mailIntervalRef.current);
      mailIntervalRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailToastShown]);

  useEffect(() => {
    getConfigFromApi()
    checkPermissionLocation();
    Capacitor.isNativePlatform() && subsDeviceOrientation();
    checkGps(1);
    watchGps();
    return () => {
      if (isWatchIdSetted && watchId.current) {
        Geolocation.clearWatch(watchId.current);
        watchId.current = null
        setIsWatchIdSetted(false)
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (routeForMapRender) {
      setRouteForMapRender((prev) => {
        prev.setMap(null);
        prev.setDirections({routes: []});
      });
    }
    return () => {
      // Side-effect cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapFilter]);

  useEffect(() => {
    dispatch(
      setStationFilter({
        is_parking: mapFilter === "MAP_PARKING",
        is_charging: mapFilter === "MAP_CHARGING",
        is_park_and_charge: mapFilter === "MAP_ALL_IN_ONE",
      })
    );
    return () => {
      // Side-effect cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapFilter]);

  /*
  TODO::If this block is required, resolve the bug, if not, remove it

    useEffect(() => {
      if (
        currentStation !== {} &&
        currentStation.TYPE !== "park_and_charge" &&
        currentStation.TYPE !== undefined
      ) {
        dispatch(setStationDetailModal(true))
      }
      return () => {
        // Side-effect cleanup
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStation]);

   */

  useEffect(() => {
    if (
      stations &&
      stations.length > 0 &&
      typeof mapFnc.maps === "object" &&
      getCoords && firstLoad
    ) {
      setMarkers(mapFnc.map, mapFnc.maps)
      setFirstLoad(false);

      if (routeForMapRender) {
        setRouteForMapRender((prev) => {
          prev.setMap(null);
          prev.setDirections({routes: []});
        });
      }
    }
    return () => {
      // Side-effect cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stations, mapFnc.maps]);

  useEffect(() => {
    if (pos.lat && pos.long) {
      dispatch(setRememberedPos(pos))
      !isModalsOpened() &&
      !firstLoad && GetStations({lat: pos.lat, long: pos.long});
    }
    return () => {
      // Side-effect cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pos]);

  useEffect(() => {
    setFirstLoad(true);
    if (isStationsLoading) return;
    GetStations({lat: pos.lat, long: pos.long});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapFilter]);

  useEffect(() => {
    locationButton && handleApiLoaded(mapFnc.map, mapFnc.maps, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center]);


  useEffect(() => {
    // search when map drag end
    if (!!stations?.length && mapFnc.map && mapFnc.maps && !firstLoad) {
      setMarkers(mapFnc.map, mapFnc.maps)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stations])

  const GetActiveSession = async () => {
    await import("../helper/parkChargeFunctions").then(
      ({parkChargeFunctions}) => parkChargeFunctions.getActiveSession(false)
    );
  };

  useEffect(() => {
    if (rememberedPos) {
      setPos(rememberedPos)
      setGetCoords(true)
      !firstLoad && GetStations({lat: rememberedPos.lat, long: rememberedPos.long});
    }
    return () => {
      // Side-effect cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GetActiveSession()

      const activeSessionInterval = setInterval(() => {
        GetActiveSession()
      }, 20000)
      return () => {
        clearInterval(activeSessionInterval)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (user) {
      setEmailToastShown(user.email_verify === 0);
    }
    return () => {
      // Side-effect cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, i18next.language]);

  useEffect(() => {
    if (!firstLoad && !filterModal.status) {
      filterStation();
    }
    return () => {
      // Side-effect cleanup
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModal]);

  useEffect(() => {
    if (mapFnc && mapFnc.map) {
      setZoom(mapFnc.map.getZoom())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapFnc.map])

  useEffect(() => {

    if(!bottomToolbarRef.current.swiper.slideTo){
      return;
    }

    /*
    switch (activeSession.type) {
      case "park_charge":
        setFilterMenu("AIO");
        break;
      case "park":
        setFilterMenu("P");
        break;
      case "charge":
        setFilterMenu("C");
        break;
      default:
        break;
    }
     */


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSession])

  const mapPageRef = useRef(null);
  const bottomToolbarRef = useRef(null);

  // GET STATIONS
  const GetStations = async (data) => {
    if (isLocationExist || rememberedPos) {
      setStationsLoading(true)
      await api
      .getParkPoints({
        options: {
          parameters: {
            lat: data.lat,
            lon: data.long,
          },
          limit: 10,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setStations(response.data.data);
        } else {
          setStations([])
          clearMap()
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setStationsLoading(false))
    }
  };

  const filterStation = () => {
    api
      .getParkPoints({
        options: {
          parameters: {
            lat: pos.lat,
            lon: pos.long,
            is_parking: true,
            is_charging: false,
            is_park_and_charge: false,
            indoor: stationFilter.indoor,
            outdoor: stationFilter.outdoor,
            public: stationFilter.publicFilter,
            private: stationFilter.privateFilter,
            min_rate: stationFilter.min_rate ? stationFilter.min_rate : 0,
            parking_max_price: stationFilter.parking_max_price,
            park_operator: stationFilter.park_operator,
            keyword: stationFilter.keyword,
          },
        },
      })
      .then((response) => {
        setStations(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  const getMapBounds = (map, maps, stations) => {
    const bounds = new maps.LatLngBounds();
    stations
    .map(station => bounds.extend({lat: parseFloat(station.lat), lng: parseFloat(station.lon)}))
    bounds
    .extend({lat: parseFloat(pos.lat), lng: parseFloat(pos.long)});
    return bounds;
  };

  const askToTurnOnGPS = () => {
    LocationAccuracy.request(
      LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY
    ).then(() => {
      setGpsAsked(false);
      // When GPS Turned ON call method to get Accurate location coordinates
    });
  };

  const checkGps = () => {
    let successCallback = (isAvailable) => {
      if (isAvailable === false) {
        AndroidPermissions.checkPermission(
          AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION
        ).then((result) => {
          if (result.hasPermission && gpsAsked === false) {
            setGpsAsked(true);
            askToTurnOnGPS();
            setGetCoords(false);
          }
        });
      } else {
        // setLocationAlertModalOpen(false)
      }
    };
    let errorCallback = (err) => console.log(err);

    if (Capacitor.isNativePlatform()) {
      Diagnostic.isLocationAvailable().then(successCallback).catch(errorCallback);
    }
  };


  const apiIsLoaded = (map) => {
    const directionsService = new window.google.maps.DirectionsService();

    const directionsRenderer = new window.google.maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor:
          directionCoords.type === "parking"
            ? "#5e53e1"
            : directionCoords.type === "station"
              ? "#34d298"
              : "#B11D94",
        strokeWeight: 3,
      },
      suppressMarkers: true,
    });

    directionsRenderer.setMap(null);
    directionsRenderer.setMap(map);

    const origin = {lat: pos.lat, lng: pos.long};
    const destination = {lat: directionCoords.lat, lng: directionCoords.long};

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          directionsRenderer.setDirections(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const startDirections = (lat, long, type) => {
    dispatch(setStationDetailModal(false))
    setDirectionCoords({lat: Number(lat), long: Number(long), type});
    apiIsLoaded(map, maps);
  };

  const setFilterMenu = (filter) => {
    if (bottomToolbarRef.current.swiper.update) {
      bottomToolbarRef.current.swiper.update();
    }

    switch (filter) {
      case "P":
      case 0:
        bottomToolbarRef.current.swiper.slideTo(0);
        dispatch(setMapFilter("MAP_PARKING"));
        break;
      case "AIO":
      case 1:
        bottomToolbarRef.current.swiper.slideTo(1);
        dispatch(setMapFilter("MAP_ALL_IN_ONE"));
        break;
      case "C":
      case 2:
        bottomToolbarRef.current.swiper.slideTo(2);
        dispatch(setMapFilter("MAP_CHARGING"));
        break;
      default:
        break;
    }
  };

  const GetStationDetail = async (station) => {
    dispatch(setLoading({status: true, message: ""})); // show loading component

    try {
      const response = station.TYPE === "parking"
        ? await api.getParkingStationDetail({
          options: {
            parameters: {
              park_id: station.entry_id, // set park_id parameter
            },
            paginate: false, // disable pagination
          },
        })
        : await api.getStationDetail({
          options: {
            parameters: {
              station_id: station.entry_id, // set station_id parameter
            },
            paginate: false, // disable pagination
          },
        });

      dispatch(setCurrentStation({
        ...station,
        DETAIL: {
          ...response.data.data[0], // set station detail
        },
      }));
      dispatch(setStationDetailModal(true)); // show station detail modal
    } catch (error) {
      Alert.error(t("try-again"))
    } finally {
      dispatch(setLoading({status: false, message: ""})); // hide loading component
    }
  };

  const clearMap = () => {
    // Get previous markers and clusters from their refs
    const prevMarkers = prevMarkersRef.current;
    const clusters = clustersRef.current;

    // Clear previous markers and clusters from the map
    prevMarkers?.forEach(marker => marker.setPosition(null));
    clusters?.forEach(cluster => cluster.setMap(null));
  };

  const setMarkers = (map, maps) => {
    // Clear previous markers from the map
    clearMap();

    // Get previous markers and clusters from their refs
    const prevMarkers = prevMarkersRef.current;
    const clusters = clustersRef.current;

    // Create an array of marker information for each station
    const markerInfo = stations?.map(station => ({
      location: {lat: parseFloat(station.lat), lng: parseFloat(station.lon)},
      station
    }));

    // Create marker options for each marker
    const markerOptions = (position, map, icon) => ({
      position,
      map,
      icon: `data:image/svg+xml;base64,${icon}`
    });

    // Create markers and add click event listener to each marker
    const markers = markerInfo?.map(marker => {
      const markerObj = new maps.Marker(
        markerOptions(
          marker.location,
          map,
          markerIcons(marker.station.TYPE)
        )
      );
      markerObj.addListener("click", () => GetStationDetail(marker.station));
      prevMarkers.push(markerObj);
      return markerObj;
    });

    // Create a renderer for the marker clusters
    const renderer = {
      render: ({count, position}) =>
        new maps.Marker({
          icon: {
            url: `data:image/svg+xml;base64,${clusteringIcons(
              count,
              mapFilter
            )}`,
            scaledSize: maps.Size(45, 45)
          },
          position
        })
    };

    // Create a marker clusterer and add it to the clusters ref
    const cluster = new MarkerClusterer({markers, map, renderer});
    clusters.push(cluster);

    // Call handleApiLoaded function if markersInitType is "init"
    if (markersInitType === "init") {
      handleApiLoaded(mapFnc.map, mapFnc.maps);
    }
  };

  const handleApiLoaded = (map, maps, centered = false) => {
    // Save references to the map and maps objects
    setMapFnc({map, maps});

    // If centered flag is true, center the map at the specified position
    if (centered) {
      map.setCenter({lat: pos.lat, lng: pos.long}); // using object literal shorthand notation
    } else {
      // If there are stations, fit the map to the bounds of all the stations and center it at the specified position
      if (stations && stations.length !== 0) {
        const bounds = getMapBounds(map, maps, stations); // get the bounds of all the stations
        map.fitBounds(bounds); // fit the map to the bounds
        map.setCenter({lat: pos.lat, lng: pos.long}); // using object literal shorthand notation
      }
    }
  };

  const subsDeviceOrientation = async () => {
    const watchHeading = await DeviceOrientation.watchHeading({frequency: 2000});
    return watchHeading.subscribe(({
      next: (data) => !isModalsOpened() && setHeading(data.trueHeading),
      error: (e) => console.error(e),
    }));
  };

  const toggleCheckMail = (redirect = false) => {
    getPerson();
    if (redirect) {
      dispatch(setMyProfileModal(true))
    }
  };


  const watchPositionCallback = (position) => {
    if (position) {
      setIsLocationExist(true);

      setPos({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
      setGetCoords(true);

      dispatch(setLocations({latitude: position.coords.latitude, longitude: position.coords.longitude}))
    }
    // checkPermissions()
  };

  const checkPermissionLocation = async () => {
    const permissionIsSet = await Geolocation.checkPermissions();
    if (permissionIsSet.location !== "granted") {
      setGetCoords(false);
    }
  };

  const searchThisArea = (dragCoords) => {
    GetStations(dragCoords).then(() => {
      setMarkersInitType("after_init")
    })
  }

  const openSessionModal = status => {
    if (!status) return;
    switch (currentStation.TYPE) {
      case "station":
        dispatch(setChargeSessionModal(status))
        break;
      case "parking":
        dispatch(setParkSessionModal(status))
        break;
      case "park_and_charge":
        dispatch(setParkChargeSessionModal(status))
        break;
      default:
        break;
    }

  }

  return pos.lat ? (
    <IonPage className="MapView" ref={mapPageRef}>
      <Toolbar
        center={center}
        setCenter={setCenter}
        pos={pos}
        stations={stations}
        setCurrentStation={station => dispatch(setCurrentStation(station))}
        setStationDetailOpen={status => dispatch(setStationDetailModal(status))}
        setChargingModal={status => dispatch(setChargeSessionModal(status))}
        setParkChargeModalOpen={status => dispatch(setParkChargeSessionModal(status))}
        setPos={setPos}
        setZoom={setZoom}
        isGPSAvailable={isGPSAvailable}
        firstName={user.first_name}
        getCoords={getCoords}
        setGetCoords={setGetCoords}
        setLocationButton={setLocationButton}
      />

      <Notifications
        isEmailToastShown={isEmailToastShown}
        setEmailToastShown={setEmailToastShown}
        toggleCheckMail={toggleCheckMail}
        isLocationAlertModalOpen={isLocationAlertModalOpen}
        setLocationAlertModalOpen={setLocationAlertModalOpen}
        switchLocationSettings={() => Diagnostic.switchToLocationSettings()}
      />

      <IonContent scrollY={false} className="no-padding">
        <GoogleMapComponent
          center={center}
          zoom={zoom}
          setZoom={setZoom}
          getConfig={getConfig}
          searchThisArea={searchThisArea}
          handleApiLoaded={handleApiLoaded}
          getCoords={getCoords}
          pos={pos}
          heading={heading}
        />
      </IonContent>

      {/*
         TODO::This place should be removed from the comment after it is made dynamic
         <AveragePriceButton mapFilter={mapFilter} />
      */}

      <BottomToolbar
        bottomToolbarRef={bottomToolbarRef}
        setFilterMenu={setFilterMenu}
        setMarkersInitType={setMarkersInitType}
        stationsLoading={isStationsLoading}
      />

      <StationDetailModal
        startDirections={startDirections}
        isStationDetailOpen={stationDetailModal.status}
        setStationDetailOpen={status => dispatch(setStationDetailModal(status))}
        setStartSessionModal={setStartSessionModal}
        station={currentStation}
        goRoute={goRoute}
        parkVipCode={parkVipCode}
        setParkVipCode={setParkVipCode}
        openSessionModal={openSessionModal}
      />

      <IonModal
        isOpen={isStartSessionModal}
        onDidDismiss={() => setStartSessionModal(false)}
        breakpoints={[0, 0.9, 1]}
        canDismiss={true}
        initialBreakpoint={0.9}
        onIonBreakpointDidChange={(e) => setBreakpoint(e.detail.breakpoint)}
        onIonModalWillPresent={() => {
          setBreakpoint(0.9)
        }}
      >
        {currentStation.TYPE === "parking" ? (
          <StartParkingOnlyModal
            isOpen={isStartSessionModal}
            station={currentStation}
            handleOpenModal={status => setStartSessionModal(status)}
            value={value}
            handleValue={setValue}
            parkVipCode={parkVipCode}
            breakpoint={breakpoint}
          />
        ) : (
          <StartChargingModal
            station={currentStation}
            closeModal={() => setStartSessionModal(false)}
            openSessionModal={status => openSessionModal(status)}
            value={value}
            setValue={setValue}
            isOpen={isStartSessionModal}
            breakpoint={breakpoint}
          />
        )}
      </IonModal>
      <SearchModal
        pos={pos}
        stations={stations}
        currentStation={currentStation}
        setCurrentStation={station => dispatch(setCurrentStation(station))}
        chargingSpeed={chargingSpeed}
        setStationDetailOpen={status => dispatch(setStationDetailModal(status))}
        setFilterMenu={setFilterMenu}
        bottomToolbarRef={bottomToolbarRef}
      />
      <SearchFilterModal
        setChargingSped={setChargingSped}
        setFilterMenu={setFilterMenu}
        bottomToolbarRef={bottomToolbarRef}
      />

      <ManagePermissionsModal
        isManagePermissionsModalOpen={isManagePermissionsModalOpen}
        setManagePermissionsModalOpen={setManagePermissionsModalOpen}
      />

      <LocationAlertModal
        isLocationAlertModalOpen={isLocationAlertModalOpen}
        setLocationAlertModalOpen={setLocationAlertModalOpen}
      />

      <Modals />

    </IonPage>
  ) : (
    ""
  );
};

export default Map;

