//Ionic & React
import { IonContent, IonModal } from "@ionic/react";
import { useEffect } from "react";
//Helpers
import { api } from "../../helper/api";
//Components
import PageToolbar from "../PageToolbar";
import { useTranslation } from "react-i18next";
import './styles/AboutApp.scss'
import { Alert } from "../../helper/alert";
const AboutApp = ({
    isOpen, setOpen
}) => {
  const {t} = useTranslation()
  useEffect(() => {
    if(isOpen){
      getContent()
    }
  },[isOpen])
  const getContent = () => {
    api.getPages("about-app").then(({data}) => {
      if(data.success && data.response.content){
        document.getElementById("about-app-content").innerHTML = data.response.content;
      }else{
        Alert.error("please-try-again")
      }
    });
  }
  return (
    <IonModal
      isOpen={isOpen}
      canDismiss={true}
      className="modal-white-background"
      onDidDismiss={() => setOpen(false)}
    >
      <PageToolbar
        title={t("about-app")}
        leftButtonType="back"
        onClick={() => setOpen(false)}
        backgroundColor="#fff"
      />
      <IonContent>
        <div className="Wrapper">
          <div id="about-app-content"></div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default AboutApp;
