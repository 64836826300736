// Ionic & React
import { Component } from "react";
import { IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import { chevronBackOutline } from "ionicons/icons";
import { safeAreaTop } from "../assets/theme/ios-safe-area";
// Plugins
import styled from "styled-components";
// Components
import Text from "./Text";
import Button from "./Button";
import BackButton from "./BackButton";

export default class PageToolbar extends Component {
  render() {
    const {
      title,
      titleLeft,
      titleRight,
      titleColor,
      leftButton,
      leftButtonType,
      leftButtonIcon,
      leftButtonIconSize,
      leftButtonColor,
      leftButtonOnClick,
      rightButton,
      rightButtonType,
      rightButtonIcon,
      rightButtonIconSize,
      rightButtonColor,
      rightButtonOnClick,
      whiteIcon,
      backgroundColor,
      rightBadge,
      rightBadgeText,
      rightBadgeBackgroundColor,
      rightBadgeTextColor,
      rightBadgeTextSize,
      rightBadgeTextWeight,
      iosSafeArea = true,
      ...props
    } = this.props;

    const DTHeader = styled(IonHeader)`
      ${iosSafeArea && safeAreaTop}

      &,
      * {
        user-select: none;
      }

      --box-shadow: none;
      --background: ${backgroundColor ? backgroundColor : "transparent"};
      --color: var(--dt-primary);

      display: flex;
      align-items: center;
      height: 55px;
      padding: 0 0;
      background: var(--background);

      &::after {
        content: none;
      }

      .Text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        ${titleLeft ? `left: 0; transform: translate(0, -50%);` : ``}
        ${titleRight
          ? `left: unset; right: 0; transform: translate(0, -50%);`
          : ``};
        display: flex;
        max-width: calc(100% - 120px);
      }
      .Text * {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .PageToolbarRightBackButton {
        min-height: unset;
        min-width: unset;
        position: absolute;
        right: 0;
      }

      .PageToolbarRightButton {
        position: absolute;
        right: 20px;
      }
      .PageToolbarRightBadge {
        background-color: ${rightBadgeBackgroundColor};
        color: ${rightBadgeTextColor};
        border-radius: 100%;
        width: 22px;
        height: 22px;
        font-size: ${rightBadgeTextSize ? rightBadgeTextSize : "12px"};
        font-weight: ${rightBadgeTextWeight ? rightBadgeTextWeight : "700"};
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        right: 50px;
      }
    `;

    const Badge = () => {
      return (
        <span
          className={`PageToolbarRightBadge ${
            rightBadgeText > 0 ? "flex" : "hidden"
          }`}
        >
          {rightBadgeText}
        </span>
      );
    };

    return (
      <DTHeader className="DTHeader">
        <IonToolbar
          class="ion-no-padding"
          style={{
            "--background": "transparent",
            "--border-width": 0,
            "--padding-start": 0,
            "--padding-end": 0,
            "--padding-top": 0,
            "--padding-bottom": 0,
            padding: 0,
          }}
        >
          <IonButtons slot="start">
            {leftButton || leftButtonType ? (
              leftButtonType === "back" ? (
                <BackButton
                  icon={chevronBackOutline}
                  text=""
                  className={`PageToolbarLeftBackButton${
                    whiteIcon ? " whiteIcon" : ""
                  }`}
                  onClick={leftButtonOnClick}
                  {...props}
                />
              ) : (
                <Button
                  cssClass="PageToolbarLeftButton"
                  theme={leftButtonType}
                  icon={leftButtonIcon}
                  iconLeft
                  iconColor={leftButtonColor}
                  iconSize={leftButtonIconSize}
                  onClick={leftButtonOnClick}
                  {...props}
                >
                  {leftButton}
                </Button>
              )
            ) : (
              ""
            )}
          </IonButtons>
          <Text
            size={17}
            weight={600}
            wrapWithDots={true}
            width={300}
            color={`${titleColor ? titleColor : "var(--dt-primary)"}`}
          >
            {title}
          </Text>
          <IonButtons slot="end">
            {rightButton || rightButtonType ? (
              rightButtonType === "back" ? (
                <BackButton
                  icon={chevronBackOutline}
                  text=""
                  closeIcon={true}
                  className={`PageToolbarRightBackButton${
                    whiteIcon ? " whiteIcon" : ""
                  }`}
                  onClick={rightButtonOnClick}
                  {...props}
                />
              ) : (
                <>
                  {rightBadge || rightBadgeText ? <Badge /> : ""}
                  <Button
                    cssClass="PageToolbarRightButton"
                    theme={rightButtonType}
                    icon={rightButtonIcon}
                    iconLeft
                    iconColor={rightButtonColor}
                    iconSize={rightButtonIconSize}
                    onClick={rightButtonOnClick}
                    {...props}
                  >
                    {rightButton}
                  </Button>
                </>
              )
            ) : (
              ""
            )}
          </IonButtons>
        </IonToolbar>
      </DTHeader>
    );
  }
}
