//Components
import ParkIcon from "../../../assets/icons/pink-park-icon.svg"

//Translate
import { useTranslation } from "react-i18next"

const ParkingCost = ({ prices, isContracted }) => {

    const Title = () => {
        return (
            <div className="text-primary hide-on-shrink">
                <h4 className="font-semibold mb-1">{t("pricing")}</h4>
            </div>  
        )
    }
    const { t } = useTranslation()

    if(prices.length !== 0){
        return (
            <>
                <Title />
                <div className={"w-[calc(100vw-32px)] flex justify-center  mt-[22px] mb-[40px] StationDetailProgressBar_park overflow-x-auto"}>
                    <div className="flex flex-row justify-start w-full">
                    { prices &&
                        prices.map((d, index) => (
                            <div key={index} className={"StationDetailProgressBar_park_progress_container flex-1"}>
                                <div className={"text-center StationDetailProgressBar_park_progress"}>
                                    <p className="text-[14px] font-semibold text-primary">
                                    {d.name}
                                    </p>
                                    <div className={`h-[12px] w-full StationDetailProgressBar_park_progress-line`} style={{"backgroundColor":d.color}}></div>
                                    <p className="text-[11px] font-semibold text-primary">
                                    {d.price_label}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </>
        )
    }else{
        return (
            <>
                <Title />
                <div className="hide-on-shrink mt-[22px] text-primary flex justify-between items-center mb-[40px] w-full">
                    <div>
                        <p className="text-[16px] font-medium flex space-x-2">
                        <img
                            src={ParkIcon}
                            className="w-[30px] h-[24px]"
                            alt=""
                        />
                        {t("parking-cost")}
                        </p>
                    </div>
                    <div>
                    {isContracted ? <p className="text-[22px] font-bold">{t("free")}</p>:<p className="text-[14px] font-bold">{t("no-price-information")}</p>}
                    </div>
                </div>
            </>
        )
    }
}

export default ParkingCost;