import { IonSlides, IonSlide } from "@ionic/react";

import Text from "../../Text";

import { useTranslation } from "react-i18next";

const ParkPhotos = ({ images, setImageModalOpen, setChoosenImageUrl }) => {

    const { t } = useTranslation()

    return (
        <div className="mt-[31px] text-primary hide-on-shrink">
            <Text weight={600}>{t("photos")}</Text>
            <div className="mt-[18px]">
                <IonSlides
                className="VenuePhotosSlider"
                options={{ spaceBetween: 12, width: 84 }}
                >
                {
                    images.map((d, index) => {
                        return (
                            <IonSlide key={index}>
                                <div className=" w-[84px] h-[84px] " onClick={() => {
                                    setImageModalOpen(true)
                                    setChoosenImageUrl(d.toString())
                                }}>
                                    <img className="rounded-xl object-cover !h-[84px] cursor-pointer" src={d} alt="" />
                                </div>
                            </IonSlide>
                        )})
                }
                </IonSlides>
            </div>
        </div>
    );
}

export default ParkPhotos