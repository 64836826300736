import { IonContent, IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { api } from '../../helper/api';
import PageToolbar from '../PageToolbar';
import Text from '../Text';
import { Alert } from '../../helper/alert';
import { useDispatch } from 'react-redux';
import { setAddSiteCodeModal } from '../../redux/slices/modalSlice';

const SiteCodeDetailBeforeCodeRegister = ({
  isOpen,
  setOpen,
  siteCodeDetail,
  openAgreement,
  setRefreshCodes,
  companyCode
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const confirmCode = (code) => {

    api.assignCode(code, companyCode).then(res => {

      if(!res.data.success) {
        Alert.error(res.data.message);
        return;
      }
      Alert.success(res.data.message)
      setRefreshCodes(true)
      dispatch(setAddSiteCodeModal({status:false,back:true}))
      setOpen(false)

    }).catch(err => console.log(err.message))
  }
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setOpen(false)}>
      <PageToolbar
        title={t("site-code-detail")}
        leftButtonType="back"
        onClick={() => setOpen(false)}
        backgroundColor="#fff"
      />
      <IonContent scrollY="false">
        <div className="SiteCodeContent flex justify-start flex-col px-2 overflow-y-auto h-full">
          <div>
            <div className="divider mb-[10px]" />
            <div className="flex w-full justify-between flex-row items-center px-3">
              <Text size={18} weight={600}>
                {t("address")}:
              </Text>
              <Text
                size={16}
                weight={400}
                align="right"
                wrapWithDots={true}
                otherStyles={"width: 200px !important;"}
              >
                {siteCodeDetail?.detail?.parking?.address ?? ""}
              </Text>
            </div>
            <div className="flex w-full justify-between flex-row items-center px-3">
              <Text size={18} weight={600}>
                {t("zonecode")}:
              </Text>
              <Text size={16} weight={400}>
                {siteCodeDetail?.detail?.parking?.zone_code ?? ""}
              </Text>
            </div>
            <div className="divider my-[10px]" />
            <div
              style={{
                marginTop: 10,
                paddingBottom: 20,
                paddingLeft: 12,
                paddingRight: 12,
              }}
            >
              <Text size={18} weight={600}>
                {t("duration-time")}:
              </Text>
              <table className="ValidDurationTable">
                <thead>
                  <tr>
                    <th>{t("day")}</th>
                    <th>{t("from")}</th>
                    <th>{t("to")}</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    siteCodeDetail.detail?.durations && Object.keys(siteCodeDetail.detail.durations).map(function(key, index) {
                      const el = siteCodeDetail?.detail?.durations[key];

                      return (
                        <tr key={el.name}>
                          <td>{el.name}</td>
                          <td>{el.durations[0].from}</td>
                          <td>{el.durations[0].to}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className="pb-[20px]">
            <div className="flex w-full p-3  mb-[20px] justify-center items-center bg-purple">
              <Text
                size={16}
                weight={600}
                color="white"
                onClick={() => openAgreement(true)}
                otherStyles="text-decoration: underline;"
              >
                {t("digital-permit-parking-code-agreement")}
              </Text>
            </div>
            <button className="DTButton" onClick={() => confirmCode(siteCodeDetail.code_string)}>
              {t("confirm")}
            </button>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default SiteCodeDetailBeforeCodeRegister;
