import {IonModal} from "@ionic/react";
import ParkChargeReceipt from "./ParkChargeReceipt";
import ChargeReceipt from "./ChargeReceipt";
import ParkReceipt from "./ParkReceipt";

const Receipts = ({receipt, setReceipt}) => {
  return (
    <IonModal isOpen={!!receipt} onDidDismiss={() => setReceipt(null)} className="RECEIPTS">
      {receipt?.type === 'park_and_charge' && (
        <ParkChargeReceipt parkChargeReceipt={receipt} close={() => setReceipt(null)}/>
      )}

      {receipt?.type === 'park' && (
        <ParkReceipt receipt={receipt} close={() => setReceipt(null)}/>
      )}

      {receipt?.type === 'charge' && (
        <ChargeReceipt chargingReceipt={receipt} close={() => setReceipt(null)}/>
      )}
    </IonModal>
  )
}

export default Receipts;
