import React, { useRef, useState, useEffect } from 'react'
import RangeIcon from '../assets/images/vectors/range-icon.svg'
import { createGesture } from '@ionic/react';

export const SwipeButton = ({ type='swipeRight', onEnding, changeValue='', value, isReset=false, setReset }) => {

    const icon = useRef();

    const [width,setWidth]=useState(0);
    const [gestureInstance, setGestureInstance] = useState(null);
    useEffect(() => {
        if(type){
            startGesture()
        }else{
            gestureInstance && gestureInstance.enabled(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type])

    useEffect(() => {
        if(isReset){
            setWidth(0)
            setReset(false)
            icon.current.dataset.open = undefined
            icon.current.style.transform = `translateX(${type === 'swipeRight' ? 0 : 250}px)`;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReset])

    const startGesture = () => {
        let i = icon.current;
        const gestureOptions = {
            el: i,
            direction: 'x',
            gestureName: 'square-drag',
            gesturePriority: 9999999,
            disableScroll: true,
            onMove: event => onMove(event),
            onEnd: event => onEnd(event)
        }

        const onMove = (event) => {
            if(type === 'swipeRight'){
                if (event.deltaX > 250 || event.deltaX < 0) return;
                if(event.deltaX === 0){
                    i.style.transform = "translateX(0px)";
                    i.dataset.open = "false";
                    return;
                }
                i.style.transform = `translateX(${event.deltaX}px)`;
                if(changeValue!==''){
                    changeValue(event.deltaX)
                }
            }else if(type === 'swipeLeft'){
                if(event.deltaX < -250 || event.deltaX>0) return;
                if(event.deltaX === 0){
                    i.style.transform = "translateX(250px)";
                    i.dataset.open = "false";
                    return;
                }
                if (event.deltaX <= 0) {
                    setWidth(55-event.deltaX)
                }

                i.style.transform = `translateX(${250+event.deltaX}px)`;
                if(changeValue!==''){
                    changeValue(`${250+event.deltaX}`)
                }
            }
        }

        const onEnd = (event) => {
            if (event.deltaX>=-250) {
                setWidth(0)
            }
            if(type === 'swipeRight'){
                if(event.deltaX < 250){
                    i.style.transform = `translateX(0px)`;
                    event.deltaX = 0;
                    if(changeValue!==''){
                        changeValue(0)
                    }
                }
                i.style.transition = ".5 ease-out";
                if(event.deltaX >= 250 && i.dataset.open !== 'true'){
                    i.style.transform = `translateX(${250}px)`;
                    i.dataset.open = "true";
                    onEnding();
                }
            }else if(type === 'swipeLeft'){
                if(event.deltaX > -250){
                    i.style.transform = `translateX(250px)`;
                    event.deltaX = 0;
                    if(changeValue!==''){
                        changeValue(250)
                    }
                }
                i.style.transition = ".5 ease-out";
                if (event.deltaX <= -250){
                    if (i.dataset.open !== 'true') {
                        i.dataset.open = "true";
                    }
                    i.style.transform = `translateX(0px)`;
                    onEnding();
                }
            }
        }

        const gesture = createGesture(gestureOptions);
        setGestureInstance(gesture)
        gesture.enable(true);
    }
  return (
    <>
        <div className={ `${type === 'swipeRight' ? 'bg-[#5D5B77]' : 'bg-[purple]'} relative w-[303px] h-[55px] flex items-center rounded-full`}>
            <div id="icon" className='z-[99999]' ref={icon} style={{transform: type === 'swipeLeft' ? 'translateX(250px)' : 'translateX(0)'}}>
                <img src={RangeIcon}  alt=''/>
            </div>
            <div className={`slideBackgroundLeft`} style={{width:width}}></div>

            {
                type === 'swipeRight' ? (
                  <div className="w-full flex absolute justify-start items-start z-10" style={{height: "inherit"}}>
                      <div className="zip">
                          <span className={`m_scroll_arrows backward one`}></span>
                          <span className={`m_scroll_arrows backward two`}></span>
                          <span className={`m_scroll_arrows backward three`}></span>
                          <span className={`m_scroll_arrows backward four`}></span>
                          <span className={`m_scroll_arrows backward five`}></span>
                          <span className={`m_scroll_arrows backward six`}></span>
                          <span className={`m_scroll_arrows backward seven`}></span>
                      </div>
                      <div
                        style={{'width': ` ${(value <= 55 ? 55 : value + 55)}px`}}
                        className="slideBackground h-full"
                      ></div>
                  </div>
                ) : (
                    <div className="w-full flex absolute justify-start items-start z-10" style={{height: "inherit"}}>
                        <div className="zip ">
                            <span className={`m_scroll_arrows seven`}></span>
                            <span className={`m_scroll_arrows six`}></span>
                            <span className={`m_scroll_arrows five`}></span>
                            <span className={`m_scroll_arrows four`}></span>
                            <span className={`m_scroll_arrows three`}></span>
                            <span className={`m_scroll_arrows two`}></span>
                            <span className={`m_scroll_arrows one`}  style={{'marginRight' : '60px'}}></span>
                        </div>
                        <div className={`slideBackgroundLeft h-full w-[${value}px]`}></div>
                    </div>
                )
            }
        </div>
    </>
  )
}
