import {useEffect, useState} from "react";
import {Capacitor} from "@capacitor/core";
import {ManagePermissionList} from "./index";
import {useTranslation} from "react-i18next";
import {checkPermissionsByDevice, togglePermission} from "../../helper/permissionFunctions";
import {AndroidPermissions} from "@awesome-cordova-plugins/android-permissions";
import {useDispatch} from "react-redux";
import {setLoading} from "../../redux/slices/globalSlice";
import {OpenNativeSettings} from "@awesome-cordova-plugins/open-native-settings";
import {App} from '@capacitor/app';
import {Alert} from "../../helper/alert";

const ManagePermissions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [permissions, setPermissions] = useState([
    { name: "location", status: false, isNative: false, android: AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION, web: 'geolocation', ios: 'location' },
    { name: "camera", status: false, isNative: false, android: AndroidPermissions.PERMISSION.CAMERA, web: 'camera', ios: 'camera' },
    { name: "notifications", status: false, isNative: false, android: AndroidPermissions.PERMISSION.POST_NOTIFICATIONS, web: 'notifications', ios: 'notifications' }
  ]);
  const handleLoading = (status, message) => {
    dispatch(setLoading({status: status, message: message}));
  }

  const checkPermissions = () => {
    !Capacitor.isNativePlatform() && checkPermissionsByDevice.web(permissions, setPermissions);
    Capacitor.getPlatform()==="android" && checkPermissionsByDevice.android(permissions, setPermissions);
    Capacitor.getPlatform()==="ios" && checkPermissionsByDevice.ios(permissions, setPermissions)
  }

  const handleToggle = (e) => {
    handleLoading(true, `${t("checking-permissions")}...`);
    const {id} = e.target;
    const permission = permissions.find(value => value.name === id);

    if (!Capacitor.isNativePlatform()) {
      togglePermission.web(permission.web)
          .then(() => alert(t('permissions-from-setting')))
          .finally(() => handleLoading(false, ''));
    } else {
      OpenNativeSettings
          .open('application_details')
          .finally(() => handleLoading(false, ''));
    }
  }

  useEffect(() => {
    checkPermissions();

    App.addListener('appStateChange', ({isActive}) => {
      isActive && checkPermissions();
    });

    return () => {
      App.removeAllListeners().catch(err => Alert.error(err));
    }
  }, []);

  return (
    <div className="FilterWrapper" style={{ padding: "0" }}>
      <div className="FilterRow">
        <span className="FilterTitle -secondary">{t("general")}</span>
        {permissions.map(({name, status}, i) => {
            return (
              <ManagePermissionList key={i} name={name} status={status} onChange={handleToggle}/>
            )
          }
        )}
      </div>
    </div>
  )
}

export default ManagePermissions;
