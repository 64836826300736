import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import PageToolbar from '../components/PageToolbar';
import {SetAddress, SetProfile} from '../components/SetupProfile';

const EditProfile = () => {
    const history = useHistory()
    const {t} = useTranslation()
    const [addresses, setAddresses] = useState([])
    const [page, setPage] = useState("")

    useEffect(() => {
        if (history.location.state && history.location.state.from) {
            setPage(history.location.state.from.toString())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Content = () => {
        if (page === "profile") {
            return (
                <SetAddress
                    addresses={addresses}
                    search={false}
                    editable={true}
                    onSubmit={() => {
                        history.push("/map", {menuModal: true, myProfileModal: true});
                    }}
                />
            )
        }

        return (
            <SetProfile
                isEdit={true}
                setAddresses={setAddresses}
                submitClick={() => setPage("address")}
            />
        )
    }

    return (
        <>
            <PageToolbar
                title={t("set-up-profile")}
                leftButtonType="back"
                onClick={() => {
                    history.push('/map', {menuModal: true, myProfileModal: page === "profile"})
                }}
                backgroundColor="#fff"
            />

            <Content/>
        </>
    )
}

export default EditProfile;
