// Ionic & React
import { useState, useEffect } from "react"
import {
  IonContent,
  IonItem,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonLoading,
} from "@ionic/react"

// Components
import PageToolbar from "../components/PageToolbar"
import Text from "../components/Text"

//Hooks
import { useTranslation } from "react-i18next"

// Helpers
import Api from "../helper/api"
import {Alert} from "../helper/alert";
import { useHistory } from "react-router"

const Inbox = () => {
  const [todayMessages, setTodayMessages] = useState([])
  const [pastMessages, setPastMessages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const today = new Date()
  const history = useHistory()
  const {t} = useTranslation()
  const api = new Api()

  const markAsRead = async () => {
    setIsLoading(true)
    await api.markMessagesAsRead().finally(()=> {
      //setUnreadMessage(false)
      setIsLoading(false)
    });
  }

  const formatDate = d => {
    let date = new Date(d)
    if (today.toLocaleDateString() === date.toLocaleDateString()) {
      return t("today");
    }else if(today.getDate() - date.getDate() === 1){
      t("yesterday");
    }else{
      return date.toLocaleDateString()
    }
  }

  const getMessages = async () => {
    setIsLoading(true)
    await api
      .getInbox()
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            setTodayMessages(
              response.data?.data?.filter((message) => {
                return formatDate(message.created_at) === "Today";
              })
            );
            setPastMessages(
              response.data?.data?.filter((message) => {
                return formatDate(message.created_at) !== "Today";
              })
            );
          }
        }
      ).finally(()=> setIsLoading(false));
  }

  const removeMessage = (id) => {
    setIsLoading(true)
    api.deleteMessage({
      "options": {
        "parameters": {
          "message_id": id
        }
      }
    }).then(() => getMessages()).finally(() => {
      setIsLoading(false)
      Alert.success(t("message_deleted"))
    })
  }


  useEffect(() => {
    getMessages()
    markAsRead()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMargin = (where) => {
    if(where === "pastMessages" && todayMessages?.length > 0){
      return 30
    }else{
      return 0;
    }
  }


  return (
   <IonContent scrollY="true" className="no-padding">
      <div className={`InboxModal modal-white-background${
        // className ? " " + className : ""
        " "
      }`}>
      <PageToolbar
        title="Inbox"
        leftButtonType="back"
        onClick={() => {
          history.push('/map', {
            menuModal: true
          })
        }}
        backgroundColor="#fff"
      />


      {
      todayMessages?.length>0 &&
        <div className="DTItems">
            <Text size="16px" weight="600" margin="0 15px 10px">
              {t("today")}
            </Text>

          {todayMessages?.map((message) => {
            return (
                <IonItemSliding className={`DTItemSliding ${message.read === 1 ? "" : "notRead"}`} key={message.id}>
                  <IonItem>

                    <span className="DTItemSlidingIcon" />

                    {message.read !== 1 && <span className="NotReadIcon"/>}
                    <div className="DTItemSlidingTitle">
                      {/* {t("out-of-order")} */}
                    </div>

                    <div className="DTItemSlidingDesc"
                    dangerouslySetInnerHTML={{__html: message.message}}
                    >
                    </div>
                    <div className="DTItemSlidingDate">
                      {formatDate(message.created_at)}
                    </div>
                  </IonItem>

                  <IonItemOptions side="end">
                    <IonItemOption
                      expandable
                      className="DTItemSlidingOption"
                      onClick={() => removeMessage(message.id)}
                    >
                      <span className="DTItemSlidingOptionIcon" />
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              )
            }
          )
        }
        </div>
      }

      <div className="DTItems" style={{marginTop: setMargin("pastMessages")}}>
        {
          pastMessages?.length>0 &&
            <Text size="16px" weight="600" margin="0 15px 10px">
              {t("past")}
            </Text>
        }
        {
          pastMessages?.map((message) => {
            return (
                <IonItemSliding className={`DTItemSliding ${message.read === 1 ? "" : "notRead"}`} key={message.id}>
                  <IonItem>
                    <span className="DTItemSlidingIcon" />
                    {message.read !== 1 && <span className="NotReadIcon"/>}
                    <div className="DTItemSlidingTitle">
                      {/* {t("out-of-order")} */}
                    </div>
                    <div className="DTItemSlidingDesc"
                    dangerouslySetInnerHTML={{__html: message.message}}
                    >
                    </div>
                    <div className="DTItemSlidingDate">
                    {formatDate(message.created_at) === "today" ? t("today") : formatDate(message.created_at) === "yesterday" ? t("yesterday") : formatDate(message.created_at)}
                    </div>
                  </IonItem>

                  <IonItemOptions side="end">
                    <IonItemOption
                      expandable
                      className="DTItemSlidingOption"
                      onClick={() => removeMessage(message.id)}
                    >
                      <span className="DTItemSlidingOptionIcon" />
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              )
            }
          )
      }
        </div>

        <IonLoading
            spinner="circular"
            isOpen={isLoading}
            onDidDismiss={() => setIsLoading(false)}
          />
    </div>

   </IonContent>
  )
}

export default Inbox
