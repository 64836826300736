import Text from "../Text";
import { t } from "i18next";

const EditManuallyButton = ({ setAddManualAddressModal }) => {
  return (
    <Text
      size="18px"
      weight="500"
      margin="12px 12px auto"
      otherStyles={{ textDecoration: "underline" }}
      onClick={() => setAddManualAddressModal(true)}
    >
      {t("or")}
      <div dangerouslySetInnerHTML={{__html: t("edit-manually")}}/>
    </Text>
  );
};

export default EditManuallyButton;
