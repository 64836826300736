//Ionic & React
import {useRef} from 'react';
import { IonModal } from '@ionic/react';
//Helpers
import { t } from 'i18next';
import { useHistory } from 'react-router';
//Components
import { Swiper, SwiperSlide } from 'swiper/react';
import PageToolbar from '../PageToolbar';
import BusinessCode from './BusinessCode';
import BusinessInfo from './BusinessInfo';

const AddBusinessAccountModal = ({
    isOpen,
    setOpen,
    setCurrentBusinesAccount,
    currentBusinessAccount,
    setBusinessModal,
    businessAccounts,
    setReloadBusinessAccounts
}) => {
    
    const addAccountSlider  = useRef()
    const history = useHistory()
    return (
        <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setOpen(false)}
        >
            <PageToolbar
            title={t("my-business-account")}
            leftButtonType="back"
            onClick={async () => {
                if (businessAccounts.length > 0) {
                  if(addAccountSlider.current.swiper.activeIndex !== 0){
                    addAccountSlider.current.swiper.slidePrev()
                  }else{
                    setOpen(false)
                  }
                } else {
                    await setOpen(false)
                    history.push('/map', {
                      menuModal: true
                    })
                }
            }}
            />
          <Swiper
          className="BusinessAccountsSlider swiper-no-swiping"
          ref={addAccountSlider}
          >
          <SwiperSlide>
            <BusinessCode
              slideRef={addAccountSlider}
              setCurrentBusinesAccount={setCurrentBusinesAccount}
              setModalOpen={setOpen}
            />
          </SwiperSlide>
          <SwiperSlide>
            <BusinessInfo
              setAddBusinessModal={setOpen}
              currentBusinessAccount={currentBusinessAccount}
              slideRef={addAccountSlider}
              setBusinessModal={setBusinessModal}
              setReloadBusinessAccounts={setReloadBusinessAccounts}
            />
          </SwiperSlide>
        </Swiper>
      </IonModal>
    );
}

export default AddBusinessAccountModal;
