import { t } from "i18next";
import { api } from "../../helper/api";
import { setAlert, setLoading } from "../../redux/slices/globalSlice";
import { store } from "../../redux/store";
import Button from "../Button";
import Text from "../Text";

const BusinessInfo = ({currentBusinessAccount, setBusinessModal, setAddBusinessModal, slideRef, setReloadBusinessAccounts}) => {

    const setCompany = async () => {
        store.dispatch(setLoading({status: true, message:""}))
        await api.assignCompany(currentBusinessAccount.company_code).then(({data}) => {
            if(data.success){
                setReloadBusinessAccounts(true)
                setBusinessModal(true)
                setAddBusinessModal(false)
            }
            else{
                store.dispatch(setAlert({status: true, message: t(data.message), type: "error"}))
            }
        }).finally(() => store.dispatch(setLoading({status: false, message: ""})))
    }

    return (
        <div className="BusinessInfo">
            <div className="InfoWrapper">
                <img src={currentBusinessAccount.logo_url} alt={currentBusinessAccount.company_name} className={"BusinessAccountLogo"}/>
                <Text size={22} weight={600} margin={"31px 0 0"}>{currentBusinessAccount.company_name}</Text>
                <Text size={18} weight={400} margin={"18px 0 0"} transform="capitalize">{currentBusinessAccount.street}</Text>
                <Text size={22} weight={400} margin={"18px 0 0"} color={"rgba(53, 51, 85, 0.5)"}>{currentBusinessAccount.phone_number}</Text>
            </div>
            <div className="BusinessInfoButtons iosSafeAreaBottomPadding">
                <Button expand={"block"} onClick={() => setCompany().catch(e => store.dispatch(setAlert({status: true, message: t("try-again"), type: "error"})))}>{t("confirm")}</Button>
                <Text
                    size={14}
                    weight={400}
                    color={"var(--dt-pink)"}
                    dangerouslySetInnerHTML={{ __html: t("not-my-business") }}
                    onClick={() => slideRef.current.swiper.slidePrev()}
                />
            </div>
        </div>
    );
}

export default BusinessInfo;
