//Ionic & React
import { IonModal } from "@ionic/react";
import { useState, useEffect } from "react";
//Helpers
import { useTranslation } from "react-i18next";
//Components
import Text from "../../Text";
import Icon from "../../Icon";
import PageToolbar from "../../PageToolbar";

const NFCModal = ({
    setNFCModalOpen,
    isNFCModalOpen
}) => {
    const {t} = useTranslation()
    const [isDisplay, setIsDisplay] = useState(false);

    useEffect(() => {
        if(isNFCModalOpen){
            setIsDisplay(false)
            let timeOut = setTimeout(() => {
                setIsDisplay(true)
            }, 5000);
            return () => {
                clearTimeout(timeOut)
            };
        }
    }, [isNFCModalOpen]);


    return (
        <IonModal
            isOpen={isNFCModalOpen}
            onDidDismiss={() => setNFCModalOpen(false)}
            className="NFCModal"
        >
            
                <div className="Content">
                    <PageToolbar
                        title={t("NFC")}
                        titleColor={"#FFF"}
                        leftButtonType="back"
                        whiteIcon={true}
                        onClick={() => setNFCModalOpen(false)}
                        />
                        <div className="flex w-full justify-center text-center">
                            <Text size={22} weight={500} color="#fff" margin={"55px 0 0"} dangerouslySetInnerHTML={{ __html: t("hold-card-close") }}/>
                        </div>
                        <div className="flex w-full justify-center text-center">
                            <Text size={14} weight={400} color="#C0C0CB" margin={"14px 0 0"} dangerouslySetInnerHTML={{__html: t("nfc-desc")}}/>
                        </div>
                        <div className="flex w-full justify-center text-center">
                            <Icon
                            name={"NFCPhone"}
                            width={103}
                            height={169}
                            className="NFCPhone"
                            />
                            <Icon
                            name={"card-icon"}
                            width={126}
                            height={78}
                            className="CardIcon"
                            />
                            <div className="circles">
                                <span className="circle1"></span>
                                <span className="circle2"></span>
                            </div>
                        </div>
                        <div className={`flex w-full justify-center text-center absolute bottom-[54px] ${!isDisplay ? "hidden" : ""}`} onClick={() => setNFCModalOpen(false)}>
                            <Text size={12} weight={500} color="#fff" margin={"14px 0 0"}>
                            {t("have-trouble")}<span className="text-[#b11d94]"> {t("enter-manually")}</span>
                            </Text>
                        </div>
                </div>
        </IonModal>
    )
}

export default NFCModal;