import { PureComponent } from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
  ResponsiveContainer,
} from "recharts"
import Text from "./Text"

const getAverage = (arr) => {
  let total = 0
  arr.forEach(({ kWh }) => (total += kWh))
  return Math.round(total / arr.length)
}
export default class Chart extends PureComponent {
  render() {
    const { monthly, monthlyData, weeklyData } = this.props

    return (
      <>
        {monthlyData && weeklyData ? (
          <ResponsiveContainer width="100%" height={154} id="BarChart">
            <BarChart height={40} data={monthly ? monthlyData : weeklyData}>
              <Bar
                dataKey="kWh"
                fill="#b11d94"
                radius={[900, 900, 900, 900]}
                maxBarSize={18}
              />
              <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tickMargin={16}
              />
              <YAxis
                interval="preserveStartEnd"
                width={14}
                axisLine={false}
                tickLine={false}
                tickMargin={0}
                orientation="right"
              />
              <ReferenceLine
                y={monthly ? getAverage(monthlyData) : getAverage(weeklyData)}
                stroke="#353355"
                strokeDasharray="3 6"
                strokeWidth={2}
              />
              <ReferenceArea
                shape={() => {
                  return (
                    <>
                      <rect
                        width="40"
                        height="27"
                        y={monthly ? 48 : 34}
                        x="0"
                        rx="13.5"
                        fill="#353355"
                      />
                      <text
                        y={monthly ? 48 : 34}
                        x="0"
                        className="BarChartAverageText"
                      >
                        avr.
                      </text>
                    </>
                  )
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Text
            size={16}
            weight={500}
            margin="20px auto 5px"
            color="var(--dt-secondary)"
            align="center"
            otherStyles="width: 100%"
          >
            No data available.
          </Text>
        )}
      </>
    )
  }
}
