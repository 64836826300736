import {t} from "i18next";
import {OverchargeCost, ReceiptTotal, Cost, AdministrationFee} from "./Partials";
import Text from "../../Text";

const ReceiptFields = ({receipt, chargeLevel}) => {
    const isOffense = receipt?.park_session_status === "offense"
  return (
    <>
            {receipt?.park_session_cost !== null ?
                <Cost
                    label={
                        t("parking-cost")
                    }
                    isOffense={isOffense}
                    duration={
                        receipt.parking_duration
                    }
                    amount={receipt.park_session_cost + t(" SEK")}
                    unitPrice={
                        `${receipt.parking_price + t(" SEK/h")} `
                    }
                />
                : ""}
            {receipt?.digital_code ?
                <div className="ReceiptTotal">
                    <Text size={15} weight={500}>
                        {t("digital-code-using")}
                    </Text>
                    <span className="bg-purple p-1 rounded-lg text-[#fff] uppercase">
                {receipt.digital_code}
              </span>
                </div> : ""}
            {receipt?.charging_session_cost ?
                <Cost
                    label={
                        t("charging-cost")
                    }
                    duration={
                        `${receipt.charging_electricity_consumption} kWh`
                    }
                    amount={receipt.charging_session_cost + t(" SEK")}
                    unitPrice={
                        `${receipt.charging_price} SEK/kWh`
                    }
                />
                : ""}
            {(receipt?.type === "park_and_charge" || receipt?.type === "charge") || (receipt?.digital_code === null && receipt?.park_session_cost > 0) ?
                <>
                    <AdministrationFee administrationFee={receipt?.administration_fee} vat={receipt?.vat}/>
                    <OverchargeCost chargeLevel={chargeLevel}/>
                    {/*<DrifterDiscount discount={discount}/>*/}
                    <ReceiptTotal total={receipt?.total_amount}/>
                </>
                : ''}
        </>
  );
}

export default ReceiptFields;
