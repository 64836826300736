import {IonContent, IonModal} from "@ionic/react";
import Text from "../Text";
import Button from "../Button";
import {useTranslation} from "react-i18next";
import {InvoiceTypeFilter, InvoiceCategoryFilter, InvoiceStatusFilter} from "./"

const InvoiceFilter = ({
                         isFilterInvoicesModalOpen,
                         setFilterInvoicesModalOpen,
                         setCountOfFilters,
                         filters,
                         setFilters,
                         invoiceFilters,
                         applyFilters,
                         clearFilter
                       }) => {
  const { t } = useTranslation()


  return (
    <IonModal
      cssClass="DTModal modal-white-background radiusModal"
      isOpen={isFilterInvoicesModalOpen}
      onDidDismiss={() => setFilterInvoicesModalOpen(false)}
      handle={false}
      breakpoints={[0.7]}
      initialBreakpoint={0.7}
    >
      <IonContent>
        <button
          className="DTModalCloseButton"
          onClick={() => setFilterInvoicesModalOpen(false)}
        />

        <Text size="24px" weight="600" color="var(--dt-primary)">
          {t("filter")}
        </Text>
        <InvoiceStatusFilter
        filters={filters}
        setFilters={setFilters}
        invoiceFilters={invoiceFilters}
        clearFilter={clearFilter}
        setCountOfFilters={setCountOfFilters}
        />
        <InvoiceCategoryFilter 
        filters={filters}
        setFilters={setFilters}
        invoiceFilters={invoiceFilters}
        clearFilter={clearFilter}
        setCountOfFilters={setCountOfFilters}
        />

      <InvoiceTypeFilter 
        filters={filters}
        setFilters={setFilters}
        invoiceFilters={invoiceFilters}
        clearFilter={clearFilter}
        setCountOfFilters={setCountOfFilters}
        />

        <Button
          theme="primary"
          expand="block"
          margin="40px 0 0"
          onClick={() => {
            applyFilters()
            setFilterInvoicesModalOpen(false)
          }}
        >
          {t("apply")}
        </Button>
      </IonContent>
    </IonModal>
  )
}

export default InvoiceFilter;
