import React from 'react'
import Text from '../Text'
import { useTranslation } from 'react-i18next'

const ReceiptDetail = ({invoice}) => {
    const {t} = useTranslation()
    const formatDate = d => {
        return new Date(d).toLocaleString("en-GB", {
            "dateStyle": "medium",
            "timeStyle": "short",
            "hour12": false
        });
    }
  return (
    <>
        <div className='flex flex-row gap-2 w-full justify-between divider-bottom py-3'>
            <Text weight={500} size={14}>
                {t("invoice-id")}:
            </Text>
            <Text weight={600} size={16}>
                DRF {invoice.payment_id}
            </Text>
        </div>
        <div className='flex flex-row gap-2 w-full justify-between divider-bottom py-3'>
            <Text weight={500} size={14}>
                {t("location")}:
            </Text>
            <Text weight={600} size={16}>
                {invoice.location_name}
            </Text>
        </div>
        <div className='flex flex-col gap-2 w-full justify-between divider-bottom py-3'>
            <div className='flex flex-row gap-2 w-full justify-between'>
                <Text weight={500} size={14}>
                    {t("start-time")}:
                </Text>
                <Text weight={600} size={16}>
                    {formatDate(invoice.created_at)}
                </Text>
            </div>
            <div className='flex flex-row gap-2 w-full justify-between'>
                <Text weight={500} size={14}>
                {t("end-time")}:
                </Text>
                <Text weight={600} size={16}>
                {formatDate(invoice.ended_at)}
                </Text>
            </div>
        </div>
        <div className='flex flex-col gap-2 w-full justify-between divider-bottom py-3'>
            <div className='flex flex-row gap-2 w-full justify-between'>
                <Text weight={500} size={14}>
                {t("plate-number")}:
                </Text>
                <Text weight={600} size={16} transform="uppercase">
                    {invoice.plate}
                </Text>
            </div>
            <div className='flex flex-row gap-2 w-full justify-between'>
                <Text weight={500} size={14}>
                {t("usage")}:
                </Text>
                <Text weight={600} size={16}>
                    {invoice?.usage_type ==="business" ? t("business") : t("personal")}
                </Text>
            </div>
            <div className='flex flex-row gap-2 w-full justify-between'>
                <Text weight={500} size={14}>
                {t("started-via")}:
                </Text>
                <Text weight={600} size={16} transform="capitalize">
                    {invoice?.is_drifterbox ? t("camera"):t("app")}
                </Text>
            </div>
        </div>
    </>
  )
}

export default ReceiptDetail