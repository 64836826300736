import { t } from "i18next";
import DTInput from "../DTInput";
import Text from "../Text";

const SelectAmount = ({selectedAmount, setSelectedAmount, inputValue, setInputValue}) => {
  const Amounts = [
    { id: 0, label: 200, value: 200 },
    { id: 1, label: 250, value: 250 },
    { id: 2, label: 300, value: 300 },
    { id: 3, label: "other", value: "other" },
  ];

  return (
    <div className="SelectAmount">
      <Text size={18} weight={600} color="var(--dt-primary)">
        {t("select-amount")}
      </Text>
      <div className="flex flex-row gap-[8px] mt-[15px]">
        {Amounts.map((a) => (
          <div
            key={a.id}
            className={`AmountItem ${a.value === selectedAmount ? "-active" : ""} ${a.value === "other" ? "capitalize" : ""}`}
            onClick={() => setSelectedAmount(a.value)}
          >
            <strong>{a.label}</strong>
            {a.value !== "other" ? t("kr") : ""}
          </div>
        ))}
      </div>
      {
        selectedAmount === "other" &&
        (<div className="mt-[30px]">
          <DTInput
          label={t("type-min")}
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          />
        </div>)
      }
    </div>
  );
};

export default SelectAmount;
