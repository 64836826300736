import { IonContent, IonModal } from "@ionic/react";
import { useEffect, useRef } from "react";
import { api } from "../../helper/api";

const ParkingPermitAgreementModal = ({isopen, setOpen}) => {
    const parkingPermitRef = useRef()
    const getContent = async () => {
        const content = await api.getPages("digital-parking-permit-agreement")
        if(content.status === 200 && content.data.success){
          if(parkingPermitRef) parkingPermitRef.current.innerHTML = content.data.response.content
        }
      }

      useEffect(() => {
        if(isopen){
            getContent()
        }
      }, [isopen])

  return (
    <IonModal
      isOpen={isopen}
      onDidDismiss={() => setOpen(false)}
      breakpoints={[0, 0.9, 1]}
      canDismiss={true}
      handle={true}
      initialBreakpoint={0.9}
      className="ParkinPermitModal"
    >
        <IonContent>
            <div ref={parkingPermitRef}></div>
        </IonContent>
    </IonModal>
  );
};

export default ParkingPermitAgreementModal;