// Ionic & React
import { useState, useRef, useEffect } from "react";
import {
  IonModal,
  useIonAlert,
  useIonRouter,
} from "@ionic/react";
//Files
import Api from "../helper/api";
//Components
import PageToolbar from "../components/PageToolbar";
import AddNewCarModal from "./AddNewCarModal";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { setVehicles } from "../redux/slices/userSlice";

import { useTranslation } from "react-i18next";
import AddNewButton from "../components/AddNewButton";
import {clearLoader, setLoading} from "../redux/slices/globalSlice";
import {Alert} from "../helper/alert";
import { MyCarsSlide } from "../components/MyCars";
import { clearModals, setMyCarsModal } from "../redux/slices/modalSlice";
import DTScrollable from "../components/DTScrollable";
import { getDefaultVehicle } from "../helper/globalFunctions";
const MyCars = ({person, ...props}) => {
  const { vehicles } = useSelector((state) => state.userSlice)
  const {myCarsModal} = useSelector((state) => state.modalSlice)
  const carsSliderRef = useRef(null)
  const api = new Api()
  const dispatch = useDispatch()
  const [showAlert] = useIonAlert()
  const router = useIonRouter();
  const { t } = useTranslation();
  const [sortedCars, setSortedCars] = useState([])
  const [isVehiclesChanged, setVehiclesChanged] = useState(false)
  const [isAddNewCarModalOpen, setAddNewCarModalOpen] = useState(false)
  const [breakpoint, setBreakPoint] = useState(false)
  const getDefVehicle = async () => {
    dispatch(setLoading({status: true, message: ""}))
    getDefaultVehicle()
    dispatch(setLoading({status: false, message: ""}))
  };

  useEffect(() => {
    if(isVehiclesChanged === true){
      getDefVehicle();
    }
    return () => setVehiclesChanged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVehiclesChanged]);

  useEffect(() => {
    setSortedCars([...vehicles].sort((x,y) => {
      return (x.is_default === y.is_default) ? 0 : x.is_default ? -1 : 1
    }))
  }, [vehicles])

  const alertMessage = (car) => {
      showAlert({
        message: t("remove-car", { plate: car.plate.toUpperCase() }),
        buttons: [
          t("no"),
          {
            text: t("yes"),
            handler: () => handleRemove(car),
          },
        ],
      });

  };

  const handleRemove = async (car) => {
    dispatch(setLoading({status: true, message: t("removing")}))
    const response = await api.removeVehicle({
      options: {
        parameters: {
          plate: car.plate,
        },
      },
    })
    if(response.status === 200 && response.data.success){
      dispatch(setVehicles(vehicles.filter((vehicle) => vehicle.plate !== car.plate)));
      setVehiclesChanged(true)
      Alert.success(t("vehicle-deleted-successfully"))
    }
    dispatch(clearLoader())
  }

  //remove car method
  const removeCar = (car) => {
    alertMessage(car);
  };


  return (
    <IonModal
      isOpen={myCarsModal.status}
      onDidDismiss={() => dispatch(setMyCarsModal(false))}
      breakpoints={[0, 0.9, 1]}
      initialBreakpoint={0.9}
      canDismiss={myCarsModal.canDismiss}
      className={` ${breakpoint !== 1 ? "radiusModal" : "fullscreen"} transition-all`}
      onIonBreakpointDidChange={(e) => setBreakPoint(e.detail.breakpoint)}
      onIonModalWillPresent={() => setBreakPoint(0.9)}
    >
      <PageToolbar
        title={t("my-cars")}
        leftButtonType="back"
        onClick={() => dispatch(setMyCarsModal(false))}
        iosSafeArea={breakpoint === 1 ? true : false}
        />
      <DTScrollable
      scrollY={false}
      cssClass="no-padding"
      >
        <AddNewButton
          onButtonClick={async () => {
            await dispatch(clearModals())
            router.push("/add-new-car", "forward")
          }}
          text={t("add-new")}
          number={vehicles?.length}
        />
        <MyCarsSlide
        cars={sortedCars}
        carsSliderRef={carsSliderRef}
        removeCar={removeCar}
        />
      </DTScrollable>
      <AddNewCarModal
        person={person}
        isAddNewCarModalOpen={isAddNewCarModalOpen}
        setAddNewCarModalOpen={setAddNewCarModalOpen}
      />
    </IonModal>
  );
};

export default MyCars;
