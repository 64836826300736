//Ionic & React
import { IonContent } from "@ionic/react";
import { useState } from "react";
//Components
import {
  DetailInfo,
  Header,
  TicketStation,
  TicketVehicle,
  TicketEditVehicleModal,
} from "../components/Tickets/TicketDetail";
import PageToolbar from "../components/PageToolbar";
//Helpers
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";


const TicketDetail = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const { ticket, status } = history.location.state;
  const { vehicles } = useSelector((state) => state.userSlice);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [updatedVehicle, setUpdatedVehicle] = useState({})
  
  const {plate: vehicle } = ticket
  const {park: station } = ticket.ticket

  return (
    <IonContent className="no-padding" scrollY={false}>
      <PageToolbar
        title={t("tickets")}
        leftButtonType="back"
        leftButtonOnClick={() => history.push("/tickets")}
        backgroundColor="#fff"
      />
      <div className="ContentDetail">
        <Header ticket={ticket} />
        <DetailInfo ticket={ticket} status={status} />
        <TicketVehicle
          logo={updatedVehicle.logo ? updatedVehicle.logo : vehicle.logo}
          brand_text={updatedVehicle.brand_text ? updatedVehicle.brand_text : vehicle.brand_text}
          model_text={updatedVehicle.model_text ? updatedVehicle.model_text : vehicle.model_text}
          plate={updatedVehicle.plate ? updatedVehicle.plate : vehicle.plate}
          setEditModalOpen={setEditModalOpen}
        />
        <TicketStation station={station} />
      </div>
      <TicketEditVehicleModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        vehicle={vehicle}
        vehicles={vehicles}
        ticket={ticket}
        setUpdatedVehicle={setUpdatedVehicle}
      />
    </IonContent>
  );
};

export default TicketDetail;
