import {AnimatedLoader, Close, VerifiedIcon} from "../assets/icons";

export const invoiceFunctions = {

  /**
   *
   * @param data {object} - invoice object
   * @param type {string | "day", "hour", "month-year"} - type of invoice
   * @returns {string} - value of invoice
   */
  formatDate: (data, type) => {
    const date = new Date(data)
    if (type === 'day') {
      return date.getDate().toString()
    } else if (type === 'month-year') {
      return date.toLocaleString('en-us', {month: 'short', year: 'numeric'})
    } else if (type === 'hour') {
      return date.toLocaleString('en-us', {hour: 'numeric', minute: 'numeric', hour12: false})
    }
  },

  /**
   *
   * @param category_id {number} - category id of invoice
   * @param park_session {object} - park session object of invoice
   * @param charging_transaction {object} - charging transaction object of invoice
   * @returns {string} - time of invoice
   */
  getTime: ({category_id, park_session, charging_transaction}) => {
    if (category_id === 2) {
      return `${invoiceFunctions.formatDate(park_session?.created_at, 'hour')} / ${invoiceFunctions.formatDate(park_session?.ended_at, 'hour')}`
    } else if (category_id === 1) {
      return `${invoiceFunctions.formatDate(charging_transaction?.created_at, 'hour')} / ${invoiceFunctions.formatDate(charging_transaction?.ended_at, 'hour')}`
    }

    return ""
  },

  /**
   *
   * @param category_id {number} - category id of invoice
   * @returns {string} - category of invoice
   */
  getCategory: (category_id) => {
    if (category_id === 1) {
      return "charge"
    } else if (category_id === 2) {
      return "parking"
    } else if (category_id === 4) {
      return "park-and-charge"
    }

    return ""
  },

  /**
   *
   * @param status_id
   * @returns {{icon: *, title: string}}
   */
  getPaymentStatus: (status_id) => {
    switch (status_id) {
      case 1:
      case 4:
        return {
          title: "unpaid",
          icon: Close,
          bg: "#198754"
        }
      case 2:
        return {
          title: "paid",
          icon: VerifiedIcon,
          bg: "#198754"
        }
      case 3:
        return {
          title: "pending",
          icon: AnimatedLoader,
          bg: "#198754"
        }
      default:
        return {
          title: "rejected",
          icon: Close,
          bg: "#198754"
        }
    }
  }
}
