import {useSelector} from "react-redux";
import {IonContent} from "@ionic/react";
import SessionInfoCard from "../../../SessionInfoCard";
import {useSpring} from "react-spring";
import {ClockIcon, GreenCharge, PlugIcon} from "../../../../assets/icons";
import {useTranslation} from "react-i18next";
import DisplayCost from "../../../DisplayCost";
import {ChargingBottom, ChargingTop} from "./ChargePartials";

const ChargeSession = ({elapsedTime, setReceipt}) => {
  const {t} = useTranslation();
  const {activeSession} = useSelector((state) => state.userSlice);

  const animationCard = useSpring({
    to: {
      opacity: 1,
      transform: "translateY(0px)",
    },
    from: {opacity: 0, transform: "translateY(-20px)"},
    config: {duration: 400},
  });

  const animation2 = useSpring({
    to: {
      opacity: 1,
      transform:"translateY(0px)",
    },
    from: { opacity: 0, transform: "translateY(-20px)" },
    delay: 200,
  });

  return (
    <IonContent className={'no-padding'}>
      <div className={'iosSafeAreaBottom'}>
        <div>

          <ChargingTop session={activeSession} elapsedTime={elapsedTime} />

          <div className={'p-[16px] h-full'}>
            <SessionInfoCard
              connectionIcon={activeSession.charge_transaction?.transaction_connection?.connection_type?.icon_url}
              connectionPower={activeSession.charge_transaction?.transaction_connection?.power_kw + "kW"}
              animation={animationCard}
              titleIcon={GreenCharge}
              titleText={t('charging')}
              firstLine={true}
              firstLineIcon={PlugIcon}
              firstLineText={t('charged')}
              firstLineDesc={
                <>
                  {activeSession.charge_transaction?.consumed_amount ?? 0}
                  <span className="text-[14px] font-semibold">{t("kWh")}</span>
                </>
              }
              secondLine={true}
              secondLineIcon={ClockIcon}
              secondLineText={t('elapsed-time')}
              collapsable={false}
              totalElapsedTime={elapsedTime}
            />

            <DisplayCost
              color={'#34D298'}
              cost={activeSession.charge_transaction?.payment_amount ?? 0}
              text={t("charging-cost")}
              desc={t("unit-price")}
              descPrice={activeSession.charge_transaction?.transaction_station?.charge_price ?? 0}
              animation={animation2}
            />

          </div>
        </div>
      </div>

      <div className={'w-full bg-primary rounded-t-3xl text-white flex flex-col items-center justify-start text-center p-[36px] absolute h-[170px] pt-[24px] bottom-0'}>
        <ChargingBottom setReceipt={setReceipt} />
      </div>
    </IonContent>
  );
}

export default ChargeSession
