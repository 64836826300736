import Text from '../../Text';
import { useTranslation } from 'react-i18next';

const ConfirmPhotos = ({photo, sendPictures}) => {
  const {t} = useTranslation()
    return (
        <div className="h-full w-full">
        <div className="w-full flex flex-col gap-[16px] px-[16px] justify-center items-center">
          <Text
          align="center"
          color={"white"}
          size={18}
          weight={600}
          margin="16px 0 16px 0"
          >{t("front-photo")}</Text>
          <img src={photo.front} alt="FrontPreview" className="h-[204px] w-[325px]"/>
        </div>
        <div className="w-full flex flex-col gap-[16px] px-[16px] justify-center items-center">
          <Text
          align="center"
          color={"white"}
          size={18}
          weight={600}
          margin="16px 0 16px 0"
          >{t("back-photo")}</Text>
          <img src={photo.back} alt="BackPreview" className="h-[204px] w-[325px]"/>
        </div>
        <div className="p-4 fixed bottom-0 w-full">
          <button className="w-full h-[55px] rounded-full bg-white text-primary font-semibold shadow-lg" onClick={() => {
            sendPictures()
          }}>{t("confirm")}</button>
        </div>
      </div>
    );
}

export default ConfirmPhotos;
