import VipIcon from "../../../assets/icons/vip-icon.svg";
import Tick from "../../../assets/icons/tick.svg";
import RightArrow from "../../../assets/icons/right-arrow-icon.svg";
import VerifyIcon from "../../../assets/icons/verified-icon.svg";
import Text from "../../Text";

import { useTranslation } from "react-i18next";

const ParkingVIPCode = ({
  check,
  setCheck,
  confirmCode,
  vipCode,
  setVipCode,
  checkVipCode,
  isContracted
}) => {
  const { t } = useTranslation();

  const setMargin = () => {
    if (check && confirmCode) return "mb-[8px]";
    else if (check && !confirmCode) return "mb-[43px]";
    else if (!check) return "";
  };

  return isContracted ? (
    <>
      <label className={`ParkingVIPCodeLabel ${check && "active"}`}>
        <div className={`VIPCodeIconText ${setMargin()}`}>
          <img src={VipIcon} className="w-[30px] h-[24px]" alt="" />
          <div className="w-full text-left ml-[8px]">
            <Text weight={600} size={14} color={"rgba(99, 92, 92, 1)"}>
              {t("i-have-vip-code")}
            </Text>
          </div>
          <div>
            <input
              type="checkbox"
              className="VIPCodeCheckbox"
              onChange={(e) => setCheck(e.target.checked)}
              disabled={confirmCode}
            />
            <span className="VIPCodeCheckboxSpan">
              {check && <img src={Tick} width={16} height={8} alt="Tick" />}
            </span>
          </div>
        </div>
        {confirmCode && (
          <div className="w-full pl-[32px] mb-[18px]">
            <Text weight={700} size={14}>
              {t("enjoy-your-discount", { discount: 30 })}
            </Text>
          </div>
        )}
        {check && (
          <div className="relative flex items-center w-full">
            <input
              type={"text"}
              className={`block border-[1px] border-primary bg-${
                confirmCode ? "[#FCFCFF]" : "white"
              } border-opacity-30
              p-[15px] pr-[50px]
              rounded-xl w-full
              text-primary
              focus:border-opacity-70
              focus:ring-blue-500
              focus:outline-0
              focus:border-primary`}
              placeholder={confirmCode ? t("code-applied") : "156SXA62A68B"}
              disabled={confirmCode}
              value={vipCode}
              onChange={(e) => setVipCode(e.target.value)}
            />
            {confirmCode ? (
              <div className="absolute flex justify-center items-center text-center rounded-xl right-[7px] bottom-[7px]  w-[39px] h-[39px]">
                <img src={VerifyIcon} alt="" />
              </div>
            ) : (
              <button
                className="absolute flex justify-center items-center text-center rounded-xl right-[7px] bottom-[7px] bg-primary w-[39px] h-[39px]"
                onClick={() => checkVipCode()}
              >
                <img src={RightArrow} alt="" />
              </button>
            )}
          </div>
        )}
      </label>
    </>
  ) : null;
};

export default ParkingVIPCode;
