import InvoiceList from './InvoiceList';
import NoInvoice from './NoInvoice';
import InvoiceSkeleton from './InvoiceSkeleton';

const Invoices = ({invoices, countOfFilters, loadingInvoices}) => {
    return !loadingInvoices ? (
        <div className="SettingsWrapper px-[16px]">
          {invoices.length>0 ? invoices.map((invoice) => (
              <div
                key={invoice.id}
                className={"mb-[10px]"}
              >
                <InvoiceList invoice={invoice}/>
              </div>
            )
          ) : invoices.length===0 && !countOfFilters ? <NoInvoice /> : ("")}
        </div>
    ) : ( <InvoiceSkeleton /> );
}

export default Invoices;
