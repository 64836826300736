import { IonSpinner } from "@ionic/react";

const DTLoader = ({message = ""}) => {
    return (
        <div className="w-full h-full bg-[#000] bg-opacity-60 flex justify-center items-center z-[9999999]">
            <div className={`w-fit h-fit bg-[#fff] text-white p-[24px] flex justify-center items-center ${message !== "" ? "gap-4" : ""}`}>
                <IonSpinner color="dark" name="circular"/>
                <span className="text-[16px] font-medium capitalize text-[#000000]">
                    {message}
                </span>
            </div>
        </div>
    );
}

export default DTLoader;
