import PageToolbar from "../components/PageToolbar";
import {IonContent} from "@ionic/react";
import CarForm from "../components/NewCar/CarForm";
import {useTranslation} from "react-i18next";
import { useHistory } from "react-router";

const AddNewCar = () => {
  const {t} = useTranslation();
  const history = useHistory();

  const goBack = () => {
      history.push('/map', {menuModal: true, myCarsModal:true})
  }

  return (
    <>
      <PageToolbar
        title={t("add-new-car")}
        leftButtonType="back"
        whiteIcon={false}
        onClick={() => goBack()}
      />

      <IonContent className={"p-0"}>
          <CarForm onSubmit={() => goBack()}/>
      </IonContent>
    </>
  )
}

export default AddNewCar;
