// Ionic & React
import { useState, useRef } from "react";
import {
  IonPage,
  IonContent,
  useIonRouter,
  IonRippleEffect,
} from "@ionic/react";
// Components
import Logo from "../components/Logo";
import OnboardingSecondSlide from "../components/OnboardingSecondSlide";
import OnboardingWelcomeSlide from "../components/OnboardingWelcomeSlide";
import OnboardingThirdSlide from "../components/OnboardingThirdSlide";
import OnboardingLastSlide from "../components/OnboardingLastSlide";
//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
// Redux
import {useDispatch, useSelector} from "react-redux";
import {setFirstTime} from "../redux/slices/userSlice";
//Helpers
import {useTranslation} from "react-i18next";

const Onboarding = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const router = useIonRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onboardingSliderRef = useRef(null);
  const { token } = useSelector(store => store.userSlice)

  const skipOnboarding = () => {
    dispatch(setFirstTime(false))
    router.push(token && token !== "" ? "/map" : "/register-setup", "forward");
  }

  return (
    <IonPage className="Onboarding">
      <IonContent>
        <div
          className={`iosSafeAreaTop OnboardingWrapper${
            activeIndex === 3 ? " last-slide" : ""
          }`}
        >
          <button
            className="OnboardingBack"
            onClick={() => onboardingSliderRef.current?.swiper.slidePrev()}
          />
          <Logo type="Logotype" height={25} width={89} />

          <Swiper
            className="OnboardingSlider"
            ref={onboardingSliderRef}
            onSlideChange={() =>
              setActiveIndex(onboardingSliderRef.current?.swiper.activeIndex)
            }
            spaceBetween={40}
            centeredSlides={true}
            speed={200}
            allowTouchMove={true}
            modules={[Pagination]}
            pagination={{ type: "bullets" }}
          >
            <SwiperSlide>
              <OnboardingWelcomeSlide />
            </SwiperSlide>
            <SwiperSlide>
              <OnboardingSecondSlide />
            </SwiperSlide>
            <SwiperSlide>
              <OnboardingThirdSlide />
            </SwiperSlide>
            <SwiperSlide>
              <OnboardingLastSlide />
            </SwiperSlide>
          </Swiper>
          <div className="OnboardingBottom">
            <button className="DTLink" onClick={() => skipOnboarding()}>
              <u className="underline-none">{t("skip")}</u>
            </button>
            <button
              className="DTFab -arrow-right ion-activatable z-50"
              onClick={() => onboardingSliderRef.current.swiper.slideNext()}
            >
              <IonRippleEffect />
            </button>

            {/* Hidden Button */}
            <div
              className="OnboardingBottomHiddenButton"
              onClick={skipOnboarding}
            >
              <span />
              <div>{t("i'm-ready-to-drift")}</div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Onboarding;
