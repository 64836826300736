import AddNewButton from "../AddNewButton";
import { useTranslation } from "react-i18next";
import CardSlider from "./CardSlider/CardSlider";
import { useSelector } from "react-redux";
import { GetCards } from "../../helper/paymentFunctions";
import { useEffect } from "react";
import { useIonRouter } from "@ionic/react";
const PaymentMethods = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { paymentMethods } = useSelector((store) => store.userSlice);
  useEffect(() => GetCards(), []);

  return (
    <>
      <AddNewButton
        text={t("add-new")}
        onButtonClick={() => router.push("/add-payment-method", "forward")}
        number={paymentMethods.length}
      />
      <CardSlider />
    </>
  );
};
export default PaymentMethods;
