import {useTranslation} from "react-i18next";

const ReceiptTotal = ({total}) => {
    const {t} = useTranslation()
    return (
        <div className="ReceiptTotal">
            <span>{t("total-price")}</span>
            <div className="ReceiptTotalPrice uppercase">
                <span>{total ? total : 0}{" SEK"}</span>
            </div>
        </div>
    )
}

export default ReceiptTotal
