import React from 'react'

import onboardingFirstSlide_car from "../assets/images/car_firstSlide_1.png";
import onboardingParkCarWheel from "../assets/images/vectors/car-wheel.svg";
import Building1 from "../assets/images/vectors/building_1.svg";
import Building2 from "../assets/images/vectors/building_2.svg";
import Building3 from "../assets/images/vectors/building_3.svg";
import Tree from "../assets/images/vectors/tree.svg";
import {useTranslation} from 'react-i18next';

const OnboardingWelcomeSlide = () => {
	const {t} = useTranslation();

	return (
		<>
			<div className="OnboardingSlideContent firstSlide">
				<div className={"onboardingFirstBg"}>
					<div className="onboardingFirstSlideHalfCircles">
						<div className="onBoardingFirstHalfCircle"></div>
						<div className="onBoardingSecondHalfCircle"></div>
						<hr className="border-b"/>
						<div className={"builds"}>
							<img className={"Building2"} src={Building2} alt={"building 2"}/>
							<img className={"Building1"} src={Building1} alt={"building_2"}/>
							<img className={"Building3"} src={Building3} alt={"building_3"}/>
							<img className={"Building5"} src={Building1} alt={"building_5"}/>
							<img className={"Tree1"} src={Tree} alt={"tree1"}/>
							<img className={"Tree2"} src={Tree} alt={"tree2"}/>
							<img className={"Tree3"} src={Tree} alt={"tree3"}/>
						</div>
					</div>
				</div>
				<div className="onboardingFirstCarContainer">
					<div className="carWheels">
						<img
							className={"onboardingParkCarWheelLeft"}
							src={onboardingParkCarWheel}
							alt={"onboardingParkCarWheel"}
						/>
						<img
							className={"onboardingParkCarWheelRight"}
							src={onboardingParkCarWheel}
							alt={"onboardingParkCarWheel"}
						/>
					</div>
					<div className="onboardingFirstSlidecarImageContainer">
						<img
							className={"onboardingFirstCar"}
							src={onboardingFirstSlide_car}
							alt={"onboardingFirstSlide_car"}
						/>
					</div>
				</div>
			</div>
			<div className="OnboardingSlideBottom">
				<div className="OnboardingTitle">{t("onboarding-first-title")}</div>
				<div className="OnboardingDesc">{t("onboarding-first-description")}</div>
			</div>
		</>
	)
}

export default OnboardingWelcomeSlide
