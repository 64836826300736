//Ionic & React
import { IonContent } from "@ionic/react";
import { useEffect, useState } from "react";
//Components
import PageToolbar from "../components/PageToolbar";
// TODO: TICKET FILTERS
// import { TicketFiltersModal } from "../components/Tickets";
import { TicketsList } from "../components/Tickets";
//Hooks
import { useTranslation } from "react-i18next";
import { api } from "../helper/api";
import { useHistory } from "react-router";
const Tickets = () => {
  const history = useHistory()
  const { t } = useTranslation();

  const [tickets, setTickets] = useState([]);
  const [loadingTickets, setLoadingTickets] = useState(false);
  const getUserTickets = () => {
    setLoadingTickets(true)
    api.getUserTickets().then(res => {
      if(res.status === 200 && res.data.response) {
        setTickets(res.data.response || [])
      }
    })
    .catch(() => setTickets([]))
    .finally(() => setLoadingTickets(false))
  }
  useEffect(() => {
    getUserTickets()
  }, [])

  return (
    <>
      <PageToolbar
        title={t("tickets")}
        leftButtonType="back"
        leftButtonOnClick={() => {
          history.push("/map", {
            menuModal: true
          })
        }}
        backgroundColor="#fff"
      />
      <IonContent className={"no-padding"}>
        <TicketsList
          tickets={tickets}
          loadingTickets={loadingTickets}
        />
      </IonContent>
      {/* TODO:: FILTERS */}
      {/* <TicketFiltersModal
      isOpen={filterTicketsModal}
      setOpen={setFilterTicketsModal}
      /> */}
    </>
  );
};

export default Tickets;
