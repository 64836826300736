import { useTranslation } from 'react-i18next';
import SearchResultsSlider from './SearchResultsSlider';

const RecentSearches = ({
    stationsNearByMe,
    stationsNearByMeLoading,
    setCurrentStation,
    GetStationDetail
}) => {
    const {t} = useTranslation()
    return (
        <>
        {stationsNearByMe.length && !stationsNearByMeLoading ? (
            <div className="SearchResultsSliderWrapper">
              <span className="SearchTitle">{t("near-by-me")}</span>
              <SearchResultsSlider
                    stations={stationsNearByMe}
                    setCurrentStation={setCurrentStation}
                    itemOnclick={(station) => {
                      GetStationDetail(station);
                    }}
                  />
            </div>
          ) : stationsNearByMeLoading && stationsNearByMe.length ? (
            <div className="SearchResultsSliderWrapper">
              <span className="SearchTitle">{t("near-by-me")}</span>
              <div className="SearchResultsSliderSkeletons">
                <div className="SearchResultsSliderSkeleton" />
                <div className="SearchResultsSliderSkeleton" />
                <div className="SearchResultsSliderSkeleton" />
              </div>
            </div>
          ) : ("") }
          </>
    );
}

export default RecentSearches;
