import { t } from "i18next";
const OffenseLabel = () => {
  return (
    <span className="text-white bg-[#ee3c24] px-2 py-1 rounded">
      {t("offense")}
    </span>
  );
};

export default OffenseLabel;
