// Logos
import { ReactComponent as LogoVerticalDark } from "../assets/logo/logo-vertical-dark.svg"
import { ReactComponent as LogoVerticalGradient } from "../assets/logo/logo-vertical-gradient.svg"
import { ReactComponent as Mono } from "../assets/logo/mono.svg"
import { ReactComponent as MonoDark } from "../assets/logo/mono-dark.svg"
import { ReactComponent as MonoGradient } from "../assets/logo/mono-gradient.svg"
import { ReactComponent as LogoLight } from "../assets/logo/logo.svg"
import { ReactComponent as LogoDark } from "../assets/logo/logo-dark.svg"
import { ReactComponent as LogoGradient } from "../assets/logo/logo-gradient.svg"
import { ReactComponent as LogoGradient2 } from "../assets/logo/logo-gradient-2.svg"
import { ReactComponent as Logotype } from "../assets/logo/logotype.svg"
import { ReactComponent as LogotypeDark } from "../assets/logo/logotype-dark.svg"
import { ReactComponent as LogotypeBeta } from "../assets/logo/logo-beta.svg"
import {LogoVertical} from "../assets/logo";

const Logo = ({ type, height, width, ...props }) => {
  const LogoSelected = () => {
    switch (type) {
      case "LogoVertical":
        return <LogoVertical style={{ height: height, width: width }} />
      case "LogoVerticalDark":
        return <LogoVerticalDark style={{ height: height, width: width }} />
      case "LogoVerticalGradient":
        return <LogoVerticalGradient style={{ height: height, width: width }} />
      case "Mono":
        return <Mono style={{ height: height, width: width }} />
      case "MonoDark":
        return <MonoDark style={{ height: height, width: width }} />
      case "MonoGradient":
        return <MonoGradient style={{ height: height, width: width }} />
      case "LogoDark":
        return <LogoDark style={{ height: height, width: width }} />
      case "LogoGradient":
        return <LogoGradient style={{ height: height, width: width }} />
      case "LogoGradient2":
        return <LogoGradient2 style={{ height: height, width: width }} />
      case "Logotype":
        return <Logotype height={height} width={width} />
      case 'LogotypeDark':
        return <LogotypeDark style={{ height: height, width: width }} />
      case 'LogotypeBeta':
        return <LogotypeBeta style={{ height: height, width: width }} />
      default:
        return <LogoLight style={{ height: height, width: width }} />
    }
  }

  return (
    <div
      className="Logo"
      style={{ height: height + "px", width: width + "px" }}
      {...props}
    >
      <LogoSelected />
    </div>
  )
}

export default Logo
