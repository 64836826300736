import CircularProgressBar from "../../CircularProgressBar/CircularProgressBar"
import NotifyIcon from "../../../assets/icons/notify-icon.svg"

//Translate
import { useTranslation } from "react-i18next"


const ParkingAvailability = ({ parkingAvailabilityState, addReminder, isContracted }) => {

    const { t } = useTranslation()

    const NoParking = () => {
      return (
        <div className="flex justify-center items-center text-center flex-col space-y-[23px] my-[25px]">
          <div className="w-[117px] h-[117px] border-pink border-[8px] rounded-full flex justify-center items-center text-center ">
            <p className="text-[12px] font-semibold text-primary">{t("no-availability")}</p>
          </div>
          <button
          onClick={() => addReminder()}
          className="StationDetailModal_border-button text-primary text-[16px] font-semibold rounded-full  h-[56px] flex items-center justify-center text-center px-3"
          >
            <img src={NotifyIcon} alt="" className="mr-[10px]" />
            {t("notify-me")}
          </button>
        </div>
      )
    }

    return isContracted  ? (
      <>
        {parkingAvailabilityState.percent===0? (
          <NoParking />
        ) : (
          <div className="flex flex-col text-center justify-center items-center">
            <CircularProgressBar percentage={parkingAvailabilityState} colour="#34D298" />
          </div>
        )}
      </>
    ) : null
  }

export default ParkingAvailability