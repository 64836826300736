import { useTranslation } from "react-i18next"
const SearchInput = ({
    searchText,
    setSearchText,
    searchInputRef,
    setSearchResults
})=> {
    const {t} = useTranslation()
    return (
        <div className="SearchInputWrapper">
            <input
                type="search"
                id="searchInput"
                placeholder={t("address-zoneCode-venueName")}
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                ref={searchInputRef}
            />
            <button
                id="clearSearchInput"
                onClick={() => {
                setSearchText("")
                setSearchResults({})
                searchInputRef.current.focus()
                }}
            />
        </div>
    )
}
export default SearchInput