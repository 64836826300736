import { PureComponent } from "react"
import { PieChart, Pie, Cell } from "recharts"

class Chart extends PureComponent {
  render() {
    const {
      monthly,
      monthlyData,
      weeklyData,
      pieColors,
      currentPieTab,
      setCurrentPieTab,
    } = this.props

    const handleClick = (e) => {
      setCurrentPieTab(e.name.toLowerCase())

      let parent = document.querySelector(
        ".recharts-sector[name='" + e.name.toLowerCase() + "']"
      )

      console.log(parent)
    }

    return (
      <PieChart width={142} height={142}>
        <Pie
          data={monthly ? monthlyData : weeklyData}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={450}
          blendStroke={true}
          innerRadius={46}
          outerRadius={60}
          fill="#8884d8"
          dataKey="value"
          onClick={handleClick}
        >
          {monthly
            ? monthlyData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={pieColors[index % pieColors.length]}
                  strokeWidth={
                    entry.name.toLowerCase() === currentPieTab ? 10 : 0
                  }
                />
              ))
            : weeklyData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={pieColors[index % pieColors.length]}
                  strokeWidth={
                    entry.name.toLowerCase() === currentPieTab ? 10 : 0
                  }
                />
              ))}
        </Pie>
      </PieChart>
    )
  }
}

export default Chart
