//Ionic & React
import { useIonRouter } from '@ionic/react';
//components
import Icon from '../Icon';
import Text from '../Text';
//Language
import { t } from 'i18next';

const BusinessAccounts = ({accounts, setAddBusinessAccountModalOpen}) => {

    const router = useIonRouter()

    return (

        <div className="BusinessAccounts">
            <div className='Accounts'>
                {accounts && accounts.map(acc => (
                    <div className='Account' key={acc.id} onClick={() => router.push(`/my-business-accounts/detail/${acc.company_id}`)}>
                        <div className='flex flex-row items-center gap-[14px]'>
                            <img src={acc.company?.logo_url} alt="" className='CompanyLogo'/>
                            <Text size={18} weight={600} color="#353355">
                                {acc.company?.company_name}
                            </Text>
                        </div>
                        <Icon
                        name="arrow-right-basic"
                        width={6}
                        height={13}
                        fill={"white"}
                        />
                    </div>
                ))}
                <button
                className="AddAccountButton"
                onClick={() => setAddBusinessAccountModalOpen(true)}
                >
                    <Icon
                        name={"add-white"}
                        fill="#fff"
                        size={15}
                    />
                    {t("add-new")}
                </button>
            </div>
        </div>
    );
}

export default BusinessAccounts;
