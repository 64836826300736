//Ionic & React
import { useState } from 'react'
import { IonModal } from '@ionic/react'
//Components
import Icon from './Icon'
import Text from './Text'
//Swiper.js
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Zoom } from 'swiper';

const ZoomModal = ({
    isOpen,
    setOpen,
    images,
    title,
    subtitle
}) => {
    const [swiper, setSwiper] = useState(null)
  return (
    <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setOpen(false)}
        className='ZoomModal'
        >
          <Text color="white" margin="32px 0 0 16px" weight="700" size="22px">
            {title}
          </Text>
          <Text color="white" margin="5px 0 0 16px" weight="500" size="18px">
            {subtitle}
          </Text>
          <div className='Content'>
            <Swiper spaceBetween={20} speed={300}
            onSwiper={setSwiper}
            modules={[Zoom, Controller]}
            zoom={true} 
            >
              {images.map((img, id) => (
                  <SwiperSlide key={id} zoom={true}>
                      <div className='swiper-zoom-container'>
                        <img src={img} alt='ZoomImage' className='segmentImg' />
                      </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
          <div className='ZoomButtons'>
            <button onClick={() => {
              swiper.zoom.in()
            }}>
              <Icon name="zoom-in" fill={"var(--dt-pink)"} size={32}/>
            </button>
            <button onClick={() => {
              swiper.zoom.out()
            }}>
              <Icon name="zoom-out" fill={"var(--dt-pink)"} size={32}/>
            </button>
            <button onClick={() => {
              setOpen(false)
            }}>
              <Icon name="zoom-close" size={32} fill={"red"}/>
            </button>
          </div>
        </IonModal>
  )
}

export default ZoomModal