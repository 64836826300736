import { useTranslation } from "react-i18next"
import Icon from "../Icon"
import Text from "../Text"

const BuyTicketItem = ({
  setBuyTicketModalOpen,
  setStartSessionModalOpen,
  setSelectTicketModal
}) => {
  const { t } = useTranslation();
  return (
    <button className="BuyTicketItem" onClick={() => {
      setStartSessionModalOpen(false)
      setSelectTicketModal(false)
      setBuyTicketModalOpen(true)
      }}>
      <div className="flex flex-row gap-2 items-center">
        <Icon name="ticket" size={35} />
        <Text weight={600}>{t("buy-ticket")}</Text>
      </div>
      <Icon name="arrow-right-basic" fill={"#fff"} />
    </button>
  );
};

export default BuyTicketItem