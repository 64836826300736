import { IonContent, useIonRouter } from "@ionic/react";
import { t } from "i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../components/Icon";
import {
  DrifterCardBalance,
  DrifterCardSettingsModal,
  SelectAmount,
  SelectCard,
  DrifterCardAdvantages,
  DrifterCardLearnMoreModal
} from "../components/MyDrifterCard";
import PageToolbar from "../components/PageToolbar";
import Text from "../components/Text";
import { Alert } from "../helper/alert";
import { api } from "../helper/api";
import { getDrifterCardBalance } from "../helper/globalFunctions";
import { setLoading } from "../redux/slices/globalSlice";

const MyDrifterCard = () => {
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [isDrifterMoreModalOpen, setDrifterMoreModalOpen] = useState(false)
  const [selectedAmount, setSelectedAmount] = useState(null)
  const [selectedAmountInputValue, setSelectedAmountInputValue] = useState("")
  const [triggerTransferFunction, setTriggerTransferFunction] = useState(false)
  const router = useIonRouter();
  const dispatch = useDispatch();

  const { user, drifterCardBalance, paymentMethods } = useSelector((store) => store.userSlice)

  useEffect(()=>{
    if(triggerTransferFunction) addDrifterBalance()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[triggerTransferFunction])

  const addDrifterBalance = () => {
    dispatch(setLoading({status: true, message: t("loading-transaction")}))
    let price = selectedAmount === null || selectedAmount === "other" ? selectedAmountInputValue : selectedAmount;
    api.addDrifterCardBalance(price, user.id )
      .then(({data}) => {
        if(data.success) Alert.success(t("transaction-success"))
        else Alert.error(data.message)
      }).catch((err) => Alert.error(t("try-again")))
      .finally(() => {
        setTriggerTransferFunction(false)
        getDrifterCardBalance()
        dispatch(setLoading({status: false, message: ""}))
      })
  }


  const Advantages = [
    {id: 0, icon: "drifter-logo", iconBgColor: "#B11D94" , bgColor: "#E8EBFB", label: '20-discount'},
    {id: 1, icon: null, bgColor: "#FFF8DD", label: 'free-parking'}
  ]

  return (
    <>
    <IonContent scrollY className="no-padding">
      <PageToolbar
        title={t("my-drifter-card")}
        leftButtonType="back"
        rightButtonType="header"
        rightButtonIcon="settings2"
        leftButtonOnClick={() => router.push("/map", "back")}
        rightButtonOnClick={() => setSettingsModalOpen(true)}
        rightBadge={false}
        backgroundColor="#fff"
        rightButtonColor="#fff"
      />
      <div className="p-[20px] pb-0">
        <DrifterCardBalance balance={drifterCardBalance} setMoreModalOpen={setDrifterMoreModalOpen}/>
        <div className="flex gap-2 items-center mt-[40px]">
          <Icon name="load-money" size={20} color="var(--dt-primary)"/>
          <Text size={22} weight={600} color="var(--dt-primary)">
            {t("load-money")}
          </Text>
        </div>
        <SelectAmount
        selectedAmount={selectedAmount}
        setSelectedAmount={setSelectedAmount}
        inputValue={selectedAmountInputValue}
        setInputValue={setSelectedAmountInputValue}
        />
      </div>
      <hr style={{borderTop: "1px solid rgba(61, 60, 87, 0.15)", margin: 20}}/>
      <SelectCard paymentMethods={paymentMethods} />
      <hr style={{borderTop: "1px solid rgba(61, 60, 87, 0.15)", margin: 20}}/>
      <DrifterCardAdvantages advantages={Advantages}/>
      <div className="fixed bottom-[20px] left-0 w-full px-[20px] z-[9999999]">
        <button 
        className="w-full h-[55px] bg-primary rounded-full"
        onClick={() => setTriggerTransferFunction(true)}
        >
          <Text size={16} weight={700} color="#fff">
            {t("transfer")}
          </Text>
        </button>
      </div>
      <DrifterCardSettingsModal
      isOpen={isSettingsModalOpen}
      setOpen={setSettingsModalOpen}
      />
    </IonContent>
    <DrifterCardLearnMoreModal isOpen={isDrifterMoreModalOpen} setOpen={setDrifterMoreModalOpen}/>
    </>
  );
};

export default MyDrifterCard;
