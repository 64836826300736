//Ionic & React
import { IonModal, IonContent } from "@ionic/react"
import {memo} from 'react'
//Components
import Text from "./Text"
import Button from "./Button"
//Helpers
import { useTranslation } from "react-i18next"
//Plugins
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic"
const LocationAlertModal = ({
    isLocationAlertModalOpen,
    setLocationAlertModalOpen
}) => {

    const {t} = useTranslation()

    return (
        <IonModal
        isOpen={isLocationAlertModalOpen}
        onDidDismiss={() => setLocationAlertModalOpen(false)}
      >
        <IonContent>
          <div className="Wrapper" style={{ height: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
            >
              <Text size={20} weight={400}>
                {t("turn-on-location-desc")}
              </Text>
              <Button
                theme="primary"
                expand="fit"
                margin="42px auto 0"
                onClick={() => {
                  setLocationAlertModalOpen(false)
                  Diagnostic.switchToLocationSettings()
                }}
              >
                {t("manage-permissions")}
              </Button>
            </div>
          </div>
        </IonContent>
      </IonModal> 
    )

}

export default memo(LocationAlertModal)