import {
  ParkStationTitle,
  ParkStationAvailability,
  ParkStationCost,
  ParkStationVIPCode,
  ParkStationPhotos,
} from "./index";

const ParkStationDetail = ({
  station,
  addParkingReminder,
  haveVipCode,
  sethaveVipCode,
  verify,
  setVerify,
  parkVipCode,
  setParkVipCode,
  checkVipCode,
  setImageModalOpen,
  setChoosenImageUrl,
  isContracted
}) => {
  return (
    <>
      <ParkStationTitle
        title={station.DETAIL ? station.DETAIL.name : station.NAME}
      />
      <ParkStationAvailability
        parkingAvailabilityState={{percent: station.DETAIL?.available_percent, count: station.DETAIL?.available_lots}}
        addReminder={addParkingReminder}
        isContracted={isContracted}
      />
      <ParkStationCost isContracted={isContracted} prices={station.DETAIL?.prices} />
      <ParkStationVIPCode
        check={haveVipCode}
        setCheck={sethaveVipCode}
        confirmCode={verify}
        setConfirmCode={setVerify}
        vipCode={parkVipCode}
        setVipCode={setParkVipCode}
        checkVipCode={checkVipCode}
      />
      {station.DETAIL?.image_urls.length > 0 && (
        <ParkStationPhotos
          images={station.DETAIL?.image_urls}
          setImageModalOpen={setImageModalOpen}
          setChoosenImageUrl={setChoosenImageUrl}
        />
      )}
    </>
  );
};

export default ParkStationDetail;
