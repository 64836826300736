import Payment from "payment";
import validator from "validator";
import {
  PaymentSheetEventsEnum,
  Stripe,
} from "@capacitor-community/stripe";
import { api } from "./api";
import { store } from "../redux/store";
import { setPaymentMethods } from "../redux/slices/userSlice";
import {clearLoader, setLoading} from "../redux/slices/globalSlice";

// Get Cards
export const GetCards = async () => {
  store.dispatch(setLoading({ status: true, message: "" }));
  const response = await api.getCards();
  if (response?.status === 200 && response?.data?.success) {
    store.dispatch(setPaymentMethods(response?.data?.response));
  }
  store.dispatch(clearLoader());
};

export const RemoveCard = (id) => {
  return api
    .removeCreditCard({
      options: {
        parameters: {
          card_id: id,
        },
      },
    })
    .then(({ data }) => {
      if (data.success) {
        GetCards();
      }
    });
};

export const SetDefaultCard = async (id) => {
  const response = await api.setDefaultCard({
    options: {
      parameters: {
        payment_method_id: id,
      },
    },
  });
  const { data } = response;
  if (data.success) {
    GetCards();
  }
};

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export const formatCreditCardNumber = (value) => {
  if (!value) return value;

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
};

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export const validate = (values) => {
  const errors = {};
  // eslint-disable-next-line array-callback-return
  values.map(({ type, value }) => {
    if (value) {
      switch (type) {
        case "expiry":
          let today =  new Date()
          let month = value.split("/")[0]
          let year =  String(today.getFullYear().toString().slice(0,2) + value.split("/")[1])
          if(month>12 || month<0 || year < today.getFullYear()) errors[type] = "Invalid Expire Date"
          else errors[type] = "success";
          break;
        case "cvc":
          if(value.length !== 3) errors[type] = "Invalid CVC"
          else errors[type] = "success";
          break;
        case "creditCard":
          if(!validator.isCreditCard(value)) errors[type] = "Invalid Card Number"
          else errors[type] = "success";
          break;
        default:
          break;
      }
    } else {
      errors[type] =  "error"
    }
  });
  return { ...errors }
};

export const getPaymentIntent = (payment_id, payment_method_type = "card") => {
  return new Promise((resolve, reject) => {
    api
      .paymentIntent({
        options: {
          parameters: {payment_id, payment_method_type},
        },
      })
      .then((response) => resolve(response.data.response))
      .catch((error) => {
        reject(error);
      });
  });
};

export const Pay = {
  sheet: (payment_id) => {
    return new Promise(async (resolve, reject) => {
      const { id, client_secret, customer } = await getPaymentIntent(
        payment_id
      ).catch(() => reject("error-getting-payment-intent"));

      await Stripe.createPaymentSheet({
        paymentIntentClientSecret: client_secret,
        customerId: customer,
        customerEphemeralKeySecret: id,
        merchantDisplayName: "Drifter",
      }).catch((err) => reject(err));

      const { paymentResult } = await Stripe.presentPaymentSheet().catch(
        (err) => reject(err)
      );

      paymentResult === PaymentSheetEventsEnum.Completed &&
        resolve(paymentResult);
      reject(paymentResult);
    });
  },
};
