import {useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    legend: {
        display: false
    },
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false,
        },
    },
    scales: {
        x: {
            grid:{
                display:false,
            },
            display: true,
            title: {
                display: false,
            },
        },
        y: {
            grid:{
                display:false
            },
            display: true,
            title: {
                display: false,
            }
        }
    },
    elements: {
        point:{
            radius: 0
        }
    }
};



 const LineChart = ({stationId, lineChartData}) => {
    const [labels, setLabels] = useState([])
    const [datas, setDatas] = useState([])
    const [averagePrice,setAveragePrice]= useState(0);
    
    const handleData = () => {
        setAveragePrice(lineChartData.average);
        for (const key in lineChartData.result) {
            setLabels(labels => [...labels, lineChartData.result[key].hour.toString()]);
            setDatas(datas => [...datas, lineChartData.result[key].price]);
        }
    }
    const data = {
        labels,
        datasets: [
            {
                data:datas,
                segment: {
                    borderColor: (ctx) => {
                        let sorted = datas.sort()
                        const yVal = ctx.p1.parsed.y;
                        if (yVal<=averagePrice)
                        {
                            return 'rgba(85, 151, 190)'
                        }else if(yVal>averagePrice && yVal<sorted.pop()){
                            return 'rgba(237, 216, 26)';
                        }else{
                            return 'rgba(239, 11, 8)';
                        }
                    }
                },
                tension:0.5,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointStyle: 'dots',
                pointRadius: 2,
                pointBackgroundColor:'white',
                pointBorderColor: 'rgb(0, 0, 0)'
            }
        ],
    };

    useEffect(()=> {
        handleData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return <Line options={options} data={data} />

}

export default LineChart;
