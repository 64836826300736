import { useState, useEffect, useRef } from "react"
import { setMapFilter } from "../redux/slices/filterStationSlice";
import {useDispatch, useSelector} from "react-redux";

const SearchResultsFab = ({
  onSearchResultsFabClick,
  bottomToolbarRef,
  setFilterMenu,
}) => {
  const dispatch = useDispatch();
  const [isExpanded, setExpanded] = useState(false)
  const {mapFilter} = useSelector((state) => state.filterStationSlice);

  const searchResultsFabRef = useRef(null)

  const handleFabItemClick = (filter) => {
    let menu =
      filter === "MAP_CHARGING"
        ? "C"
        : filter === "MAP_PARKING"
        ? "P"
        : "MAP_ALL_IN_ONE"

    setFilterMenu(menu)
    dispatch(setMapFilter(filter));
    setExpanded(false)

    bottomToolbarRef.current.swiper.update()
  }


  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setExpanded(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(searchResultsFabRef)

  return (
    <div
      className={`SearchResultsFab${isExpanded ? " expanded" : ""}`}
      ref={searchResultsFabRef}
    >
      <span
        className="SearchResultsFabExpand"
        onClick={() => onSearchResultsFabClick()}
      />

      <div
        className={`SearchResultsFabToggle -${
          mapFilter === "MAP_CHARGING"
            ? "charging"
            : mapFilter === "MAP_PARKING"
            ? "parking"
            : "park_and_charge"
        }`}
        onClick={() => {
          setExpanded(false)
          onSearchResultsFabClick()
        }}
      >
        <span className="SearchResultsFabIcon" />
      </div>

      <div className="SearchResultsFabItems">
        <div
          className="SearchResultsFabItem -park_and_charge"
          onClick={() => handleFabItemClick("MAP_ALL_IN_ONE")}
        >
          <span className="SearchResultsFabIcon" />
        </div>
        <div
          className="SearchResultsFabItem -parking"
          onClick={() => handleFabItemClick("MAP_PARKING")}
        >
          <span className="SearchResultsFabIcon" />
        </div>
        <div
          className="SearchResultsFabItem -charging"
          onClick={() => handleFabItemClick("MAP_CHARGING")}
        >
          <span className="SearchResultsFabIcon" />
        </div>
      </div>
    </div>
  )
}

export default SearchResultsFab
