import {IonContent} from "@ionic/react";
import {Swiper, SwiperSlide} from "swiper/react";
import DTCar from "../../../DTCar";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Text from "../../../Text";
import {useTranslation} from "react-i18next";
import SessionInfoCard from "../../../SessionInfoCard";
import {animated, useSpring} from "react-spring";
import {ClockIcon, PlugIcon, BlueParkIcon, ParkChargeIcon} from "../../../../assets/icons";
import DisplayCost from "../../../DisplayCost";
import ParkingCostRange from "../../../ParkingCostRange";
import {ParkChargeBottom, StartChargeButton} from "./ParkChargePartials";
import {setLoading} from "../../../../redux/slices/globalSlice";
import {parkChargeFunctions} from "../../../../helper/parkChargeFunctions";
import {setActiveSession} from "../../../../redux/slices/userSlice";
import {setSessionModal} from "../../../../redux/slices/modalSlice";
import {api} from "../../../../helper/api";
import "./ParkChargeSession.css"
import {SemiCircleProgress} from "react-semicircle-progressbar";
import ElapsedTimer from "../../../ElapsedTimer";
import OverstayCountdownLabel from "../OverstayCountdownLabel";

const ParkChargeSession = ({elapsedTime, setReceipt}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const [slideValue, setSlideValue] = useState(250)
    const [isSlideReset, setSlideReset] = useState(false)
    const {activeSession: {charge_transaction, park_session}} = useSelector(state => state.userSlice)
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        if (!charge_transaction) return;

        setCounter(charge_transaction?.countdown?.finish_second)
    }, [charge_transaction]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCounter(counter => counter > 0 && counter - 1)
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    }, []);

    const isDigitalCode = useRef(charge_transaction?.is_digital_code_active || park_session?.is_digital_code_active);

    const animationCard = useSpring({
        to: {opacity: 1, transform: "translateY(0px)"},
        from: {opacity: 0, transform: "translateY(-20px)"},
        config: {duration: 400},
        delay: 200,
    })

    const animationCard2 = useSpring({
        to: {
            opacity: charge_transaction ? 1 : 0,
            transform: charge_transaction ? "translateY(0px)" : "translateY(-20px)",
        },
        from: {opacity: 0, transform: "translateY(-20px)"}, config: {duration: 500}
    })

    const animationChargingCost = useSpring({
        to: {
            opacity: charge_transaction ? 1 : 0,
            transform: charge_transaction ? "translateY(0px)" : "translateY(-20px)",
        },
        from: {opacity: 0, transform: "translateY(-20px)"}, delay: 300
    })

    const animationParkingCost2 = useSpring({
        to: {
            opacity: 1,
            transform: charge_transaction ? "translateY(0px)" : "translateY(-20px)",
        },
        from: {opacity: 0, transform: "translateY(-20px)"}, delay: 300,
    })

    const endSession = async () => {
        dispatch(setLoading({status: true, message: ""}))

        const activeSession = await parkChargeFunctions.getActiveSession()

        if (activeSession?.success) {
            api.stopParkCharge().then((response) => {
                if (response?.data?.success) {
                    setReceipt(response?.data?.response)
                    dispatch(setActiveSession({type: null, park_session: null, charge_transaction: null}))
                    dispatch(setSessionModal({status: false}))
                }
            }).finally(() => {
                dispatch(setLoading({status: false, message: ""}))
                setSlideValue(250)
                setSlideReset(true)
            })
        }
    }

    const OverstayDescription = () => {
        if (charge_transaction?.countdown?.overstay) {
            return (
                <div className={"font-bold text-[30px] whitespace-nowrap"}>
                    +{charge_transaction?.countdown?.cost} {charge_transaction?.countdown?.currency}
                    <div className={'text-[14px] pb-8'}>
                        <ElapsedTimer seconds={counter} />
                    </div>
                </div>
            )
        }

        return (
            <>
                {t("time-remaining")}
                <div className={'font-bold text-[30px] pb-5'}>
                    <ElapsedTimer seconds={counter} />
                </div>
            </>
        )
    }

    return (
        <IonContent className="no-padding" scrollY={true}>
            <div className="flex flex-col pb-[230px] overflow-y-auto overflow-x-hidden">
                <div>
                    <div className="space-x-[20px] mt-2 overflow-visible">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={0}
                            speed={300}
                        >
                            {
                                !!charge_transaction && (
                                    <SwiperSlide>
                                        <div className={"relative mx-auto w-[220px] h-[150px] translate-y-[-10%] overstayCounter"}>
                                            {!charge_transaction?.countdown?.overstay ? <OverstayCountdownLabel label={charge_transaction?.countdown?.title} /> : null}
                                            <SemiCircleProgress
                                                percentage={charge_transaction?.countdown?.percentage}
                                                size={{width: 200, height: 200}}
                                                strokeWidth={4}
                                                strokeColor={charge_transaction?.countdown?.color}
                                                hasBackground={true}
                                                bgStrokeColor={"#E1E1E8"}
                                                showPercentValue={false}
                                            />

                                            <div className={`absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] text-primary text-[14px] mt-[50px]`}>
                                                <OverstayDescription />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            }

                            <SwiperSlide>
                                <DTCar
                                    slotNumber={charge_transaction?.transaction_connection?.id}
                                    slotLabel={park_session?.slot?.label}
                                    plate={park_session?.plate_number}
                                    park={!!park_session}
                                    charge={!!charge_transaction}
                                    value={slideValue}
                                    type="park-and-charge-session"
                                />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

                {isDigitalCode.current ? (
                    <div className="flex gap-1 flex-row justify-between items-center mx-[16px] mt-4 p-2 bg-white shadow-card rounded-[10px]">
                        <Text size={15} weight={500}>
                            {t("digital-code-using")}
                        </Text>
                        <span className="bg-purple p-1 rounded-lg text-[#fff]">
                            {charge_transaction?.digital_code_text?.toUpperCase() || park_session?.digital_code_text?.toUpperCase()}
                        </span>
                    </div>
                ) : null}

                <div className="px-[16px]">
                    <SessionInfoCard
                        connectionIcon={charge_transaction?.transaction_connection?.connection_type?.icon_url}
                        connectionPower={charge_transaction?.transaction_connection?.power_kw + "kW"}
                        animation={charge_transaction ? animationCard2 : animationCard}
                        titleIcon={!charge_transaction ? BlueParkIcon : ParkChargeIcon}
                        titleText={!charge_transaction ? t("parking") : t("park-and-charge")}
                        firstLine={!!charge_transaction}
                        firstLineIcon={PlugIcon}
                        firstLineText={t("charged")}
                        firstLineDesc={
                            <>{charge_transaction?.consumed_amount}<span
                                className="text-[14px] font-semibold">{t("kWh")}</span></>
                        }
                        secondLine={!!charge_transaction}
                        secondLineIcon={ClockIcon}
                        secondLineText={t("elapsed-time")}
                        collapsable={!!charge_transaction}
                        totalElapsedTime={elapsedTime.park}
                        chargeElapsedTime={elapsedTime.charge}
                    />

                    {!isDigitalCode.current && charge_transaction ? (
                        <div className={`mt-[33px] ${!!charge_transaction === true && "mb-[20px]"} px-[14px]`}>
                            <DisplayCost
                                style={animationChargingCost}
                                color={"#34D298"}
                                cost={charge_transaction?.payment_amount ?? 0}
                                text={t("charging-cost")}
                                desc={t("unit-price")}
                                descPrice={charge_transaction?.transaction_station?.charge_price ?? 0}
                                divider={"bottom"}
                            />
                        </div>
                    ) : null}

                    <animated.div
                        className={`flex flex-col px-[14px] justify-between items-center text-center ${charge_transaction ? "mt-[16px]" : "mt-[32px]"}`}
                        style={animationParkingCost2}
                    >
                        <DisplayCost
                            color={"#5E53E1"}
                            cost={park_session?.details?.parking_cost}
                            text={t("parking-cost")}
                            offense={park_session?.session_status_id === 2}
                        />

                        <ParkingCostRange
                            unitPrice={park_session?.progressed?.label}
                            prices={park_session?.park?.prices ?? []}
                            percentage={park_session?.progressed?.percent}
                        />
                    </animated.div>

                </div>

                {(!charge_transaction || charge_transaction?.consumed_amount === 0) && <StartChargeButton/>}

                <ParkChargeBottom setSlideValue={setSlideValue} endSession={endSession} isSlideReset={isSlideReset} setSlideReset={setSlideReset}/>

            </div>
        </IonContent>
    )
}

export default ParkChargeSession;
