import { IonContent, IonModal } from '@ionic/react';
import CountryCodes from '../CountryCodes';
const SelectCountry = ({isCountryModal, setCountryModal, country, handleCountryClick}) => {
    return (
        <IonModal
        isOpen={isCountryModal}
        breakPoints={[0.1, 0.85, 1]}
        initialBreakpoint={0.85}
        canDismiss={true}
        handle={true}
        onDidDismiss={() => setCountryModal(false)}
        >
            <IonContent>
                <CountryCodes currentCountry={country.countryCode ?? ""} handleClick={handleCountryClick} fromWhere={"editProfile"}/>
            </IonContent>
        </IonModal>
    );
}

export default SelectCountry;
