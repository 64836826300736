import WarningModal from '../../pages/WarningModal'
import {useDispatch, useSelector} from 'react-redux'
import {setVersionControlModal} from '../../redux/slices/modalSlice'
import Text from '../Text'
import Icon from '../Icon'
import {useTranslation} from 'react-i18next'
import Button from '../Button'
import {Capacitor} from "@capacitor/core";
import {Browser} from "@capacitor/browser";
const VersionControlModal = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const {status} = useSelector(state => state.modalSlice.versionControlModal)
    const {config} = useSelector((state) => state.userSlice)


    const redirectToStore = async () => {
        if (Capacitor.isNativePlatform()) {
            if (Capacitor.getPlatform() === "android") {
                await Browser.open({url: config.store.android_url});
            } else if (Capacitor.getPlatform() === "ios") {
                await Browser.open({url: config.store.ios_url});
            } else {
                return false;
            }
        }
    }

    return (
        <WarningModal
            isOpen={status}
            onDidDismiss={() => status && dispatch(setVersionControlModal(false))}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            canDismiss={false}
        >
            <div className='w-full flex items-center justify-center flex-col gap-[40px]' style={{
                height: "calc(100vh - 100px)"
            }}>
                <Icon name="update-required" size={200}/>
                <Text size="24px" dangerouslySetInnerHTML={{__html: t("new-vers-available")}}/>
                <Button onClick={() => redirectToStore()}>
                    <Text color="#fff">
                        {t("update")}
                    </Text>
                </Button>
            </div>
        </WarningModal>
    )
}

export default VersionControlModal