const RegisterProfileProgress = ({step, count, onClick}) => {
    const handleClicked = (e) => {
        const targetIndex = Number(e.target.getAttribute("data-index"))

        if (e.target.classList.contains("done")) {
            onClick(targetIndex);
        }
    }

    return (
        <div className="SetUpProfileProgress">
            {
                [...Array(count)].map((_, index) => (
                    <div key={index} className={"SetUpProfileProgressItem"}>
                        <div
                            className={`SetUpProfileStep ${step > index ? "done" : ""} ${step === index ? "active" : ""}`}
                            data-index={index}
                            onClick={handleClicked}
                        >
                            {index + 1}
                        </div>
                        {index !== count - 1 && <div className="divider"></div>}
                    </div>
                ))
            }
        </div>
    )
}

export default RegisterProfileProgress;
