import { IonContent, useIonRouter } from "@ionic/react";
import PageToolbar from "../components/PageToolbar";
import { PaymentMethods } from "../components/PaymentMethods";

import { useTranslation } from "react-i18next";
const PaymentMethodPage = () => {
  const { t } = useTranslation();
  const router = useIonRouter();

  return (
    <IonContent className={`PaymentMethodModal no-padding`}>
      <PageToolbar
        title={t("payment-methods")}
        leftButtonType="back"
        onClick={() => router.push('/plan-and-payments', 'back')}
      />
      <IonContent className="no-padding">
        <PaymentMethods />
      </IonContent>
    </IonContent>
  );
};

export default PaymentMethodPage;
