import Text from "../../Text";
import { useTranslation } from "react-i18next";

const TicketVehicle = ({logo, brand_text, model_text, plate, setEditModalOpen }) => {
  const { t } = useTranslation();
  return (
    <div className="InfoArea mt-[19px]">
      <div className="CarInfos">
        <img src={logo} alt="" className="CarBrandPosition" />
        <div className="CarInfoText text-left">
          <Text size={20} weight={700} color="#353355">
            {brand_text}
          </Text>
          <Text size={16} weight={400} color="#353355">
            {model_text}
          </Text>
          <div className="AddCarCheckboxPlate">{plate}</div>
        </div>
      </div>
      <div className="ChangeButtonWrapper">
        <Text
          size={12}
          weight={500}
          color="#B11D94"
          transform="capitalize"
          onClick={() => setEditModalOpen(true)}
        >
          {t("change")}
        </Text>
      </div>
    </div>
  );
};

export default TicketVehicle;
