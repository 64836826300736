import { IonSlide, useIonRouter } from "@ionic/react";
import { t } from "i18next";
import { useState } from "react";
import { api } from "../../helper/api";
import { setAlert } from "../../redux/slices/globalSlice";
import { store } from "../../redux/store";
import Button from "../Button";
import Icon from "../Icon";
import Text from "../Text";

const SetBusinessCard = ({
  businessSlider,
  paymentMethods,
  setBusinessSlideModalOpen,
  businessId,
  getBusinessDetail,
  currentBusinessAccount,
  comingFrom,
}) => {
  const [choosenCard, setChoosenCard] = useState(null);

  const router = useIonRouter();

  const setBusinessCard = async (payment_id) => {
    api.setCompanyCard(businessId, payment_id).then(({data}) => {
      if (data.success) {
        businessSlider.current.swiper.slideNext();
        comingFrom === "business-detail" && getBusinessDetail();
      } else
        store.dispatch(
          setAlert({ status: true, message: t("try-again"), type: "error" })
        );
    })
  };

  return (
    <IonSlide>
      <div className="BusinessCardSlide">
        <div className="CardListWrapper">
          {paymentMethods.length > 0 ? (
            paymentMethods.map((p) => (
              <label
                className="BusinessRadioWrapper"
                key={p.id}
                htmlFor={p.id}
                onClick={() => setChoosenCard(p.id)}
              >
                <input
                  type="radio"
                  id={p.id}
                  name="p.card.last4"
                  defaultChecked={
                    currentBusinessAccount.payment_method &&
                    p.id === currentBusinessAccount.payment_method?.id
                  }
                />
                <div className="BusinessRadio">
                  {p.card.brand === "mastercard" ? (
                    <Icon
                      name={"mastercard"}
                      height={31}
                      width={49}
                      margin={"0 17px 0 0"}
                    />
                  ) : p.card.brand === "visa" ? (
                    <Icon
                      name={"visa"}
                      fill={"#BFC1C3"}
                      height={20}
                      width={62}
                      margin={"0 17px 0 0"}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="BusinessRadioContent">
                    <Text
                      size="14px"
                      weight="400"
                      color="#353355"
                      otherStyles={"opacity: 0.6; text-transform: capitalize"}
                      align="left"
                    >
                      {p.card.brand}
                    </Text>
                    <Text
                      size="12px"
                      weight="500"
                      color="#353355"
                      margin="6px 0 0"
                      align="left"
                    >
                      **** **** **** {p.card.last4}
                    </Text>
                  </div>
                </div>
              </label>
            ))
          ) : (
            <div className="NoItemsWrapper">
              <div className="NoItems -no-icon">
                <Icon
                  name={"no-card"}
                  width={52}
                  height={37}
                  fill="rgba(190, 190, 217, 0.18)"
                />
              </div>
              <Text size="22px" weight="600" margin="12px 0 0">
                {t("oops")}...
              </Text>
              <Text size="14px" weight="400" margin="12px 0 auto">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("no-payment-method-yet"),
                  }}
                ></div>
              </Text>
            </div>
          )}
        </div>
        <div className="SetBusinesCardButtons">
          <Text
            size={16}
            weight={700}
            color={"var(--dt-pink)"}
            dangerouslySetInnerHTML={{ __html: t("add-new-card") }}
            onClick={async () => {
              await setBusinessSlideModalOpen(false);
              router.push("/add-payment-method", "forward");
            }}
          />
          {paymentMethods.length !== 0 && <Button
            margin={"28px 0 0"}
            onClick={() => {
              setBusinessCard(choosenCard);
            }}
          >
            {t("set-my-business-card")}
          </Button>}
        </div>
      </div>
    </IonSlide>
  );
};

export default SetBusinessCard;
