// Ionic & React
import { useState, useEffect, useRef, memo } from "react"
import { IonContent, IonModal } from "@ionic/react"

import "./SearchModal.css";

// Components
import PageToolbar from "../components/PageToolbar";
import SearchResultsFab from "../components/SearchResultsFab";
import SearchInput from "../components/SearchStations/SearchInput";
import SearchResults from "../components/SearchStations/SearchResults";

// Helper
import Api from "./../helper/api";
import { useDispatch, useSelector } from "react-redux";
import { setRecentSearches } from "../redux/slices/userSlice";
import { setSearchModal, setFilterModal } from "../redux/slices/modalSlice";
import { setLoading } from "../redux/slices/globalSlice";
import RecentSearches from "../components/SearchStations/RecentSearches";

const SearchModal = ({
  pos,
  stations,
  currentStation,
  setCurrentStation,
  setStationDetailOpen,
  setFilterMenu,
  bottomToolbarRef,
  className,
  chargingSpeed,
  ...props
}) => {
  const api = new Api();
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [title, setTitle] = useState("parking-areas");
  
  const searchInputRef = useRef(null);
  const {recentSearches} = useSelector((state) => state.userSlice);
  const {searchModal} = useSelector((state) => state.modalSlice);


  useEffect(() => {
    const timeout = setTimeout(() => {
      searchText && handleSearchInputChange();
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);


  const getGroupFromArray = (list, key) =>{
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const handleSearchResult = (array) => {
    setSearchResults(getGroupFromArray(array, "TYPE").parking);
  };
  // GET STATIONS
  const GetStations = async (param) => {
    await api
      .getParkPoints(param)
      .then((response) => {
        if(response.data.data) handleSearchResult(response.data.data);
        else setSearchResults({})
      })
  };

  const addNewRecentSearch = (station) => {
    if(recentSearches === [] || recentSearches?.find(r => r.entry_id === station.entry_id) === undefined){
      dispatch(setRecentSearches(station))
    }
  }

  const handleSearchInputChange = () => {
    if (searchText) {
      GetStations({
        options: {
          parameters: {
            lat: pos.lat,
            lon: pos.long,
            keyword: searchText,
          },
        },
      });
    } else {
      setSearchText("");
      setSearchResults({});
    }
  };

  const GetStationDetail = (station) => {
    dispatch(setLoading({status: true, message: ""}))
    api
        .getParkingStationDetail({
          options: {
            parameters: {
              park_id: station.entry_id,
            },
            paginate: false,
          },
        })
        .then((response) => {
          setCurrentStation({
            ...station,
            DETAIL: {
              ...response.data.data[0],
            },
          });
        })
        .then(() => {
          dispatch(setSearchModal(false))
          setStationDetailOpen(true)
          dispatch(setLoading({status: false, message: ""}))
        })
  };

  const recentTitleStyle = {
    marginBottom:
      recentSearches && recentSearches.length ? "30px" : "0",
  }

  
  useEffect(() => {
    if(searchModal.status){
      handleNearByStations().finally(() => setStationsNearByMeLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchModal, stations]);

  const [stationsNearByMe, setStationsNearByMe] = useState([])
  const [stationsNearByMeLoading, setStationsNearByMeLoading] = useState(false)

  const handleNearByStations = async () => {
    setStationsNearByMeLoading(true)
    setStationsNearByMe([])
    if(stations){
      // eslint-disable-next-line array-callback-return
      stations.map(station => {
        if(station.DISTANCE <= 1){
          setStationsNearByMe(prev => ([...prev, station]))
        }
      })
    }
  }

  useEffect(() => {
    if(searchModal.status){
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 500)
    }
  }, [searchModal.status])

  return (
    <IonModal
      isOpen={searchModal.status}
      className={`SearchModal${className ? " " + className : ""} fullscreen`}
      onDidDismiss={() => dispatch(setSearchModal(false))}
      {...props}
    >
      <PageToolbar
        title="Search"
        leftButtonType="back"
        onClick={() => dispatch(setSearchModal(false))}
        backgroundColor="#fff"
      />

      <IonContent className="no-padding">
        <div className={`SearchWrapper${searchText&&searchResults.length ? " searching" : ""}`}>
          <SearchInput
            searchText={searchText}
            setSearchText={setSearchText}
            searchInputRef={searchInputRef}
            setSearchResults={setSearchResults}
          />
          <RecentSearches
          stationsNearByMe={stationsNearByMe}
          stationsNearByMeLoading={stationsNearByMeLoading}
          setCurrentStation={setCurrentStation}
          GetStationDetail={GetStationDetail}
          />
          <SearchResults
          searchResults={searchResults}
          searchText={searchText}
          GetStationDetail={GetStationDetail}
          addNewRecentSearch={addNewRecentSearch}
          title={"parking-areas"}
          recentSearches={recentSearches}
          recentTitleStyle={recentTitleStyle}
          />
        </div>
        <SearchResultsFab
          setFilterMenu={setFilterMenu}
          onSearchResultsFabClick={() => dispatch(setFilterModal(true))}
          bottomToolbarRef={bottomToolbarRef}
        />
      </IonContent>
    </IonModal>
  );
};

export default memo(SearchModal)
