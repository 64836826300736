// React & Ionic
import React, {useEffect, memo} from "react"
import {IonApp, setupIonicReact} from "@ionic/react"

import '@capacitor-community/camera-preview'

// Redux
import {useDispatch, useSelector} from "react-redux"

// Core CSS required for Ionic components to work properly
import "@ionic/react/css/core.css"

// Basic CSS for apps built with Ionic
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"

// Optional CSS utils that can be commented out
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

// Swiper slider
import 'swiper/css';
import 'swiper/less/pagination';

// Drifter theme
import "./assets/theme/main.css"

import Routes from "./Routes";
import {getConfigFromApi, updateUserInfo} from "./helper/globalFunctions";
import i18next from "i18next";

import DTAlert from "./components/DTAlert";
import DTLoader from "./components/DTLoader"
import {api} from "./helper/api";
import {clearLocations} from "./redux/slices/userSlice";
import { VersionControlModal } from "./components"

//========================
//=========== Drifter App
//========================

setupIonicReact({
  scrollAssist: false,
})

const App = () => {
  const {token, language, locations} = useSelector((state) => state.userSlice)
  const dispatch = useDispatch()
  const {loading} = useSelector((state) => state.globalSlice)

  useEffect(() => {
    if(token){
      updateUserInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const checkLanguage = async () => {
    await i18next.changeLanguage(language)
  }

  // Hardware back button handled globally by page toolbar back button
  document.addEventListener('ionBackButton', (ev) => {
    ev.detail.register(10, () => {
      document.getElementsByTagName('ion-back-button').item(0).click();
    })
  });

  const traceLocation = () => {
    if(locations.length){
      api.sendLocation(locations)
        .then(res => res?.data?.success && dispatch(clearLocations()))
    }
  }

  useEffect(() => {
    
    if(!token) return;
    const interval = setInterval(() => traceLocation(), 60000)
    return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    checkLanguage().catch(() => window.location.reload())
    getConfigFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <IonApp>
      <Routes/>
      <DTAlert/>
      {loading.status && <DTLoader message={loading.message}/>}
      <VersionControlModal />
    </IonApp>
  )
}

export default memo(App)
