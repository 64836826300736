import {useSelector} from "react-redux";
import {animated, useSpring} from "react-spring";
import {useTranslation} from "react-i18next";
import SessionInfoCard from "../../../../SessionInfoCard";
import BlueParkIcon from "../../../../../assets/icons/blue-park-icon.svg";
import ClockIcon from "../../../../../assets/icons/clock-icon.svg";
import DisplayCost from "../../../../DisplayCost";
import ParkingCostRange from "../../../../ParkingCostRange";

const ParkInfo = ({elapsedTime}) => {
  const {activeSession: {park_session}} = useSelector(state => state.userSlice)

  const parkingPrice = park_session?.details?.parking_cost
  const parkPrices = park_session?.park?.prices
  const digitalCode = park_session?.is_digital_code_active
  const offense = park_session?.session_status_id === 2

  const animation1 = useSpring({
    to: {opacity: 1, transform: "translateY(0px)"},
    from: {opacity: 0, transform: "translateY(-20px)"},
  });

  const animation2 = useSpring({
    to: {opacity: 1, transform: "translateY(0px)"},
    from: {opacity: 0, transform: "translateY(-20px)"},
    delay: 100,
  });

  const {t} = useTranslation();

  return (
    <div className="px-[16px]">
      <SessionInfoCard
        animation={animation1}
        titleIcon={BlueParkIcon}
        titleText={t("parking")}
        firstLine={false}
        secondLine={true}
        secondLineIcon={ClockIcon}
        secondLineText={t("elapsed-time")}
        collapsable={false}
        totalElapsedTime={elapsedTime}
      />

      {!digitalCode && (
        <div className="mt-[20px] px-[14px]">
          <animated.div
            className="flex space-x-2 justify-between items-center text-center text-[#353355] mt-[23px] px-[3px]"
            style={animation2}
          >
            <DisplayCost color={"#5E53E1"} cost={parkingPrice} text={t("parking-cost")} offense={offense}/>
          </animated.div>

          <ParkingCostRange
            unitPrice={park_session?.progressed?.label}
            prices={parkPrices}
            percentage={park_session?.progressed?.percent}
          />
        </div>)}
    </div>
  );
};

export default ParkInfo;
