import { useTranslation } from 'react-i18next';
import Text from '../Text';

const MenuItem = (props) => {
    const {t} = useTranslation()
    return (
      <div className="DTItem" onClick={() => props.itemOnClick()}>
        <Text
        dangerouslySetInnerHTML={{ __html: t(props.label)}}
        />
        {props.counter ? (
          <span className="DTItemBadge">{props.counter}</span>
        ) : (
          ""
        )}
      </div>
    );
}

export default MenuItem;
