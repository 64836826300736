import Car from "../../assets/images/car.png"
import parkingLights from "../../assets/images/vectors/parking_lights.svg"
import ChargingIcon from "../../assets/icons/charging-icon.svg"
import ParkSession from "./ParkSession"
import ChargeSession from "./ChargeSession"
import ParkChargeSession from "./ParkChargeSession"

const DTCar = ({
    slotNumber,
    slotLabel,
    plate,
    value,
    park,
    charge,
    type
}) => {
    return (
        <div>
            {
                type === "park-session" ? (
                    <ParkSession
                    value={value}
                    slotNumber={slotNumber}
                    plate={plate}
                    parkingLights={parkingLights}
                    car={Car}
                    />
                ) : type === "park-and-charge-session" ? (
                    <ParkChargeSession
                    slotLabel={slotLabel}
                    slotNumber={slotNumber}
                    value={value}
                    plate={plate}
                    parkingLights={parkingLights}
                    car={Car}
                    chargingIcon={ChargingIcon}
                    park={park}
                    charge={charge}
                    />
                ) : (
                    <ChargeSession
                    car={Car}
                    chargingIcon={ChargingIcon}
                    slotNumber={slotNumber}
                    />
                )
            }
        </div>
    );
}

export default DTCar;
