import React, { useEffect, useState } from 'react'
import Text from '../Text'
import { Capacitor } from '@capacitor/core'
import {App as CapacitorApp} from '@capacitor/app';
import { useSelector } from 'react-redux';

const VersionLabel = () => {

    const [versionLabel, setVersionLabel] = useState({})
    const {menuModal} = useSelector(state => state.modalSlice)
    const isNativePlatform = Capacitor.isNativePlatform()
    const {config} = useSelector(state => state.userSlice)

    const handleVersion = async () => {
        const appInfo = isNativePlatform && await CapacitorApp.getInfo() 
        if(isNativePlatform){
            setVersionLabel({
                appName: appInfo.name ?? process.env.REACT_APP_TITLE,
                app: `${appInfo.version}.${appInfo.build}`,
                server: `${config?.version?.live}`
            })
        }else{
            setVersionLabel({
                appName: appInfo.name ?? process.env.REACT_APP_TITLE,
                app: null,
                server: `${config?.version?.live}`
            })
        }
      }

    useEffect(() => {
        menuModal.status && handleVersion()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuModal])
  return (
    <>
        <Text weight="600" size={18} align="right" margin="16px 0 0 0">
            Drifter V {versionLabel.app ? `${versionLabel.app} /` : null} {versionLabel.server}
        </Text>
    </>
  )
}

export default VersionLabel