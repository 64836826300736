// Ionic & React
import {IonContent, IonRippleEffect} from "@ionic/react";

// Components
import Logo from "../Logo";
import Text from "../Text";

//Hooks
import {useTranslation} from "react-i18next";
import ReceiptFields from "./ReceiptFields";

const ParkchargeReceipt = ({parkChargeReceipt, close}) => {

  const {t} = useTranslation()

  return (
    <IonContent className="YourReceiptModalContent">
      <div className="YourReceiptModal overflow-hidden">
        <div className="YourReceipt overflow-y-auto overflow-x-hidden">
          <h2 className="YourReceiptTitle text-center">{t("your-receipt")}</h2>

          <div className="ReceiptContainer">
            <div className="Receipt">
              <div className="ReceiptLogo">
                <Logo type="Mono" height={38} width={33}/>
              </div>
              <Text size="18px" weight="500" margin="12px 0 0">
                {t("have-nice-trip")}
              </Text>
              <div className="ReceiptStrokes"/>
              <ReceiptFields receipt={parkChargeReceipt}/>
            </div>
          </div>
          <button className="DTButtonPink ion-activatable" onClick={close}>
            {t("done")}
            <IonRippleEffect/>
          </button>
        </div>
      </div>
    </IonContent>
  );

};

export default ParkchargeReceipt
