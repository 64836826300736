// Ionic & React
import { IonPage, IonContent } from "@ionic/react"

// Redux
import { useSelector } from "react-redux"

// Components
import Logo from "./../components/Logo"
import Text from "./../components/Text"
import Button from "./../components/Button"

import { useTranslation } from "react-i18next"

const NotFound = () => {
  const { token } = useSelector((state) => state.userSlice)

  const { t } = useTranslation()

  return (
    <IonPage>
      <IonContent>
        <div className="Landing" style={{ height: "100%" }}>
          <div className="LandingWrapper">
            <Logo height={124} width={116} type="MonoDark" />
            <Text
              select="none"
              cssClass="title"
              color="var(--dt-primary)"
              aling="center"
              margin="0 auto 0 0"
            >
              {t("not-found")}
            </Text>
            <Text
              select="none"
              cssClass="subtitle"
              color="var(--dt-primary)"
              aling="center"
              margin="12px auto 0 0"
            >
              {t("page-doesnt-exist")}
            </Text>
            <Button
              theme="link"
              routerLink={token ? "/map" : "/enter-phone-number"}
              routerDirection="back"
              margin="26px auto 0 0"
            >
              {t("back-to-drifting")}
            </Button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default NotFound
