import {IonContent, IonPage} from "@ionic/react";
import {RegisterProfile} from "../components";
import PageToolbar from "../components/PageToolbar";

const SetUpProfile = () => {

    return (
        <IonPage className="dark-page">
            <PageToolbar />
            <IonContent scrollEvents={false}>
                <div className="Landing">
                    <div className="LandingWrapper">
                        <RegisterProfile/>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default SetUpProfile
