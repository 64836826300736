import { IonModal } from '@ionic/react';
import {useState} from 'react';
import { useTranslation } from 'react-i18next';
import DTInput from '../DTInput';
import Icon from '../Icon';
import PageToolbar from '../PageToolbar';
import Text from '../Text';
import SelectCountry from './SelectCountry';
import { useSelector } from 'react-redux';

const AddManualAddress = ({isOpen, setOpen, setAddress}) => {
    const {t} = useTranslation()
    const [isCountryModal, setCountryModal] = useState(false)
    const [country, setCountry] = useState({})
    const [formData, setFormData] = useState({})

    const {street, zip_code, city, country: user_country} = useSelector(state => state.userSlice.user)

    const handleCountryClick = (country) => {
        setCountry(country)
        setCountryModal(false)
        setFormData(prev => ({...prev, country: country.countryShortName}))
    }

    const handleSubmit = e => {
        e.preventDefault()
        setAddress({
            ssn: formData.ssn,
            street: formData.street,
            zip_code: formData.zipCode,
            city: formData.city,
            country: formData.country
        })
        setOpen(false)
    }

    return (
        <IonModal
        isOpen={isOpen}
        onDidDismiss={() => setOpen(false)}
        >
            <PageToolbar
            title={t("set-up-profile")}
            leftButtonType="back"
            onClick={() => setOpen(false)}
            backgroundColor="#fff"
        />
            <form onSubmit={handleSubmit}>
                <div className='p-4 flex flex-col justify-center items-center gap-4'>
                    <DTInput
                        label={t("street")}
                        placeholder={street}
                        type={"text"}
                        autoFocus
                        onChange={e => setFormData(prev => ({...prev, street: e.target.value?.toUpperCase()}))}
                    />
                    <DTInput
                        label={t("zip-code")}
                        placeholder={zip_code}
                        type={"number"}
                        autoFocus
                        onChange={e => setFormData(prev => ({...prev, zipCode: e.target.value}))}
                    />
                    <DTInput
                        label={t("city")}
                        placeholder={city }
                        type={"text"}
                        autoFocus
                        onChange={e => setFormData(prev => ({...prev, city: e.target.value?.toUpperCase()}))}
                    />
                    <div onClick={() => setCountryModal(true)} className='w-full h-[55px] pr-4'>
                        <div className='w-full h-[55px] flex justify-between items-center border-[var(--dt-primary-300)] border-[1px] p-4 rounded-[12px]'>
                            <Text color="#9A99AA">{t("select-country")}</Text>
                            <div className='flex flex-row items-center justify-center gap-2'>
                            {(country.countryName || user_country) && <Text cssClass="CountryName">{country.countryName ?? user_country}</Text>}
                            <Icon
                            name={(country.countryName || user_country) ? "pink-thick" : "arrow-up"}
                            size={20}
                            className={`${!(country.countryName || user_country) && "rotate-180"}`}
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full absolute bottom-[16px] p-4'>
                    <button type='submit' className='w-full h-[55px] rounded-full font-[600] bg-primary text-white'>Submit</button>
                </div>
            </form>
            <SelectCountry
                country={country.countryShortName ?? {countryShortName: user_country}}
                isCountryModal={isCountryModal}
                setCountryModal={setCountryModal}
                handleCountryClick={handleCountryClick}
            />
        </IonModal>
    );
}

export default AddManualAddress;
