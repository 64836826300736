// import React, { useEffect, useState, useRef, memo } from "react";
import { useTranslation } from "react-i18next";
// import { Swiper, SwiperSlide } from "swiper/react";
import DTToast from "./DTToast";

function Notifications(props) {

  // TODO: This component will refactor after notification module has been ready
  
  const {
    isEmailToastShown,
    setEmailToastShown,
    toggleCheckMail,
    // isLocationAlertModalOpen,
    // setLocationAlertModalOpen,
    // switchToLocationSettings,
  } = props;

  const { t } = useTranslation();

  return (
    <div className="NotificationsWrapper --oneView">
      {isEmailToastShown && (
        <DTToast
            theme="primary"
            title={t("confirm-mail")}
            message={t("check-profile")}
            isShown={true}
            onToastClick={() => setEmailToastShown(false)}
            onToastMessageClick={() => toggleCheckMail(true)}
          />
      )
    }
    </div>
  )

  // const [countNotification, setCountNotification]  = useState(1)
  
  // useEffect(() => {
  //   if(isEmailToastShown) {
  //     setCountNotification(prev => prev + 1)
  //   }
  //   if(isLocationAlertModalOpen) {
  //     setCountNotification(prev => prev + 1)
  //   }
  // }, [isEmailToastShown, isLocationAlertModalOpen]);

  // const Notification = () => {
  //   return (
  //     <div>
  //     {isEmailToastShown ? (
  //       <DTToast
  //           theme="primary"
  //           title={t("confirm-mail")}
  //           message={t("check-profile")}
  //           isShown={true}
  //           onToastClick={() => setEmailToastShown(false)}
  //           onToastMessageClick={() => toggleCheckMail(true)}
  //         />
  //     ) : isLocationAlertModalOpen ? (
  //         <DTToast
  //           className="ios-safe-area"
  //           theme="error"
  //           title={t("turn-on-location-desc")}
  //           message={t("settings")}
  //           isShown={true}
  //           onToastMessageClick={() => {
  //             setLocationAlertModalOpen(false);
  //             switchToLocationSettings();
  //           }}
  //         />
  //     ) : null
  //   }
  //     </div>
  //   )
  // }



  // return (
  //   <div className={`NotificationsWrapper ${countNotification === 1 && "--oneView"}`}>
  //     {
  //       countNotification === 1 ? (
  //         <Notification />
  //       ) : (
  //         <Swiper
  //           spaceBetween={20}
  //           speed={300}
  //           allowTouchMove={true}
  //           slidesPerView={1.4}
  //         >
  //           {isEmailToastShown && <SwiperSlide>
  //             <DTToast
  //               theme="primary"
  //               title={t("confirm-mail")}
  //               message={t("check-profile")}
  //               isShown={true}
  //               onToastClick={() => setEmailToastShown(false)}
  //               onToastMessageClick={() => toggleCheckMail(true)}
  //             />
  //           </SwiperSlide>}
  //           {isLocationAlertModalOpen && <SwiperSlide>
  //             <DTToast
  //               className="ios-safe-area"
  //               theme="error"
  //               title={t("turn-on-location-desc")}
  //               message={t("settings")}
  //               isShown={true}
  //               onToastMessageClick={() => {
  //                 setLocationAlertModalOpen(false);
  //                 switchToLocationSettings();
  //               }}
  //             />
  //           </SwiperSlide>}
  //         </Swiper>
  //       )
  //     }
      
  //   </div>
  // );
}

export default Notifications;
