// Ionic & React
import {  IonContent, IonRippleEffect } from "@ionic/react";


// Components
import Logo from "../Logo";
import Text from "../Text";


//Hooks
import {useTranslation} from "react-i18next";
import ReceiptFields from "./ReceiptFields";

const ChargeReceipt = ({
  chargeLevel,
  chargingReceipt,
  close
}) => {

  const {t} = useTranslation()

  const discount = {isDiscount: false, discountDetail: {id: 456789, amount: 30}};

  const drifterCard = {isDrifterCard: false, id: 123456, balance: 12};

  return (
    <IonContent className="YourReceiptModalContent">
      <div className="YourReceiptModal h-full">
        <div className="YourReceipt">
          <h2 className="YourReceiptTitle">{t("your-receipt")}</h2>

          <div className="ReceiptContainer">
            <div className="Receipt">
              <div className="ReceiptLogo">
                <Logo type="Mono" height={38} width={33} />
              </div>
              <Text size="18px" weight="500" margin="12px 0 0">
              {t("have-nice-trip")}
              </Text>

              <div className="ReceiptStrokes"/>
              <ReceiptFields from="receipt" receipt={chargingReceipt} discount={discount} chargeLevel={chargeLevel} />
            {/* {
              chargingReceipt.charging_session_cost ? (
                <>
                  {chargingReceipt.digital_code ? (
                    <div className="flex gap-1 flex-row justify-between items-center rounded-[10px] mb-3">
                    <Text size={15} weight={500} >
                      {t("digital-code-using")}
                    </Text>
                    {chargingReceipt.digital_code && <span className="bg-purple p-1 rounded-lg text-[#fff] uppercase">{chargingReceipt.digital_code}</span>}
                  </div>
                  ) : <ReceiptFields from="receipt" receipt={chargingReceipt} discount={discount} chargeLevel={chargeLevel} />}
                </>
              ) : (
                <div className="py-3">
                  <Text weight="600" transform="unset">
                    {t("not-paying")}
                  </Text>
                </div>
              )
            } */}
            </div>
            {drifterCard.isDrifterCard && (
              <>
                <div>
                  <p className="text-white font-semibold text-[14px]">
                    - {t("SEK")}
                    <span className="font-medium text-[#C0C0CB]">
                    {t("charged-from-drifter")}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
          <button className="DTButtonPink ion-activatable" onClick={close}>
            {t("done")}
            <IonRippleEffect />
          </button>
        </div>
      </div>
    </IonContent>
  );
};

export default ChargeReceipt;
