import CountryItem from './CountryItem';
import {memo, useEffect, useState} from "react";

const CountryList = ({countries, onItemClick}) => {
    const [filteredCountry, setFilteredCountry] = useState([]);

    useEffect(() => {
        if (countries && countries.length > 0) {
            setFilteredCountry(countries)
        }
    }, [countries]);

    const runFilter = (e) => {
        const searchText = e.target.value;
        const isNum = /^\d+$/.test(searchText);

        setFilteredCountry(countries.filter(c => {
            if (isNum) {
                return c.countryCode.startsWith(searchText)
            }

            if (searchText.startsWith("+")) {
                return searchText.split("+")[1] && c.countryCode.startsWith(searchText.split("+")[1])
            }

            return c.countryName.toUpperCase().startsWith(searchText.toUpperCase())
        }))
    }

    return (
        <div className="CountryList">
            <input className="input" style={inputStyles} type="search" autoFocus onChange={runFilter}/>

            {
                filteredCountry.map((country, idx) => (
                    <CountryItem key={idx} country={country} onItemClick={onItemClick} dialingCodeShown/>
                ))
            }
        </div>
    )
}

export default memo(CountryList)

const inputStyles = {
    border: '1px solid #cbcbcb',
    color: '#525252',
    width: '95%',
    height: '40px',
    margin: '15px',
    marginTop: '15px',
    paddingLeft: '30px',
    borderRadius: 12
};
