import { IonRippleEffect } from "@ionic/react";
const AddNewButton = ({ onButtonClick, number, text }) => {
  return (
    <button
      className="AddNewButton ion-activatable"
      onClick={onButtonClick}
      disabled={false}
    >
      {text}
      <span>{number}</span>
      <IonRippleEffect />
    </button>
  );
};

export default AddNewButton;
