import { useState } from "react";
import { animated, useSpring } from "react-spring";

import ElapsedTimer from "./ElapsedTimer";

import DownArrowIcon from "../assets/icons/down-arrow-icon.svg"
import UpArrowIcon from "../assets/icons/up-arrow-icon.svg"

import { useTranslation } from "react-i18next";
import Text from "./Text";

const SessionInfoCard = ({
  animation,
  titleIcon,
  titleText,
  firstLine,
  firstLineIcon,
  firstLineText,
  firstLineDesc,
  secondLine,
  secondLineIcon,
  secondLineText,
  collapsable,
  totalElapsedTime,
  setTotalElapsedTime,
  parkElapsedTime,
  chargeElapsedTime,
  setParkElapsedTime,
  setChargeElapsedTime,
  connectionIcon,
  connectionPower
}) => {

  const [showInfo, setShowInfo] = useState(false);

  const showInfoAnim = useSpring({
    to: {
      opacity: showInfo ? 1 : 0,
      transform: collapsable ? "translateY(0px)" : "translateY(-20px)",
    },
    from: { opacity: 0, transform: "translateY(-20px)" },
  })

  const { t } = useTranslation();

  return (
    <animated.div
      className="bg-white shadow-card rounded-xl mt-[16px] p-[14px]"
      style={animation}
    >
      <div className="flex justify-between items-center">
        <div className="flex w-full gap-2 items-center">
          <img src={titleIcon} alt="" />
          <span className="text-[18px] font-semibold text-primary">
            {titleText}
          </span>
        </div>
        {connectionIcon && connectionPower ? <span className="flex flex-row gap-2 items-center pr-3">
        <img src={connectionIcon} alt="CONNECTION_ICON" width="24px"/>
        <Text weight="600" color="var(--dt-primary)">
          {connectionPower}
        </Text>
      </span> : null}
      </div>
      {firstLine === true && (
        <div className="flex space-x-2 justify-between items-center text-center mt-[10px]">
          <div className="flex space-x-1">
            <img src={firstLineIcon} alt="" />
            <span className="text-[14px] font-medium text-primary">
              {firstLineText}
            </span>
          </div>
          <div>
            <p className="text-[22px] font-bold text-primary">
              {firstLineDesc}
            </p>
          </div>
        </div>
      )}
      <div className="flex space-x-2 justify-between items-center text-center mt-[23px]">
        <div className="flex space-x-1">
          <img src={secondLineIcon} alt="" />
          <span className="text-[14px] font-medium text-primary">
            {secondLineText}
            {collapsable && (
              <span className="text-[14px]"> ({t("total")})</span>
            )}
          </span>
        </div>
        <div className="flex items-center justify-center text-center" onClick={() => setShowInfo(!showInfo)}>
          <div>
            <ElapsedTimer
              classname="text-[22px] font-bold text-primary"
              isActive={true}
              seconds={totalElapsedTime}
              setSeconds={setTotalElapsedTime}
            />
          </div>
          <div className="flex items-center justify-center text-center">
            {collapsable === true && (
              <button>
                <img
                  className="ml-[5px]"
                  src={showInfo === false ? DownArrowIcon : UpArrowIcon}
                  alt=""
                />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className={`${!showInfo && "hidden"}`}>
        <animated.div
          style={showInfoAnim}
          className="px-[20px] pb-[6px] mt-[26px] text-[14px]"
        >
          <div className="flex flex-row justify-between items-center text-center pb-[8px] divider-bottom">
            <div className="text-primary font-medium">
              <p className="a">{t("park")}</p>
            </div>
            <div>
              <ElapsedTimer
                classname="text-gray font-semibold"
                isActive={true}
                seconds={totalElapsedTime}
                setSeconds={""}
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center text-center mt-[8px]">
            <div className="text-primary font-medium">
              <p className="a">{t("charge")}</p>
            </div>
            <div>
              <ElapsedTimer
                classname="text-gray font-semibold"
                isActive={true}
                seconds={chargeElapsedTime}
                setSeconds={setChargeElapsedTime}
              />
            </div>
          </div>
        </animated.div>
      </div>
    </animated.div>
  );
}

export default SessionInfoCard