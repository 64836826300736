import {Capacitor} from "@capacitor/core";
import {SwipeButton} from "../../../../SwipeButton";
import React from "react";
import {useSpring} from "react-spring";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

const ParkChargeBottom = ({setSlideValue, endSession, isSlideReset, setSlideReset}) => {
  const {t} = useTranslation();
  const {activeSession: {charge_transaction, park_session}, config} = useSelector(state => state.userSlice)

  const isDigitalCode = charge_transaction?.is_digital_code_active || park_session?.is_digital_code_active
  const totalCost = charge_transaction ? (park_session?.details?.payment_amount + charge_transaction?.details?.payment_amount).toFixed(2) : park_session?.details?.payment_amount ?? 0

  const animationBottomDiv = useSpring({
    to: {transform: "translateY(0px)"},
    from: {transform: "translateY(100px)"},
    delay: 130,
    config: {duration: 600}
  })

  return (
    <div
      style={animationBottomDiv}
      className={`w-full bg-primary rounded-t-3xl text-white flex flex-col items-center justify-center text-center p-[36px] fixed !bottom-0 left-0 activeSessionBottom`}
    >
      {!isDigitalCode && (
        <div className="flex flex-row justify-between items-center w-full">
          <p className="text-white font-medium text-[16px]">
            {t("total-cost")}
          </p>
          <div>
            <p className="text-white font-bold text-[26px]">
              {totalCost}
              <span className="text-white font-medium text-[16px]">{t("SEK")}</span>
            </p>
          </div>
        </div>
      )}

      {config?.settings?.web_session_start || Capacitor.isNativePlatform() ?
        <div>
          <div className="mt-[4px] mb-[12px]">
            <p className="text-[14px] font-semibold text-opacity-[.65] text-white">
              {t("swipe-back-to-end")}
            </p>
          </div>
          <div className="">
            <SwipeButton type="swipeLeft" changeValue={setSlideValue} onEnding={endSession} isReset={isSlideReset} setReset={setSlideReset}/>
          </div>
        </div>
        : (
          <p className="text-subwhite mt-[9px]">
            {t("use-the-mobile-app-to-finish")}
          </p>
        )}
    </div>
  );
}

export default ParkChargeBottom
