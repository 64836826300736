//Ionic
import { IonModal } from "@ionic/react";
//Components
import PageToolbar from "../components/PageToolbar";
import Text from "../components/Text";
import Button from "../components/Button";
import Icon from "../components/Icon";
//Helpers
import { useTranslation } from "react-i18next";
import { Browser } from "@capacitor/browser";
import { useSelector } from "react-redux";

const SupportModal = ({isOpen, setOpen, setFAQModalOpen}) => {
  const {t} = useTranslation();
  const {drifter_info} = useSelector(state => state.userSlice.config)
  return (
    <IonModal
      isOpen={isOpen}
      className={`modal-white-background`}
      onDidDismiss={() => setOpen(false)}
    >
      <PageToolbar
        title={t("support")}
        leftButtonType="back"
        leftButtonOnClick={() => setOpen(false)}
        backgroundColor="#fff"
      />
      <div className="px-4">
        <Text weight={500} transform="unset">
          {t("do-you-need-info")}
        </Text>
        <Button
          onClick={async () => {
            await Browser.open({ url: "https://drifterworld.se/" });
          }}
          margin="20px 0 0 0"
        >
          {t("how-it-works")}
        </Button>
        <Button onClick={() => setFAQModalOpen(true)} margin="20px 0 0 0">
          {t("FAQ")}
        </Button>
        <div className="absolute bottom-[30px] w-[calc(100%-32px)]">
          <Text weight={500} transform="unset">
            {t("contact-us")}
          </Text>
          <div className="border-purple border-4 rounded p-2 mt-[10px]">
            <div className="flex flex-row gap-2">
              <Icon name="telephone" size={22} fill={"var(--dt-primary)"} />
              <Text>{drifter_info?.tel}</Text>
            </div>
            <div className="flex flex-row gap-2">
              <Icon name="mail" size={22} fill={"var(--dt-primary)"} />
              <Text transform="unset">{drifter_info?.support_mail}</Text>
            </div>
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default SupportModal;
