//React
import { useState } from 'react';
//Assets
import BackIcon from "../assets/icons/back-icon.svg";
import CloseCrossModalIcon from "../assets/icons/close-modal-blue-cross.svg";
//Helpers
import { t } from 'i18next';
import {setSlotId} from "../redux/slices/userSlice";
import {useDispatch} from "react-redux";
//Swiper.js
import { Swiper, SwiperSlide } from 'swiper/react';
//Components
import ZoomModal from './ZoomModal';
import Button from './Button';
import Text from './Text';
const EnterSlotCode = ({
    startSession,
    segmentMobileSchemas,
    setSlotCodeModal,
    station
}) => {
  const dispatch = useDispatch();
  const [isZoomModalOpen, setZoomModalOpen] = useState(false)
  const handleCodeSubmit = (e)=> {
    e.preventDefault()
    startSession()
  }
    return (
      <div className="w-full h-full bg-primary p-[20px]">
        <div className="flex justify-between items-center text-center w-full">
          <div onClick={() => setSlotCodeModal(false)}>
            <img src={BackIcon} alt="" />
          </div>
          <div>
            <button
              onClick={() => {
                setSlotCodeModal(false);
              }}
            >
              <img src={CloseCrossModalIcon} alt="" />
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-center text-center items-center mt-[5%] mb-[10px]">
          <Text size="17px" weight="600" color="white" align="left" lineHeight="22px" transform="unset">
            {t("enter-slot-code")}.
          </Text>
        </div>
        <div className="flex justify-center text-center items-center mb-[15px]">
          <Swiper spaceBetween={20} speed={300}>
            {segmentMobileSchemas.map((segmentMobileSchema, id) => (
              <SwiperSlide
                key={id}
                onClick={() => {
                  setZoomModalOpen(true);
                }}
              >
                <img
                  src={segmentMobileSchema}
                  alt="SEGMENT_SCHEMA"
                  className="segmentImg"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <form
          onSubmit={(e) => {
            handleCodeSubmit(e);
          }}
        >
          <div className="flex justify-center text-center items-center mb-[20px]">
            <input
              style={{ textTransform: "uppercase" }}
              type="text"
              id={"enterCodeInput"}
              className="ParkChargeCodeInput"
              autoFocus
              placeholder={t("enter-slot")}
              onChange={e => dispatch(setSlotId(e.target.value))}
            />
          </div>
          <div className="flex justify-center text-center items-center mb-[20%] px-[16px]">
            <Button
            type="submit"
            theme="secondary"
            expand="block"
            >
              {t("confirm")}
            </Button>
          </div>
        </form>
        <ZoomModal
          isOpen={isZoomModalOpen}
          setOpen={setZoomModalOpen}
          images={segmentMobileSchemas}
          title={station.NAME}
          subtitle={t("parking-schema")}
        />
      </div>
    );
}

export default EnterSlotCode;
