import React from 'react';
import Text from '../../Text';
import { useTranslation } from 'react-i18next';

const CameraButton = ({capture}) => {
    const {t} = useTranslation()
    return (
        <>
        <Text color={"rgba(255, 255, 255, 0.65)"} size={14} weight={500}>
          {t("take-photo")}
        </Text>
        <button className="TakePhotoButton"
        onClick={() => capture()}
        >
          <span className="TakePhotoButtonInner"/>
        </button>
        </>
    );
}

export default CameraButton;
