//Ionic & React
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
//Components
import { SiteCodes} from '../components/MySiteCodes';
import AddSiteCodeModal from '../components/MySiteCodes/AddSiteCodeModal';
import PageToolbar from '../components/PageToolbar';
//Helpers
import { api } from '../helper/api';
import { useTranslation } from 'react-i18next';
//Redux
import {useDispatch, useSelector} from 'react-redux';
import {setAlert, setLoading} from '../redux/slices/globalSlice';
import {setAddSiteCodeModal} from '../redux/slices/modalSlice';


const MySiteCodes = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const history = useHistory()
    const [firstLoad,setFirstLoad]=useState(true)
    const [siteCodes, setSiteCodes] = useState()
    const [refreshCodes, setRefreshCodes] = useState(false)
    const { addSiteCodeModal } = useSelector((state) => state.modalSlice);

    const getSiteCodes = async () => {
        dispatch(setLoading({status: true, message: ""}))
        const response = await api.getSiteCodes()
        if(response.data.success) {
            const codes = response.data.data.data
            setSiteCodes(codes)
        }else {
            dispatch(setAlert({ status: true,message: t('site-code-not-found'),type: "error", }));
        }
        dispatch(setLoading({status: false, message: ""}))
        return response.data.data.data
    }

    useEffect(() => {
        getSiteCodes().then(res => {
            if(!!res.length === false && firstLoad) {
                dispatch(setAddSiteCodeModal({status:true,back:false}))
                setFirstLoad(false)
            }
        })
    }, [])

    useEffect(() => {
        if(refreshCodes){
            getSiteCodes()
            return () => setRefreshCodes(false)
        }
    }, [refreshCodes])


    useEffect(()=>{
        if (!firstLoad && !addSiteCodeModal.status && !addSiteCodeModal.back)
        {
            getSiteCodes()
        }else if(!firstLoad && !addSiteCodeModal.status){
            history.push('/map', {
                menuModal: true
              })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addSiteCodeModal])

    return (
        <div className='MySiteCodes'>
            <PageToolbar
            title={t("my-site-codes-title")}
            leftButtonType="back"
            onClick={() => {
                history.push('/map', {
                    menuModal: true
                  })
            }}
            />
            <SiteCodes siteCodes={siteCodes}/>
            <AddSiteCodeModal siteCodes={siteCodes} setRefreshCodes={setRefreshCodes}/>
        </div>
    );
}

export default MySiteCodes;
