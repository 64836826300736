import {CapacitorHttp} from '@capacitor/core';
import {store} from '../redux/store';

let defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    Locale: store.getState().userSlice.language
};

const handleToken = () => {
    const _token = store.getState().userSlice.token;

    if (_token) {
        defaultHeaders.Authorization = `Bearer ${_token}`;
        return true
    } else if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/register-phone" &&
        window.location.pathname !== "/enter-phone-number" &&
        window.location.pathname !== "/enter-sms-code"
        ) {
        window.location = "/register-phone";
        return false
    }
}

const get = async (path, baseUrl, isToken, headers) => {
    const OPTIONS = {
        url: `${baseUrl}${path}`,
        headers: {...defaultHeaders, ...headers},
    };
    if(isToken){
        return await CapacitorHttp.get(OPTIONS)
    }
}
const post = async (path, data, baseUrl, isToken, headers) => {
    const OPTIONS = {
        url: `${baseUrl}${path}`,
        headers: {...defaultHeaders, ...headers},
        data
    };
    if(isToken){
        return await CapacitorHttp.post(OPTIONS)
    }
}

export const fetch = async (type, path, data, headers = {}) => {
    const isToken = handleToken()
    const {isDev} = store.getState().globalSlice
    const baseUrl = !isDev ? process.env.REACT_APP_API_URL : process.env.REACT_APP_TEST_API_URL
    if(type.toUpperCase() === "GET"){
        return await get(path, baseUrl, isToken, headers)
    }else{
        return await post(path, data, baseUrl, isToken, headers)
    }
}