// Ionic & React
import {IonContent, IonModal} from "@ionic/react"

// Components
import PageToolbar from "../components/PageToolbar"
import DTAccordion from "../components/DTAccordion"

import {useTranslation} from "react-i18next"
import {useEffect, useState} from "react";
import {api} from "../helper/api";
import { Alert } from "../helper/alert";

const FAQModal = ({isFAQModalOpen, setFAQModalOpen, className, ...props}) => {

    const {t} = useTranslation()

    const [questions, setQuestions] = useState([]);

    const getFaq = async () => {
        try {
            const result = await api.getFaq()
            if(result.data?.length){
                setQuestions(result.data)
            }
        } catch (error) {
            Alert.error(t("try-again"));
            setFAQModalOpen(false)
        }

    }

    useEffect(() => {
        isFAQModalOpen && getFaq();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFAQModalOpen])

    return (
        <IonModal
            isOpen={isFAQModalOpen}
            canDismiss={true}
            className={`FAQModal modal-white-background${
                className ? " " + className : ""
            }`}
            onDidDismiss={() => setFAQModalOpen(false)}
            {...props}
        >
            <PageToolbar
                title={t("FAQ")}
                leftButtonType="back"
                onClick={() => setFAQModalOpen(false)}
                backgroundColor="#fff"
            />

            <IonContent>
                {questions?.map((question, idx) => (
                    <DTAccordion
                        className="FAQAccordion"
                        title={question.title}
                        content={question.content}
                        isHTMLContent={true}
                        key={idx}
                    />
                ))}
            </IonContent>
        </IonModal>
    )
}

export default FAQModal
