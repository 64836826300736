import { ChargeStationTop } from "./ChargeStationDetail"
import { ParkStationTop } from "./ParkStationDetail"

const StationDetailTop = ({
  closeModal,
  logo,
  name,
  distance,
  isContracted,
  stationType,
  station,
  zoneCode
}) => {
  return (
    <>
      {stationType === "parking" ? (
        <ParkStationTop
          name={name}
          logo={logo}
          distance={distance}
          isContracted={isContracted}
          closeModal={closeModal}
          zoneCode={zoneCode}
        />
      ) : (
        <ChargeStationTop
          name={name}
          distance={distance}
          isContracted={isContracted}
          station={station}
          zoneCode={zoneCode}
        />
      )}
    </>
  );
};

export default StationDetailTop
