import { useTranslation } from "react-i18next";
import Text from "../../Text";

const Header = ({ ticket }) => {
  const { t } = useTranslation();
  return (
    <div className="header">
      <div className="flex flex-col">
        <Text size={24} weight={600}>
          #{ticket.id}
        </Text>
        <Text>{t("long-term-ticket")}</Text>
      </div>
    </div>
  );
};

export default Header;
