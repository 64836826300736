import { IonItem } from "@ionic/react"
import styled from "styled-components"

const DTSelectWrapper = styled("div")`
  position: relative;
  width: 100%;

  & > label {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 14px;
    color: #635c5c;
    font-size: 16px;
    font-weight: 400;
    transform: translateY(-50%);
    transition: all 0.1s ease-out;
    z-index: 1;
  }

  .item-has-value ~ label {
    top: -6px;
    transform: translateY(-100%);
    color: #635c5c;
    font-size: 14px;
    font-weight: 600;
  }

  .DTClearButton {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -6px;
    right: 14px;
    transform: translateY(-100%);
    color: var(--dt-pink);
    font-size: 14px;
    font-weight: 400;
    transition: all 0.1s ease-out;
    background: transperant;
  }
  .item-has-value ~ .DTClearButton {
    opacity: 1;
    pointer-events: all;
  }
`

const DTItem = styled(IonItem)`
  --background: #fff;
  --background-hover: #fff;
  --background-activated: #fff;
  --background-focused: #fff;
  --border-radius: 12px;
  --ripple-color: var(--dt-primary);
  --color: var(--dt-primary);
  --hover-color: var(--dt-primary);
  --placeholder-color: #635c5c;
  --placeholder-opacity: 1;
  --highlight-background: transparent;

  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--dt-primary-300);
  color: var(--dt-primary);
  font-size: 16px;
  font-weight: 500;
  transition: all 0.1s ease-out;

  &.item-has-value {
    border-color: var(--dt-primary);
  }

  ::part(native) {
    height: 55px;
    border: none;
  }
  ::part(native)::after {
    content: none;
  }
  ::part(native) .item-highlight {
    display: none !important;
  }
  ::part(native) .item-inner {
    border: none;
    padding: 0 14px;
  }

  .in-item {
    border: none;
    width: 100%;
  }
`

const DTSelect = ({ children, label, margin, clearable, clearOnclick, ...props }) => {
  return (
    <DTSelectWrapper style={{ margin: margin ? margin : "" }}>
      <DTItem {...props}>{children}</DTItem>
      {label ? <label>{label}</label> : ""}
      {clearable ? <button onClick={clearOnclick} className="DTClearButton">Clear</button> : ""}
    </DTSelectWrapper>
  )
}

export default DTSelect
