import { memo } from "react";
import Icon from "../../Icon";
const DrifterCard = ({onButtonClick}) => {

  return (
    <div className="CardWrapper">
      <div className="DrifterCard">
        <div className="DrifterMask"></div>
        <Icon
          name={"card-chip"}
          width={38}
          height={32}
          className="DrifterCardChip"
        />
        <button
          className="DrifterCardAddButton"
          onClick={onButtonClick}
        >
          <Icon
            name={"add"}
            width={19}
            height={19}
            fill={"var(--dt-primary)"}
          />
        </button>
      </div>
    </div>
  );
};

export default memo(DrifterCard);
