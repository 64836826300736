import { useTranslation } from "react-i18next"
import "./CircularProgressBar.css"

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0
  const tooHigh = percentage > 100
  return tooLow ? 0 : tooHigh ? 100 : +percentage
}

const Circle = ({ colour, pct, strokeWidth }) => {
  const r = 70
  const circ = 2 * Math.PI * r
  const strokePct = ((100 - pct) * circ) / 100
  return (
    <circle
      r={r}
      cx={100}
      cy={100}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""} // remove colour as 0% sets full circumference
      strokeWidth={strokeWidth}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap=""
    ></circle>
  )
}

const CircularProgressBar = ({ percentage, colour }) => {
  const {t} = useTranslation()
  const pct = cleanPercentage(percentage.percent)
  return (
    <div className="CircularProgressBar">
      <svg className="CircularProgressBar" width={200} height={200}>
        <g transform={`rotate(-90 ${"100 100"})`}>
          <Circle colour="lightgrey" strokeWidth="6px" />
          <Circle colour={colour} pct={-pct} strokeWidth="12px" />
        </g>
      </svg>
      <div className="CircularProgressBarContent">
        <span className="CircularProgressBarPercentage">
          {percentage.count}
        </span>
        <span className="CircularProgressBarText" dangerouslySetInnerHTML={{
          __html: t("free-count-to-park")
        }}/>
      </div>
    </div>
  )
}

export default CircularProgressBar
