// Ionic & React
import {useEffect, useState, memo} from "react"
import {
  IonContent,
  IonModal,
  IonRippleEffect
} from "@ionic/react"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {setAddNewCarModal, setCarSave, setVehicles} from "../redux/slices/userSlice"

// Components
import PageToolbar from "../components/PageToolbar"
import Text from "../components/Text"
import Icon from "../components/Icon"
import Button from "../components/Button"
import { Swiper, SwiperSlide } from 'swiper/react';


// Helper
import Api from "../helper/api"
import { useRef } from "react"
import { useTranslation } from "react-i18next";
import {clearLoader, setLoading} from "../redux/slices/globalSlice";
import {Alert} from "../helper/alert";
import { getDefaultVehicle } from "../helper/globalFunctions"


const AddNewCarModal = ({
                          isAddNewCarModalOpen,
                          isProfileRedirect,
                          setAddNewCarModalOpen,
                          className,
                          newCurrentPlate,
                          closeModal,
                          saveHandler,
                          ...props
                        }) => {
  const alertText = ""
  const [isAlertModalOpen, setAlertModalOpen] = useState(false)
  const border = ""
  const [brands, setBrands] = useState([])
  const [selectedBrand, setSelectedBrand] = useState({})
  const [selectedModel, setSelectedModel] = useState(0)
  const [models, setModels] = useState([])
  const [searchBrandText, setSearchBrandText] = useState("")
  const [searchModelText, setSearchModelText] = useState("")
  const [isCarModalOpen, setCarModalOpen] = useState(false)
  const isAddedByApi = false
  const nativePlate = ""
  const [brandsFilter, setBrandsFilter] = useState([])
  const [modelsFilter, setModelsFilter] = useState([])

  const {user} = useSelector((store) => store.userSlice)
  const {t} = useTranslation()

  useEffect(()=>{
    isAddNewCarModalOpen && getVehicleBrands();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isAddNewCarModalOpen])

  useEffect(() => {
    if (searchBrandText.length > 1) {
      setBrandsFilter(brands.filter(b => b.name.toLowerCase().includes(searchBrandText.toLowerCase())))
    } else {
      setBrandsFilter(brands)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchBrandText])

  useEffect(() => {
    setTimeout(() => {
      searchModelText && searchModelsBySearchText(selectedBrand.id, searchModelText)
    }, 500);
    return () => clearTimeout()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchModelText])

  const dispatch = useDispatch()

  const plateInputRef = useRef(null)
  const searchBrandInputRef = useRef(null)
  const searchModelInputRef = useRef(null)
  const addNewCarSliderRef = useRef(null)

  const api = new Api()

  // GET CARS
  const GetVehicle = async () => {
    api.getVehicles().then(({data}) => {
      dispatch(setVehicles(data?.data ?? []))
    })
  }

  const getVehicleBrands = async () => {
    await api.getVehicleBrands({
      options: {
        paginate: false
      }
    }).then(({data}) => {
      if (data.data.length > 0) {
        setBrands(data.data)
        setBrandsFilter(data.data)
      }
      dispatch(clearLoader())
    }).catch((error) => {
      console.log(error)
    })
  }

  const getVehicleModels = async (data) => {
    dispatch(setLoading({status: true, message: t("models-loading")+"..."}))

    return new Promise((resolve, reject) => {
      api.getVehicleModels({
        options: {
          parameters: {
            brand_id: data
          },
        },
      }).then(({data}) => {
        if(data.success){
          setModels(data.response)
          setModelsFilter(data.response)
          searchModelText.length > 0 && searchModelInputRef.current.focus()
          resolve(data.response)
        }
      }).catch((error) => reject(error))
      .finally(() => dispatch(clearLoader()))
    })
  }

  const searchModelsBySearchText = (id, text) => {
    api.getVehicleModels({
      options: {
        parameters: {
          brand_id: id,
          keyword: text
        },
      },
    }).then(({data}) => {
      if(data.success){
        setModels(data.response)
        setModelsFilter(data.response)
      }
    }).catch((error) => console.error(error))
  }

  const handleSubmit = async () => {
    if (newCurrentPlate !== "" && newCurrentPlate !== null) {
      handleEditSubmit()
    } else if (isAddedByApi) {
      GetVehicle()
      GetDefaultVehicle()
      setCarModalOpen(false)
      dispatch(setAddNewCarModal(false))
    } else {
      dispatch(setLoading({status: true, message: t("checking-your-license-plate")+"..."}))

      handleEditSubmit(nativePlate)
    }
    saveHandler()
  }

  const handleEditSubmit = async (e) => {
    dispatch(setLoading({status: true, message: t("updating-car-information")+"..."}))
    api.addVehicle({
      options: {
        parameters: {
          plate: e !== "" && e != null ? nativePlate : newCurrentPlate,
          brand_id: selectedBrand.id,
          model_id: selectedModel.id,
        },
      },
    }).then((response) => {
      if (response.data.success === true) {
        setCarModalOpen(false)
        setAddNewCarModalOpen(false)
        dispatch(setAddNewCarModal(false))
        if (closeModal) {
          closeModal(false)
        }
        GetVehicle()
        GetDefaultVehicle()
        if (isProfileRedirect){
          dispatch(setCarSave(true))
        }
      } else {
        Alert.error(response.data.message)
      }
      dispatch(clearLoader())
    })
  }

  const GetDefaultVehicle = () => getDefaultVehicle()

  return (
      <IonModal
          isOpen={isAddNewCarModalOpen}
          canDismiss={true}
          className={`AddNewCarModal modal-light-background dark-page${className ? " " + className : ""}`}
          onDidDismiss={() => setAddNewCarModalOpen(false)}
          {...props}
      >
        <IonContent>
          <div className="h-full bg-primary">
            <PageToolbar
                title={t("add-new-car")}
                whiteIcon={true}
                leftButtonType="back"
                onClick={() => {
                  if (addNewCarSliderRef.current.swiper.activeIndex > 0) {
                    addNewCarSliderRef.current.swiper.slidePrev()
                    setSearchModelText("")
                  } else {
                    setAddNewCarModalOpen(false)
                  }
                }}
            />
              <form className="WhiteWrapper" style={{ padding: "0" }} onSubmit={(e) => e.preventDefault()}>
                <Swiper
                    className="AddNewCarSlider"
                    speed= {150}
                    spaceBetween= {50}
                    resistanceRatio= {0}
                    allowTouchMove= {false}
                    ref={addNewCarSliderRef}
                    style={{ padding: "16px" }}
                >
                  {newCurrentPlate === "" || newCurrentPlate === null || newCurrentPlate === undefined ? (
                      <SwiperSlide>
                        <Text
                            size="22px"
                            weight="600"
                            margin="18px 10px 38px"
                            align="left"
                        >
                          {t("enter-your-cars-registration-number")}
                        </Text>

                        <div
                            className={`DTInput PlateInput -static ${border}`}
                            style={{ margin: "0 0 16px" }}
                        >
                          <input
                              type="search"
                              placeholder="ZAC567"
                              autoFocus
                              ref={plateInputRef}
                          />
                          <label htmlFor="">{t("plate-number")}</label>
                        </div>

                        <Button
                            theme={"primary"}
                            expand={"block"}
                            margin={"12px 0 0"}
                        >
                          {t("find-my-car")}
                          <IonRippleEffect />
                        </Button>
                      </SwiperSlide>
                  ):''}

                  <SwiperSlide>
                    <Text
                        size="22px"
                        weight="600"
                        margin="18px 10px 23px"
                        align="left"
                    >
                      {t("search-brand")}
                    </Text>

                    <div className="SearchInputWrapper">
                      <input
                          type="search"
                          id="searchInput"
                          placeholder={t("type-your-car-brand")}
                          value={searchBrandText}
                          onChange={(e)=> setSearchBrandText(e.target.value)}
                          ref={searchBrandInputRef}
                      />
                      <button
                          id="clearSearchInput"
                            onClick={(e) => {
                            e.preventDefault()
                            setSearchBrandText("")
                            searchBrandInputRef.current.focus()
                          }}
                      />
                    </div>
                    {brands.length ? (
                        <div className="CarBrands">
                          {isAddedByApi !== true && brands ? (
                              brandsFilter.map((brand, idx) => {
                                return (
                                  (
                                    <div
                                      key={idx}
                                      className="CarBrand ion-activatable"
                                      onClick={() => {
                                        getVehicleModels(brand.id).then(() => {
                                          setSelectedBrand({id:brand.id,name:brand.name,thumb:brand.thumb})
                                          addNewCarSliderRef.current.swiper.slideNext()
                                          dispatch(clearLoader())
                                        })

                                      }}>
                                      <div className="CarBrandLogo">
                                        <img src={brand.thumb} alt="Audi" />
                                      </div>
                                      <span className="CarBrandName">{brand.name}</span>
                                      <IonRippleEffect />
                                    </div>
                                  )
                                )
                              })
                          ) : (
                              <div
                                  className="CarsSliderSkeleton"
                                  style={{ marginBottom: "128px" }}
                              />
                          )}

                        </div>
                    ):''}




                  </SwiperSlide>

                  <SwiperSlide>
                    <Text
                        size="22px"
                        weight="600"
                        margin="18px 10px 23px"
                        align="left"
                    >
                      {t("search-model")}
                    </Text>

                    <div className="SearchInputWrapper">
                      <input
                          type="search"
                          id="searchInput"
                          placeholder={t("type-your-car-model")}
                          value={searchModelText}
                          ref={searchModelInputRef}
                          onChange={(e) => setSearchModelText(e.target.value)}
                      />
                      <button
                          id="clearSearchInput"
                          onClick={(e) => {
                            e.preventDefault()
                            setSearchModelText("")
                            searchModelInputRef.current.focus()
                          }}
                      />
                    </div>
                    {models.length ? (
                        <div className="CarModels">

                          {isAddedByApi!==true && models ? (
                              modelsFilter.map((model, idx) => (
                                  <div className="CarModel" key={idx} onClick={() =>{
                                    setSelectedModel(model)
                                    setCarModalOpen(true)
                                  }}>
                                    <div className="CarModelImage">
                                      <img src={model.image_url} alt="Aston Martin Cygnet" />
                                    </div>
                                    <span className="CarModelName">{model.name}</span>
                                    <span className="CarBrandName">{selectedBrand.name}</span>
                                  </div>
                              ))
                          ) : (
                              <div
                                  className="CarsSliderSkeleton"
                                  style={{ marginBottom: "128px" }}
                              />
                          )}
                        </div>
                    ):''}
                  </SwiperSlide>
                </Swiper>
              </form>

              {/* New Car Preview Modal */}
              <IonModal
                  isOpen={isCarModalOpen}
                  breakpoints={[0.8, 1]}
                  initialBreakpoint={1}
                  handle={false}
                  onDidDismiss={() => setCarModalOpen(false)}
              >
                <div className="w-full h-full bg-primary">
                  <div className="w-full bg-primary">
                    <PageToolbar
                      title={t("add-new-car")}
                      titleColor="#fff"
                      leftButtonType="back"
                      whiteIcon="true"
                      leftButtonOnClick={() => setCarModalOpen(false)}
                      iosSafeArea={false}
                    />
                  </div>
                <div className="bg-white h-full rounded-t-[28px] p-[16px]">
                  <div className="CarModalTop">
                    <div className="CarImage">
                      <img
                          src={selectedModel.image_url}
                          alt="Selected Model"
                          draggable={false}
                      />
                    </div>
                  </div>

                  <div className="CarModalInformation">
                    <div className="CarLogo">
                      <img src={selectedBrand.thumb} alt="Selected Brand" />
                    </div>
                    <div className="CarModalName">
                      <Text size="20" weight="600">
                        {selectedBrand.name}
                      </Text>
                      <Text size="16" weight="400">
                        {selectedModel.name}
                      </Text>
                    </div>
                  </div>

                  <div className={"CarModalBottom"}>
                    <ul className="CarModalList">
                      <li>
                        <Icon
                            name="document"
                            color="var(--dt-primary-300)"
                            height="16"
                        />
                        {t("registered-to")}
                        <div>
                          <span>{user.first_name  + " " + user.last_name}</span>
                        </div>
                      </li>

                            {selectedModel.ev_charge_type && (<li>
                              <Icon
                                  name="connector-type"
                                  color="var(--dt-primary-300)"
                                  height="16"
                              />
                                {t("connector-type")}
                              <div>
                                <span>{selectedModel.ev_charge_type.title}</span>
                              </div>
                            </li>
                            )}
                      <></>
                      {selectedModel.battery && (
                          <li>
                            <Icon
                                name="battery-capacity"
                                color="var(--dt-primary-300)"
                                height="16"
                            />
                            {t("battery-capacity")}
                            <div>
                              <span>{selectedModel.battery} kw</span>
                            </div>
                          </li>
                      )}
                      <></>
                        {(selectedModel.charging_time_ac || selectedModel.charging_time_dc ) && (
                          <li>
                            <Icon
                                name="charge-time"
                                color="var(--dt-primary-300)"
                                height="16"
                            />
                            {t("charge-time")}
                            <div>
                              {selectedModel.charging_time_ac && <span>{t("charging-time", {type: "AC", time: selectedModel.charging_time_ac})}</span>}
                              {selectedModel.charging_time_dc && <span>{t("charging-time", {type: "DC", time: selectedModel.charging_time_dc})}</span>}
                            </div>
                          </li>
                            )}
                    </ul>

                    <Button
                        theme="secondary"
                        margin="5% auto 0"
                        onClick={() => handleSubmit()}
                    >
                      {t("save-my-car")}
                    </Button>

                    <Text
                        margin="3% auto"
                        size="12px"
                        weight="500"
                        color="var(--dt-primary-300)"
                        align="center"
                        onClick={() => setCarModalOpen(false)}
                    >
                      {t("this-is-not-my-car")}
                    </Text>
                  </div>


                  </div>
                </div>
              </IonModal>
              {/* Alert Modal */}
              <IonModal
                  className="DTAlertModal"
                  isOpen={isAlertModalOpen}
                  onDidDismiss={() => setAlertModalOpen(false)}
              >
                <IonContent>
                  <div className="DTAlertModalContent">
                    <Text size={20} weight={400}>
                      {alertText}
                    </Text>
                    <Button
                        theme="secondary"
                        margin="42px 0 0"
                        onClick={() => setAlertModalOpen(false)}
                    >
                      {t("back")}
                    </Button>
                  </div>
                </IonContent>
              </IonModal>
          </div>
          </IonContent>
      </IonModal>
  )
}

export default memo(AddNewCarModal);
