import { IonModal } from "@ionic/react";
import { t } from "i18next";
import PageToolbar from "../PageToolbar";
import ToggleSwitch from "../ToggleSwitch";

const DrifterCardSettingsModal = ({ isOpen, setOpen }) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => setOpen(false)}>
      <PageToolbar
        title={t("drifter-card-settings")}
        leftButtonType="back"
        leftButtonOnClick={() => setOpen(false)}
      />
      <div className="p-[20px]">
      <label className={"FilterItem w-full justify-between"} htmlFor="have-balance">
        <div><p className="text-[#B11D94] font-[600] text-[16px]">
            {t("if-i-have-balance")} <br/>
            <span className="text-primary font-[400] text-[14px]">{t("if-i-have-balance-desc")}</span>
        </p></div>
        <div className="flex flex-col pt-1">
        <ToggleSwitch id="have-balance" checked={true}/>
        <p className="text-[rgba(61, 60, 87, 0.75)] font-normal text-[12px]">({t("recommended")})</p>
        </div>
      </label>
      <label className={"FilterItem mt-[30px]"} htmlFor="have-not-balance">
        <p className="text-[#B11D94] font-[600] text-[16px]">
            {t("if-i-have-not-balance")} <br/>
            <span className="text-primary font-[400] text-[14px]" dangerouslySetInnerHTML={{ __html: t("if-i-have-not-balance-desc")}}/>
        </p>
        <ToggleSwitch id="have-not-balance" />
      </label>
      </div>
    </IonModal>
  );
};

export default DrifterCardSettingsModal;
