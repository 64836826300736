import { useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import {useParams} from "react-router";
//Helpers
import { t } from "i18next";
import { api } from "../../helper/api";
import { setLoading } from "../../redux/slices/globalSlice";
import { store } from "../../redux/store";
//Components
import Icon from "../Icon";
import PageToolbar from "../PageToolbar";
import Text from "../Text";
import SetBusinessSlider from "./SetBusinessSlider";

const BusinessDetail = () => {

    const {businessId} = useParams()
    const router = useIonRouter()

    const [businessAccount, setBusinessAccount] = useState({});
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [editSliderActiveIndex, setEditSliderActiveIndex] = useState(0);

    const getBusinessDetail = async () => {
        store.dispatch(setLoading({status: true, message: ""}))
        await api.getBusinessDetail(businessId).then(({data}) => {
            if(data.success) setBusinessAccount(data.response)
            store.dispatch(setLoading({status: false, message: ""}))
        })
    }

    useEffect(() => {
        getBusinessDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const removeCompany = () => {
        store.dispatch(setLoading({status: true, message: ""}))
        api.removeAssignedCompany(businessId).then(({data}) => {
            if(data.success) {
                store.dispatch(setLoading({status: false, message: ""}))
            }
        }).finally(() => router.push("/my-business-accounts", "forward"))
    }

    return (
      <div>
        <PageToolbar
          title={t("my-business-account")}
          leftButtonType="back"
          onClick={() => {
            router.push("/my-business-accounts");
          }}
        />
        <div className="BusinessInfo">
          <div className="InfoWrapper">
            <button className="RemoveButton" onClick={() => removeCompany()}>
              {t("remove")}
            </button>
            <img
              src={businessAccount.company?.logo_url}
              alt={businessAccount.company?.company_name}
              className={"BusinessAccountLogo"}
            />
            <Text size={22} weight={600} margin={"31px 0 0"}>
              {businessAccount.company?.company_name}
            </Text>
            <Text
              size={18}
              weight={400}
              margin={"18px 0 0"}
              transform="capitalize"
            >
              {businessAccount.company?.street}
            </Text>
            <Text
              size={22}
              weight={400}
              margin={"18px 0 0"}
              color={"rgba(53, 51, 85, 0.5)"}
            >
              {businessAccount.company?.phone_number}
            </Text>
            <div className="InfoArea mt-[49px]">
              <div className="CardInfos">
                {businessAccount.payment_method ? (
                  businessAccount?.payment_method?.card?.brand ===
                  "mastercard" ? (
                    <Icon
                      name={"mastercard"}
                      height={31}
                      width={49}
                      margin={"0 17px 0 0"}
                      className="CardBrandPosition"
                    />
                  ) : businessAccount?.payment_method?.card?.brand ===
                    "visa" ? (
                    <Icon
                      name={"visa"}
                      fill={"#BFC1C3"}
                      height={20}
                      width={62}
                      margin={"0 17px 0 0"}
                      className="CardBrandPosition"
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  ""
                )}

                <div className="CardInfoText">
                  {businessAccount.payment_method ? (
                    <Text
                      size={14}
                      weight={400}
                      color="#353355"
                      otherStyles={"opacity: 0.6"}
                      transform="capitalize"
                    >
                      {businessAccount?.payment_method?.card?.brand}
                    </Text>
                  ) : (
                    <Text size={18} weight={400}>
                      {t("no-payment-method")}
                    </Text>
                  )}
                  <Text
                    size={14}
                    weight={400}
                    color="#353355"
                    otherStyles={"opacity: 0.6"}
                    transform="capitalize"
                  >
                    {businessAccount?.payment_method?.card?.brand}
                  </Text>
                  {businessAccount.payment_method && (
                    <Text size={12} weight={400} color="#353355">
                      **** **** ****{" "}
                      {businessAccount?.payment_method?.card?.last4}
                    </Text>
                  )}
                </div>
              </div>
              <div className="ChangeButtonWrapper">
                <Text
                  size={12}
                  weight={500}
                  color="#B11D94"
                  transform="capitalize"
                  onClick={() => {
                    setEditModalOpen(true);
                    setEditSliderActiveIndex(0);
                  }}
                >
                  {t("change")}
                </Text>
              </div>
            </div>
            <div className="InfoArea mt-[19px]">
              <div className="CarInfos">
                {businessAccount.vehicle && (
                  <img
                    src={businessAccount.vehicle?.logo}
                    alt=""
                    className="CarBrandPosition"
                  />
                )}
                <div className="CarInfoText text-left">
                  {businessAccount.vehicle ? (
                    <>
                      <Text size={20} weight={700} color="#353355">
                        {businessAccount?.vehicle?.brand_text}
                      </Text>
                      <Text size={16} weight={400} color="#353355">
                        {businessAccount?.vehicle?.model_text}
                      </Text>
                      <div className="AddCarCheckboxPlate">
                        {businessAccount?.vehicle?.plate}
                      </div>
                    </>
                  ) : (
                    <Text size={18} weight={400}>
                      No added vehicle yet.
                    </Text>
                  )}
                </div>
              </div>
              <div className="ChangeButtonWrapper">
                <Text
                  size={12}
                  weight={500}
                  color="#B11D94"
                  transform="capitalize"
                  onClick={() => {
                    setEditModalOpen(true);
                    setEditSliderActiveIndex(1);
                  }}
                >
                  {t("change")}
                </Text>
              </div>
            </div>
          </div>
        </div>

        <SetBusinessSlider
          slideActiveIndex={editSliderActiveIndex}
          isOpen={editModalOpen}
          setOpen={setEditModalOpen}
          currentBusinessAccount={businessAccount}
          getBusinessDetail={getBusinessDetail}
          comingFrom="business-detail"
        />
      </div>
    );
}

export default BusinessDetail;
