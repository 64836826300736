//Ionic & React
import {useState} from "react";
import {IonSlides} from "@ionic/react";
//Redux
import {useDispatch, useSelector} from "react-redux";
import {setMapFilter} from "../../redux/slices/filterStationSlice";

//Components
import BottomToolbarSlideItem from "./BottomToolbarSlideItem";
import {Alert} from "../../helper/alert";
import {useTranslation} from "react-i18next";
import {setSessionModal} from "../../redux/slices/modalSlice";

const BottomToolbarSlide = ({
  bottomToolbarRef,
  setFilterMenu,
  setMarkersInitType,
  stationsLoading
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()

  const {activeSession} = useSelector((state) => state.userSlice);
  const [slides, setSlides] = useState([
    {
      id: 0,
      filterName: "MAP_PARKING",
      text: "park",
      icon: "parking-solid",
      slideClassName: "BottomParking",
      textClassName: "BottomTextParking",
      activeTextClassName: "activeTextPark",
      active: true,
    }
  ]);

  const bottomToolbarOpts = {
    speed: 100,
    allowTouchMove: false,
    resistanceRatio: 0,
    centeredSlides: true,
    slidesPerView: "auto",
  };

  const handleSlideChange = (e) => {
    setSlides((prev) => [
      ...prev.map((obj, idx) => {
        if (idx === e.target.swiper.activeIndex) {
          return {...obj, active: true};
        }
        if (idx !== e.target.swiper.activeIndex) {
          return {...obj, active: false};
        }
        return obj;
      }),
    ]);
    dispatch(setMapFilter(slides[e.target.swiper.activeIndex].filterName))
    setFilterMenu(e.target.swiper.activeIndex);
  };
  const handleSlideClick = () => {
    if (!stationsLoading) {
      setFilterMenu("P");
      activeSession.type === "park" && dispatch(setSessionModal(true))
    } else {
      Alert.error(t("wait-loading"))
    }
  };

  return (
    <IonSlides
      options={bottomToolbarOpts}
      ref={bottomToolbarRef}
      onIonSlideDidChange={(e) => {
        handleSlideChange(e)
        setMarkersInitType("init")
      }}
    >
      {slides.map((slide) => (
        <BottomToolbarSlideItem
          key={slide.id}
          icon={slide.icon}
          text={slide.text}
          isActive={slide.active}
          filterName={slide.filterName}
          slideOnclick={() => handleSlideClick(slide)}
          slideClass={`BottomIconWrapper ${slide.slideClassName}`}
          textClass={`BottomText ${slide.textClassName}`}
          activeTextClassName={` activeText ${slide.activeTextClassName}`}
        />
      ))}
    </IonSlides>
  );
};
export default BottomToolbarSlide;
