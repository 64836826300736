import { useTranslation } from "react-i18next";
import Text from "../../Text";

const DetailInfo = ({ ticket, status }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2 my-[25px]">
      <div className="flex flex-row items-center justify-between">
        <Text weight={600}>{t("status")}</Text>
        <Text>
          <span
            className={`TicketAvailability ${
              status ? "--available" : ""
            }`}
          />
          {status ? "Available" : "Out of date"}
        </Text>
      </div>
      <div className="flex flex-row items-center justify-between">
        <Text weight={600}>{t("validity-period")}</Text>
        <Text>{ticket.ticket.name}</Text>
      </div>
    </div>
  );
};

export default DetailInfo;
