import { useIonRouter } from "@ionic/react";
import { t } from "i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import Icon from "../Icon";
import Text from "../Text";

const SelectCard = ({ paymentMethods }) => {

  const router = useIonRouter()

  const AddNewCard = (props) => {
    return (
      <div className="AddNewCardSlide" onClick={props.onclick}>
        <Icon name={"add"} size={13}/>
      </div>
    )
  }
  
  return (
    <div className="SelectCard">
      <Text size={18} weight={600} color="#B11D94">
        {t("select-card")}
      </Text>
      <Swiper
        spaceBetween={20}
        speed={200}
        allowTouchMove={true}
        slidesPerView={1.9}
        slidesPerGroup={paymentMethods.length}
        className="mt-[25px]"
      >
        {
          paymentMethods.length > 0 ? (
            paymentMethods.map((p) => (
              <SwiperSlide key={p.id}>
                <label
                  className="DrifterCardPaymentMethodsRadioWrapper"
                  htmlFor={p.id}
                >
                  <input type="radio" id={p.id} name="p.card.last4" defaultChecked={p.default === 1}/>
                  <div className="DrifterCardPaymentMethodsRadio">
                    {p.card.brand === "mastercard" ? (
                      <Icon
                        name={"mastercard"}
                        height={23}
                        width={37}
                        margin={"0 15px 0 0"}
                      />
                    ) : (
                      <Icon
                        name={"visa"}
                        fill={"#BFC1C3"}
                        height={12}
                        width={40}
                        margin={"0 15px 0 0"}
                      />
                    )}
                    <div className="DrifterCardPaymentMethodsRadioContent">
                      <Text
                        size="14px"
                        weight="400"
                        color="#353355"
                        otherStyles={"opacity: 0.6; text-transform: capitalize"}
                        align="left"
                      >
                        {p.card.brand}
                      </Text>
                      <Text
                        size="12px"
                        weight="400"
                        color="#353355"
                        margin="6px 0 0"
                        align="left"
                      >
                        **** **** **** {p.card.last4}
                      </Text>
                    </div>
                  </div>
                </label>
              </SwiperSlide>
            ))
          ) : (
            <>
            <Text size={14} weight={400} color="var(--dt-primary)">
              {t("any-card")}
            </Text>
            <button className="addPayment" onClick={() => router.push("/add-payment-method", "forward")}>
              <Icon
              name={"add"}
              size={14}
              />
              <Text
              size={16}
              weight={600}
              >
                {t("add-payment-method")}
              </Text>
            </button>
            </>
          )
        }

        {paymentMethods.length > 0 && 
        <SwiperSlide className="LastSlide">
          <AddNewCard onclick={() => router.push("/add-payment-method", "forward")}/>
        </SwiperSlide>}
      </Swiper>
    </div>
  );
};

export default SelectCard;
