import React from 'react';
import { MyCars, MyProfile } from '../pages';

const MenuModals = () => {
    return (
        <>
            <MyProfile />
            <MyCars />
        </>
    );
}

export default MenuModals;
