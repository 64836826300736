// Ionic & React
import { useState } from "react"
import { IonContent, IonModal } from "@ionic/react"

// Pages
import ChangeLanguageModal from "./ChangeLanguageModal"
import ManagePermissionsModal from "./ManagePermissionsModal"
import UsageAccountModal from "./UsageAccountModal"

// Components
import PageToolbar from "../components/PageToolbar"
import Icon from "../components/Icon"

import { useTranslation } from "react-i18next"

const SettingsModal = ({
  isSettingsModalOpen,
  setSettingsModalOpen,
  className,
  ...props
}) => {

  const { t } = useTranslation()
  const [isManagePermissionsModalOpen, setManagePermissionsModalOpen] = useState(false)
  const [isChangeLanguageModalOpen, setChangeLanguageModalOpen] =
  useState(false)
  const [isUsageAccountModal, setUsageAccountModal] = useState(false)

  return (
    <IonModal
      isOpen={isSettingsModalOpen}
      canDismiss={true}
      className={`SettingsModal modal-white-background${
        className ? " " + className : ""
      }`}
      onDidDismiss={() => setSettingsModalOpen(false)}
      {...props}
    >
      <PageToolbar
        title={t("settings")}
        leftButtonType="back"
        onClick={() => setSettingsModalOpen(false)}
        backgroundColor="#fff"
      />

      <IonContent>
        <div className="SettingsWrapper">
          <div
            className="SettingRow"
            onClick={() => setChangeLanguageModalOpen(true)}
          >
            <span className="SettingIcon">
              <Icon name="world" size={22} fill="var(--dt-primary)" />
            </span>
            <div className="SettingTitle">{t("change-language")}</div>
          </div>
          <div
            className="SettingRow"
            onClick={() => setManagePermissionsModalOpen(true)}
          >
            <span className="SettingIcon">
              <Icon name="permissions" size={22} fill="var(--dt-primary)" />
            </span>
            <div className="SettingTitle">{t("change-permissions")}</div>
          </div>
          <div
            className="SettingRow"
            onClick={() => setUsageAccountModal(true)}
          >
            <span className="SettingIcon">
              <Icon name="usage-account" width="15" height="24" fill="var(--dt-primary)" />
            </span>
            <div className="SettingTitle">{t("usage-account")}</div>
          </div>
        </div>
      </IonContent>

      <ChangeLanguageModal
        isChangeLanguageModalOpen={isChangeLanguageModalOpen}
        setChangeLanguageModalOpen={setChangeLanguageModalOpen}
      />

      <ManagePermissionsModal
          isManagePermissionsModalOpen={isManagePermissionsModalOpen}
          setManagePermissionsModalOpen={setManagePermissionsModalOpen}
      />
      <UsageAccountModal
          isOpen={isUsageAccountModal}
          setOpen={setUsageAccountModal}
      />
    </IonModal>
  )
}

export default SettingsModal
