//Ionic & React
import React, { useEffect, useState } from "react";
import { IonModal,IonContent} from "@ionic/react";
//Components
import ChargeIcon from "../assets/icons/charge.svg";
import ScanIcon from "../assets/icons/scan-icon.svg";
import NumpadIcon from "../assets/icons/numpad-icon.svg";
import WhiteParkIcon from "../assets/icons/white-park-icon.svg";
import EnterChargeBoxCode from "./EnterChargeBoxCode";
import StartParkingOnlyModal from "./StartParkingOnlyModal";
import ParkChargeIcon from "../assets/icons/park-charge-icon.svg";
import SlotIcon from "../assets/icons/slot-icon.svg";
import AccountSelector from "./AccountSelector";
//Helpers
import { BarcodeScanner } from "@awesome-cordova-plugins/barcode-scanner";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { parkChargeFunctions } from "../helper/parkChargeFunctions";
import { setLoading } from "../redux/slices/globalSlice";
import { Alert } from "../helper/alert";
import { api } from "../helper/api";
import {setCodeId} from "../redux/slices/userSlice";
import VehicleSelector from "./VehicleSelector";
import SessionModalsToolbar from "./SessionModalsToolbar";
import EnterSlotCode from "./EnterSlotCode";
import {setSessionModal, setStationDetailModal} from "../redux/slices/modalSlice";

const StartChargingModal = ({
  closeModal,
  station,
  openSessionModal,
  value,
  setValue,
  isOpen,
  breakpoint
}) => {

  const [isEnterCodeComponent, setIsEnterCodeComponent] = React.useState(false);
  const [isStartParkingOnlyModal, setStartParkingOnlyModal] = React.useState(false)
  const [accounts, setAccounts] = useState([]);
  const [isStartChargeSession, setIsStartChargeSession] = useState(false);
  const {defaultVehicle, codeId, slotId, usageAccount, user} = useSelector((state) => state.userSlice);
  const sessionType = station.TYPE === "station" ? "charge" : "park_and_charge"
  const [modalBreakpoint, setModalBreakpoint] = useState(0.9)
  const [vehicleInUse, setVehicleInUse] = useState(null)
  const [isSlotCodeModal, setSlotCodeModal] = useState(false);
  const dispatch = useDispatch()

    useEffect(() => {
      if (isStartChargeSession) startChargingSession();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStartChargeSession]);

  const startChargingSession = () => {
      if (sessionType === "charge") startChargeSession();
      else startParkChargeSession();
    }

  useEffect(() => {
    if (isOpen) {
      getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  const [isScanActive, setScanActive] = useState(false)
  const scanner = () => {
    setScanActive(true)
    BarcodeScanner.scan().then((barcodeData) => {
      if (!barcodeData.cancelled) {
        dispatch(setCodeId(barcodeData.text))
        setIsStartChargeSession(true)
      }
    }).finally(() => setScanActive(false))
  };

  const getAccounts = async () => {
    const { data } = await api.getCompanyList();
    if (data.success) {
      setAccounts(data.response);
    }
  };

  const startChargeSession = () => {
    dispatch(setLoading({ status: true, message: t("starting-session") }))

    const vehicle_id = vehicleInUse ? vehicleInUse : defaultVehicle.id;

    parkChargeFunctions.startCharge(station.DETAIL?.connections[0]?.id, vehicle_id, false,codeId,usageAccount,slotId).then(({data}) => {
      if(data.success) {
        dispatch(setSessionModal(true))
        dispatch(setStationDetailModal(false))
        closeModal(true)
      } else {
        setIsStartChargeSession(false)
      }
    })
    .catch(() => setIsStartChargeSession(false))
    .finally(() => dispatch(setLoading({status: false, message: ""})))
  }

  const startParkChargeSession = () => {
    dispatch(setLoading({status: true, message: t("starting-session")}))

    const vehicle_id = vehicleInUse ? vehicleInUse : defaultVehicle.id;

    api.startParkCharge(station.DETAIL.park_id, codeId, vehicle_id, slotId, usageAccount)
    .then(({data}) => {
      if (data.success) {
        dispatch(setSessionModal(true))
        dispatch(setStationDetailModal(false))
        closeModal(true);
      } else {
        setIsStartChargeSession(false)
      }
    })
    .catch(() => setIsStartChargeSession(false))
    .finally(() => dispatch(setLoading({status: false, message: ""})))
  }

  const isPossibleToStartSession = async (type = "code") => {
    if (!user.email_verify) {
      Alert.error(t("you-should-verification-your-email"));
      return false;
    }

    const vehicle_id = vehicleInUse ? vehicleInUse : defaultVehicle.id;

    api.checkStartSessionIsPossible(sessionType, station.entry_id, vehicle_id)
    .then(({data}) =>{
      if(data.success){
        if(sessionType === "charge") {
          if(data.response?.charge){
            if(type === "code") setIsEnterCodeComponent(true);
            else if (type=== "slot") setSlotCodeModal(true);
            else !isScanActive ? scanner() : Alert.warning(t("scanner-active"))
          }else{
            data.message && Alert.error(`${(data.message[0].toUpperCase()+data.message.slice(1,))}!`)
          }
        }else{
          if(data.response?.park_and_charge){
            if(type === "code"){
              setIsEnterCodeComponent(true);
              return;
            }

            if (type === "slot"){
              setSlotCodeModal(true);
              return;
            }

            !isScanActive ? scanner() : Alert.warning(t("scanner-active"))

          }else{
            data.message && Alert.error(`${(data.message[0].toUpperCase()+data.message.slice(1,))}!`)
          }
        }
      }else{
        data.message && Alert.error(`${(data.message[0].toUpperCase()+data.message.slice(1,))}!`)
      }
    })
  }

  const GetChargeCodeComponent = () => {
    return (
      <div className="flex flex-col items-center justify-center text-center bg-primary">
        <div className="flex space-x-2">
          {sessionType === "charge" ? (
            <>
              <img src={ChargeIcon} alt="" />
              <span className="text-[14px] font-normal">{t("charge")}</span>
            </>
          ) : (
            <>
              <img src={ParkChargeIcon} alt="" />
              <span className="text-[14px] font-normal">{t("park-and-charge")}</span>
            </>
          )}
        </div>
        <div className="my-[20px]">
        <VehicleSelector
          setVehicle={(id) => setVehicleInUse(id)}
          vehicleInUse={vehicleInUse ?? defaultVehicle?.id}
        />
        </div>
        <div className="flex space-x-[20px] flex-row justify-center items-center text-center mb-[8%]">
          <div>
            <button
              onClick={() => isPossibleToStartSession("scan")}
              className="w-[105px] h-[100px] rounded-[20px] border-button-white flex flex-col justify-center items-center text-center space-y-[10.96px]"
            >
              <img src={ScanIcon} alt="" />
              <span>{t("scan-qr")}</span>
            </button>
          </div>

          {!station.DETAIL?.parking?.segment_mobile_schema?.length ?
          <div>
            <p className="text-[14px] font-normal text-[rgba(255, 255, 255, .65)]">
              {t("or")}
            </p>
          </div>
         : null}
          <div>
            <button
              onClick={() => {
                isPossibleToStartSession("code")
              }}
              className="w-[105px] h-[100px] rounded-[20px] border-button-white flex flex-col justify-center items-center text-center space-y-[10.96px]"
            >
              <img src={NumpadIcon} alt="" />
              <span>{t("enter-code")}</span>
            </button>
          </div>
          {station.DETAIL?.parking?.segment_mobile_schema?.length ?
              <div>
                <button
                    onClick={() => {
                      isPossibleToStartSession('slot')
                    }}
                    className="w-[105px] h-[100px] rounded-[20px] border-button-white flex flex-col justify-center items-center text-center space-y-[10.96px]"
                >
                  <img src={SlotIcon} alt="" />
                  <span>{t("select-slot")}</span>
                </button>
              </div>
          : null}
        </div>
        <AccountSelector accounts={accounts} isActive={isOpen} usageAccount={usageAccount}/>
      </div>
    );
  };

  const BottomSection = () => {
    return (
      <>
        {sessionType === "charge" ? (
          <div
            className={`w-full bg-primary rounded-t-[35px] text-white flex flex-col items-center justify-start text-center p-[16px] absolute bottom-0
             ${isEnterCodeComponent ? "h-full" : "h-[100%]"} transition-all`}
          >
            <GetChargeCodeComponent/>
          </div>
        ) : (
          <div
            className={`park-charg-resp w-full bg-[#5E53E1] rounded-t-[35px] text-white flex flex-col items-center justify-start text-center absolute bottom-0 ${
              isEnterCodeComponent ? "h-full" : "h-[100%]"
            } transition-all`}
          >
            <div>
              <button
                onClick={() => setStartParkingOnlyModal(true)}
                className="text-white flex space-x-2 justify-center text-center items-center border-button-white rounded-full px-[50px] py-[16px] my-[35px]"
              >
                <img
                  src={WhiteParkIcon}
                  alt=""
                  className="w-[24px]  h-[24px]"
                />
                <p className="text-[14px] font-medium">
                  {t("start-parking-only")}
                </p>
              </button>
            </div>
            <div className="bg-primary rounded-t-[35px] w-full h-full pt-[24px]">
              <GetChargeCodeComponent/>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
    {
      !isEnterCodeComponent ?
      (<SessionModalsToolbar
        stationName={station?.NAME}
        stationLogo={station.DETAIL?.operator_logo }
        dismissModal={() => closeModal()}
        breakpoint={breakpoint}
      />) :
      null
    }
    <IonContent className={"no-padding"}>
    <div>
      <div
        className={`flex flex-col gap-4 justify-center items-center text-center text-[#353355] px-[16px] h-[13%]`}
      >
        <h3
          className="text-[20px] font-medium text-primary"
          dangerouslySetInnerHTML={{
            __html: t("start-charge-warn"),
          }}
        />
      </div>
      <div
        className={`w-full bg-${
          sessionType === "charge" ? "primay" : "[#5E53E1]"
        } rounded-t-3xl text-white flex flex-col items-center justify-start text-center absolute bottom-0
      ${isEnterCodeComponent ? "h-full" : "h-[85%]"} transition-[height]`}
      >
        {!isEnterCodeComponent ? (
          <BottomSection />
        ) : (
          <EnterChargeBoxCode
            setShow={setIsEnterCodeComponent}
            startSession={() => setIsStartChargeSession(true)}
          />
        )}
      </div>


      <IonModal
          isOpen={isSlotCodeModal}
          onDidDismiss={() => setStartParkingOnlyModal(false)}
          breakpoints={[0, 1]}
          canDismiss={true}
          initialBreakpoint={1}
          className={`transition-all`}
          onIonBreakpointDidChange={(e) => setModalBreakpoint(e.detail.breakpoint)}
          onIonModalWillPresent={() => setModalBreakpoint(0.9)}
      >
        <EnterSlotCode
            setSlotCodeModal={setSlotCodeModal}
            station={station}
            startSession={() => setIsStartChargeSession(true)}
            segmentMobileSchemas={station.DETAIL?.parking?.segment_mobile_schema}
        />
      </IonModal>

      {sessionType !== "charge" && (
        <IonModal
          isOpen={isStartParkingOnlyModal}
          onDidDismiss={() => setStartParkingOnlyModal(false)}
          breakpoints={[0, 0.9, 1]}
          canDismiss={true}
          initialBreakpoint={0.9}
          className={`${modalBreakpoint !== 1 ? "radiusModal" : ""} transition-all`}
          onIonBreakpointDidChange={(e) => setModalBreakpoint(e.detail.breakpoint)}
          onIonModalWillPresent={() => setModalBreakpoint(0.9)}
        >
          <StartParkingOnlyModal
            isOpen={isStartParkingOnlyModal}
            station={station}
            handleOpenModal={setStartParkingOnlyModal}
            value={value}
            handleValue={setValue}
            openSessionModal={openSessionModal}
            vehicleInUseFromStartCharge={vehicleInUse}
            usageAccountFromStartCharge={usageAccount}
            breakpoints={modalBreakpoint}
          />
        </IonModal>
      )}
    </div>
      </IonContent>
      </>
  );
};

export default StartChargingModal;
