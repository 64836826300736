import {useSpring, animated} from "react-spring"

const ParkChargeSession = ({
                               slotLabel,
                               slotNumber,
                               plate,
                               park,
                               parkingLights,
                               value,
                               chargingIcon,
                               car,
                               charge
                           }) => {

    const animationOpacity = useSpring({
        to: { opacity: charge ? 1 : 0 },
        from: { opacity: 0 },
        config: { duration: 800 },
        delay: 200,
    })

    return (
      <div className="bg-blue carParkImage carParkImageParking h-[130px] w-[352px] rounded-3xl border-r-[7px] border-[#5E53E1] flex items-center justify-center text-center">
            <div className="rounded-3xl w-[320px] border-r-[1px] border-t-[1px] border-b-[1px] border-primary border-opacity-20 flex justify-center items-center text-center">
                <div className="flex flex-col justify-center items-center gap-1">
                    {slotLabel && <div className="text-[12px] font-medium text-purple bg-white px-[7px] py-[3px] rounded-full">
                        {slotLabel}
                    </div>}
                    {slotNumber && <div className="text-[12px] font-medium text-[gray] bg-white px-[7px] py-[3px] rounded-full" style={{ border: "1px solid #34D298" }}>
                        {slotNumber}
                    </div>}
                    <div className="text-[12px] font-medium text-[gray] bg-white px-[7px] py-[3px] rounded-full uppercase">
                        {plate}
                    </div>
                </div>
                {park && (
                    <img src={parkingLights} className="parkingLights" style={{marginLeft: "14px", transform: `translate(${value - 180}px, -50%)`}} alt=""/>
                )}
                <img src={car} alt="" style={{position: "relative", left: `${value >= 210 ? 10 : value - 200}px`}}/>
                {park === true && (
                    <>
                        <animated.div
                            style={animationOpacity}
                            className="absolute z-50 rounded-full flex justify-center items-center text-center"
                        >
                            <img className="" src={chargingIcon} alt="" />
                        </animated.div>
                        <div className="chargingWaveAnimation absolute w-[128px] h-[128px] border-2 border-[#34D298] border-opacity-50 z-10 rounded-full flex justify-center items-center text-center" />
                        <div className="chargingWaveAnimationInner absolute w-[96px] h-[96px] border-2 border-[#34D298] rounded-full" />
                    </>
                )}
            </div>
        </div>
    )
}

export default ParkChargeSession
