import React from 'react';

const ModalHandler = () => {
    return (
        <div className="ModalHandlerWrapper">
            <span className="ModalHandler"></span>
        </div>
    );
}

export default ModalHandler;
