// Ionic & React
import { IonPage, IonContent } from "@ionic/react"

import Spinner from "./../components/Spinner"

const Loading = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="Landing">
          <div className="LandingWrapper">
            <Spinner size={60} speed="1.5" margin="auto" />
          </div>
        </div>
      </IonContent>
    </IonPage>
  )
}

export default Loading
