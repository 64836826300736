import {store} from "../redux/store";
import {setDefaultVehicle, setDrifterCardBalance, setUser, setVehicles, setConfig, storeReset} from "../redux/slices/userSlice";
import {api} from "./api";
import {Alert} from "./alert";
import OneSignal from "onesignal-cordova-plugin";
import {Capacitor} from "@capacitor/core";
import {App as CapacitorApp} from '@capacitor/app';

import {clearModals, setVersionControlModal} from "../redux/slices/modalSlice";
import {t} from 'i18next'
import { setAlert, setLoading } from "../redux/slices/globalSlice";
export const getPerson = () => {
    return new Promise(async (resolve) => {
        api.getPerson({paginate: false}).then((response) => {
            if (response?.data?.success) {
                store.dispatch(setUser(response?.data?.response))
                Capacitor.isNativePlatform() && OneSignal.setExternalUserId(`${response.data.response.id}`)
                resolve(response.data.response)
            }
        })
    })
}

const getVehicles = () => {
    api.getVehicles().then((response) => {
        if (response && response.status === 200) {
            store.dispatch(setVehicles(response?.data?.data ?? []))
            return response?.data?.data
        }
        return []
    }).then((response) => {
        response?.length > 0 && getDefaultVehicle()
    })
}

export const getDefaultVehicle = () => {
    api.getDefaultVehicle().then((response) => {

        if (response?.status !== 200 || response.data.data === null || response.data.data.length === 0) {
            store.dispatch(setDefaultVehicle({}))
            return
        }
        store.dispatch(setDefaultVehicle(response.data?.data[0]))
    })
}


export const getDrifterCardBalance = () => {
    api.getDrifterCardBalance().then(({data}) => {
        if (data.success) {
            store.dispatch(setDrifterCardBalance(data?.response[0]?.balance))
        }
    }).catch(err => Alert.error(err));
}

export const updateUserInfo = () => {
    getPerson()
    getVehicles()
}

export const isModalsOpened = () => {
    let modals = document.getElementsByTagName('ion-modal')
    if (modals) {
        for (const key in modals) {
            let elem = modals[key]
            if (typeof elem === 'object' && elem.getAttribute('is-open') === 'true') {
                return true
            }
        }
    }
    return false;
}

const handleVersion = async (config) => {
    const info = await CapacitorApp.getInfo()
    if (Capacitor.getPlatform() === "android") {
        if (info.build < config.data.config.version.android) {
            store.dispatch(setVersionControlModal(true));
        }
    } else if (Capacitor.getPlatform() === "ios") {
        if (info.build < config.data.config.version.ios) {
            store.dispatch(setVersionControlModal(true));
        }
    }
}

export const getConfigFromApi = async () => {
    const configRes = await api.getConfig();
    if(configRes?.status === 200 && configRes?.data?.status){
        store.dispatch(setConfig(configRes?.data?.config));
        if(!Capacitor.isNativePlatform()) return
        handleVersion(configRes)
    }
}

export const clusteringIcons = (count, type) => {
    if (type === "MAP_ALL_IN_ONE") {
        return window.btoa(`
        <svg width="65" height="43" viewBox="0 0 65 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="clip0_8443_2895">
            <rect width="23" height="22" fill="white" transform="translate(10 8)"/>
          </clipPath>
        </defs>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.37258 0 0 5.37258 0 12V26C0 32.6274 5.37258 38 12 38H27.5003L30.6173 41.9598C31.9415 43.3467 34.117 43.3467 35.4412 41.9598L38.5374 38H53C59.6274 38 65 32.6274 65 26V12C65 5.37258 59.6274 0 53 0H12Z" fill="#B11D94"/>
        <g clip-path="url(#clip0_8443_2895)">
          <path d="M15.5 18.2121V16.6318H10.0138V18.2121C10.0138 19.7706 11.2419 21.0339 12.7569 21.0339C14.2719 21.0339 15.5 19.7706 15.5 18.2121Z" fill="white"/>
          <path d="M11.8288 18.8329H11.0295V14.7294C11.0295 14.5024 11.2084 14.3184 11.4291 14.3184C11.6498 14.3184 11.8287 14.5024 11.8287 14.7294V18.8329H11.8288Z" fill="white"/>
          <path d="M14.4843 18.8329H13.6849V14.7294C13.6849 14.5024 13.8638 14.3184 14.0845 14.3184C14.3052 14.3184 14.4841 14.5024 14.4841 14.7294V18.8329H14.4843Z" fill="white"/>
          <path d="M19.5464 23.6925H21.7836V20.8846H22.9152C24.8013 20.8846 26.232 19.6381 26.232 17.7616C26.232 15.844 24.8013 14.6523 22.9152 14.6523H19.5464V23.6925ZM21.7836 18.83V16.7069H22.9283C23.5526 16.7069 23.9558 17.1315 23.9558 17.7616C23.9558 18.4054 23.5526 18.83 22.9283 18.83H21.7836Z" fill="white"/>
          <path d="M22.0601 9C19.1534 9 16.5444 10.3223 14.762 12.4096C14.5904 12.6105 14.6686 12.9157 14.9104 13.0221V13.0221C15.0787 13.0962 15.2751 13.0476 15.3953 12.9084C17.0309 11.0162 19.4109 9.81973 22.0601 9.81973C26.9808 9.81973 30.9842 13.938 30.9842 18.9999C30.9842 24.0618 26.9808 28.1801 22.0601 28.1801C17.1395 28.1801 13.1361 24.062 13.1361 19.0001C13.1361 18.742 13.1477 18.4866 13.1688 18.234C13.1835 18.058 13.0878 17.8902 12.9262 17.819V17.819C12.6807 17.711 12.3998 17.8681 12.3768 18.1353C12.3523 18.4203 12.3392 18.7086 12.3392 18.9999C12.3392 24.5139 16.7002 29 22.0603 29C27.4203 29 31.7813 24.5139 31.7813 18.9999C31.7813 13.486 27.4202 9 22.0601 9Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3818 12.0849C16.2526 9.89406 18.9973 8.5 22.0601 8.5C27.7096 8.5 32.2813 13.2232 32.2813 18.9999C32.2813 24.7766 27.7097 29.5 22.0603 29.5C16.4108 29.5 11.8392 24.7766 11.8392 18.9999C11.8392 18.6936 11.853 18.391 11.8787 18.0924C11.9327 17.4647 12.5861 17.123 13.1276 17.3614C13.4923 17.522 13.6988 17.8955 13.6671 18.2756C13.647 18.5155 13.6361 18.7569 13.6361 19.0001C13.6361 23.7993 17.4288 27.6801 22.0601 27.6801C26.6914 27.6801 30.4842 23.7991 30.4842 18.9999C30.4842 14.2007 26.6914 10.3197 22.0601 10.3197C19.5659 10.3197 17.3207 11.4455 15.7736 13.2354C15.5164 13.533 15.0873 13.6462 14.709 13.4797C14.1782 13.2462 13.9801 12.5552 14.3818 12.0849ZM14.762 12.4096C16.5444 10.3223 19.1534 9 22.0601 9C27.4202 9 31.7813 13.486 31.7813 18.9999C31.7813 24.5139 27.4203 29 22.0603 29C16.7002 29 12.3392 24.5139 12.3392 18.9999C12.3392 18.7086 12.3523 18.4203 12.3768 18.1353C12.3998 17.8681 12.6807 17.711 12.9262 17.819C13.0878 17.8902 13.1835 18.058 13.1688 18.234C13.1477 18.4866 13.1361 18.742 13.1361 19.0001C13.1361 24.062 17.1395 28.1801 22.0601 28.1801C26.9808 28.1801 30.9842 24.0618 30.9842 18.9999C30.9842 13.938 26.9808 9.81973 22.0601 9.81973C19.4109 9.81973 17.0309 11.0162 15.3953 12.9084C15.2751 13.0476 15.0787 13.0962 14.9104 13.0221C14.6686 12.9157 14.5904 12.6105 14.762 12.4096Z" fill="white"/>
        </g>
        <text style="fill: rgb(255, 255, 255); font-family: Arial, sans-serif; font-size: 14px; white-space: pre;" x="38.964" y="23.248">${count}</text>
      </svg>
        `)
    } else if (type === "MAP_PARKING") {
        return window.btoa(`
        <svg width="65" height="43" viewBox="0 0 65 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.37258 0 0 5.37258 0 12V26C0 32.6274 5.37258 38 12 38H27.5003L30.6173 41.9598C31.9415 43.3467 34.117 43.3467 35.4412 41.9598L38.5374 38H53C59.6274 38 65 32.6274 65 26V12C65 5.37258 59.6274 0 53 0H12Z" fill="#5E53E1"/>
          <path d="M16 25.7575H19.657V21.5598H21.5068C24.5898 21.5598 26.9286 19.6965 26.9286 16.8913C26.9286 14.0246 24.5898 12.2432 21.5068 12.2432H16V25.7575ZM19.657 18.4884V15.3146H21.5281C22.5486 15.3146 23.2078 15.9494 23.2078 16.8913C23.2078 17.8536 22.5486 18.4884 21.5281 18.4884H19.657Z" fill="white"/>
          <text style="fill: rgb(255, 255, 255); font-family: Arial, sans-serif; font-size: 14px; white-space: pre;" x="34.428" y="23.881">${count}</text>
        </svg>`)
    } else {
        return window.btoa(`
       <svg width="65" height="43"  viewBox="0 0 65 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0C5.37258 0 0 5.37258 0 12V26C0 32.6274 5.37258 38 12 38H27.5003L30.6173 41.9598C31.9415 43.3467 34.117 43.3467 35.4412 41.9598L38.5374 38H53C59.6274 38 65 32.6274 65 26V12C65 5.37258 59.6274 0 53 0H12Z" fill="#34D298"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.6821 27.8506C17.6824 27.9748 17.8374 28.0312 17.9174 27.9362L26.3868 17.8815C26.4598 17.7948 26.3982 17.6623 26.2848 17.6623H21.5701C21.2024 17.6623 20.9041 17.3646 20.9034 16.997L20.8893 10.1502C20.8891 10.026 20.734 9.96958 20.654 10.0646L12.1847 20.1187C12.1116 20.2054 12.1733 20.338 12.2867 20.338H17.0014C17.369 20.338 17.6673 20.6356 17.668 21.0033L17.6821 27.8506Z" fill="#FFFEFE"/>
        <text style="fill: rgb(255, 255, 255); font-family: Arial, sans-serif; font-size: 14px; white-space: pre;" x="30.997" y="23.524">${count}</text>
       </svg>`)
    }

}

export const markerIcons = (type) => {
    if (type === "park_and_charge") {
        return window.btoa(`<svg width="34" height="43" viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.97851 5.2003C11.8639 -1.94228 23.0784 -1.72714 29.6752 5.84571C35.8597 12.9022 35.2825 23.9603 28.7682 30.7156L20.1923 41.6446C18.4607 43.4518 15.6158 43.4518 13.8842 41.6446L4.97851 30.3714C-1.6595 23.444 -1.6595 12.1708 4.97851 5.2003Z" fill="#B11D94"/>
		<path d="M10.5 16.2121V14.6318H5.01379V16.2121C5.01379 17.7706 6.24186 19.0339 7.75688 19.0339C9.27191 19.0339 10.5 17.7706 10.5 16.2121Z" fill="white"/>
		<path d="M6.8289 16.8329H6.02954V12.7294C6.02954 12.5024 6.20842 12.3184 6.42913 12.3184C6.64985 12.3184 6.82872 12.5024 6.82872 12.7294V16.8329H6.8289Z" fill="white"/>
		<path d="M9.48429 16.8329H8.68494V12.7294C8.68494 12.5024 8.86381 12.3184 9.08453 12.3184C9.30524 12.3184 9.48412 12.5024 9.48412 12.7294V16.8329H9.48429Z" fill="white"/>
		<path d="M14.5464 21.6925H16.7836V18.8846H17.9152C19.8012 18.8846 21.232 17.6381 21.232 15.7616C21.232 13.844 19.8012 12.6523 17.9152 12.6523H14.5464V21.6925ZM16.7836 16.83V14.7069H17.9282C18.5525 14.7069 18.9557 15.1315 18.9557 15.7616C18.9557 16.4054 18.5525 16.83 17.9282 16.83H16.7836Z" fill="white"/>
		<path d="M17.0601 7C14.1534 7 11.5444 8.32231 9.76198 10.4096C9.59043 10.6105 9.66856 10.9157 9.91036 11.0221C10.0787 11.0962 10.2751 11.0476 10.3953 10.9084C12.0309 9.01625 14.4109 7.81973 17.0601 7.81973C21.9808 7.81973 25.9842 11.938 25.9842 16.9999C25.9842 22.0618 21.9808 26.1801 17.0601 26.1801C12.1395 26.1801 8.13609 22.062 8.13609 17.0001C8.13609 16.742 8.14769 16.4866 8.16878 16.234C8.18348 16.058 8.08776 15.8902 7.92617 15.819C7.68068 15.711 7.39984 15.8681 7.37682 16.1353C7.35228 16.4203 7.33923 16.7086 7.33923 16.9999C7.33923 22.5139 11.7002 27 17.0603 27C22.4203 27 26.7813 22.5139 26.7813 16.9999C26.7813 11.486 22.4202 7 17.0601 7Z" fill="white"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M9.38175 10.0849C11.2526 7.89406 13.9973 6.5 17.0601 6.5C22.7096 6.5 27.2813 11.2232 27.2813 16.9999C27.2813 22.7766 22.7097 27.5 17.0603 27.5C11.4108 27.5 6.83923 22.7766 6.83923 16.9999C6.83923 16.6936 6.85295 16.391 6.87867 16.0924C6.93273 15.4647 7.58614 15.123 8.12765 15.3614C8.4923 15.522 8.69879 15.8955 8.66705 16.2756C8.64701 16.5155 8.63609 16.7569 8.63609 17.0001C8.63609 21.7993 12.4288 25.6801 17.0601 25.6801C21.6914 25.6801 25.4842 21.7991 25.4842 16.9999C25.4842 12.2007 21.6914 8.31973 17.0601 8.31973C14.5659 8.31973 12.3207 9.44553 10.7736 11.2354C10.5164 11.533 10.0873 11.6462 9.70897 11.4797C9.17823 11.2462 8.98015 10.5552 9.38175 10.0849ZM9.76198 10.4096C11.5444 8.32231 14.1534 7 17.0601 7C22.4202 7 26.7813 11.486 26.7813 16.9999C26.7813 22.5139 22.4203 27 17.0603 27C11.7002 27 7.33923 22.5139 7.33923 16.9999C7.33923 16.7086 7.35228 16.4203 7.37682 16.1353C7.39984 15.8681 7.68068 15.711 7.92617 15.819C8.08776 15.8902 8.18348 16.058 8.16878 16.234C8.14769 16.4866 8.13609 16.742 8.13609 17.0001C8.13609 22.062 12.1395 26.1801 17.0601 26.1801C21.9808 26.1801 25.9842 22.0618 25.9842 16.9999C25.9842 11.938 21.9808 7.81973 17.0601 7.81973C14.4109 7.81973 12.0309 9.01625 10.3953 10.9084C10.2751 11.0476 10.0787 11.0962 9.91036 11.0221C9.66856 10.9157 9.59043 10.6105 9.76198 10.4096Z" fill="white"/>
		<path d="M10.5 16.2121V14.6318H5.01379V16.2121C5.01379 17.7706 6.24186 19.0339 7.75688 19.0339C9.27191 19.0339 10.5 17.7706 10.5 16.2121Z" fill="white"/>
		<path d="M6.8289 16.8329H6.02954V12.7294C6.02954 12.5024 6.20842 12.3184 6.42913 12.3184C6.64985 12.3184 6.82872 12.5024 6.82872 12.7294V16.8329H6.8289Z" fill="white"/>
		<path d="M9.48429 16.8329H8.68494V12.7294C8.68494 12.5024 8.86381 12.3184 9.08453 12.3184C9.30524 12.3184 9.48412 12.5024 9.48412 12.7294V16.8329H9.48429Z" fill="white"/>
		</svg>`)
    } else if (type === "parking") {
        return window.btoa(`<svg width="34" height="43" viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.97851 5.2003C11.8639 -1.94228 23.0784 -1.72714 29.6752 5.84571C35.8597 12.9022 35.2825 23.9603 28.7682 30.7156L20.1923 41.6446C18.4607 43.4518 15.6158 43.4518 13.8842 41.6446L4.97851 30.3714C-1.6595 23.444 -1.6595 12.1708 4.97851 5.2003Z" fill="#5E53E1"/>
		<path d="M12.1428 24.3424H15.7999V20.1448H17.6496C20.7326 20.1448 23.0714 18.2815 23.0714 15.4762C23.0714 12.6096 20.7326 10.8281 17.6496 10.8281H12.1428V24.3424ZM15.7999 17.0734V13.8996H17.6709C18.6915 13.8996 19.3506 14.5343 19.3506 15.4762C19.3506 16.4386 18.6915 17.0734 17.6709 17.0734H15.7999Z" fill="white"/>
		</svg>`)
    } else {
        return window.btoa(`<svg width="34" height="43" viewBox="0 0 34 43" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.97851 5.20029C11.8639 -1.94228 23.0784 -1.72714 29.6752 5.84571C35.8597 12.9022 35.2825 23.9603 28.7682 30.7156L20.1923 41.6446C18.4607 43.4518 15.6158 43.4518 13.8842 41.6446L4.97851 30.3714C-1.6595 23.444 -1.6595 12.1708 4.97851 5.20029Z" fill="#34D298"/>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M15.3965 26.4356C15.3967 26.5598 15.5518 26.6162 15.6318 26.5212L24.1011 16.4665C24.1742 16.3798 24.1125 16.2472 23.9991 16.2472H19.2844C18.9168 16.2472 18.6185 15.9496 18.6177 15.5819L18.6037 8.73517C18.6034 8.61095 18.4484 8.55454 18.3684 8.64954L9.89903 18.7037C9.82599 18.7904 9.88763 18.9229 10.001 18.9229H14.7157C15.0834 18.9229 15.3816 19.2206 15.3824 19.5882L15.3965 26.4356Z" fill="#FFFEFE"/>
		</svg>`)
    }
}

export const getConfig = (type) => {
    let {google, mapbox, paypal, stripe} = store.getState().userSlice.config;

    switch (type) {
        case "google_map_token":
            return google?.map_token
        case "mapbox_nav_token":
            return mapbox?.nav_token
        case "mapbox_simulation_mode":
            return mapbox?.simulation_mode
        case "paypal_client":
            return paypal?.client_id
        case "paypal_currency":
            return paypal?.currency
        case "stripe_publishable":
            return stripe?.publishable_key
        default:
            return ""
    }
}

export const formatElapsedTime = (seconds) => {
    if (seconds) {
        return new Date(seconds * 1000).toISOString().slice(11, 19);
    }
}
export const formatDate = (date) => {
    if (date) {
      return new Date(date)
        .toISOString()
        .slice(0, 10)
        .split("-")
        .reverse()
        .join(".");
    }
    return "";
  };
const removeToken = async (type = "") => {
    store.dispatch(clearModals())
    Capacitor.isNativePlatform() && OneSignal.removeExternalUserId()
    store.dispatch(storeReset())
    store.dispatch(setLoading({status: false, message: ""}))
}

export const logout = () => {
    store.dispatch(setLoading({status: true, message: t("logging-out")}))
    removeToken()
}

export const deleteAccount = async () => {
    store.dispatch(setLoading({status: true, message: t("logging-out")}))
    const res = await api.deleteAccount()
    if (res.data.success === true) {
        removeToken()
    }else{
        store.dispatch(setAlert({status: true, message: t("try-again"), type: "error"}))
    }
}