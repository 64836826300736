import { IonContent, IonModal } from "@ionic/react";
import { t } from "i18next";
import { useEffect } from "react";
import { api } from "../../helper/api";
import PageToolbar from "../PageToolbar";
import "./styles/AboutDrifter.scss";
const AboutDrifter = ({ isOpen, setOpen }) => {
  useEffect(() => {
    if(isOpen){
      getContent()
    }
  },[isOpen])
  const getContent = () => {
    api.getPages("about-drifter").then(({data}) => {
      if(data.success){
        document.getElementById("about-drifter").innerHTML = data.response.content;
      }
    });
  }
  return (
    <IonModal
      isOpen={isOpen}
      canDismiss={true}
      className="modal-white-background"
      onDidDismiss={() => setOpen(false)}
    >
      <PageToolbar
        title={t("about-drifter")}
        leftButtonType="back"
        onClick={() => setOpen(false)}
        backgroundColor="#fff"
      />
      <IonContent>
        <div className="Wrapper">
          <div id="about-drifter"/>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default AboutDrifter;
