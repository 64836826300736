import { IonContent } from '@ionic/react';
import { t } from 'i18next';

const AboutModalContent = ({
    setAboutAppOpen,
    setAboutDrifterOpen,
    setPrivacyPolicyOpen,
    setTermsAndConditionsOpen,
    setContactModal
}) => {

  const AboutRoutes = [
    { id: 1, label: "privacy-and-policy" },
    { id: 2, label: "terms-conditions" },
    { id: 3, label: "contact" }
  ];

  const openModal = (id) => {
    switch (id) {
      case 1:
        setPrivacyPolicyOpen(true)
        break;
      case 2:
        setTermsAndConditionsOpen(true)
        break;
      case 3:
        setContactModal(true)
        break;
      default:
        break;
    }
  }

    return (
      <IonContent className='no-padding' scrollY={false}>
        <div className="DTItems">
          {AboutRoutes.map(r => (
            <div className="DTItem capitalize" onClick={() => openModal(r.id)} key={r.id}>
              {t(r.label)}
            </div>
          ))}
        </div>
      </IonContent>
    );
}

export default AboutModalContent;
