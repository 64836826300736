import React from 'react'

const ChargeSession = ({
    slotNumber,
    car,
    chargingIcon,
}) => {
    return (
        <div className="  mt-[37px] bg-blue h-[130px] w-[340px] rounded-3xl border-r-[7px] border-primary flex items-center justify-center text-center">
            <div className="rounded-3xl w-[300px] border-r-[1px] border-t-[1px] border-b-[1px] border-primary border-opacity-20 flex justify-center items-center text-center">
                {slotNumber && <div className="text-[12px] font-medium text-[gray] bg-white px-[7px] py-[3px] rounded-full" style={{ border: "1px solid #34D298" }}>
                    {slotNumber}
                </div>}
                <img
                    src={car}
                    alt=""
                    className="z-30"
                />
                <div className="absolute z-50 rounded-full flex justify-center items-center text-center">
                    <img src={chargingIcon} alt="" />
                </div>
                <div className="chargingWaveAnimation absolute w-[128px] h-[128px] border-2 border-[#34D298] border-opacity-50 z-10 rounded-full flex justify-center items-center text-center" />
                <div className="chargingWaveAnimationInner absolute w-[96px] h-[96px] border-2 border-[#34D298] rounded-full" />
            </div>
        </div>
  )
}

export default ChargeSession
