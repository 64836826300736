import { AddressSkeleton, NoItems, SearchResults } from "./Partials";

const Address = ({
  personForm,
  isEdit,
  setUpProfileRef,
  addressesLoading,
  searchResults,
  addresses,
  setCurrentAddress,
  setAddress,
  currentAddress,
  setAddManualAddressModal,
}) => {
  const completeLater = (e) => {
    e.preventDefault();
    if (e.target.id === "complete-later") {
      setUpProfileRef.current.swiper.slideTo(2);
    }
  };

  if (addressesLoading) {
    return <AddressSkeleton />;
  }

  if (searchResults?.length > 0) {
    return (
      <SearchResults
        isEdit={isEdit}
        addresses={addresses}
        searchResults={searchResults}
        currentAddress={currentAddress}
        setCurrentAddress={setCurrentAddress}
        setAddress={setAddress}
      />
    );
  }
  
  return (
    <NoItems
      setAddManualAddressModal={setAddManualAddressModal}
      completeLater={completeLater}
      isEdit={isEdit}
      personForm={personForm}
    />
  );
};

export default Address;
