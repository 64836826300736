import { createSlice } from "@reduxjs/toolkit"

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState: {
    loading: {
      status: false,
      message: "",
    },
    alert: {
      status: false,
      message: "",
      type: null,
    },
    isDev: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    clearLoader: (state) => {
      state.loading = {status: false, message: ""}
    },
    setAlert: (state, action) => {
      if(typeof action.payload === "boolean"){
        state.alert.status = action.payload
      }else{
        state.alert = action.payload
      }
    },
    setIsDev: (state, action) => {
      state.isDev = action.payload
    }
  },
})

export const {
  setLoading,
  clearLoader,
  setAlert,
  setIsDev
} = globalSlice.actions
export default globalSlice.reducer
