import { IonButton, IonIcon } from '@ionic/react';
import { t } from 'i18next';
import { trashOutline } from "ionicons/icons";

const MyCarsSlideItemInner = ({car, removeCar}) => {
    return (
      <div className="CarsSlideWrapper">
        <IonButton
          className="CarsSlideButton w-[25px] h-[25px]"
          onClick={() => removeCar(car)}
        >
          <IonIcon
            slot="icon-only"
            icon={trashOutline}
            size="small"
          />
        </IonButton>
        <div className="CarsSlideImage flex w-full justify-center">
          <img
            src={car.image}
            alt={car.brand_name ? car.brand_name : t("unknown-brand")}
            draggable={false}
            className="CarsSlideCarImage"
          />
        </div>
        <div className="CarsSlideContent">
          <div className="CarsSlideLogo">
            <img
              src={car.logo}
              alt={car.brand_name ? car.brand_name : t("unknown-brand")}
            />
          </div>
          <div>
            <div className="CarsSlideBrand">
              {car.brand_name ? car.brand_name : t("unknown-brand")}
            </div>
            <div className="CarsSlideModel">
              <p>{car.model_name ? car.model_name : t("unknown-model")}</p>
            </div>
          </div>
        </div>
        <div className="CarsSlideBottom">
          <div className="CarsSlidePlate">
            <p>
            {car.plate ? car.plate : "Unknown plate"}
            </p>
          </div>
          <div className="CarsSlideType">{t("personal")}</div>
        </div>
      </div>
    );
}

export default MyCarsSlideItemInner;
