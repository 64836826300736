import {useTranslation} from 'react-i18next'
import {animated} from "react-spring"
import { OffenseLabel } from './Invoice'

const DisplayCost = ({cost, descPrice, text, desc, color, offense, divider=false, animation=false, ...props}) => {

    const { t } = useTranslation()

    return (
        <animated.div {...props} className={`flex flex-col justify-between pt-[12px] pb-[12px] ${divider==="bottom" ? 'divider-bottom' : divider==="top" ? 'divider-top' : ""} w-full`} >
            {
                text &&
                <div className="flex flex-row justify-between items-center ">
                    <div className="flex items-center space-x-1">
                        <div className={`w-[10px] h-[10px] rounded-[3px] bg-[${color}]`}></div>
                        <p className="text-primary text-[14px] font-medium">
                        {text}
                        </p>
                        {offense && <OffenseLabel />}
                    </div>
                    <div className="flex items-center">
                        <p className="text-[22px] font-bold text-primary">
                            {cost} <span className="text-[16px] font-normal">{t("SEK")}</span>
                        </p>
                    </div>
                </div>
            }
            {
                desc &&
                <div className="flex flex-row justify-between items-center">
                    <div className="flex items-center">
                        <p className="text-primary ml-[14px] text-[12px] font-normal">
                        {desc}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <p className="text-[14px] font-semibold text-gray">
                        {descPrice} <span className="text-[14px] font-normal">{t("SEK/kWH")}</span>
                        </p>
                    </div>
                </div>
            }
        </animated.div>
        )


}

export default DisplayCost
