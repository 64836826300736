import {IonContent, IonPage, useIonRouter} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams} from "react-router";
import { api } from "../../helper/api";
import PageToolbar from "../PageToolbar";
import Text from "../Text";
import { useEffect,useState } from "react";
import ParkingPermitAgreementModal from "./ParkingPermitAgreementModal";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/globalSlice";
import { formatDate } from "../../helper/globalFunctions";

const SiteCodeDetail = () => {
  const params = useParams()
  const dispatch = useDispatch()
  const router = useIonRouter();
  const history = useHistory()
  const { t } = useTranslation();
  const [siteCodeContent, setSiteCodeContent] = useState({})
  const [parkingPermitAgreementModal, setParkingPermitAgreementModal] = useState(false);

  useEffect(() => {
    getDetail()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const getDetail = async () => {
    dispatch(setLoading({status: true, message: ""}))
    const res = await api.getSiteCodeAssignmentDetail(params.id);
    dispatch(setLoading({status: false, message: ""}))
    if(!res.data.success) return;
    setSiteCodeContent(res.data.data)
  }

  const TerminateButton = ({assignment_id}) => {
    return (
      <button
        className="DTButtonOutlined"
        onClick={() => {
          api
            .removeSiteCode(assignment_id)
            .then((res) => router.goBack());
        }}
      >
        {t("terminate")}
      </button>
    );
  }

  return (
    <IonPage>
      <PageToolbar
        title={t("site-code-detail")}
        leftButtonType="back"
        onClick={() => router.goBack()}
        backgroundColor="#fff"
      />
      <IonContent scrollY="false">
        <div className="SiteCodeContent flex justify-start flex-col px-2 overflow-y-auto h-full">
          <div>
            <div className="flex w-full justify-between flex-row items-center px-3">
              <Text size={18} weight={600}>
                {t("station")}:
              </Text>
              <Text
                size={16}
                weight={400}
                align="right"
                wrapWithDots={true}
                otherStyles={"width: 200px !important;"}
              >
                {siteCodeContent?.parking?.name ?? ""}
              </Text>
            </div>
            <div className="flex w-full justify-between flex-row items-center px-3">
              <Text size={18} weight={600}>
                {t("address")}:
              </Text>
              <Text
                size={16}
                weight={400}
                align="right"
                wrapWithDots={true}
                otherStyles={"width: 200px !important;"}
              >
                {siteCodeContent?.parking?.address ?? ""}
              </Text>
            </div>
            <div className="flex w-full justify-between flex-row items-center px-3">
              <Text size={18} weight={600}>
                {t("zonecode")}:
              </Text>
              <Text size={16} weight={400}>
                {siteCodeContent?.parking?.zone_code ?? ""}
              </Text>
            </div>
            <div className="divider-bottom my-[10px]" />
              <div className="flex w-full justify-between flex-row items-center px-3">
                <Text size={18} weight={600}>
                  {t("assignment-id")}:
                </Text>
                <Text size={16} weight={400}>
                  #{siteCodeContent?.assignment_id ?? ""}
                </Text>
              </div>
              <div className="flex w-full justify-between flex-row items-center px-3">
                <Text size={18} weight={600}>
                  {t("assignment-status")}:
                </Text>
                <Text size={16} weight={400}>
                  {siteCodeContent?.agreement_status ?? ""}
                </Text>
              </div>
              <div className="flex w-full justify-between flex-row items-center px-3">
                <Text size={18} weight={600}>
                  {t("terminated-at")}:
                </Text>
                <Text size={16} weight={400}>
                  {formatDate(siteCodeContent?.terminated_at) ?? ""}
                </Text>
              </div>
            <div className="divider-bottom my-[10px]" />
            <div
              style={{
                marginTop: 10,
                paddingBottom: 20,
                paddingLeft: 12,
                paddingRight: 12,
              }}
            >
              <Text size={18} weight={600}>
                {t("duration-time")}:
              </Text>
              <table className="ValidDurationTable">
                <thead>
                  <tr>
                    <th>{t("day")}</th>
                    <th>{t("from")}</th>
                    <th>{t("to")}</th>
                  </tr>
                </thead>
                <tbody>
                  {siteCodeContent?.durations &&
                    Object.keys(siteCodeContent.durations).map(function (
                      key,
                      index
                    ) {
                      const el = siteCodeContent?.durations[key];

                      return (
                        <tr key={el.name}>
                          <td>{el.name}</td>
                          <td>{el.durations[0].from}</td>
                          <td>{el.durations[0].to}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="pb-[20px]">
            <div className="flex w-full p-3  mb-[20px] justify-center items-center bg-purple">
              <Text
                size={16}
                weight={600}
                color="white"
                onClick={() => setParkingPermitAgreementModal(true)}
                otherStyles="text-decoration: underline;"
              >
                {t("digital-permit-parking-code-agreement")}
              </Text>
            </div>
            {history.location.state.status !== "TERMINATED" && (
              <TerminateButton assignment_id={siteCodeContent.assignment_id} />
            )}
          </div>
        </div>
      </IonContent>
      <ParkingPermitAgreementModal
        isopen={parkingPermitAgreementModal}
        setOpen={setParkingPermitAgreementModal}
      />
    </IonPage>
  );
};

export default SiteCodeDetail;
