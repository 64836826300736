import { useTranslation } from 'react-i18next';
import Icon from '../Icon';
import Text from '../Text';

const InvoiceClearFiltersButton = ({clearFilters}) => {
    const{t}=useTranslation()
    return (
        <div className="flex w-full justify-center">
        <button onClick={() => clearFilters()} className="gap-[10px] w-[152px] h-[55px] flex items-center justify-center bg-purple rounded-full fixed bottom-[20px]">
        <Icon
        name="close"
        size={10}
        color="#fff"
        />
        <Text size={16} weight={700} color={"#fff"}>{t("clear-filters")}</Text>
      </button>
      </div>
    );
}

export default InvoiceClearFiltersButton;
