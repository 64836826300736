// Ionic & React
import { IonContent, IonRippleEffect } from "@ionic/react";

// Components
import Logo from "../Logo";
import Text from "../Text";

import {useTranslation} from "react-i18next";
import ReceiptFields from "./ReceiptFields";

const ParkReceipt = ({close, receipt}) => {

  const {t} = useTranslation()

  const discount = {
    isDiscount: false,
    discountDetail: {
      id: 456789,
      amount: 30,
    },
  };

  const drifterCard = {
    isDrifterCard: false,
    id: 123456,
    balance: 12,
  };

  return (
    <IonContent className="YourReceiptModalContent">
      <div className="YourReceiptModal h-full">
        <div className="YourReceipt">
          <h2 className="YourReceiptTitle">{t("your-receipt")}</h2>

          <div className="ReceiptContainer">
            <div className="Receipt">
              <div className="ReceiptLogo">
                <Logo type="Mono" height={38} width={33} />
              </div>
              <Text size="18px" weight="500" margin="12px 0 0">
                {t("have-nice-trip")}
              </Text>

              <div className="ReceiptStrokes" />
              {!receipt.park_session_cost !== 0 ? (
              <ReceiptFields
                from="receipt"
                receipt={receipt}
                discount={discount}
              />) : (
                <div className="py-3">
                  <Text weight="600" transform="unset">
                    {t("not-paying")}
                  </Text>
                </div>
              )}
            </div>
            {drifterCard.isDrifterCard && !receipt.is_digital_code_active && (
              <>
                <div>
                  <p className="text-white font-semibold text-[14px]">
                    50 {t("SEK")}
                    <span className="font-medium text-[#C0C0CB]">
                      {t("charged-from-drifter")}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
          <button className="DTButtonPink ion-activatable" onClick={close}>
            {t("done")}
            <IonRippleEffect />
          </button>
        </div>
      </div>
    </IonContent>
  );
};

export default ParkReceipt;
