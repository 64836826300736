import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/react"
import Text from "./Text"
import { useTranslation } from "react-i18next"

const ChartWrapper = ({
  timeRange,
  monthly,
  handleTimeRangeChange,
  totalConsumption,
  totalCost,
  average,
  children,
  ...props
}) => {

  const { t } = useTranslation()

  return (
    <div className="ChartWrapper" {...props}>
      {timeRange ? (
        <div className="ChartTimeRange">
          <Text size={12} weight={400} margin="0 0 20px">
            {t("23-30-march")}
          </Text>

          <IonSegment
            className="DTSegment"
            onIonChange={(e) => handleTimeRangeChange(e.detail.value)}
          >
            <IonSegmentButton value="weekly" checked={!monthly}>
              <IonLabel>{t("weekly")}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="monthly" checked={monthly}>
              <IonLabel>{t("monthly")}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </div>
      ) : (
        ""
      )}
      <div className="ChartContent">
        <div className="ChartHeader">
          {totalConsumption ? (
            <div>
              <Text size={14} weight={400}>
                {t("total-consumption")}
              </Text>
              <Text size={24} weight={700} margin="4px 0 0">
                {totalConsumption}
              </Text>
            </div>
          ) : (
            ""
          )}
          {totalCost ? (
            <div>
              <Text size={14} weight={400}>
                {t("total-cost")}
              </Text>
              <Text size={24} weight={700} margin="4px 0 0">
                {totalCost}
              </Text>
            </div>
          ) : (
            ""
          )}
          {average ? (
            <div>
              <Text size={14} weight={400}>
                {t("avr-per-day")}
              </Text>
              <Text
                size={24}
                weight={700}
                color="rgba(61, 60, 87, 0.75)"
                margin="4px 0 0"
              >
                {average}
              </Text>
            </div>
          ) : (
            ""
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default ChartWrapper
