import {IonSlide, IonSlides} from "@ionic/react";
import {useEffect, useRef} from "react";
import Text from "../../Text";
import {useTranslation} from "react-i18next";

const RegisterProfileHeader = ({activeSlide, sliderOptions}) => {
    const {t} = useTranslation();
    const setUpProfileTitleRef = useRef(null)

    useEffect(() => {
        setUpProfileTitleRef.current.slideTo(activeSlide)
    }, [activeSlide])

    return (
        <IonSlides
            className={'SetUpProfileTitleSlider'}
            options={sliderOptions}
            ref={setUpProfileTitleRef}
        >
            <IonSlide>
                <Text cssClass={"title"} color={"#fff"}>
                    {t("set-up-profile")}
                </Text>
            </IonSlide>
            <IonSlide>
                <Text cssClass={"title"} color={"#fff"}>
                    {t("select-your-address")}
                </Text>
            </IonSlide>
            <IonSlide>
                <Text cssClass={"title"} color={"#fff"}>
                    {t("add-your-card")}
                </Text>
            </IonSlide>
            <IonSlide>
                <Text cssClass={"title"} color={"#fff"}>
                    {t("license-plate")}
                </Text>
            </IonSlide>
            <IonSlide>
                <Text cssClass={"title"} color={"#fff"}>
                    {t("site-code")}
                </Text>
            </IonSlide>

        </IonSlides>
    );
}

export default RegisterProfileHeader;
