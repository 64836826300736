// Ionic & React
import { IonContent, IonModal } from "@ionic/react"

import "./Menu.css"

// Plugins
import { PieChart, Pie, Cell } from "recharts"

// Components
import PageToolbar from "../components/PageToolbar"
import Logo from "../components/Logo"
import Text from "../components/Text"
import Icon from "../components/Icon"

// Animation Assets
import animationCar from "../assets/images/vectors/animation-car.svg"
import animationTree from "../assets/images/vectors/animation-tree.svg"
import animationFloor from "../assets/images/vectors/animation-floor.svg"
import animationCarStrokes from "../assets/images/vectors/animation-car-strokes.svg"
import animationCarStrokes2 from "../assets/images/vectors/animation-car-strokes-2.svg"
import animationCarStrokes3 from "../assets/images/vectors/animation-car-strokes-3.svg"

import { useTranslation } from "react-i18next"

const DrifterRewards = ({
  setFAQModalOpen,
  rewardsData,
  isDrifterRewardsOpen,
  setDrifterRewardsOpen,
  className,
  ...props
}) => {

  const { t } = useTranslation()

  return (
    <IonModal
      isOpen={isDrifterRewardsOpen}
      canDismiss={true}
      className={`DrifterRewards modal-white-background${
        className ? " " + className : ""
      }`}
      onDidDismiss={() => setDrifterRewardsOpen(false)}
      {...props}
    >
      <PageToolbar
        title={t("drifter-rewards")}
        leftButtonType="back"
        onClick={() => setDrifterRewardsOpen(false)}
        backgroundColor="#fff"
      />

      <IonContent>
        <div className="CarAnimation">
          <img
            className="animationCar"
            src={animationCar}
            alt="Car"
            draggable="false"
          />
          <img
            className="animationTree"
            src={animationTree}
            alt="Tree"
            draggable="false"
          />
          <img
            className="animationTree2"
            src={animationTree}
            alt="Tree"
            draggable="false"
          />
          <img
            className="animationTree3"
            src={animationTree}
            alt="Tree"
            draggable="false"
          />
          <img
            className="animationFloor"
            src={animationFloor}
            alt="Floor"
            draggable="false"
          />
          <img
            className="animationCarStrokes"
            src={animationCarStrokes}
            alt="Car Strokes"
            draggable="false"
          />
          <img
            className="animationCarStrokes2"
            src={animationCarStrokes2}
            alt="Car Strokes"
            draggable="false"
          />
          <img
            className="animationCarStrokes3"
            src={animationCarStrokes3}
            alt="Car Strokes"
            draggable="false"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "18px 0 35px",
          }}
        >
          <Text size="22px" weight="600" margin="0 9px">
            {t("drift-and-save")}
          </Text>
          <Text size="16px" weight="400" margin="18px 9px 0"
          dangerouslySetInnerHTML={{ __html: t("drift-and-save-description") }}
          >
          </Text>
        </div>

        <div className="MenuRewardsWrapper">
          <div className="MenuRewards">
            <div className="MenuRewardsLeft">
              <div className="MenuRewardsPoints">
                <div className="MenuRewardsTitle">{t("drifter-rewards")}</div>
                <div>
                  <span>{rewardsData[0].points}</span>
                  <Logo type="Mono" height={25} width={22} />
                </div>
                {t("points")}
              </div>
            </div>
            <div className="MenuRewardsRight">
              <button
                className="FAQButton"
                onClick={() => setFAQModalOpen(true)}
              >
                {t("FAQ")}
              </button>
              <Text size="12px" weight="400" margin="auto 0 3px" color="#fff">
                {t("expire-date")}
              </Text>
              <Text size="12px" weight="400" color="rgba(255, 255, 255, 0.65)">
                12.21.2023
              </Text>
            </div>
            <div className="MenuRewardsChart">
              <PieChart width={124} height={124}>
                <Pie
                  data={rewardsData}
                  dataKey="points"
                  cx="50%"
                  cy="50%"
                  blendStroke={true}
                  startAngle={230}
                  endAngle={-50}
                  innerRadius={52}
                  outerRadius={61}
                  cornerRadius={20}
                  fill="#fff"
                >
                  {rewardsData.map((entry, idx) => (
                    <Cell key={`cell-${idx}`} fill={rewardsData[idx].color} />
                  ))}
                </Pie>
                <Pie
                  data={[{ pointsTotal: 4281 }]}
                  dataKey="pointsTotal"
                  cx="50%"
                  cy="50%"
                  blendStroke={true}
                  startAngle={230}
                  endAngle={-50}
                  innerRadius={52}
                  outerRadius={61}
                  cornerRadius={20}
                  fill="#353355"
                  isAnimationActive={false}
                />
              </PieChart>
              <Logo type="Mono" height={44} width={51} />
              <div className="MenuRewardsChartDetails">
                <div className="MenuRewardsChartPoint">
                  {rewardsData[1].points}
                </div>
                <span>{t("to-next-reward")}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="ChartWrapper" style={{ marginTop: "20px" }}>
          <div className="ChartRow">
            <Icon name="co2" size={42} fill="var(--dt-primary)" />
            <div>
              <Text size={16} weight={400} color="var(--dt-primary)">
                {t("total-CO2-savings")}
              </Text>
              <Text size={22} weight={700} margin="2px 0 0">
                934 gr
              </Text>
            </div>
          </div>
          <div className="ChartRow">
            <Icon name="tree" height={47} width={37} fill="var(--dt-primary)" />
            <div>
              <Text size={16} weight={400} color="var(--dt-primary)">
              {t("saved-trees")}
              </Text>
              <Text size={22} weight={700} margin="2px 0 0">
                4
              </Text>
            </div>
          </div>
        </div>
      </IonContent>
    </IonModal>
  )
}

export default DrifterRewards
