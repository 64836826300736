import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

import "./index.css"
import "./i18n"
import {Provider} from "react-redux"
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./redux/store";
import {Loading} from "./pages";

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { initFunctions } from "./helper/initFunctions"


initFunctions.setAll()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <React.StrictMode>
        <App/>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);
