// Ionic & React
import { useState } from "react"
import { IonContent, IonModal } from "@ionic/react"

// Components
import PageToolbar from "../components/PageToolbar"
import ChartWrapper from "../components/ChartWrapper"
import BarChart from "../components/BarChart"
import PieChart from "../components/PieChart"

import eonLogo from "../assets/logos/svg/eon.svg"
import aimoLogo from "../assets/logos/Aimo Park.png"
import ionityLogo from "../assets/logos/Ionity.png"

import { useTranslation } from "react-i18next"

const StatisticsModal = ({
  isStatisticsOpen,
  setStatisticsOpen,
  className,
  ...props
}) => {
  const [monthly, setMonthly] = useState(true)
  const [currentPieTab, setCurrentPieTab] = useState("parking")

  const { t } = useTranslation()

  const monthlyBarData = [
    {
      name: "J",
      kWh: 6,
    },
    {
      name: "F",
      kWh: 4,
    },
    {
      name: "M",
      kWh: 8,
    },
    {
      name: "A",
      kWh: 2,
    },
    {
      name: "M",
      kWh: 3,
    },
    {
      name: "J",
      kWh: 4,
    },
    {
      name: "J",
      kWh: 3,
    },
    {
      name: "A",
      kWh: 2,
    },
    {
      name: "S",
      kWh: 5,
    },
    {
      name: "O",
      kWh: 1,
    },
    {
      name: "N",
      kWh: 7,
    },
    {
      name: "D",
      kWh: 4,
    },
  ]

  const weeklyBarData = [
    {
      name: "M",
      kWh: 8,
    },
    {
      name: "T",
      kWh: 6,
    },
    {
      name: "W",
      kWh: 4,
    },
    {
      name: "T",
      kWh: 0,
    },
    {
      name: "F",
      kWh: 8,
    },
    {
      name: "S",
      kWh: 4,
    },
    {
      name: "S",
      kWh: 2,
    },
  ]

  const monthlyPieData = [
    {
      name: "Charging",
      value: 533,
    },
    {
      name: "Overstay",
      value: 333,
    },
    {
      name: "Parking",
      value: 133,
    },
  ]

  const weeklyPieData = [
    {
      name: "Charging",
      value: 933,
    },
    {
      name: "Overstay",
      value: 33,
    },
    {
      name: "Parking",
      value: 33,
    },
  ]

  //----------------- charging - overstay - parking
  const pieColors = ["#2EE589", "#FC1167", "#6561A1"]

  const handleTimeRangeChange = (value) => {
    if (value === "monthly") setMonthly(true)
    else setMonthly(false)
  }

  return (
    <IonModal
      isOpen={isStatisticsOpen}
      canDismiss={true}
      className={`StatisticsModal${className ? " " + className : ""}`}
      onDidDismiss={() => setStatisticsOpen(false)}
      {...props}
    >
      <PageToolbar
        title="Statistics"
        leftButtonType="back"
        onClick={() => setStatisticsOpen(false)}
      />
      <IonContent scrollY={true}>
        <ChartWrapper
          timeRange={true}
          monthly={monthly}
          handleTimeRangeChange={handleTimeRangeChange}
          totalConsumption="33kWh"
        >
          {/* ==== BAR CHART === */}
          <BarChart
            monthly={monthly}
            monthlyData={monthlyBarData}
            weeklyData={weeklyBarData}
          />
        </ChartWrapper>

        <ChartWrapper totalCost="25 kr" average="16 kr">
          <div className="PieChartWrapper">
            {/* ==== PIE CHART === */}
            <div className="PieTabs">
              <div
                className={`PieData -charging${
                  currentPieTab === "charging" ? " active" : ""
                }`}
                onClick={() => setCurrentPieTab("charging")}
              >
                <div className="PieTitle">
                  <span />
                  {t("charging")}
                </div>
                <div className="PieContent">10,7 {t("kr")}</div>
              </div>
              <div
                className={`PieData -overstay${
                  currentPieTab === "overstay" ? " active" : ""
                }`}
                onClick={() => setCurrentPieTab("overstay")}
              >
                <div className="PieTitle">
                  <span />
                  {t("overstay")}
                </div>
                <div className="PieContent">9 kr</div>
              </div>
              <div
                className={`PieData -parking${
                  currentPieTab === "parking" ? " active" : ""
                }`}
                onClick={() => setCurrentPieTab("parking")}
              >
                <div className="PieTitle">
                  <span />
                  {t("parking")}
                </div>
                <div className="PieContent">3,5 {t("kr")}</div>
              </div>
            </div>

            <div className="PieRow">
              <PieChart
                monthly={monthly}
                monthlyData={monthlyPieData}
                weeklyData={weeklyPieData}
                pieColors={pieColors}
                currentPieTab={currentPieTab}
                setCurrentPieTab={setCurrentPieTab}
              />
              <div className="PieFirms">
                <div className="PieFirm">
                  <div className="PieFirmLogo">
                    <img src={eonLogo} alt="eon" />
                  </div>
                  <span>3.5 {t("kr")}</span>
                </div>
                <div className="PieFirm">
                  <div className="PieFirmLogo">
                    <img src={aimoLogo} alt="aimo park" />
                  </div>
                  <span>120 {t("kr")}</span>
                </div>
                <div className="PieFirm">
                  <div className="PieFirmLogo">
                    <img src={ionityLogo} alt="Ionity" />
                  </div>
                  <span>500 {t("kr")}</span>
                </div>
                <div className="PieFirm" style={{ border: "none" }}>
                  <div className="PieFirmLogo">Et al</div>
                  <span>12 {t("kr")}</span>
                </div>
              </div>
            </div>
          </div>
        </ChartWrapper>
      </IonContent>
    </IonModal>
  )
}

export default StatisticsModal
