import { t } from 'i18next';
import { LogoMono } from '../../assets/logo';
import drifterWatermark from "../../assets/images/drifter-logo.png"
import Icon from '../Icon';
const DrifterCardBalance = ({balance, setMoreModalOpen}) => {
    return (
        <div className={"text-white bg-purple rounded-[16px] p-[20px] h-[204px]"}>
        <img className={"absolute right-0 top-[130px]"} src={drifterWatermark} alt={"Background for My Drifter Card area"}/>
        <div className="absolute right-[50px]">
          <LogoMono width={45} height={49}/>
        </div>
        <div className={"head flex justify-between ion-align-items-center mb-[20px]"}>
          <p className={"text-[20px] font-[600] flex justify-center items-center"}>
          <span className="text-[12px] bg-white text-pink px-1 mr-[4px] font-medium">
            Beta
          </span>
            {t("my-drifter-card")}</p>
        </div>
        <div className={"inline-flex ion-align-items-center"}>
          <div>
            <p className={"opacity-[.56] text-[14px] font-light"}>{t("current-balance")}</p>
            <p className={"text-[30px] font-bold"}>
              {balance} <sup className={"font-normal text-[20px] top-[-0.4rem] !text-white"}>{t("kr")}</sup>
            </p>
          </div>
        </div>
        <div className='w-full flex justify-end'>
        <div
          className={"w-fit gap-[5px] text-[12px] flex ion-align-items-center border rounded-[36px] px-[11px] py-[5px] z-[99999999]"}
          onClick={() => setMoreModalOpen(true)}
        >
          <div className={"flex ion-align-items-center justify-center bg-white h-[20px] w-[20px] rounded-[50%] mr-1"}>
            <Icon name={"info"} height={10} width={10} fill={"#B11D94"}/>
          </div>
          {t("learn-more")}
        </div>
        </div>
      </div>
    );
}

export default DrifterCardBalance;
