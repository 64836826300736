import React from 'react'

import { IonContent, IonModal } from '@ionic/react'

const WarningModal = ({
    children,
    ...props
}) => {
  return (
    <IonModal className="radiusModal" {...props}>
      <IonContent scrollY="false">
        <div className="ModalWrapper">
          {children}
        </div>
      </IonContent>
    </IonModal>
  )
}

export default WarningModal
