//Ionic & React
import {useEffect, useState} from "react";
//Components
import Icon from "../Icon";
import Text from "../Text";
import Button from "../Button";
//Helpers
import { useTranslation } from "react-i18next";
import { api } from "../../helper/api";
import { Keyboard } from "@capacitor/keyboard";
//Redux
import { store } from "../../redux/store";
import { setLoading } from "../../redux/slices/globalSlice";
import { Capacitor } from "@capacitor/core";

const BusinessCode = ({setCurrentBusinesAccount, slideRef}) => {

  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [isKeyboard, setIsKeyboard] = useState(false);

  useEffect(() => {
    Capacitor.isNativePlatform() && Keyboard.addListener('keyboardWillShow', () => setIsKeyboard(true));

    return () => {
        Capacitor.isNativePlatform() && Keyboard.removeAllListeners();
    }
  }, []);


  const getCompanyByCode = async () => {
    store.dispatch(setLoading({status: true, message: ""}))
    api.getComponyByCode(code).then(res => {
      if(res.status === 200 && res.data.success === true) {
        setCurrentBusinesAccount(res.data.response)
        slideRef.current.swiper.slideNext()
      }
    })
    store.dispatch(setLoading({status: false, message: ""}))
  }

  const submitHandler = (e) => {
    e.preventDefault();
    getCompanyByCode()
  }

  return (
    <div className={`BusinessCode ${isKeyboard ? "overflow-y-auto pb-[30px]" : ""}`}>
      <div className="BusinessIconWrapper">
        <Icon
        name={"business"}
        fill="var(--dt-primary)"
        width={30}
        height={27}
        />
      </div>
      <Text
        color={"var(--dt-primary)"}
        weight={500}
        size={22}
        margin={"35px 0 0"}
        dangerouslySetInnerHTML={{ __html: t("enter-8-digits") }}
      />
      <form onSubmit={(e)=>submitHandler(e)}>
        <input
        type="text"
        value={code}
        className="BusinessCodeInput"
        onChange={e => setCode(e.target.value)}
        placeholder="ENTER CODE"
        />
        <Button type={"submit"}>{t("find-my-business")}</Button>
      </form>
    </div>
  );
};

export default BusinessCode;
