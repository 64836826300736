import DTInput from "../DTInput";
import {useTranslation} from "react-i18next";
import Address from "./Address";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setLoading} from "../../redux/slices/globalSlice";
import {api} from "../../helper/api";
import {useIonAlert} from "@ionic/react";
import {setUser} from "../../redux/slices/userSlice";
import Text from "../Text";
import EditManuallyButton from "./EditManuallyButton";
import AddManualAddress from "./AddManualAddress";

const SetAddress = ({addresses, onSubmit, search = true, editable = false, activeSlide}) => {
    const [showAlert] = useIonAlert();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {user} = useSelector((state) => state.userSlice);
    const [searchResults, setSearchResults] = useState([]);
    const [fetchLoading, setFetchLoading] = useState(true)
    const [addManualAddressModal, setAddManualAddressModal] = useState(false)

    useEffect(() => {
        setSearchResults(addresses ?? [])
    }, [addresses]);

    useEffect(() => {
        activeSlide === 1 && getAddresses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSlide]);

    const runFilter = (e) => {
        const filteredAddresses = addresses.filter((address) => {
            return address.zipCode.toLowerCase().includes(e.target.value.toLowerCase())
        })

        setSearchResults(filteredAddresses ?? [])
    }

    const getAddresses = () => {
        setFetchLoading(true)

        api.getUserInformationFromGovernment(user).then(({data}) => {
            setSearchResults(data?.response ?? [])
        }).finally(() => {
            setFetchLoading(false)
        })
    }

    const setAddress = (address) => {
        dispatch(setLoading({status: true, message: t("saving-your-address")}));
        api.userEdit(address).then(({data, status}) => {
            if (status !== 200) {
                return showAlert({message: t("something-went-wrong")});
            }

            dispatch(setUser(data?.response));
            onSubmit(data?.response);

        }).finally(() => {
            dispatch(setLoading({status: false, message: ""}));
        });
    }

    return (
        <>
            <div
                className={'SetUpProfileForm overflow-hidden iosSafeAreaBottomPadding flex flex-col justify-between align-center'}
            >
                {(search && addresses?.length) > 1 && (
                    <div className={"pl-[16px] pr-[8px] text-left"}>
                        <DTInput
                            onChange={runFilter}
                            margin={`24px 0 0`}
                            label={t("your-postcode")}
                            autoFocus
                        />

                        <Text size="16px" weight="500" align="left">
                            {t("results-found", { count: addresses?.length })}
                        </Text>
                    </div>
                )}

                <div className={"flex-1"}>
                    <Address
                        addresses={addresses}
                        personForm={user}
                        searchResults={searchResults}
                        setAddress={setAddress}
                        addressesLoading={fetchLoading}
                    />
                </div>


                {
                    editable && (
                        <div className={"mb-[40px] pt-3 text-center"}>
                            <EditManuallyButton setAddManualAddressModal={() => setAddManualAddressModal(true)} />

                            <AddManualAddress
                                isOpen={addManualAddressModal}
                                setOpen={setAddManualAddressModal}
                                setAddress={setAddress}
                            />

                        </div>
                    )
                }
            </div>
        </>
    )
}

export default SetAddress
