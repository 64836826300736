import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {UnplugIcon} from "../../../../../assets/icons";
import {setLoading} from "../../../../../redux/slices/globalSlice";
import {parkChargeFunctions} from "../../../../../helper/parkChargeFunctions";
import {setActiveSession} from "../../../../../redux/slices/userSlice";
import {setSessionModal} from "../../../../../redux/slices/modalSlice";

const ChargingBottom = ({ setReceipt }) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {activeSession} = useSelector((state) => state.userSlice);

  const stopSession = async () => {
    dispatch(setLoading({status: true, message: ''}))

    parkChargeFunctions.stopCharge(activeSession.charge_transaction.id).then(({data: {success, response}}) => {
      if (success) {
        setReceipt(response)
        dispatch(setActiveSession({type: null, park_session: null, charge_transaction: null}))
        dispatch(setSessionModal({status: false}))
      }
    }).finally(() => {
      dispatch(setLoading({status: false, message: ''}))
    })
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full px-[10px]">
        <div>
          <p className="text-white font-medium text-[16px]">
            {t("total-cost")}
          </p>
        </div>
        <div>
          <p className="text-white font-bold text-[26px]">
            {activeSession.charge_transaction?.payment_amount ?? 0} {" "}
            <span className="text-white font-medium text-[16px]">
                {t("SEK")}
              </span>
          </p>
        </div>
      </div>
      <div
        className="mt-[21px] bg-purple text-[16px] text-[rgba(255,255,255,0.7)] font-medium italic flex justify-center items-center text-center space-x-[16px] rounded-[14px] w-[303px] h-[67px] py-[16px]"
        onClick={stopSession}
      >
        <img src={UnplugIcon} alt="" />
        <p className="text-[rgba(255, 255, 255, .7);] italic font-medium text-[16px]">
          {t("unplug-to-end")}
        </p>
      </div>
    </>
  )
}

export default ChargingBottom;
