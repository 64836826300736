import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useRef } from "react";

const PicturePreview = ({previewUrl, setCropped}) => {
    const cropperRef = useRef(null);
    const onCrop = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        setCropped(cropper.getCroppedCanvas().toDataURL());
      };
    return (
        <Cropper
        src={previewUrl}
        style={{ height: 400, width: window.innerWidth }}
        initialAspectRatio={16 / 9}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
        />
    )
}

export default PicturePreview;
