import React from 'react';

const MenuUser = ({profilePic, label}) => {
    return (
        <>
        <div className="MenuUserImage">
              <img
                src={profilePic}
                alt="User Profile"/>
            </div>
            <div className="MenuUserName">
              {label}
            </div>
        </>
    );
}

export default MenuUser;
