import { createSlice } from "@reduxjs/toolkit"

export const modalSlice = createSlice({
  name: "modalSlice",
  initialState: {
    menuModal: {
        status: false,
        canDismiss: true
    },
    myProfileModal: {
      status: false,
      canDismiss: true
    },
    myCarsModal: {
      status: false,
      canDismiss: true
    },
    searchModal: {
      status: false,
      canDismiss: true
    },
    filterModal: {
      status: false,
      canDismiss: true
    },
    sessionModal: {
      status: false,
    },
    parkSessionModal: {
      status: false,
      canDismiss: true
    },
    chargeSessionModal: {
      status: false,
      canDismiss: true
    },
    parkChargeSessionModal: {
      status: false,
      canDismiss: true
    },
    stationDetailModal: {
      status: false,
      canDismiss: true
    },
    addSiteCodeModal: {
      status: false,
      back:false
    },
    addMyBusinessAccountModal: {
      status: false
    },
    buyTicketModal: {
      status: false,
    },
    editEmailModal: {
      status: false
    },
    versionControlModal: {
      status: false
    }
  },
  reducers: {
    setMenuModal : (state, action) => {
      state.menuModal.status = action.payload
    },
    setMyProfileModal : (state, action) => {
      state.myProfileModal.status = action.payload
    },
    setMyCarsModal : (state, action) => {
      state.myCarsModal.status = action.payload
    },
    setSessionModal : (state, action) => {
      state.sessionModal.status = action.payload
    },
    setParkSessionModal : (state, action) => {
      state.parkSessionModal.status = action.payload
    },
    setChargeSessionModal : (state, action) => {
      state.chargeSessionModal.status = action.payload
    },
    setParkChargeSessionModal : (state, action) => {
      state.parkChargeSessionModal.status = action.payload
    },
    setStationDetailModal : (state, action) => {
      state.stationDetailModal.status = action.payload
    },
    setSearchModal : (state, action) => {
      state.searchModal.status = action.payload
    },
    setFilterModal : (state, action) => {
      state.filterModal.status = action.payload
    },
    setAddSiteCodeModal : (state, action) => {
      state.addSiteCodeModal = action.payload
    },
    setAddMyBusinsessAccountModal : (state, action) => {
      state.addMyBusinessAccountModal.status = action.payload
    },
    setBuyTicketModal : (state, action) => {
      state.buyTicketModal.status = action.payload
    },
    setEditEmailModal : (state, action) => {
      state.editEmailModal.status = action.payload
    },
    setVersionControlModal: (state, action) => {
      state.versionControlModal.status = action.payload
    },
    clearModals: state => {
      state.myProfileModal.status = false
      state.menuModal.status = false
      state.myCarsModal.status = false
    }
  },
})

export const {
  setMenuModal,
  setMyProfileModal,
  setMyCarsModal,
  setSessionModal,
  setParkSessionModal,
  setChargeSessionModal,
  setParkChargeSessionModal,
  setStationDetailModal,
  setSearchModal,
  setFilterModal,
  setAddSiteCodeModal,
  setAddMyBusinsessAccountModal,
  clearModals,
  setBuyTicketModal,
  setEditEmailModal,
  setVersionControlModal
} = modalSlice.actions
export default modalSlice.reducer
