const AddressSkeleton = () => {
    return (
        <div className="AddressList" style={{ margin: "60px 0 0" }}>
          <div className="AddressBoxSkeleton" />
          <div className="AddressBoxSkeleton" />
          <div className="AddressBoxSkeleton" />
        </div>
      );
}

export default AddressSkeleton