/**
 * Created by drifter-mobile.
 * User: ozcandurak
 * Date: 19.08.2022
 * Time: 17:14
 */

const safeArea = `
    margin-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
    margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */
    margin-bottom: constant(safe-area-inset-bottom); /* Status bar height on iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom); /* Status bar height on iOS 11+ */
    
    /*
      position: relative;
      height: calc(100vh - env(safe-area-inset-top));
    */`

const safeAreaTop = `
  margin-top: constant(safe-area-inset-top); /* Status bar height on iOS 11.0 */
  margin-top: env(safe-area-inset-top); /* Status bar height on iOS 11+ */

  /*
  position: relative;
  height: calc(100vh - env(safe-area-inset-top));
  */`



export {
  safeArea,
  safeAreaTop
};