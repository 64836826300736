// Ionic & React
import { Component } from "react"
import { IonBackButton } from "@ionic/react"
import { chevronBackOutline } from "ionicons/icons"

// Plugins
import styled from "styled-components"
export default class BackButton extends Component {
  render() {
    const { className, children, icon, closeIcon, defaultHref, ...props } =
      this.props

    const DTBackButton = styled(IonBackButton)`
      --icon-font-size: 17px;
      --background: transparent;
      --background-focused: transparent;
      --background-focused-opacity: transparent;
      --background-hover: transparent;
      --background-hover-opacity: 1;
      --ripple-color: transparent;

      height: 100% !important;
      padding: 0 !important;
      color: var(--dt-primary);
      display: flex !important;
      align-items: center !important;
      overflow: visible;

      ::part(native) {
        height: 100% !important;
        width: 60px;
        padding: 0;
        background-image: ${closeIcon
          ? `url("data:image/svg+xml,%3Csvg width='514' height='512' viewBox='0 0 514 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M497.589 425.532C518.405 444.747 518.405 478.374 497.589 497.589C487.981 507.196 475.171 512 462.361 512C447.95 512 435.14 507.196 425.532 497.589L257.401 329.457L87.6685 497.589C78.061 507.196 65.251 512 52.441 512C38.0297 512 25.2197 507.196 15.6122 497.589C-5.20407 478.374 -5.20407 444.747 15.6122 425.532L183.744 255.8L15.6122 87.6685C-5.20407 68.4535 -5.20407 34.8272 15.6122 15.6122C34.8272 -5.20407 68.4535 -5.20407 87.6685 15.6122L257.401 183.744L425.532 15.6122C444.747 -5.20407 478.374 -5.20407 497.589 15.6122C518.405 34.8272 518.405 68.4535 497.589 87.6685L329.457 257.401L497.589 425.532Z' fill='%23353355'/%3E%3C/svg%3E%0A")`
          : `url("data:image/svg+xml,%3Csvg width='596' height='512' viewBox='0 0 596 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M595.597 255.334C595.597 273.988 580.94 288.645 563.619 288.645H110.592L287.805 456.531C301.13 468.523 301.13 489.842 289.138 501.834C277.146 515.158 257.159 515.158 243.835 503.166L9.32702 279.318C2.66486 272.655 0 264.661 0 255.334C0 247.339 2.66486 239.345 9.32702 232.682L243.835 8.83394C257.159 -3.15795 277.146 -3.15795 289.138 10.1664C301.13 22.1583 301.13 43.4772 287.805 55.469L110.592 223.355H563.619C582.273 223.355 595.597 238.012 595.597 255.334Z' fill='%23353355'/%3E%3C/svg%3E%0A")`};
        background-position: center center;
        background-repeat: no-repeat;
        background-size: ${closeIcon ? `10px` : `17px`};
        overflow: visible !important;
      }

      &.whiteIcon::part(native) {
        background-image: ${closeIcon
          ? `url("data:image/svg+xml,%3Csvg width='514' height='512' viewBox='0 0 514 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M497.589 425.532C518.405 444.747 518.405 478.374 497.589 497.589C487.981 507.196 475.171 512 462.361 512C447.95 512 435.14 507.196 425.532 497.589L257.401 329.457L87.6685 497.589C78.061 507.196 65.251 512 52.441 512C38.0297 512 25.2197 507.196 15.6122 497.589C-5.20407 478.374 -5.20407 444.747 15.6122 425.532L183.744 255.8L15.6122 87.6685C-5.20407 68.4535 -5.20407 34.8272 15.6122 15.6122C34.8272 -5.20407 68.4535 -5.20407 87.6685 15.6122L257.401 183.744L425.532 15.6122C444.747 -5.20407 478.374 -5.20407 497.589 15.6122C518.405 34.8272 518.405 68.4535 497.589 87.6685L329.457 257.401L497.589 425.532Z' fill='%23353355'/%3E%3C/svg%3E%0A")`
          : `url("data:image/svg+xml,%3Csvg width='596' height='512' viewBox='0 0 596 512' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M595.597 255.334C595.597 273.988 580.94 288.645 563.619 288.645H110.592L287.805 456.531C301.13 468.523 301.13 489.842 289.138 501.834C277.146 515.158 257.159 515.158 243.835 503.166L9.32702 279.318C2.66486 272.655 0 264.661 0 255.334C0 247.339 2.66486 239.345 9.32702 232.682L243.835 8.83394C257.159 -3.15795 277.146 -3.15795 289.138 10.1664C301.13 22.1583 301.13 43.4772 287.805 55.469L110.592 223.355H563.619C582.273 223.355 595.597 238.012 595.597 255.334Z' fill='%23fff'/%3E%3C/svg%3E%0A")`};
      }

      ::part(text) {
        padding-top: 1px;
        padding-left: 12px;
        font-size: 16px;
        font-weight: 700;
        text-transform: initial;
        line-height: 1;
      }

      ::part(icon) {
        opacity: 0;
        visibility: hidden;
      }
    `

    return (
      <DTBackButton
        {...props}
        icon={chevronBackOutline}
        defaultHref={defaultHref ? defaultHref : "/tabs"}
        className={className}
      />
    )
  }
}
