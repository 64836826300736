//Ionic & React
import { useState, useLayoutEffect } from "react";
//Components
import PageToolbar from "./PageToolbar"
import Text from "./Text"
import ConfirmIcon from "../assets/images/vectors/confirm-icon.svg"
//Capacitor
import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from '@capacitor/app';
import { CameraPreview } from '@capacitor-community/camera-preview';
//Helpers
import { useTranslation } from "react-i18next"
//Redux
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/slices/globalSlice";
import { api } from "../helper/api";

const Report = ({isOpen, setOpen}) => {
    
    const { t } = useTranslation()
    const [imageUrl, setImageUrl] = useState(null)
    const [useThis, setUseThis] = useState(false)
    const [isCameraOn,setIsCameraOn] = useState(false)
    const dispatch = useDispatch()

    const cameraOptions = {
        parent: 'report-camera',
        storeToFile: false,
        width: window.innerWidth,
        height: 300,
        disableAudio: true,
        x: 0,
        y: 130,
        toBack: false,
        rotateWhenOrientationChanged: false   
    }

    const sendPicture = async () => {
        dispatch(setLoading({status: true, message: ""}))
        await api.postReportImage({
            "options": {
                "parameters": {
                    "image": imageUrl
                }
            }
        })
        .then(res => {
            res.data.success && setUseThis(true)
        })
        .finally(() => dispatch(setLoading({status: false, message: ""})))
    }

    useLayoutEffect(() => {
        if(isOpen){
            CameraPreview.start(cameraOptions);
            setIsCameraOn(true)
            setUseThis(false)
            setImageUrl(null)
        }else{
            CameraPreview.stop()
            setIsCameraOn(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    if (Capacitor.isNativePlatform()){
        CapacitorApp.addListener('backButton', ({ canGoBack }) => {
              if(canGoBack)
              {
                CameraPreview.stop()
                setIsCameraOn(false)
              }
        });
      }

    const takePicture = () => {
        CameraPreview.captureSample({
            quality: 100
        }).then(res => {
            setImageUrl("data:image/jpeg;base64,"+res.value)
            setIsCameraOn(false)
            CameraPreview.stop()
        })
    }

    const EndOfReport = () => {
        return (
            <div className="EndReportSession">
                <img src={ConfirmIcon} alt="ConfirmIcon" />
                <Text margin={"0 0 11px"} color={"#FFF"} size={22} weight={500}>{t("got-your-report")}</Text>
                <Text margin={"0 0 37px"} color={"rgba(255,255,255,0.65)"} size={14} weight={400}
                dangerouslySetInnerHTML={{__html: t("got-your-report-desc")}}
                ></Text>
                <button className="EndReportSessionButton" onClick={() => setOpen(false)}>
                    {t("go-to-park")}
                </button>
            </div>
        )
    }

  return (
    <>
            <div className="bg-primary h-full flex flex-col">
                <PageToolbar
                title={t("report")}
                titleColor="white"
                leftButtonType="back"
                whiteIcon={true}
                onClick={() => {
                    if(isCameraOn){
                        setIsCameraOn(false)
                        CameraPreview.stop()
                    }
                    setOpen(false)
                }}
                />
                {useThis ? <EndOfReport/> : (
                <>
                    <div className="ReportModalDesc">
                        <Text 
                            color={'white'}
                            size={14}
                            dangerouslySetInnerHTML={{ __html: t("report-page-desc") }}
                        >
                        </Text>
                    </div>

                    <div className={`${imageUrl && "hidden"}`}
                    style={{height: "300px", position: "absolute", top: "200px", width: `${window.innerWidth}px`}}
                    id="report-camera"></div>
                    
                    {
                    imageUrl && 
                        (
                            <div className="ReportModalCameraContent">
                                <img src={imageUrl} alt="" />
                            </div>
                        )
                    }
                    <div className="ReportModalBottom">
                        {!imageUrl ? (
                        <>
                            <Text color={"rgba(255, 255, 255, 0.65)"} size={14} weight={500}>
                                {t("take-photo")}
                            </Text>
                            <div className="TakePhotoButton">
                                <button className="TakePhotoButtonInner" onClick={() => takePicture()}>
                                </button>
                            </div>
                        </>
                        ):(
                        <div className="TookedButtons"> 
                            <button className="TookedBtn RetryButton" onClick={() => {
                                    setImageUrl(null)
                                    CameraPreview.start(cameraOptions)
                                }}>{t("retry")}</button>
                            <button className="TookedBtn UseThisButton" onClick={() => sendPicture()}>{t("use-this")}</button>
                        </div>
                        )}

                    </div>
                </>
                )}
            </div>
    </>
  )
}

export default Report