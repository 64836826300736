import { Swiper, SwiperSlide } from 'swiper/react';
import ParkChargeIcon from './ParkChargeIcon';

const SearchResultsSlider = ({
  stations,
  itemOnclick
}) => {
  return (
    <Swiper
      width={304}
      spaceBetween={30}
      slidesPerView={"auto"}
      className="SearchResultsSlider"
    >
      {stations.map((station, idx) =>
        station ? (
          <SwiperSlide
            key={station.entry_id}
            className="SearchResultsSlide"
            onClick={() => {
              itemOnclick(station);
            }}
          >
            <ParkChargeIcon station={station} />

            <div className="ResultsSliderContent">
              <div className="ResultsSliderTitle">{station.NAME}</div>
              <div className="ResultsSliderAddres">
                {station.address ? station.address : "No address information."}
              </div>
              <div className="ResultsSliderDistance">
                {station.DISTANCE.toFixed(2)} km
              </div>
            </div>

            <span className="ResultsSliderCTA">
              <svg
                width="7"
                height="14"
                viewBox="0 0 7 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.359841 0.231804C0.784118 -0.12176 1.41468 -0.0644363 1.76825 0.359841L6.66155 6.2318C7.03239 6.67682 7.0324 7.32323 6.66155 7.76825L1.76825 13.6402C1.41468 14.0645 0.784118 14.1218 0.359841 13.7682C-0.0644363 13.4147 -0.12176 12.7841 0.231804 12.3598L4.69832 7.00003L0.231804 1.64021C-0.12176 1.21593 -0.0644363 0.585368 0.359841 0.231804Z"
                  fill="#353355"
                />
              </svg>
            </span>
          </SwiperSlide>
        ) : (
          ""
        )
      )}
    </Swiper>
  )
}

export default SearchResultsSlider
