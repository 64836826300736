//Ionic & React
import { useEffect, useState } from "react";
import { IonModal, IonSkeletonText } from "@ionic/react";
//Helpers
import { api } from "../../helper/api";
import { useTranslation } from "react-i18next";
//Components
import Text from "../Text";
import Icon from "../Icon";
import Button from "../Button";
import SelectTicketModalItem from "./SelectTicketModalItem";
import BuyTicketItem from "./BuyTicketItem";
//Redux
import { useDispatch } from "react-redux";
import { setBuyTicketModal } from "../../redux/slices/modalSlice";
const SelectTicketModal = ({
  isOpen,
  setOpen,
  vehicle,
  station,
  setIsStartSession,
  setStartSessionModalOpen
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [ticket, setTicket] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      api
        .getActiveLongTermTickets(station.entry_id, vehicle)
        .then((res) => {
          if (res.status === 200 && res.data.success) {
            setTicket(res.data.response);
          }
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const SKELETON_STYLE = {
    height: 8,
    "--background": "rgb(82, 84, 86)",
    "--background-rgb": "82, 84, 86",
  };

  const Skeleton = () => {
    return (
      <div className="TicketSkeleton RadioRow ion-activatable">
        <IonSkeletonText animated={true} style={SKELETON_STYLE} />
        <IonSkeletonText animated={true} style={SKELETON_STYLE} />
        <IonSkeletonText animated={true} style={SKELETON_STYLE} />
      </div>
    );
  };

  const Ticket = () =>
    ticket.id ? (
      <SelectTicketModalItem ticket={ticket} />
    ) : (
      <BuyTicketItem
        setIsStartSession={setIsStartSession}
        setSelectTicketModal={setOpen}
        setStartSessionModalOpen={setStartSessionModalOpen}
        setBuyTicketModalOpen={status => dispatch(setBuyTicketModal(status))}
      />
    );

  return (
      <IonModal
        isOpen={isOpen}
        breakpoints={[0, 0.4]}
        initialBreakpoint={0.4}
        onDidDismiss={() => setOpen(false)}
        className="SelectTicketModal"
      >
        <div className="p-4">
          <div className="flex flex-row justify-between mb-[10px]">
            <Text weight={600} size={24} color="var(--dt-primary)">
              {t("start-session")}
            </Text>
            <Icon name={"close-modal"} onClick={() => setOpen(false)} />
          </div>
          <div className="pb-3">
            <Text weight={500} size={16}>
              {t("using-tickets")}
            </Text>
            {isLoading === false ? <Ticket /> : <Skeleton />}
          </div>
          <Button onClick={() => setIsStartSession(true)}>
            <Text weight={600} size={16} color="#fff">
              {t("start-parking")}
            </Text>
            {ticket.id ? (
              <Text margin="0 0 0 4px" weight={400} size={16} color="#fff">
                {t("with-ID", { id: ticket.id ? ticket.id : "" })}
              </Text>
            ) : (
              <Text margin="0 0 0 4px" weight={400} size={16} color="#fff">
                {t("now")}
              </Text>
            )}
          </Button>
        </div>
      </IonModal>
  );
};

export default SelectTicketModal;
