import {IonModal} from '@ionic/react';
import { t } from 'i18next';
import PageToolbar from '../PageToolbar';
import SiteCode from "./SiteCode";
import {setAddSiteCodeModal} from "../../redux/slices/modalSlice";
import {useDispatch, useSelector} from "react-redux";

const AddSiteCodeModal = ({setRefreshCodes}) => {
    const dispatch = useDispatch()
    const { addSiteCodeModal } = useSelector((state) => state.modalSlice);

    return (
      <IonModal isOpen={addSiteCodeModal.status} canDismiss={true}>
        <PageToolbar
          title={t("add-site-code")}
          leftButtonType="back"
          onClick={() => {
            dispatch(setAddSiteCodeModal({status:false,back:true}))
          }}
        />
            <SiteCode setRefreshCodes={setRefreshCodes}/>

      </IonModal>
    );
}

export default AddSiteCodeModal;
