import { useTranslation } from 'react-i18next';

const PreviewButtons = ({retry, chooseThis}) => {
    const {t} = useTranslation()
    return (
        <div className="TookedButtons">
        <button
          className="TookedBtn RetryButton"
          onClick={() => retry()}
        >
          {t("retry")}
        </button>
        <button
          className="TookedBtn UseThisButton"
          onClick={() => chooseThis()}
        >
          {t("use-this")}
        </button>
      </div>
    );
}

export default PreviewButtons;
