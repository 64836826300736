import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import OneSignal from "onesignal-cordova-plugin";
import {Alert} from "./alert";

export const initFunctions = {
    sentry: () => {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [new BrowserTracing()],
            tracesSampleRate: 1.0,
            release: `LAST_COMMIT: ${process.env.REACT_APP_GIT_SHA}`
        });
    },
    setOnesignal: () => {
        OneSignal.setAppId(process.env.REACT_APP_ONESIGNAL_API_KEY);
        OneSignal.setNotificationWillShowInForegroundHandler(function (notificationReceivedEvent) {
            const notification = notificationReceivedEvent.getNotification();
            Alert.warning(notification.body)
        })
        OneSignal.setNotificationOpenedHandler(function (jsonData) {});
        OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {});
    },
    setAll: () => {
        initFunctions.sentry();
        document.addEventListener('deviceready', () => {
            initFunctions.setOnesignal();
        }, false)
    }
}
