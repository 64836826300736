import { IonContent, IonModal } from "@ionic/react";
import { t } from "i18next";
import React from "react";
import { api } from "../../helper/api";
import Icon from "../Icon";
import PageToolbar from "../PageToolbar";
import './styles/TermsAndConditions.scss'
const TermsAndConditions = ({isOpen, setOpen, registered=true}) => {
  React.useEffect(() => {
    if(isOpen){
      getContent()
    }
  }, [isOpen])
  const getContent = () => {
    api.getPages("terms-and-conditions").then(({data}) => {
      if(data.success){
        document.getElementById("terms-and-conditions").innerHTML = data.response.content;
      }
    })
  }


  if(registered){
    return (
      <IonModal
        isOpen={isOpen}
        canDismiss={true}
        className="modal-white-background"
        onDidDismiss={() => setOpen(false)}
      >
        <PageToolbar
          title={t("terms-and-conditions")}
          leftButtonType="back"
          onClick={() => setOpen(false)}
          backgroundColor="#fff"
        />
        <IonContent>
          <div className="Wrapper">
            <div id="terms-and-conditions"/>
          </div>
        </IonContent>
      </IonModal>
    );
  }else{
    return (
      <IonModal
      isOpen={isOpen}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      canDismiss={true}
      cssClass="DTModal radiusModal"
      onDidDismiss={() => setOpen(false)}
      onIonBreakpointDidChange={e => {
        if(e.detail.breakpoint === 0){
          setOpen(false)
        }
      }}
      >
        <IonContent>
          <Icon 
          cssClass="absolute top-[32px] right-[32px]"
          name="close"
          size={18}
          color="var(--dt-primary)"
          onClick={() => setOpen(false)}
          />
          <div className="Wrapper">
            <div id="terms-and-conditions"></div>
          </div>
        </IonContent>
      </IonModal>
    );
  }



  
};

export default TermsAndConditions;
