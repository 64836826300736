import {IonModal, IonSlide} from "@ionic/react";
import CardSlider from "../PaymentMethods/CardSlider/CardSlider";
import {useState} from "react";
import PaymentForm from "../PaymentMethods/PaymentForm";
import {useSelector} from "react-redux";
import Button from "../Button";
import {useTranslation} from "react-i18next";

const SetCard = ({onSuccess}) => {
    const {t} = useTranslation();
    const [isAddCardModalOpen, setAddCardModalOpen] = useState(false);
    const {paymentMethods} = useSelector((store) => store.userSlice);

    const addedHandler = () => {
        setAddCardModalOpen(false);
        onSuccess();
    }

    return (
        <IonSlide>
            <div style={{width: "100%"}}>
                <CardSlider addNewHandler={() => setAddCardModalOpen(true)}/>

                {
                    paymentMethods?.length > 0 && (
                        <Button
                            cssClass={"absolute bottom-2 left-0 pl-[16px] pr-[16px] z-10"}
                            theme="primary"
                            margin="0"
                            onClick={addedHandler}
                            type={'submit'}
                        >
                            {t("next")}
                        </Button>
                    )
                }


            </div>


            <IonModal
                isOpen={isAddCardModalOpen}
                breakpoints={[0.7, 1]}
                initialBreakpoint={1}
                handle={false}
                cssClass="DTModal"
                onDidDismiss={() => setAddCardModalOpen(false)}
            >
                <div style={{padding: "16px"}}>
                    <PaymentForm successCallback={addedHandler} />
                </div>
            </IonModal>
        </IonSlide>
    );
}

export default SetCard;
