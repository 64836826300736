// Ionic & React
import { useState } from "react"
import { IonRippleEffect } from "@ionic/react"

const DTAccordion = ({ title, content, className, isHTMLContent=false }) => {
  const [isActive, setActive] = useState(false)

  return (
    <div
      className={`DTAccordion${isActive ? " active" : ""} ${
        className ? className : ""
      }`}
      onClick={() => setActive(!isActive)}
    >
      <div className="DTAccordionTitle w-full">
        {title}
        <span className="DTAccordionArrow" />
        <IonRippleEffect />
      </div>
      {isHTMLContent ? (
        <div
          className="DTAccordionContent w-full"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <div className="DTAccordionContent w-full">{content}</div>
      )}
    </div>
  );
}

export default DTAccordion
