//Ionic & React
import { useEffect, useState } from "react";
//Components
import Button from "../Button";
//Helpers
import {useTranslation} from "react-i18next";
import {Alert} from "../../helper/alert";
import { api } from "../../helper/api";
import { useHistory, useParams } from "react-router";
import { IonSpinner } from "@ionic/react";

const PayWithCard = ({ id, onSuccess }) => {
  const { t } = useTranslation();
  const history = useHistory()
  const params = useParams()
  const [isLoading, setLoading] = useState(false)
  const [defaultCard, setDefaultCard] = useState(null);

  const getDefaultCard = async () => {
    const result = await api.getDefaultCard()
    if(result.status === 200 && result.data?.data !== null){
      setDefaultCard(result.data.data[0])
    }
  };
  const handlePay = () => {
    defaultCard
      ? payWithCard()
      : history.push("/add-payment-method", {
          from: `invoices/detail/${params.invoiceId}`,
        });
  };

  useEffect(() => getDefaultCard(), []);

  const payWithCard = () => {
    setLoading(true)
    api
      .payWithDefaultCard(id)
      .then(({ data }) => {
        if (data.success) {
          onSuccess();
          Alert.success(t("payment_success"));
        }
      })
      .catch((err) => Alert.error(`${err}`))
      .finally(() => setLoading(false));
  };
  const ButtonInner = () => {
    
    if(isLoading){
      return <IonSpinner />
    }

    return (
      <>
        {defaultCard
          ? t("pay-with-card", { card: defaultCard.card?.last4 })
          : t("add-card")}
      </>
    );
  };

  return (
    <Button style={{ marginBottom: 10 }} onClick={handlePay}>
      <ButtonInner />
    </Button>
  );
};

export default PayWithCard;
