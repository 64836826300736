//React & Ionic
import { useEffect, useState } from "react";
import { IonContent, IonModal } from "@ionic/react";
//Helpers
import { api } from "../helper/api";
import { useTranslation } from "react-i18next";
//Components
import PageToolbar from "../components/PageToolbar";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { setUsageAccount } from "../redux/slices/userSlice";

const UsageAccountModal = ({isOpen, setOpen, className, props}) => {
    const {usageAccount} = useSelector(state => state.userSlice)
	const [businessAccounts, setBusinesAccounts] = useState([])
	const [accountTypes, setBusinesAccountType] = useState([{id: "personal", name: "personal"}])
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const getBusinessAccounts = async () => {
		const res = await api.getCompanyList()
		if(res?.status === 200 && res?.data?.success) {
			setBusinesAccounts(res?.data?.response)
		}
	}
	
	useEffect(() => {
		if(isOpen) getBusinessAccounts()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);
	
	useEffect(() => {
		if(businessAccounts.length){
			setBusinesAccountType([{id: "personal", name: "personal"}, ...businessAccounts])
		}
	}, [businessAccounts])

	const setCurrentUsageAccount = (acc) => {
		dispatch(setUsageAccount(acc))
	}
  return (
    <IonModal
			isOpen={isOpen}
			canDismiss={true}
			className={`ChangeLanguageModal modal-white-background${
				className ? " " + className : ""
			}`}
			onDidDismiss={() => setOpen(false)}
			{...props}
		>
			<PageToolbar
				title={t("usage-account")}
				leftButtonType="back"
				leftButtonOnClick={() => setOpen(false)}
				backgroundColor="#fff"
			/>
			<IonContent>
				<div className="RadiosWrapper">
					{accountTypes.map(account => {
						return (
							<label
								key={account.id}
								className="RadioRow ion-activatable"
								htmlFor={account.id}
								onClick={() => setCurrentUsageAccount(!account.company ? account.name : account?.company?.company_name)}
							>
								<div className="RadioRowLeft">
									<div className="RadioRowTitle capitalize">{!account.company ? account.name : account?.company?.company_name}</div>
								</div>
								<span className="RadioInput">
									<input type="radio" id={account.id} name="accountTypes[]" defaultChecked={!account.company ? usageAccount === account.id : usageAccount === account.company.id}/>
									<span/>
            					</span>
							</label>
						)
					})}
				</div>
			</IonContent>
		</IonModal>
  )
}

export default UsageAccountModal
