//Ionic & React
import { useEffect, useState } from "react"
import { IonModal, IonSelect, IonSelectOption, IonSpinner } from "@ionic/react"
//Components
import Text from "../Text"
import Icon from "../Icon"
import DTSelect from "../DTSelect"
import Button from "../Button"
//Helpers
import { api } from "../../helper/api"
import { Alert } from "../../helper/alert"
import { useTranslation } from "react-i18next"
//Redux
import { useDispatch, useSelector } from "react-redux"
import { setBuyTicketModal } from "../../redux/slices/modalSlice"

const BuyTicketModal = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [purchaseLoading, setPurchaseLoading] = useState(false)
    const [tickets, setTickets] = useState([])
    const [selectedTicketOption, setSelectedTicketOption] = useState({})
    const [selectedVehicle, setSelectedVehicle] = useState({})
    const [previewTicketModal, setPreviewTicketModal] = useState(false)
    const { buyTicketModal } = useSelector(state => state.modalSlice)
    const { currentStation } = useSelector((state) => state.propSlice);
    const { paymentMethods, vehicles } = useSelector((state) => state.userSlice);

    useEffect(() => {
        if(buyTicketModal.status){
            api.getTickets(currentStation.entry_id).then(res => {
                if(res.status === 200 && res.data.success){
                    setTickets(res.data.response)
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyTicketModal.status])
    const onPaymentSuccess = () => {
        setPreviewTicketModal(false)
        dispatch(setBuyTicketModal(false))
    }
    const confirmTicketPurchase = () => {
        setPurchaseLoading(true)
        api.buyTicket(selectedTicketOption.id, selectedVehicle.id, currentStation.entry_id).then(({data}) => {
            if (data.success) {
                onPaymentSuccess();
                Alert.success(t("payment_success"))
            }
          })
          .catch((err) => Alert.error(`${err}`))
          .finally(() => setPurchaseLoading(false))
    }
  return (
    <>
        <IonModal
        isOpen={buyTicketModal.status}
        onDidDismiss={() => dispatch(setBuyTicketModal(false))}
        breakpoints={[0, 0.9]}
        initialBreakpoint={0.9}
        className="BuyTicketModal"
        >
            <div className="content">
            <div className="header">
                <Text weight={600} size={24} color="var(--dt-primary)">
                {t("buy-ticket")}
                </Text>
                <Icon name={"close-modal"} onClick={() => dispatch(setBuyTicketModal(false))} />
            </div>
            <div className="main-content">
                <div className="icon-wrapper">
                    <Icon name="ticket" size={35}/>
                </div>
                <Text
                size={22}
                weight={400}
                dangerouslySetInnerHTML={{__html: t("create-ticket-page-text", {stationName: currentStation.NAME})}}
                />
                <DTSelect label={t("validity-period")} margin={"40px 0 0 0"}>
                    <IonSelect
                        id="vehicle_select"
                        onIonChange={(e) => {
                        e.preventDefault()
                        setSelectedTicketOption(e.detail.value)
                        }}
                        className={"capitalize"}
                        interfaceOptions={{
                        header: t("select-validity-period"),
                        cssClass: "VehicleSelectForTicket",
                        }}
                    >
                        {tickets?.map((t) => {
                        return (
                            <IonSelectOption
                            key={t.id}
                            value={t}
                            >
                            {t.name}
                            </IonSelectOption>
                        );
                        })}
                    </IonSelect>
                </DTSelect>
                <DTSelect label={t("select-vehicle")} margin={"30px 0 0 0"}>
                    <IonSelect
                        id="validity_select"
                        onIonChange={(e) => {
                        e.preventDefault()
                        setSelectedVehicle(e.detail.value)
                        }}
                        className={"capitalize"}
                        interfaceOptions={{
                        header: t("select-vehicle"),
                        cssClass: "VehicleSelectForTicket",
                        }}
                    >
                        {vehicles?.map((v) => {
                        return (
                            <IonSelectOption
                            key={v.id}
                            value={v}
                            >
                            {v?.plate?.toUpperCase()}
                            </IonSelectOption>
                        );
                        })}
                    </IonSelect>
                </DTSelect>
                <Button margin={"15px 0 0 0"} onClick={() => {
                    if(paymentMethods.length > 0){
                        setPreviewTicketModal(true)
                    }else{
                        Alert.error(t("add-payment-first"))
                    }
                    }}>
                    {t("create-my-ticket")}
                </Button>
            </div>
            </div>
        </IonModal>
        <IonModal
        isOpen={previewTicketModal}
        onDidDismiss={() => setPreviewTicketModal(false)}
        breakpoints={[0, 0.5]}
        initialBreakpoint={0.5}
        canDismiss={true}
        >
            <div className="p-4">
                <Text weight={600} size={24}>
                    {t("confirm-purchase")}
                </Text>
                <div className="mt-[25px] flex flex-row justify-between">
                    <Text weight={500} color={"var(--dt-pink)"}>
                        {t("validity-period")}
                    </Text>
                    <Text>
                        {selectedTicketOption.name}
                    </Text>
                </div>
                <div className="mt-[15px] flex flex-row justify-between">
                    <Text weight={500} transform={"capitalize"} color={"var(--dt-pink)"}>
                        {t("vehicle")}
                    </Text>
                    <Text>
                        {selectedVehicle?.plate?.toUpperCase()}
                    </Text>
                </div>
                <div className="mt-[15px] flex flex-row justify-between">
                    <Text weight={600} transform={"capitalize"}  color={"var(--dt-pink)"}>
                        {t("total-cost")}
                    </Text>
                    <Text>
                        {selectedTicketOption.price}<strong>sek</strong>
                    </Text>
                </div>
                <Button onClick={() => confirmTicketPurchase()} margin={"40px 0 0 0"}>
                    {purchaseLoading ? <IonSpinner /> : t("confirm")}
                </Button>
            </div>
        </IonModal>
    </>
  )
}

export default BuyTicketModal