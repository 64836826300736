//Ionic
import { IonModal, IonSelect, IonSelectOption } from "@ionic/react";
//Components
import DTSelect from "../../DTSelect";
import Button from "../../Button";
import Text from "../../Text";
import Icon from "../../Icon";
//Helpers
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { api } from "../../../helper/api";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/globalSlice";

const TicketEditVehicleModal = ({
  editModalOpen,
  setEditModalOpen,
  ticket,
  vehicles,
  setUpdatedVehicle
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const {plate: vehicle } = ticket
  const [selected, setSelected] = useState({})
  const apply = () => {
    dispatch(setLoading({status: true, mesaage: ""}))
    api.assignTicketToAnotherPlate(ticket.id, selected.id).then(res => {
      if(res.status === 200 && res.data.success === true) {
        setUpdatedVehicle(res.data.response.plate)
      }
    }).finally(() => {
      dispatch(setLoading({status: false, mesaage: ""}))
      setEditModalOpen(false)})
  }
  return (
    <IonModal
      isOpen={editModalOpen}
      breakpoints={[0, 0.4]}
      initialBreakpoint={0.4}
      onDidDismiss={() => setEditModalOpen(false)}
    >
      <div className="p-4">
        <div className="flex flex-row justify-between">
          <Text weight={600}>{t("select-vehicle")}</Text>
          <Icon name={"close-modal"} onClick={() => setEditModalOpen(false)} />
        </div>
        <DTSelect label={!selected.id ? vehicle.plate.toUpperCase() : ""} margin={"20px 0 0 0"}>
          <IonSelect
            id="vehicle_select"
            onIonChange={(e) => {
              e.preventDefault()
              setSelected(e.detail.value)
            }}
            className={"capitalize"}
            interfaceOptions={{
              header: t("select-vehicle"),
              cssClass: "VehicleSelectForTicket",
            }}
          >
            {vehicles?.map((v) => {
              return (
                <IonSelectOption
                  key={v.id}
                  value={v}
                  className="uppercase"
                >
                  {v.plate.toUpperCase()}
                </IonSelectOption>
              );
            })}
          </IonSelect>
        </DTSelect>
        <Button
        onClick={() => apply()}
        margin="20px 0 0 0"
        >
          Apply
        </Button>
      </div>
    </IonModal>
  );
};

export default TicketEditVehicleModal;
