import { useIonRouter } from "@ionic/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {invoiceFunctions} from "../../helper/invoiceFunctions";
import OffenseLabel from "./OffenseLabel";

const InvoiceDate = ({created_at, category_id, park_session, charging_transaction}) => {
  return (
    <div>
      <span className="InvoiceDay">
        {invoiceFunctions.formatDate(created_at, 'day')}
      </span>
      <span className="InvoiceMonthYear">
        {invoiceFunctions.formatDate(created_at, 'month-year')}
      </span>
      <span className="InvoiceTime">
        {invoiceFunctions.getTime({category_id, park_session, charging_transaction})}
      </span>
    </div>
  )
}

const InvoiceList = ({invoice}) => {
  const {t} = useTranslation();
  const router = useIonRouter()
  const isDigitalCodeUsed = invoice.park_session?.is_digital_code_active ?? invoice.charging_transaction?.is_digital_code_active
  const isOffense = invoice.park_session?.session_status_id === 2
  return (
    <div className="Invoice">
      <div className="flex flex-row w-full" onClick={() => router.push(`/invoices/detail/${invoice.id}`)}>
        <div className="InoviceDate">
          <InvoiceDate {...invoice} />
        </div>
        <div className="InvoiceContent">
          <div className="flex flex-row gap-2 items-center">
            <span className={`InvoiceType -${invoiceFunctions.getCategory(invoice.category_id)}`}>
              {t(invoiceFunctions.getCategory(invoice.category_id))}
            </span>
            {isOffense ? <OffenseLabel /> : null}
          </div>
          <span className="InvoiceTitle">{invoice.name}</span>
          {isDigitalCodeUsed === 0 || invoice.fees.payment_amount > 0 ? (
            <span className="text-primary">
              {invoice.fees.payment_amount} {invoice.currency.toUpperCase()} {" "}
              <span className={"font-light text-[12px]"}>
                ({t(invoiceFunctions.getPaymentStatus(invoice.status_id).title)})
              </span>
            </span>
          ) : <span className="text-white font-[500] bg-purple w-[fit-content] px-2 rounded">{t("digital-code")}</span>}
          <span className="InvoiceNo">{t("invoice-no")}: #{invoice.id}</span>
        </div>
      </div>
      <div className="InvoiceButtons">
        {/* {invoice.invoice_download && (
          <a href={invoice.invoice_download} target="_blank" rel="noreferrer">
          <div className="InvoiceDownload"/>
          </a>
        )} */}
        <div className="InvoiceStatus">
          <img src={invoiceFunctions.getPaymentStatus(invoice.status_id).icon} alt="" />
        </div>
      </div>
    </div>
  )
}

export default InvoiceList;
