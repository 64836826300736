// Ionic & React
import {IonContent, IonModal} from "@ionic/react"
import Icon from "../components/Icon"

// Components
import PageToolbar from "../components/PageToolbar"
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {setLanguage} from "../redux/slices/userSlice";

const ChangeLanguageModal = ({
  isChangeLanguageModalOpen,
  setChangeLanguageModalOpen,
  className,
  ...props
}) => {
  const { language: currentLanguage } = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const languages = [
    { id: "en", name: "English", icon: "uk-flag" },
    { id: "sv", name: "Svenska", icon: "se-flag" },
    //{ id: "de", name: "Deutsch", icon: "de-flag" },
  ];

  const setCurrentLanguage = (lang) => {
    i18next.changeLanguage(lang).then(() => dispatch(setLanguage(lang)));
  };

  return (
    <IonModal
      isOpen={isChangeLanguageModalOpen}
      canDismiss={true}
      className={`ChangeLanguageModal modal-white-background${
        className ? " " + className : ""
      }`}
      onDidDismiss={() => setChangeLanguageModalOpen(false)}
      {...props}
    >
      <PageToolbar
        title={t("change-language")}
        leftButtonType="back"
        onClick={() => setChangeLanguageModalOpen(false)}
        backgroundColor="#fff"
      />

      <IonContent>
        <div className="RadiosWrapper">
          {languages.map((language) => {
            return (
              <label
                key={language.id}
                className="RadioRow ion-activatable"
                htmlFor={language.id}
                onClick={() => setCurrentLanguage(language.id)}
              >
                <div className="RadioRowLeft">
                  <span className="RadioRowIcon">
                    <Icon name={language.icon} height={13} width={20} />
                  </span>
                  <div className="RadioRowTitle">{language.name}</div>
                </div>
                <span className="RadioInput">
                  <input
                    type="radio"
                    id={language.id}
                    name="languages[]"
                    defaultChecked={currentLanguage === language.id}
                  />
                  <span />
                </span>
              </label>
            );
          })}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ChangeLanguageModal
