import { t } from "i18next";
import SearchResultItem from "./SearchResultItem";
const SearchResultsList = ({
    searchText,
    itemOnclick,
    searchResults,
    listTitle,
    searchTitleStyle = false,
    className = false
}) => {


  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"))
    return (
      <span>
        {parts.map((part, idx) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span className="highlighted" key={idx}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    )
  }

    return (
      <div className="SearchResultsWrapper">
        <div className={`SearchResultsRow ${className}`}>
            <span className="SearchTitle" style={{searchTitleStyle}}>{t(listTitle)}</span>
            <div className="Results">
            {searchResults.map((item) => (
                <SearchResultItem 
                key={item.entry_id}
                title={searchText
                    ? getHighlightedText(item.NAME, searchText)
                    : item.NAME}
                onClick={() => {
                    itemOnclick(item)
                }}
                distance={item.DISTANCE}
                stationType={item.TYPE === 'station' ? "charging" : item.TYPE}
                zoneCode={item.ZONECODE}
                />
            ))}
            </div>
        </div>
      </div>
    )
}
export default SearchResultsList;