import {IonModal} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {ChargeSession, ParkChargeSession, ParkSession} from "./Partials";
import {setSessionModal} from "../../redux/slices/modalSlice";
import SessionModalsToolbar from "../SessionModalsToolbar";
import {useEffect, useState} from "react";
import {setLoading} from "../../redux/slices/globalSlice";
import {parkChargeFunctions} from "../../helper/parkChargeFunctions";
import {useTranslation} from "react-i18next";
import {Alert} from "../../helper/alert";

const ActiveSessions = ({setReceipt}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {sessionModal} = useSelector((state) => state.modalSlice)
  const {activeSession} = useSelector(state => state.userSlice)
  const [elapsedTime, setElapsedTime] = useState({park: null, charge: null})
  const {type, park_session, charge_transaction, previous_status} = activeSession;

  useEffect(() => {
    setElapsedTime({park: park_session?.session_duration ?? null, charge: charge_transaction?.session_duration ?? null})
  }, [park_session, charge_transaction])

  useEffect(() => {
    dispatch(setLoading({status: true, message: t("getting-session")}))

    parkChargeFunctions.getActiveSession()
        .finally(() => dispatch(setLoading({status: false, message: ""})))
        .catch(err => Alert.error(err))

    const timer = setInterval(() => {
      setElapsedTime(({park, charge}) => {

        if ((park !== null && park % 60 === 0) || (charge !== null && charge % 60 === 0)) {
          parkChargeFunctions.getActiveSession().catch(err => Alert.error(err))
        }

        return {park: park !== null ? park + 1 : null, charge: charge !== null ? charge + 1 : null}
      })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    if (previous_status && !type) {
      dispatch(setSessionModal(false))
      Alert.warning(t("your-session-is-over"))
    }
  }, [activeSession]);


  return (
    <IonModal
      isOpen={sessionModal.status}
      onWillDismiss={() => dispatch(setSessionModal(false))}
      canDismiss={true}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      className={'radiusModal'}
    >

      <SessionModalsToolbar dismissModal={() => dispatch(setSessionModal(false))}/>

      <div className="w-full flex justify-center translate-y-[-20px]">
          <span className="bg-purple w-[fit-content] text-white rounded px-1">
            {park_session?.park?.zone_code ?? ""}
          </span>
      </div>

      {type === "park" && <ParkSession setReceipt={setReceipt} elapsedTime={elapsedTime.park}/>}
      {type === "charge" && <ChargeSession setReceipt={setReceipt} elapsedTime={elapsedTime.charge}/>}
      {type === "park_charge" && <ParkChargeSession setReceipt={setReceipt} elapsedTime={elapsedTime}/>}

    </IonModal>
  );
}

export default ActiveSessions;
