//Ionic & React
import { useState } from "react";
import { useIonRouter } from "@ionic/react";
//Components
import Card from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import "../../../assets/theme/Payment.scss";
import validator from "validator";
import NFCModal from "../AddNewPaymentMethod/NFCModal";
//Helpers
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  validate,
} from "../../../helper/paymentFunctions";
import { api } from "../../../helper/api";
//Redux
import { useDispatch } from "react-redux";
import {clearLoader, setLoading} from "../../../redux/slices/globalSlice";
import { setPaymentMethods } from "../../../redux/slices/userSlice";
import { Alert } from "../../../helper/alert";
import AddCardForm from "./AddCardForm";

const PaymentForm = ({successCallback}) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [displayForm, setDisplayForm] = useState("activateClass");
  let error = {}

  const [isNFCModalOpen, setNFCModalOpen] = useState(false);

  const dispatch = useDispatch();
  const router = useIonRouter();

  const createCard = (card) => {
    dispatch(setLoading({status: true, message: "Creating..."}));

    api.createCard(card).then((response) => {
      if (response?.data?.success === true) {
        if (successCallback) {
            successCallback();
        } else {
          router.push("/payment-methods", "forward");
        }
      }
    }).then(() => getCards()).finally(() => {
      dispatch(clearLoader());
    });
  };

  const getCards = () => {
    api.getCards().then((response) => {
      if (response.data.success === true) {
        dispatch(setPaymentMethods(response.data.response));
      }
    }).finally(() => dispatch(setLoading({status: false, message: ""})));
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    let isValid;
    const formValues = [
      { type: "creditCard", value: number },
      { type: "name", value: name },
      { type: "expiry", value: expiry },
      { type: "cvc", value: cvc },
    ]
    error = validate(formValues);
    for(const err in error){
      let e = document.getElementsByClassName(err.toString())
      if(error[err] !== "success"){
        Alert.error(error[err])
        e[0].classList.add("error")
        isValid = false;
        break;
      }else{
        if(e[0].classList.contains("error")) e[0].classList.remove("error")
        isValid = true
        continue;
      }
    }
    if(isValid){
      if (validator.isCreditCard(number) && !!name && !!expiry && !!cvc) {
        createCard({
          options: {
            parameters: {
              name: name,
              email: "info@visiosoft.com.tr",
              card: {
                card_number: number,
                expire_month: expiry.split("/")[0],
                expire_year: expiry.split("/")[1],
                cvc: cvc,
              },
            },
          },
        });
      }
    }
  };

  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case "number":
        target.value = formatCreditCardNumber(target.value);
        setNumber(target.value);
        if (target.value.length > 15) {
          setDisplayForm("activateClass");
        } else {
          setDisplayForm("deactivateClass");
        }
        break;
      case "expiry":
        target.value = formatExpirationDate(target.value);
        setExpiry(target.value);
        break;
      case "cvc":
        target.value = formatCVC(target.value);
        setCvc(target.value);
        break;
      case "name":
        setName(target.value);
        break;
      default:
        break;
    }
  }

  return (
      <>
        <div key="Payment">
          <div className="App-payment">
            <Card number={number} name={name} expiry={expiry} cvc={cvc}/>
            <AddCardForm
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                number={number}
                name={name}
                cvc={cvc}
                expiry={expiry}
                displayForm={displayForm}
                error={error}
            />
          </div>
        </div>
        <NFCModal
            setNFCModalOpen={setNFCModalOpen}
            isNFCModalOpen={isNFCModalOpen}
        />
      </>
  );
};

export default PaymentForm;
