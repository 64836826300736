import { useTranslation } from "react-i18next";
import { invoiceFunctions } from "../../helper/invoiceFunctions";
import PaymentButtons from "./PaymentButtons";

const PayButton = ({invoice, setPaymentStart, onSuccess}) => {
    const {t} = useTranslation()
  if (
    invoice &&
    (invoice?.status_id === 1 || invoice?.status_id === 4)
  ) {
    return (
      <div className={"mt-5"}>
        <PaymentButtons
          setPaymentStart={setPaymentStart}
          invoice={invoice}
          onSuccess={onSuccess}
        />
      </div>
    );
  } else {
    return (
      <div
        className={
          "flex w-full justify-center bg-[#198754] text-white p-[10px] mt-[10px] rounded-[5px]"
        }
      >
        <img
          src={
            invoiceFunctions.getPaymentStatus(invoice?.status_id).icon
          }
          className={"pr-1"}
          alt={""}
        />
        {t(invoiceFunctions.getPaymentStatus(invoice?.status_id).title)}
      </div>
    );
  }
};

export default PayButton;
