import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import MyCarsSlideItemInner from "./MyCarsSlideItemInner";
import NoVehicles from "./NoVehicles";

import { useSelector } from "react-redux";

const MyCarsSlide = ({carsSliderRef, cars, removeCar}) => {
  const { loading } = useSelector((state) => state.globalSlice)
  const LoadingSkeleton = () => (
    <div className="CarsSliderSkeleton" style={{ marginBottom: "128px" }} />
  );
    return (
        <Swiper
        spaceBetween={20}
        centeredSlides={true}
        resistanceRatio={0.5}
        threshold={10}
        speed={200}
        ref={carsSliderRef}
        className="CarsSlider"
        modules={[Pagination]}
        pagination={{ type: "bullets" }}
      >

          {cars?.length > 0 ? (
            cars.map(car => (
              <SwiperSlide
                className="CarsSlide"
                key={car.id}
                style={{ marginBottom: "128px" }}
              >
                <MyCarsSlideItemInner car={car} removeCar={() => removeCar(car)} />
              </SwiperSlide>
            ))
          ) : loading.status === false && cars?.length === 0 ? (
            <NoVehicles />
          ) : loading.status === true ? (
            <LoadingSkeleton />
          ) : null}

      </Swiper>
    );
}

export default MyCarsSlide;
