// Ionic & React
import {memo, useState} from "react"
import {IonModal} from "@ionic/react"

// Components
import PageToolbar from "../components/PageToolbar"

import {useTranslation} from "react-i18next"
import {MyProfile} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { setMyProfileModal } from "../redux/slices/modalSlice";

const MyProfiles = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [breakpoint, setBreakPoint] = useState(0)
  const {myProfileModal} = useSelector((state) => state.modalSlice)
  return (
    <IonModal
    isOpen={myProfileModal.status}
    onDidDismiss={() => dispatch(setMyProfileModal(false))}
    breakpoints={[0, 0.9, 1]}
    initialBreakpoint={0.9}
    canDismiss={myProfileModal.canDismiss}
    className={` ${breakpoint !== 1 ? "radiusModal" : "fullscreen"} transition-all`}
    onIonBreakpointDidChange={(e) => setBreakPoint(e.detail.breakpoint)}
    onIonModalWillPresent={() => setBreakPoint(0.9)}
    >
      <PageToolbar
        title={t("my-profile")}
        leftButtonType="back"
        leftButtonOnClick={() => {
          dispatch(setMyProfileModal(false))
        }}
        backgroundColor="#fff"
        iosSafeArea={breakpoint === 1 ? true : false}
      />
      <MyProfile />
    </IonModal>
  )
}

export default memo(MyProfiles)