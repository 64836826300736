import React from 'react';
import { formatElapsedTime } from '../../../../helper/globalFunctions';
import { useTranslation } from 'react-i18next';
import { OffenseLabel } from '../../../Invoice';

const Cost = ({duration, amount, unitPrice, label, isOffense}) => {
    const {t} = useTranslation()
    return (
      <div className="ReceiptRows divider-bottom">
        <div className="ReceiptRow">
          <div>
            <span className="ReceiptRowTitle">
              {label}{" "}{isOffense ? <OffenseLabel /> : null}
            </span>
            <span className="ReceiptRowTime">
              {typeof duration === "string"
                ? duration
                : formatElapsedTime(duration)}
            </span>
          </div>
          <div>
            <span className="ReceiptRowPrice uppercase">
              <span className="normal-case text-[12px] text-[#898989]">
                {t("inc-vat") + " "}
              </span>
              {amount}
            </span>
            <span className="ReceiptRowPricing text-right">{unitPrice}</span>
          </div>
        </div>
      </div>
    );
}

export default Cost;
