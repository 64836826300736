import { IonContent, IonModal } from '@ionic/react';
import React from 'react';
import { api } from '../../helper/api';

const SiteCodeAgreement = ({isOpen, setOpen}) => {

    React.useEffect(() => {
        if(isOpen){
          getContent()
        }
      }, [isOpen])
      const getContent = () => {
        api.getPages("digital-parking-permit-agreement").then(({data}) => {
          if(data.success){
            document.getElementById("site-code-agreement").innerHTML = data.response.content;
          }
        })
      }

    return (
        <IonModal
        isOpen={isOpen}
        breakpoints={[0, 0.8, 1]}
        initialBreakpoint={0.8}
        canDismiss={true}
        cssClass="DTModal radiusModal"
        onDidDismiss={() => setOpen(false)}
        onIonBreakpointDidChange={e => {
            if(e.detail.breakpoint === 0){
            setOpen(false)
            }
        }}
        >
            <IonContent>
                <div className="Wrapper">
                    <div id="site-code-agreement" className='text-primary'></div>
                </div>
            </IonContent>
        </IonModal>
    );
}

export default SiteCodeAgreement;
