import { IonRippleEffect } from "@ionic/react";
import { useTranslation } from "react-i18next";
const UseButton = ({
    isInUse,
    setInUse,
}) => {
    const {t} = useTranslation()
    return (
        <button
        className={`UseButton ion-activatable
          ${isInUse ? "active" : ""}
          `}
        onClick={setInUse}
        >
                  {isInUse ? t("in-use") : t("make-primary")}
                  <IonRippleEffect />
                </button>
    )
}
export default UseButton;