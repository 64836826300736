import { useHistory } from "react-router";
import Icon from "../Icon";
import Text from "../Text";
import { formatDate } from "../../helper/globalFunctions";

const SiteCodeList = ({ siteCodes }) => {
  const history = useHistory();

  return siteCodes
    ? siteCodes.map((acc) => (
        <div
          className="Code"
          key={acc.assignment_id}
          onClick={() =>
            history.push(`/my-site-codes/${acc.assignment_id}`, {
              status: acc.agreement_status.toUpperCase()
            })
          }
        >
          <div className="flex flex-row items-left">
            <span className="text-gray mr-2">#{acc.assignment_id}</span>
            <div className="flex flex-col">
              <Text
                size={18}
                weight={600}
                color="#353355"
                transform="uppercase"
              >
                {acc.code?.code_name}
              </Text>
              <Text size={13} weight={600} color="var(--dt-pink)">
                {acc.agreement_status}{" "}
                {acc.terminated_at
                  ? `at ${formatDate(acc.terminated_at)}`
                  : null}
              </Text>
            </div>
          </div>
          <Icon name="arrow-right-basic" width={6} height={13} fill={"#fff"} />
        </div>
      ))
    : null;
};

export default SiteCodeList;
