import React, { useRef, useState } from 'react'

import LeftBottom from '../assets/images/vectors/left_bottom_build.svg'
import RightTop from '../assets/images/vectors/right_top_build.svg'
import LeftTop from '../assets/images/vectors/left_top_build.svg'
import onboarding_gradient from "../assets/images/onboarding-gradient.png";
import Car from '../assets/images/lastSlide_car.png'
import {useTranslation} from "react-i18next";


const OnboardingLastSlide = () => {
  const { t } = useTranslation();

  const [animEnd, setAnimEnd] = useState(true);

  const conditionalAnimationRef = useRef();

  if (conditionalAnimationRef.current) {
    conditionalAnimationRef.current.addEventListener("animationend", () => {
      setAnimEnd(false);
    });
  }

  return (
    <>
    <img
      className="onboardingGradient"
      src={onboarding_gradient}
      alt="onboardingGradient"
      draggable={false}
    />
      <div className="OnboardingSlideContent">
        <div className='OnboardingLastSlide'>
          <img src={LeftTop} alt="LeftTop" className={`OnboardingLastSlideBG_LeftTop ${animEnd && "animate"}`} />
          <img src={RightTop} alt="RightTop" className={`OnboardingLastSlideBG_RightTop ${animEnd && "animate"}`} />
          <img src={LeftBottom} alt="LeftBottom" className={`OnboardingLastSlideBG_LeftBottom ${animEnd && "animate"}`} />
          <div className="onboardingLastSlideCenter">
            <div className={`LastSlideCar ${animEnd && "animate"}`} ref={conditionalAnimationRef}>
              <img src={Car} alt="Car" className='LastSlideCarImg' />
            </div>
            <div className={`slideBorder ${animEnd && "animate"}`}></div>
          </div>
        </div>
      </div>
      <div className="OnboardingSlideBottom">
        <div className="OnboardingTitle">{t("onboarding-fourth-title")}</div>
        <div className="OnboardingDesc" dangerouslySetInnerHTML={{
          __html: t("onboarding-fourth-description")
        }}></div>
      </div>
    </>
  )
}
export default OnboardingLastSlide
