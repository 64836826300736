//Ionic & React
import { useEffect, useState } from "react";
import {IonSlide} from "@ionic/react";
//Components
import Icon from "../Icon";
//Redux
import { useSelector } from "react-redux";
import { t } from "i18next";

const BottomToolbarSlideItem = ({
  slideClass,
  isActive,
  slideOnclick,
  icon,
  text,
  textClass,
  activeTextClassName,
  filterName,
}) => {
  const {activeSession} = useSelector((state) => state.userSlice);
  const [slideClassState, setSlideClassState] = useState(slideClass);
  const [textClassState, setTextClassState] = useState(textClass);
  const [textState, setTextState] = useState(text);

  const handleActiveSessionAnimation = () => {
    setSlideClassState((prev) => {
      if (!String(prev).includes("activeSession"))
        return prev + " activeSession";
      else return prev;
    });
  }

  const editClassText = () => {
    if (activeSession.type) {
      switch (activeSession.type) {
        case "park":
          if (filterName === "MAP_PARKING") {
            setTextState(activeSession.park_session?.plate_number)
            setTextClassState(prev => {
              if(!String(prev).includes(String(activeTextClassName))) return prev + " " + activeTextClassName
              else return prev
            })
            handleActiveSessionAnimation()
          }
          break;
        case "charge":
          if (filterName === "MAP_CHARGING") {
            setTextState(activeSession.charge_transaction?.transaction_vehicle?.plate)
            setTextClassState(prev => {
              if(!String(prev).includes(String(activeTextClassName))) return prev + " " + activeTextClassName
              else return prev
            })
            handleActiveSessionAnimation()
          }
          break;
        case "park_charge":
          if (filterName === "MAP_ALL_IN_ONE") {
            setTextState(activeSession.park_session?.plate_number)
            setTextClassState(prev => {
              if(!String(prev).includes(String(activeTextClassName))) return prev + " " + activeTextClassName
              else return prev
            })
            handleActiveSessionAnimation()
          }
          break;
        default:
          break;
      }
    }
    else{
      setSlideClassState(slideClass)
      setTextState(text)
      setTextClassState(textClass)
    }
  }

  useEffect(() => {
    editClassText()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[activeSession])

  return (
    <IonSlide
      className={slideClassState}
      onClick={slideOnclick}
    >
      <div className="BottomIcon">
        <Icon name={icon} fill="#fff" />
      </div>
      <span
        className={`${isActive ? " active" : ""} ${textClassState}`}
      >
        {t(textState)}
      </span>
    </IonSlide>
  );
};
export default BottomToolbarSlideItem;
