import React from 'react'

const ElapsedTimer = ({seconds, classname}) => {
  const formatSeconds = seconds => {
    const pad = n => n.toString().replace(/\b(\d)\b/, '0$1');

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds / 60) - (h * 60));
    const s = Math.floor(seconds - h * 3600 - m * 60);

    return `${pad(h)}:${pad(m)}:${pad(s)}`;
  }

  return (
    <p className={classname}>{formatSeconds(seconds)}</p>
  )
}

export default ElapsedTimer;
