import {useTranslation} from 'react-i18next';
import Text from '../../Text';
import ConfirmIcon from "../../../assets/images/vectors/confirm-icon.svg";
import {useDispatch, useSelector} from 'react-redux';
import {setMyProfileModal} from '../../../redux/slices/modalSlice';
import {useHistory} from 'react-router';
import {useEffect, useRef, useState} from "react";
import {api} from "../../../helper/api";
import {setAlert} from "../../../redux/slices/globalSlice";
import {getPerson} from "../../../helper/globalFunctions";

const Ending = ({setIsStart}) => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const history = useHistory()
    const {user} = useSelector((state) => state.userSlice);
    const [error, setError] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            checkSign(user.ssn)
        }, 3000);

        const timeoutID = setTimeout(() => {
            redirectToBankID()
        }, 1000)
        return () => {
            clearInterval(interval);
            clearTimeout(timeoutID)
        }
    }, []);

    useEffect(() => {
        if (error) {
            dispatch(setAlert({status: true, message: t(error), type: "error"}))
        }
    }, [error])

    const redirectToBankID = async () => {
        window.location.href = process.env.REACT_APP_BANKID_URL
    }
    const checkSign = (ssn) => {
        api.checkDisabledCardSign(ssn).then(res => {
            if (res.status === 200 && res.data.success === true) {
                if (res.data.response === "complete") {
                    getPerson().catch(() => setError("sign-success-but-person-failed"))
                    goMyProfile()
                }

                if (res.data.response === "failed") {
                    setError("signing-request-failed-please-try-again");
                    goMyProfile()
                }

            } else {
                setError("error-message");
                goMyProfile()
            }
        }).catch(() => {
            setError("error-message");
            goMyProfile()
        })
    }

    const goMyProfile = () => {
        setIsStart(false)
        dispatch(setMyProfileModal(true))
        history.push('/map', {
            menuModal: true,
            myProfileModal: true
        })
    }
    return (
        <div className="EndReportSession">
            <img src={ConfirmIcon} alt="ConfirmIcon"/>
            <Text margin={"0 0 11px"} color={"#FFF"} size={22} weight={500}>
                {t("got-your-disable-permit")}
            </Text>
            <Text
                margin={"0 0 37px"}
                color={"rgba(255,255,255,0.65)"}
                size={14}
                weight={400}
                dangerouslySetInnerHTML={{__html: t("got-your-report-desc")}}
            ></Text>
            <button
                className="EndReportSessionButton"
                onClick={() => goMyProfile()}
            >
                {t("cancel")}
            </button>
        </div>
    );
}

export default Ending;
