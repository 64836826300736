
import Icon from "../Icon";

const SelectTicketModalItem = ({ticket}) => {
  return (
    <label
      className="Ticket RadioRow ion-activatable"
      htmlFor={ticket.id}
    >
      <div className="RadioRowLeft">
        <span className="RadioRowIcon">
        <Icon name="ticket" size={35} />
        </span>
        <div className="RadioRowTitle">#{ticket.id}</div>
      </div>
      <span className="RadioInput">
        <input
          type="radio"
          id={ticket.id}
          name="tickets[]"
          defaultChecked={true}
        />
        <span />
      </span>
    </label>
  );
};

export default SelectTicketModalItem;
