import ProfileHeader from "./ProfileHeader";

import {Actions, Address, Disabled} from "./Detail";

import { useSelector } from "react-redux";
import DTScrollable from "../DTScrollable";

const MyProfile = () => {

  const {user} = useSelector((state) => state.userSlice);
  const {is_disabled} = user;
  return (
    <DTScrollable extraPadding={false} cssClass="no-padding" scrollY={true}>
      <ProfileHeader/>
      <div className="Wrapper">
        <Address/>
        {is_disabled ? <Disabled /> : null}
        {/* 
          TODO: Temporarily removed.
          <InviteCard/> 
        */}
        {/*
        Temporarily removed. if this is added again. You must be import connect component
        <Connects/>
         */}
        <Actions isDisabled={is_disabled}/>
      </div>
    </DTScrollable>
  )
}

export default MyProfile;
