//Components
import SiteCodeList from './SiteCodeList';
import AddSiteCodeButton from './AddSiteCodeButton';

const SiteCodes = ({siteCodes}) => {
    return (
        <div className="SiteCodes">
            <div className='Codes'>
                <SiteCodeList siteCodes={siteCodes}/>
            </div>
            <AddSiteCodeButton />
        </div>
    );
}

export default SiteCodes;
