import React from 'react'
import {useTranslation} from 'react-i18next';

const AdministrationFee = ({administrationFee, vat}) => {
    const {t} = useTranslation();

    return (
        <>
            <div className="ReceiptRows divider-bottom">
                <div className="ReceiptRow">
                    <div>
                        <span className="ReceiptRowTitle">{t("administration-fee")}</span>
                    </div>
                    <div>
                    <span className="ReceiptRowPrice uppercase">
                    {administrationFee}{" SEK"}
                    </span>
                    </div>
                </div>
            </div>
            <div className="ReceiptRows divider-bottom">
                <div className="ReceiptRow">
                    <div>
                        <span className="ReceiptRowTitle">{t("total-vat-amount")}</span>
                    </div>
                    <div>
                    <span className="ReceiptRowPrice uppercase">
                    {vat}{" SEK"}
                    </span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdministrationFee
