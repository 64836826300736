import { useTranslation } from 'react-i18next';
import Text from './Text';
import { clearModals } from '../redux/slices/modalSlice';
import { useDispatch } from 'react-redux';

const MenuSetUpProfileButton = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()

  return (
      <div className="MenuNotUser">
            <Text 
            size={26}
            weight={600}
            color="#fff"
            >
              {t("menu")}
            </Text>
            <button
            className="SetupProfileMenuButton"
            onClick={() => {
              dispatch(clearModals())
          }}
            >
              {t("set-up-your-profile")}
            </button>
      </div>
  );
}

export default MenuSetUpProfileButton;
