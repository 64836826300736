import { t } from "i18next";
import Text from "../Text";
import NoVehicle from "../../assets/images/vectors/no-vehicle.svg";
const NoVehicles = () => {
    return (
        <div className="NoVehicles">
            <div className="NoVehiclesImage">
                <img src={NoVehicle} alt="No Vehicle" />
            </div>
            <div className="NoVehicleText">
                <div className="NoVehicleTextTitle">
                <Text size={16} weight={500} color={"#333333"}>
                    {t("add-vehicle")}
                </Text>
                </div>
                <div className="NoVehicleTextDesc">
                <Text
                    dangerouslySetInnerHTML={{
                    __html: t("add-vehicle-desc"),
                    }}
                    size={14}
                    color={"#333333"}
                    weight={400}
                ></Text>
                </div>
            </div>
        </div>
    );
}

export default NoVehicles;
