import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import DTSelect from '../DTSelect';

const InvoiceTypeFilter = ({
    filters,
    setFilters,
    invoiceFilters,
    clearFilter,
    setCountOfFilters
}) => {
    const { t } = useTranslation()
    return (
        <DTSelect label={t("invoice-type")} margin="40px 0 0" clearable={filters.type_id !== "all"} clearOnclick={() => clearFilter('account')}>
          <IonSelect
            id="filter-invoice_account"
            className={'capitalize'}
            onIonChange={(e) => {
              e.preventDefault()
              setFilters( prevState => ({
              ...prevState,
              "type_id": e.detail.value
            }))
            if(e.detail.value !== 'all'){
              setCountOfFilters(prevState => prevState + 1)
            }else{
              setCountOfFilters(prevState => prevState - 1)
            }
          }}
            value={filters.type_id}
          >
            <IonSelectOption value="all" className={'capitalize'}>{t("all")}</IonSelectOption>
            {
              invoiceFilters.account_types?.map((accountFilter, idx) => {
                return (
                  <IonSelectOption className={'capitalize'} key={idx} value={accountFilter?.id}>{accountFilter?.name}</IonSelectOption>
                )
              })
            }
          </IonSelect>
        </DTSelect>
    );
}

export default InvoiceTypeFilter;
