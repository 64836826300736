import Text from "../../Text";

const TicketStation = ({ station }) => {
  return (
    <div className="InfoArea mt-[19px]">
      <div className="CarInfos">
        <img src={station.operator_logo} alt="" className="CarBrandPosition" />
        <div className="CarInfoText text-left">
          <Text size={20} weight={700} color="#353355">
            {station?.name}
          </Text>
          <Text size={16} weight={400} color="#353355">
            {station?.address}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default TicketStation;
