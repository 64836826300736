//React & Ionic
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {IonContent, useIonRouter} from "@ionic/react";
import { App as CapacitorApp } from '@capacitor/app';
import {Capacitor} from "@capacitor/core";
//Components
import PageToolbar from "../components/PageToolbar";
import PayButton from "../components/Invoice/PayButton";
import ReceiptFields from "../components/ReceiptComponents/ReceiptFields";
//Helpers
import {useTranslation} from "react-i18next";
import {api} from "../helper/api";
import {Alert} from "../helper/alert";
//Redux
import {useDispatch} from "react-redux";
import {clearLoader, setLoading} from "../redux/slices/globalSlice";
import ReceiptDetail from "../components/Invoice/ReceiptDetail";

const InvoiceDetail = () => {
  const {invoiceId} = useParams()
  const router = useIonRouter()
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [invoice, setInvoice] = useState();
  const [paymentStart,setPaymentStart] = useState(false);

  const getPayment = () => {
    dispatch(setLoading({status: true, message: ""}))

    api.getPayment(invoiceId).then(({data}) => {
      if (data.success) {
        setInvoice(data.response)
      } else {
        Alert.error(data.message)
      }
    }).finally(() => {
      dispatch(clearLoader())
    })
  }

  const onSuccess = () => {
    if (invoice.status_id !== 2) {
      setTimeout(() => getPayment(), 5000)
    }
  }

  useEffect(() => {
    getPayment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const discount= {
    isDiscount: false,
    discountDetail: {
      id: 456789,
      amount: 30,
    },
  };

  if (Capacitor.isNativePlatform()){
    CapacitorApp.addListener('backButton', (canGoBack) => {
          if(canGoBack && paymentStart)
          {
            api.cancelPayment(paymentStart).finally(setPaymentStart(false))
          }
    });
  }

  return (
    <>
      <PageToolbar
        title={t("invoice-detail")}
        leftButtonType="back"
        onClick={() => router.push("/invoices", "back")}
      />

      <IonContent className={"invoice-detail"}>
        {invoice && (
          <>
            <ReceiptDetail invoice={invoice}/>
            <ReceiptFields from="invoice" receipt={invoice} discount={discount} chargeLevel={0}/>
            {((invoice.digital_code && invoice.type === "park") || !invoice.total_amount) ? null : <PayButton invoice={invoice} setPaymentStart={setPaymentStart} onSuccess={onSuccess} />}
          </>
        )}
      </IonContent>
    </>
  )
  
}

export default InvoiceDetail;
