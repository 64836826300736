import { useRef, useState } from "react";

import onboarding_thirdSlide_discountIcon from "../assets/images/vectors/onboarding_thirdSlide_discountIcon.svg";
import onboarding_thirdSlide_drifterCard from "../assets/images/vectors/onboarding_thirdSlide_drifterCard.svg";
import onboarding_gradient from "../assets/images/onboarding-gradient.png";
import {useTranslation} from "react-i18next";

const OnboardingThirdSlide = () => {
  const {t} = useTranslation();

  const onboardingDiscountRef = useRef();
  const [slideUp, setSlideUp] = useState(true);
  
  if(onboardingDiscountRef.current){
    onboardingDiscountRef.current.addEventListener("animationend", () => {
      setSlideUp(false);
    });
  }

  return (
    <>
       <img
       className="onboardingGradient"
      src={onboarding_gradient}
      alt="onboardingGradient"
      draggable={false}
    />
    <div className="OnboardingSlideContent">
        <div className={`OnboardingDiscount ${slideUp && "slideUp"}`}>
          <img
            src={onboarding_thirdSlide_discountIcon}
            alt="onboarding_thirdSlide_discountIcon"
            className="onboarding_thirdSlide_discountIcon2"
          />
          <img
            src={onboarding_thirdSlide_drifterCard}
            alt="onboarding_thirdSlide_drifterCard"
            className="onboarding_thirdSlide_drifterCard"
            ref={onboardingDiscountRef}
          />
          <div className="onboarding_thirdSlide_discountIcon1_container">
            <img
              src={onboarding_thirdSlide_discountIcon}
              alt="onboarding_thirdSlide_discountIcon"
              className="onboarding_thirdSlide_discountIcon1"
            />
          </div>
          <img
            src={onboarding_thirdSlide_discountIcon}
            alt="onboarding_thirdSlide_discountIcon"
            className="onboarding_thirdSlide_discountIcon3"
          />
        </div>
      </div>
      <div className="OnboardingSlideBottom">
        <div className="OnboardingTitle">{t("onboarding-third-title")}</div>
        <div className="OnboardingDesc" dangerouslySetInnerHTML={{
          __html: t("onboarding-third-description")
        }}></div>
      </div>
    </>
  )
}

export default OnboardingThirdSlide
