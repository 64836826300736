import * as axios from "axios";
import QueryString from "qs";
import {store} from "../redux/store";
import {storeReset} from "../redux/slices/userSlice";
import {Alert} from "./alert";

export default class Api {

    constructor() {
        this.client = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }

    // errorHandler will compose a handleGlobally function
    errorHandler = (error) => {
        const {status, message} = error;
        this.handleErrorStatus(status)
        this.handleErrorMessage(message)
    };

    // handling error messages
    handleErrorMessage = (message) => {
        if (message) {
            Alert.error(message.slice(0, 1).toUpperCase() + message.slice(1) + "!")
        }
    }

    // handling error status
    handleErrorStatus = (status) => {
        switch (status) {
            case 400:
            case 401:
            case "Network Error":
                store.dispatch(storeReset());
                window.location = "/register-setup";
                console.log("Please login to access this resource");
                break;
            // case 404:
            //   window.location = "/notfound";
            //   console.log("The requested resource does not exist or has been deleted");
            //   break;
            default:
                break;
        }
    }

    init = () => {
        const {isDev} = store.getState().globalSlice
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "locale": store.getState().userSlice.language,
            "Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        this.client = axios.create({
            baseURL: !isDev ? this.api_url : process.env.REACT_APP_TEST_API_URL,
            timeout: 31000,
            headers: headers,
        });
        this.client.interceptors.request.use(
            async (config) => {
                const _token = store.getState().userSlice.token;
                if (_token) {
                    config.headers.Authorization = `Bearer ${_token}`;
                } else if (
                    window.location.pathname !== "/" &&
                    window.location.pathname !== "/register-setup" &&
                    window.location.pathname !== "/enter-phone-number" &&
                    window.location.pathname !== "/enter-sms-code"
                ) {
                    window.location = "/register-setup";
                }
                return config;
            },
            (error) => {
                this.errorHandler(error);
                return Promise.reject(error);
            }
        );
        return this.client;
    };

    makeCall = (type, path, data) => {
        let callType;
        if (type === "get") {
            callType = this.init().get(path, data)
        } else if (type === "put") {
            callType = this.init().put(path, data)
        } else {
            callType = this.init().post(path, data)
        }
        return callType;
    }

    createCall = async (type, path, data = {}, handleError = true) => {
        let res = this.makeCall(type, path, data).then((res) => {

            if (path === "activate-phone") {
                return res;
            } else {
                if (res.data.success) {
                    if (res.data.success === true) {
                        return res;
                    } else {
                        handleError && this.errorHandler(false)
                        return {data: {success: false}}
                    }
                } else {
                    handleError && this.errorHandler(res.data)
                    return res;
                }
            }
        }).catch((err) => handleError && this.errorHandler(err.toJSON()));
        return res;
    }

    getConfig = () => {
        return this.createCall("post", "config");
    }

    registerPhone = (data) => {
        return this.createCall("post", "register-phone", data)
    };

    activatePhone = (data) => {
        return this.createCall("post", "activate-phone", data)
    };

    addVehicle = (data) => {
        return this.createCall("post", "function/vehicle/vehicle/add", data)
    };

    addMultipleVehicle = (data) => {
        return this.createCall("post", "function/vehicle/vehicle/add-multiple-plate", data)
    };

    updateVehicle = (data) => {
        return this.createCall("post", "function/vehicle/vehicle/editByApi", data)
    };

    editVehicle = (data) => {
        return this.createCall("post", "function/vehicle/vehicle/edit", data)
    };


    userEdit = (data) => {
        return this.createCall("post", "function/users/users/edit", {options: {parameters: data}})
    }

    /**
     * @deprecated
     * use userEdit function
     */
    editPerson = (data) => {
        return this.createCall("post", "function/users/users/edit", data)
    };

    editPP = (path) => {
        let data = new FormData();
        data.append("options[parameters][profile_photo]", path[0]);

        return this.createCall("post", "function/users/users/update-profile-photo", data)
    };

    startParking = (data) => {
        return this.createCall("post", "function/parking/parking_session/start", data)
    };

    getActiveParkingSession = () => {
        return this.createCall("post", "function/parking/parking_session/get_user_active_session")
    };

    stopParking = (data) => {
        // return this.createCall("post", "function/parking/parking_session/end", data)
        return this.createCall("post", "function/parking/parking_session/endV2", data)
    };

    pay = (data) => {
        return this.createCall("post", "function/payment/payment/createPayment", data)
    };

    paymentIntent = (data) => {
        return this.createCall("post", "function/payment/payment/createPaymentIntent", data)
    };

    confirmPayment = (data) => {
        return this.createCall("post", "function/payment/payment/confirmPayment", data)
    };

    /**
     *
     * @param id {number} the id of the invoice you want pay with default card
     * @returns {object}
     */

    payWithDefaultCard = (id) => {
        return this.createCall("post", "function/payment/payment/pay", {options: {parameters: {payment_id: id}}})
    }

    /**
     * @param id {number} the id of the invoice you want to receive
     * @returns {object}
     */
    getPayment = (id) => {
        return this.createCall("post", "function/payment/payment/getPaymentV2", {options: {parameters: {payment_id: id}}});
    }

    createCard = (data) => {
        return this.createCall("post", "function/payment/payment/createCard", data)
    };

    getPerson = () => {
        return this.createCall("post", "function/users/users/show")
    };

    getUserInformationFromGovernment = (data) => {
        const params = QueryString.stringify({options: {parameters: data}});
        return this.createCall("post", "function/users/users/showPersonsApi?" + params + "", {}, false)
    };

    /**
     * @deprecated
     * use getUserInformationFromGovernment function
     * @param data
     * @returns {Promise<*>}
     */
    getPersons = (data) => {
        return this.createCall("post", "function/users/users/showPersonsApi?" + QueryString.stringify(data) + "", {}, false)
    };

    resendActivation = () => {
        return this.createCall("post", "function/users/users/resend-activation-mail")
    };

    getCards = () => {
        return this.createCall("post", "function/payment/payment/cards")
    };

    sendLocation = (data) => {
        return this.createCall("post", "function/gps_tracing/tracing/add", {
            options: {parameters: {points: data}},
            limit: 10
        });
    };

    checkBankIdSignStatus = (data) => {
        return this.createCall("post", "function/parking/code_user/check_bankid_sign_status", {options: {parameters: data}})
    }

    removeCodeWithBankId = (data) => {
        return this.createCall("post", "function/parking/code_user/remove_code_with_bankid", {options: {parameters: data}})
    }
    signCodeWithBankId = (data) => {
        return this.createCall("post", "function/parking/code_user/sign_code_with_bankid", {options: {parameters: data}})
    }
    createSiteCode = (data) => {
        return this.createCall("post", "function/parking/code_user/assigning", {options: {parameters: {site_code: data}}});
    }

    getSiteCodes = () => {
        return this.createCall("get", "v2/permit-code/list");
    };

    removeSiteCode = (id) => {
        return this.createCall("put", "v2/permit-code/terminate", {"assignment_id": id});
    };

    getSiteCodeDetail = (site_code, companyCode = null) => {
        return this.createCall("get", "v2/permit-code/details?" + QueryString.stringify({
            "code_string": site_code,
            "company_string": companyCode
        }));
    };

    getSiteCodeAssignmentDetail = (id) => {
        return this.createCall("get", "v2/permit-code/assignment?" + QueryString.stringify({"assignment_id": id}));
    };

    assignCode = (siteCode, companyCode) => {
        return this.createCall("post", "v2/permit-code/assign", {
            "code_string": siteCode,
            "company_string": companyCode
        });
    }

    /**
     *
     * @returns {object}
     */
    getDefaultCard = () => {
        return this.createCall("get", "function/payment/payment/getDefaultCard")
    }


    getParkingStationDetail = (data) => {
        return this.createCall("get", "function/parking/park/detail?" + QueryString.stringify(data) + "")
    };

    getParkPoints = (data) => {
        return this.createCall("get", "function/parking/park/list?" + QueryString.stringify(data));
    };

    getDefaultVehicle = () => {
        return this.createCall("get", "function/vehicle/vehicle/getDefaultVehicle");
    };

    setDefaultCar = (data) => {
        return this.createCall("post", "function/vehicle/vehicle/setDefaultVehicle", data);
    };

    getVehicles = () => {
        return this.createCall("get", "function/vehicle/vehicle/list");
    };

    getVehiclesByApi = () => {
        return this.createCall("post", "function/vehicle/vehicle/showByApi");
    };

    findVehicleByPlate = (data) => {
        return this.createCall("post", "function/vehicle/vehicle/findVehicleByPlateForApi?", data)
    };

    getVehicleTypes = (data) => {
        return this.createCall("get", "function/vehicle/vehicle-type/list", data);
    };

    getVehicleBrands = () => {
        return this.createCall("get", "function/vehicle/brands/list");
    };

    getVehicleModels = (data) => {
        return this.createCall("post", "function/vehicle/model/list?", data);
    };

    removeVehicle = (data) => {
        return this.createCall("post", "function/vehicle/vehicle/remove", data);
    };

    getFuelTypes = (data) => {
        return this.createCall("get", "function/vehicle/fuel-type/list", data);
    };

    getInvoiceFilters = () => {
        return this.createCall("get", "function/payment/payment/references");
    };

    /**
     *
     * @param category_id {string | "all", number}
     * @param status_id {string | "all", number}
     * @param type_id {string | "all", number}
     * @returns {object}
     */
    getInvoices = (category_id, status_id, type_id) => {
        return this.createCall("get", "function/payment/payment/list?" + QueryString.stringify({
            "options": {
                "parameters": {
                    "category_id": category_id,
                    "status_id": status_id,
                    "account_type_id": type_id
                }
            }
        }));
    };

    /**
     *
     * @param id the id of the invoice you want payment started
     * @returns {*}
     */
    startPayment = (id) => {
        return this.createCall("post", "function/payment/payment/startPayment", {options: {parameters: {payment_id: id}}})
    }

    /**
     *
     * @param id the id of the invoice you want payment canceled
     * @returns {*}
     */
    cancelPayment = (id) => {
        return this.createCall("post", "function/payment/payment/cancelPayment", {options: {parameters: {payment_id: id}}})
    }

    deleteAccount = () => {
        return this.createCall("post", "function/users/users/deleteAccount");
    };

    updateProfilePhoto = (files) => {
        return new Promise((resolve, reject) => {
            api
                .editPP(files)
                .then(() => {
                    let img_elem = document.getElementById("profile_photo");

                    // FileReader support
                    if (FileReader && files && files.length) {
                        let fr = new FileReader();
                        fr.onload = function () {
                            img_elem.src = fr.result;
                        };
                        fr.readAsDataURL(files[0]);
                    }

                    img_elem.src = files;
                    img_elem.style = "display:block";
                })
                .then(() => resolve(true))
                .catch((error) => reject(error));
        });
    };

    getVehicleByPlate = (plate) => {
        return new Promise((resolve, reject) => {
            api
                .findVehicleByPlate({options: {parameters: {plate: plate}}})
                .then(({data, status}) => {
                    if (status === 200) {
                        const rawVehicle = data.response[0];

                        let vehicle = {
                            connector_type: "NA",
                            battery_capacity: "NA",
                            charge_time: "NA",
                            owner: "NA",
                        };
                        vehicle.info = rawVehicle.basic.data;
                        vehicle.technical = rawVehicle.technical.data;
                        vehicle.status = rawVehicle.status;
                        vehicle.inspection = rawVehicle.inspection;
                        vehicle.eco = rawVehicle.eco;
                        if (rawVehicle.additionalElectricData) {
                            let additionalElectricData =
                                rawVehicle.additionalElectricData.additionalElectricData.data;

                            vehicle.connector_type =
                                additionalElectricData.charging.obc.charge_plug;
                            vehicle.charge_values =
                                additionalElectricData.charging.obc.charge_standard_table;
                            vehicle.charge_max = vehicle.charge_values[0].charge_time;
                            vehicle.charge_min =
                                vehicle.charge_values[vehicle.charge_values - 1].charge_time;
                            vehicle.battery_capacity =
                                additionalElectricData.battery.capacity_useable;
                            vehicle.chargeTime =
                                vehicle.charge_max +
                                " min AC - " +
                                vehicle.charge_min +
                                " min DC";
                        }

                        if (rawVehicle.owner) {
                            vehicle.owner_info = rawVehicle.owner.data;

                            if (vehicle.owner_info.type === "person") {
                                vehicle.owner =
                                    vehicle.owner_info.attributes?.name.first_name +
                                    " " +
                                    vehicle.owner_info.attributes?.name.last_name;
                            } else {
                                vehicle.owner = vehicle.owner_info.attributes?.legal_name;
                            }
                        }
                        resolve(vehicle);
                    } else if (data.error_code === 400) {
                        reject(`${plate.toUpperCase()} is already registered to Drifter!`);
                    }
                })
                .catch((error) => reject(error));
        });
    };

    getFilteredParkingStations = (data) => {
        return this.createCall("get", "function/parking/park/list?" + QueryString.stringify(data) + "");
    };
    markMessagesAsRead = () => {
        return this.createCall("get", "function/inbox/message/mark-as-read");
    };

    getInbox = () => {
        return this.createCall("get", "function/inbox/message/list");
    };

    deleteMessage = (data) => {
        return this.createCall("post", "function/inbox/message/delete-message", data);
    };

    postReportImage = (data) => {
        return this.createCall("post", "function/parking/occupancy_report/submit_report", data)
    }

    addParkingAvailabilityReminder = data => {
        return this.createCall("post", "function/parking/availability_reminder/add", data)
    }

    removeCreditCard = (data) => {
        return this.createCall("post", "function/payment/payment/removeCard", data);
    };

    setDefaultCard = (data) => {
        return this.createCall("post", "function/payment/payment/setDefaultCard", data);
    };

    getComponyByCode = code => {
        return this.createCall("post", "function/company/employee/getCompanyByCode",
            {
                "options": {
                    "parameters": {
                        "company_code": code
                    }
                }
            }
        )
    }

    assignCompany = code => {
        return this.createCall("post", "function/company/employee/assignBusinessAccount",
            {
                "options": {
                    "parameters": {
                        "company_code": code
                    }
                }
            }
        )
    }

    setCompanyCard = (company_id, payment_id) => {
        return this.createCall("post", "function/company/employee/SetPaymentMethod",
            {
                "options": {
                    "parameters": {
                        "company_id": company_id,
                        "payment_method_id": payment_id
                    }
                }
            }
        )
    }

    setCompanyVehicle = (company_id, vehicle_id) => {
        return this.createCall("post", "function/company/employee/SetVehicle",
            {
                "options": {
                    "parameters": {
                        "company_id": company_id,
                        "vehicle_id": vehicle_id
                    }
                }
            }
        )
    }

    getBusinessDetail = (company_id) => {
        return this.createCall("post", "function/company/employee/getCompany",
            {
                "options": {
                    "parameters": {
                        "company_id": company_id
                    }
                }
            }
        )
    }
    removeAssignedCompany = (company_id) => {
        return this.createCall("post", "function/company/employee/removeAssignedCompany",
            {
                "options": {
                    "parameters": {
                        "company_id": company_id
                    }
                }
            }
        )

    }

    getCompanyList = () => {
        return this.createCall("post", "function/company/employee/list")
    }

    getPages = data => {
        return this.createCall("post", `pages/${data}`);
    }

    getFaq = data => {
        return this.createCall("get", `get-faq`);
    }

    checkParkVIPCode = (code, park_id) => {
        return this.createCall("post", "function/parking/parking_session/vipCodeValidation", {
            "options": {
                "parameters": {
                    "vip_code": code,
                    "park_id": park_id
                }
            }
        })
    }
    getDrifterCardBalance = () => {
        return this.createCall("post", 'function/balances/balances/my-balance', {
            "options": {
                "parameters": {
                    "user_id": store.getState().userSlice.user.id
                }
            }
        });
    }

    addDrifterCardBalance = (price, user_id, name, balance_note) => {
        return this.createCall("post", "function/balances/balances/add", {
            "options": {
                "parameters": {
                    "price": price,
                    "user_id": user_id,
                    "name": name ?? "",
                    "balance_note": balance_note ?? ""
                }
            }
        })
    }

    getParkChargeOperators = () => {
        return this.createCall("post", "function/operator/operator/list");
    }

    uploadDisabledCard = (data) => {
        return this.createCall("post", "function/users/users/updateDisabledCardPhoto", data)
    }

    getTickets = (park_id) => {
        return this.createCall("post", "function/parking/ticket/list", {
            "options": {
                "parameters": {
                    "park_id": park_id,
                }
            }
        })
    }
    getUserTickets = (park_id, plate_id) => {
        return this.createCall("post", "function/parking/ticket_user/list?" + QueryString.stringify({
            "options": {
                "park_id": park_id,
                "plate_id": plate_id
            }
        }))
    }
    getActiveLongTermTickets = (park_id, plate_id) => {
        return this.createCall("post", "function/parking/ticket_user/active-ticket", {
            "options": {
                "parameters": {
                    "park_id": park_id,
                    "plate_id": plate_id
                }
            }
        }, false)
    }
    buyTicket = (ticket_id, plate_id, park_id) => {
        return this.createCall("post", "function/payment/payment/buy-ticket", {
            "options": {
                "parameters": {
                    "park_id": park_id,
                    "plate_id": plate_id,
                    "ticket_id": ticket_id,
                }
            }
        })

    }
    assignTicketToAnotherPlate = (ticket_id, plate_id) => {
        return this.createCall("post", "function/parking/ticket_user/assign-to-plate", {
            "options": {
                "parameters": {
                    "ticket_id": ticket_id,
                    "plate_id": plate_id,
                }
            }
        })
    }
    getDigitalCodeDetail = (id) => {
        return this.createCall("post", "function/parking/code_user/details", {
            "options": {
                "parameters": {
                    "id": id
                }
            }
        })
    }
    checkDisabledCardSign = (ssn) => {
        return this.createCall("post", "function/bankid/sign_requests/check-sign", {
            "options": {
                "parameters": {
                    "ssn": ssn,
                    'request_type': "disabled_card_photo"
                }
            }
        })
    }
}

export const api = new Api();
