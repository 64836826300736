import {useTranslation} from "react-i18next";

const OverchargeCost = ({chargeLevel}) => {
  const {t} = useTranslation()

  return (
    <>
      {chargeLevel >= 80 && (
        <div className="ReceiptRows divider-bottom">
          <div className="ReceiptRow">
            <div>
              <span className="ReceiptRowTitle">{t("80-overcharge-cost")}</span>
              <span className="ReceiptRowTime">00:00:0</span>
            </div>
            <div>
              <span className="ReceiptRowPrice">- {t("SEK")}</span>
              <span className="ReceiptRowPricing">2 {t("SEK/kWh")}</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OverchargeCost;
