import React from "react";
import Text from "../Text";
import { useTranslation } from "react-i18next";

const NoInvoice = () => {
  const { t } = useTranslation();
  return (
    <div className="NoInvoice">
      <Text
        size={16}
        weight={500}
        color={"#333333"}
        dangerouslySetInnerHTML={{
          __html: t("no-invoice-title"),
        }}
      ></Text>
      <Text
        dangerouslySetInnerHTML={{
          __html: t("no-invoice-desc"),
        }}
        size={14}
        color={"#333333"}
        weight={400}
        margin={"10px 0 0"}
      ></Text>
    </div>
  );
};

export default NoInvoice;
