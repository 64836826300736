import { IonContent } from "@ionic/react";

function DTScrollable({
    children,
    scrollY=true,
    scrollX=false,
    cssClass,
    extraPadding
}){
    return (
        <IonContent
        scrollY={scrollY}
        scrollX={scrollX ? scrollX : false}
        scrollEvents={scrollX === true || scrollY === true ? true : false}
        className={cssClass}
        >
            <div className={`${extraPadding ? "pb-[80px]" : ""}`}>
                {children}
            </div>
        </IonContent>
    );
}

export default DTScrollable;
