import { IonModal, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { LogoMono } from '../../assets/logo';
import Icon from '../Icon';
import Text from '../Text';

const DrifterCardLearnMoreModal = ({isOpen, setOpen, closeRootModal}) => {

    const {t} = useTranslation()
    const router = useIonRouter()

    return (
        <IonModal
      isOpen={isOpen}
      onDidDismiss={()=>setOpen(false)}
      className="radiusModal"
      breakpoints={[0.7]}
      initialBreakpoint={0.7}
      >
        <div className="w-full justify-end flex pt-[20px] pr-[20px]">
          <div className="bg-[#C0C0CB] rounded-full w-[32px] h-[32px] flex justify-center items-center" onClick={() => setOpen(false)}>
            <Icon
            name={"close"}
            size={11}
            color={"white"}
            />
          </div>
        </div>
        <div className="w-full flex justify-center items-start p-[20px] pt-0">
          <div className="flex w-full justify-center pt-[30px] ">
            <div className="w-[80px] h-[80px] flex justify-center items-center bg-purple rounded-full">
              <LogoMono width={33} height={36}/>
            </div>
            <div className="w-[80px] h-[80px] flex justify-center items-center bg-[#EFBF14] rounded-full ml-[-25px]">
              <Icon
              size={40}
              color="transperant"
              name="discount"
              />
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center items-center flex-col px-[20px]">
          <Text
            size={24}
            weight={600}
            color={"var(--dt-primary)"}
            dangerouslySetInnerHTML={{__html: t("start-to-earn")}}
            align="center"
            margin={"0 0 17px"}
          />
          <Text
            size={14}
            weight={400}
            color={"var(--dt-primary)"}
            dangerouslySetInnerHTML={{__html: t("drifter-learn-desc")}}
            align="center"
            margin={"0 0 60px"}
          />
          <button
          onClick={async () => {
            await closeRootModal && closeRootModal()
            await setOpen(false)
            await router.push('/my-drifter-card', "forward")
          }}
          className="w-full h-[55px] rounded-full flex justify-center items-center gap-[10px] bg-primary"
          >
            <Icon
            name="load-money"
            size={17}
            color="transperant"
            />
            <Text
            size={16}
            weight={700}
            color="#fff"
            >
              {t("load-money")}
            </Text>
          </button>
        </div>
      </IonModal>
    );
}

export default DrifterCardLearnMoreModal;
