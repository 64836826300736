//Ionic
import { IonContent, useIonRouter } from "@ionic/react";
//Components
import PageToolbar from "../components/PageToolbar";
import Icon from "../components/Icon";
//Helpers
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
const PlanAndPayments = () => {
  const history = useHistory()
  const router = useIonRouter()
  const { t } = useTranslation();
  return (
    <IonContent className={"no-padding"}>
      <PageToolbar
        title={t("plan-and-payments")}
        leftButtonType="back"
        onClick={() => {
          history.push('/map', {
            menuModal: true
          })
        }}
        backgroundColor="#fff"
      />
      <div className="SettingsWrapper px-[16px]">
        <button
          className="SettingRow"
          onClick={() => router.push("/payment-methods", "forward")}
        >
          <span className="SettingIcon">
            <Icon name="card" size={22} fill="var(--dt-primary)" />
          </span>
          <div className="SettingTitle">{t("payment-methods")}</div>
        </button>
        <button
          className="SettingRow"
          onClick={() => router.push("/invoices", "forward")}
        >
          <span className="SettingIcon">
            <Icon name="invoice" size={22} fill="var(--dt-primary)" />
          </span>
          <div className="SettingTitle">{t("invoices")}</div>
        </button>
      </div>
    </IonContent>
  );
};

export default PlanAndPayments;
