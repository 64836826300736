import {RegisterProfileForm, RegisterProfileHeader, RegisterProfileProgress} from "./Partials";
import {useState} from "react";

const RegisterProfile = () => {
    const sliderOptions = {spaceBetween: 100, allowTouchMove: false, noSwiping: true}
    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <>
            <RegisterProfileHeader sliderOptions={sliderOptions} activeSlide={activeSlide}/>

            <RegisterProfileProgress step={activeSlide} count={4} onClick={setActiveSlide}/>

            <RegisterProfileForm
                sliderOptions={sliderOptions}
                activeSlide={activeSlide}
                setActiveSlide={setActiveSlide}
            />
        </>
    );
}

export default RegisterProfile;
