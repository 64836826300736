import MenuItem from "./MenuItem";

const MenuList = (props) => {
  return (
    <div className="DTItems">
      {props.menuItems.map((item) => (
        <MenuItem
          key={item.label}
          label={item.label}
          counter={item.counter ?? false}
          itemOnClick={item.itemOnClick}
        />
      ))}
    </div>
  );
};

export default MenuList;
