//Ionic & React
import { IonModal, IonSlide, IonSlides } from "@ionic/react";
import { useRef, useEffect, useState } from "react";
//Helpers
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/globalSlice";
import { api } from "../../helper/api";
//Components
import Icon from "../Icon";
import Text from "../Text";
import SetBusinessCar from "./SetBusinessCar";
import SetBusinessCard from "./SetBusinessCard";
import { useTranslation } from "react-i18next";

const SetBusinessSlider = ({
  isOpen,
  setOpen,
  currentBusinessAccount,
  slideActiveIndex = 0,
  getBusinessDetail,
  getBusinessAccounts,
  comingFrom,
}) => {
  const businessSliderOpts = {
    spaceBetween: 100,
    initialSlide: slideActiveIndex,
    allowTouchMove: false,
    noSwiping: true,
  };
  const businessSlider = useRef();

  const businessTitleSliderOpts = {
    spaceBetween: 100,
    initialSlide: slideActiveIndex,
    allowTouchMove: false,
    noSwiping: true,
  };
  const businessTitleSlider = useRef();

  const [paymentMethods, setPaymentMethods] = useState([]);

  const dispatch = useDispatch();
  const {t} = useTranslation()

  const getPaymentMethods = () => {
    dispatch(setLoading({ status: true, message: "" }));
    api
      .getCards()
      .then(({ data }) => data.success && setPaymentMethods(data.response))
      .finally(() => dispatch(setLoading({ status: false, message: "" })));
  };

  useEffect(() => {
    if (isOpen) {
      getPaymentMethods();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => setOpen(false)}
      className="no-padding"
    >
      <div className="BusinessSliderModal iosSafeAreaTop">
        <div className="BusinessSlideHead">
          <div className="BusinessSlideToolbar">
            <div
              className="h-[60px] w-[60px]"
              onClick={() => {
                if (businessSlider.current.swiper.activeIndex !== 0)
                  businessSlider.current.swiper.slidePrev();
                else setOpen(false);
              }}
            >
              <Icon name={"back"} color="#fff" size={20} />
            </div>
          </div>
          <div className="BusinessSlideTitle">
            <IonSlides
              className="BusinessTitleSlider"
              options={businessTitleSliderOpts}
              ref={businessTitleSlider}
            >
              <IonSlide>
                <Text size={26} weight={600} color="#fff"
                dangerouslySetInnerHTML={{__html: t("select-business-card")}}
                />
              </IonSlide>
              <IonSlide>
                <Text size={26} weight={600} color="#fff"
                dangerouslySetInnerHTML={{__html: t("select-business-vehicle")}}
                />
              </IonSlide>
            </IonSlides>
          </div>
        </div>

        <IonSlides
          className="BusinessSlider"
          options={businessSliderOpts}
          ref={businessSlider}
          onIonSlideDidChange={(e) => {
            businessTitleSlider?.current?.swiper?.slideTo(
              e.target.swiper.activeIndex
            );
          }}
        >
          <SetBusinessCard
            comingFrom={comingFrom}
            setBusinessSlideModalOpen={setOpen}
            businessSlider={businessSlider}
            paymentMethods={paymentMethods}
            businessId={
              currentBusinessAccount.company_id || currentBusinessAccount.id
            }
            getBusinessDetail={getBusinessDetail}
            currentBusinessAccount={currentBusinessAccount}
          />
          <SetBusinessCar
            comingFrom={comingFrom}
            setBusinessSlideModalOpen={setOpen}
            businessSlider={businessSlider}
            businessId={
              currentBusinessAccount.company_id || currentBusinessAccount.id
            }
            getBusinessDetail={getBusinessDetail}
            getBusinessAccounts={getBusinessAccounts}
            currentBusinessAccount={currentBusinessAccount}
          />
        </IonSlides>
      </div>
    </IonModal>
  );
};

export default SetBusinessSlider;
