import UnitPriceTooltip from "./UnitPriceTooltip/UnitPriceTooltip"
import {useSpring, animated} from "react-spring"

const ParkingCostRange = ({prices, unitPrice, percentage}) => {

  const rangeWidthAnim = useSpring({
    to: {width: `${percentage}%`},
    from: {width: `0%`},
    delay: 130,
    config: {duration: 3000},
  })

  const tooltipAnim = useSpring({
    to: {left: `${percentage}%`},
    from: {left: `0%`},
    delay: 130,
    config: {duration: 3000}
  })

  const animation2 = useSpring({
    to: {opacity: 1, transform: "translateY(0px)"},
    from: {opacity: 0, transform: "translateY(-20px)"},
    delay: 100,
  })

  return (
    <animated.div className="mt-[12px] w-full" style={animation2}>
      <div className="flex flex-col items-center justify-center text-center relative">
        <ul className="list-none flex flex-row items justify-between w-full">
          {prices?.map(price => <li className="text-[12px] font-semibold text-gray" key={price.id}>{price.name}</li>)}
        </ul>

        <div className="w-full h-[10px] bg pt-[2px] rounded-full px-[2px] relative overflow-hidden">

          <ul className="list-none flex flex-row items justify-between ">
            {prices?.map(price => <li className="w-[6px] h-[6px] rounded-full bg-white" key={price.id}/>)}
          </ul>

          <animated.div className="h-[10px] bg-[#5E53E1] rounded-full absolute top-0 left-0" style={rangeWidthAnim}/>
        </div>

        <div className={'w-full translate-x-[-35px]'}>
          <animated.div className={"absolute"} style={tooltipAnim}>
            <UnitPriceTooltip price={unitPrice}/>
          </animated.div>
        </div>
      </div>
    </animated.div>
  )
}

export default ParkingCostRange
