import { t } from "i18next";
import Icon from "../Icon";

const MenuTopButtons = ({ goPath, isUnreadMessage, setSettingsModalOpen }) => {
  return (
    <>
      <button onClick={() => goPath("/inbox")} className="MenuTopButton">
        {isUnreadMessage && <span className="MenuTopButtonBadge" />}
        <Icon name="envelope" size={16} margin="0 12px 0 0" fill="#c0c0cB" />
        {t("inbox")}
      </button>
      <button
        className="MenuTopButton"
        onClick={() => setSettingsModalOpen(true)}
      >
        <Icon name="settings" size={16} margin="0 12px 0 0" fill="#c0c0cB" />
        {t("settings")}
      </button>
    </>
  );
};

export default MenuTopButtons;
