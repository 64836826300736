import React from "react";

const OverstayCountdownLabel = ({ label }) => {
  return (
    <p className="text-primary text-[16px] font-medium text-center whitespace-nowrap">
      {label}
    </p>
  );
};

export default OverstayCountdownLabel;
