import ToggleSwitch from "../ToggleSwitch";
import {useTranslation} from "react-i18next";

const ManagePermissionList = ({name, status, onChange}) => {
  const { t } = useTranslation();

  return (
    <label className={"FilterItem"} htmlFor={name}>
      {t(name)}
      <ToggleSwitch checked={status} id={name} onChange={onChange}/>
    </label>
  )
}

export default ManagePermissionList;
