import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Icon from "../../Icon";
import { useHistory } from 'react-router';
import { clearModals } from "../../../redux/slices/modalSlice";

const Address = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {user} = useSelector((store) => store.userSlice)
  const [goEditProfile, setGoEditProfile] = useState(false)

  const address = [
      {address: user.street?user.street:"N/A"},
      {city: user.city?user.city:"N/A"},
      {zip_code: user.zip_code?user.zip_code:"N/A"},
      {country: user.country?user.country : "N/A"}
  ]

  const asyncClearModals = async () => dispatch(clearModals())

  useEffect(() => {
    if(goEditProfile){
      asyncClearModals().finally(() => history.push("/edit-profile",  {"from": "profile"}))
    }
    // eslint-disable-next-line
  }, [goEditProfile]);

  return (
    <>
      <div className="ProfileInfo">
        <button
        className="absolute w-[44px] h-[44px] rounded-full bg-purple flex items-center justify-center right-[10%] top-[10px] z-[9999]"
        onClick={() => {
          setGoEditProfile(true)
        }}
        >
          <Icon
            name={"editIconWhite"}
            size={18}
            fill="#b11d94"
          />
        </button>
        {
          address.map((item, index) => {
            return (
              <div key={index} className="ProfileInfoItem">
                <span>{t(Object.keys(item)[0])}</span>
                {item[Object.keys(item)[0]]}
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default Address;
