
import { useTranslation } from 'react-i18next';
import SearchResultsList from './SearchResultsList';
import NoItems from './NoItems';

const SearchResults = ({
    searchResults,
    searchText,
    GetStationDetail,
    addNewRecentSearch,
    title,
    recentSearches,
    recentTitleStyle
}) => {

    const {t} = useTranslation()

    if(searchText){
      if(searchResults && searchResults.length){
        return (
          <SearchResultsList
                searchText={searchText}
                searchResults={searchResults}
                itemOnclick={(station) => {
                  GetStationDetail(station);
                  addNewRecentSearch(station);
                }}
                listTitle={title}
              />
        )
      }else{
        return <NoItems title={t("no-result")} text={t("you-dont-have-any-result")} type="search"/>
      }
    }else{
      if(recentSearches && recentSearches.length){
        return (
          <SearchResultsList
                searchText={searchText}
                searchResults={recentSearches}
                itemOnclick={(station) => {
                  GetStationDetail(station);
                }}
                listTitle={t("recent-search")}
                searchTitleStyle={recentTitleStyle}
                className={"-recent-search"}
              />
        )
      }else{
        return (
          <NoItems
            title={t("recent")}
            text={t("you-dont-have-any-recent-search")}
            type="recent"
          />
        );
      }
    }
  }


export default SearchResults;
