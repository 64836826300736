import { IonSkeletonText } from "@ionic/react"
import TicketItem from "./TicketItem"

const TicketsList = ({tickets, countOfFilters, loadingTickets}) => {
  const SKELETON_STYLE = {
    height: 8,
    width: "100%",
    "--background": "rgb(82, 84, 86)",
    "--background-rgb": "82, 84, 86",
  };
  const TICKET_ICON_SKELETON_STYLE = {
    height: 35,
    width: 35,
    "--background": "rgb(82, 84, 86)",
    "--background-rgb": "82, 84, 86",
    "--border-radius": "35px"
  };
  const TicketsSkeleton = () => {
    return (
      <div className="TicketSkeletonItem mb-2">
        <IonSkeletonText animated={true} style={TICKET_ICON_SKELETON_STYLE}/>
        <div className="w-full">
          <IonSkeletonText animated={true} style={SKELETON_STYLE}/>
          <IonSkeletonText animated={true} style={SKELETON_STYLE}/>
        </div>
      </div>
    )
  }

  return (
    <div className="TicketsList">
      {!loadingTickets
        ? tickets.map((ticket) => (
            <TicketItem key={ticket.id} ticket={ticket} />
          ))
        : [...Array(5).keys()].map((a) => <TicketsSkeleton key={a} />)}
    </div>
  );
}

export default TicketsList