import React from 'react';
import CloseSessionModalButton from './CloseSessionModalButton';
import {useSelector} from "react-redux";

const SessionModalsToolbar = ({dismissModal, breakpoint, stationName}) => {
  const {activeSession} = useSelector(state => state.userSlice)
  const {currentStation} = useSelector((state) => state.propSlice)

  const stationLogo = currentStation.DETAIL?.operator_logo || activeSession?.park_session?.park?.operator_logo
  const title = activeSession?.park_session?.park?.name || stationName

  return (
    <div>
      <div className={`flex justify-between items-center text-center p-[16px]`}>
        <div className="flex -space-x-2 overflow-hidden">
          {
              stationLogo && (
                  <img
                      className="bg-white inline-block h-10 w-10 rounded-full ring-2 ring-white"
                      src={stationLogo}
                      alt="STATION_LOGO"
                  />
              )
          }
        </div>
        <div className="">
          <p className="text-primary text-[16px] font-semibold animate__animated   animate__fadeInDown">
            {title || ""}
          </p>
        </div>
        <div>
          <CloseSessionModalButton onButtonClick={dismissModal} breakpoint={breakpoint}/>
        </div>
      </div>
    </div>
  );
}

export default SessionModalsToolbar;
