import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LogoMono } from '../../assets/logo';
import Text from '../Text';

const DrifterCardAdvantages = ({advantages}) => {

    return (
        <div className='DrifterCardAdvantages'>
            <Text weight={600} size={18} color="var(--dt-primary)">{t("drifter-advantages")}</Text>
            <Swiper
            spaceBetween={20}
            speed={200}
            allowTouchMove={true}
            slidesPerView={1.8}
            slidesPerGroup={advantages.length}
            className='DrifterCardAdvantagesSlider'
            >
                {advantages && advantages.map(ad => (
                    <SwiperSlide key={ad.id} className='DrifterCardAdvantagesSlideItem'>
                        <div style={{backgroundColor: ad.bgColor}} className="DrifterCardAdvantagesItem">
                            <div className='AdvantageIconWrapper' style={{backgroundColor: ad.iconBgColor}}>
                                <div className='AdvantageIcon'>
                                    {ad.icon === 'drifter-logo' && <LogoMono width={17} height={19}/>}
                                </div>
                            </div>
                            <div id="label" dangerouslySetInnerHTML={{__html: t(ad.label)}} style={{textAlign: "left"}}/>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default DrifterCardAdvantages;
