import { IonContent, IonPage} from '@ionic/react';
import PageToolbar from '../components/PageToolbar';
import { useTranslation } from 'react-i18next';
import Text from '../components/Text';
import { ActionButtons, ConfirmPhotos, Ending, PreviewContent } from '../components/MyProfile/AddDisablePermit';
import { useEffect, useState } from 'react';
import { CameraPreview } from '@capacitor-community/camera-preview';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/slices/globalSlice';
import { api } from '../helper/api';
import { useHistory } from 'react-router';

const AddDisablePermit = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const [isStart, setIsStart] = useState(false)
    const [cropped, setCropped] = useState("")
    const [activeView, setActiveView] = useState("camera");
    const [activeSide, setActiveSide] = useState("front");
    const [startCamera, setStartCamera] = useState(false)
    const [previewUrl, setPreviewUrl] = useState("")
    const [photo, setPhoto] = useState({front: "", back: ""})

    const CAMERA_OPTIONS = {
        parent: "camera",
        storeToFile: false,
        width: window.innerWidth,
        height: 204,
        disableAudio: true,
        x: 0,
        y: 200,
        toBack: false,
        rotateWhenOrientationChanged: false,
        disableExifHeaderStripping: false,
        position: 'rear'
    };

    useEffect(() => {
        setStartCamera(true)
        setIsStart(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        if(isStart){
            if(startCamera) CameraPreview.start(CAMERA_OPTIONS)
            else CameraPreview.stop()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startCamera])

    const setTitle = () => {
        if (activeView === "camera") {
        if (activeSide === "back") {
            return t("add-disable-permit-page-back-desc");
        } else {
            return t("add-disable-permit-page-front-desc");
        }
        } else {
        if (activeSide === "front") {
            return t("front-pic-preview");
        } else {
            return t("back-pic-preview");
        }
        }
    };

    const capture = () => {
        CameraPreview.captureSample({
            quality: 100,
            width: window.innerWidth,
            height: 225
        }).then((res) => {
            if(res.value){
            setPreviewUrl("data:image/jpeg;base64," + res.value)
            };
        }).finally(() => {
            setStartCamera(false)
            setActiveView("preview")
        })
    }
    const retry = () => {
        setActiveView("camera")
        setStartCamera(true)
        setPreviewUrl("")
    }

    const chooseThisPic = () => {
        if(activeSide === "front") {
            setPhoto(prev => ({
                ...prev,
                front: cropped
            }))
            setStartCamera(true)
            setActiveView("camera")
            setActiveSide("back")
            setPreviewUrl("")
        }else{
            setPhoto(prev => ({
            ...prev,
                back: cropped
            }))
            setActiveView("confirm")
        }
    }
    const sendPictures = () => {
        dispatch(setLoading({ status: true, message: "" }));
    api
      .uploadDisabledCard({
        options: {
          parameters: {
            disabled_front_photo: photo.front,
            disabled_back_photo: photo.back,
          },
        },
      })
      .then((res) => {
        if(res?.data?.success){
            setActiveView("end");
        }
      })
      .finally(() => {
        dispatch(setLoading({ status: false, message: "" }))
    });
    }

    const close = (ev) => {
        ev.detail.register(10, () => {
            setStartCamera(false)
            history.push('/map', {
                menuModal: true,
                myProfileModal: true
              })
            CameraPreview.stop()
            setIsStart(false)
        });
    }
    document.addEventListener('ionBackButton', close);
    
    
    return (
        <IonPage className='AddDisablePermitPage'>
        <PageToolbar
        title={t("add-disabled-permit")}
        titleColor="white"
        leftButtonType="back"
        whiteIcon={true}
        leftButtonOnClick={async () => {
            setStartCamera(false)
            history.push('/map', {
                menuModal: true,
                myProfileModal: true
            })
            CameraPreview.stop()
            setIsStart(false)
        }}
      />
      <IonContent className="AddDisablePermitContent">
        {activeView === "confirm" ? (
            <ConfirmPhotos photo={photo} sendPictures={sendPictures}/>
        ) : activeView === "end" ? (
            <Ending setIsStart={setIsStart}/>
        ) : (
            <>
                <Text
                align="center"
                color={"white"}
                size={18}
                weight={600}
                dangerouslySetInnerHTML={{ __html: setTitle() }}
                margin="16px 0 0 0"
                otherStyles={{ padding: "0 8px 0 8px" }}
                />
                <PreviewContent
                activeView={activeView}
                previewUrl={previewUrl}
                setCropped={setCropped}
                />
                <ActionButtons
                activeView={activeView}
                capture={() => capture()}
                retry={() => retry()}
                chooseThis={() => chooseThisPic()}
                />
            </>
        )
        }
      </IonContent>
        </IonPage>
    );
}

export default AddDisablePermit;
