const ParkStationTop = ({closeModal, logo, name, distance, zoneCode}) => {
  return (
    <>
      <button
        className={`DTModalCloseButton`}
        onClick={() => {
          closeModal();
        }}
      />
      <div className="StationTop Parking iosSafeAreaTop">
        <div className={`StationLogo`}>{logo}</div>
        <div>
        <div className='flex flex-row gap-2'>
              <p className="StationName">{name}</p>
              {zoneCode ? <p className="StationZoneCode">{zoneCode}</p> :null}
             </div>
          <span className="StationDistance">{distance}</span>
        </div>
      </div>
    </>
  );
};

export default ParkStationTop;
