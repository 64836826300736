import {t} from 'i18next';
import React from 'react';
import BackIcon from "../assets/icons/back-icon.svg";
import ElectricCarIcon from "../assets/icons/electric-car-icon.svg";
import CloseCrossModalIcon from "../assets/icons/close-modal-blue-cross.svg";
import {setCodeId} from "../redux/slices/userSlice";
import {useDispatch} from "react-redux";

const EnterChargeBoxCode = ({setShow, startSession}) => {
  const dispatch = useDispatch();

  const handleCodeSubmit = (e) => {
    let enterCodeInput = document.getElementById('enterCodeInput');
    dispatch(setCodeId(enterCodeInput.value))
    e.preventDefault();
    startSession()
  }

  return (
    <div className='w-full h-full bg-primary p-[20px]'>
      <div className="flex justify-between items-center text-center w-full">
        <div onClick={() => setShow(false)}>
          <img src={BackIcon} alt=""/>
        </div>
        <div>
          <button
            onClick={() => {
              //setChargingModalOpen(false);
              setShow(false);
            }}
          >
            <img src={CloseCrossModalIcon} alt=""/>
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center text-center items-center mt-[30%] mb-[50px]">
        <h3 className="text-[22px] font-medium text-white">
          {t("enter-code-on-post")}
        </h3>
        <p className="text-[#C0C0CB] text-[14px] font-normal mt-[13px]">
          {t("usually-6-digits")}
        </p>
      </div>
      <form
        onSubmit={(e) => {
          handleCodeSubmit(e);
        }}
      >
        <div className="flex justify-center text-center items-center mb-[50px]">
          <input
            style={{textTransform: "uppercase"}}
            type="text"
            id={"enterCodeInput"}
            className="ParkChargeCodeInput"
            autoFocus
          />
        </div>
        <div className="flex justify-center text-center items-center mb-[20%] px-[16px]">
          <button
            type={"submit"}
            className="bg-white text-primary w-[344px] h-[56px] text-[16px] font-bold rounded-full"
          >
            {t("confirm")}
          </button>
        </div>
      </form>
      <div className="flex justify-center items-center text-center">
        <div className="bg-purple w-[311px] h-[67px] rounded-[14px] flex items-center">
          <img src={ElectricCarIcon} alt=""/>
          <span
            className="text-white text-[14px] font-normal italic text-left ml-[25px]"
            dangerouslySetInnerHTML={{
              __html: t("make-sure-car-connected"),
            }}
          ></span>
        </div>
      </div>
    </div>
  );
}

export default EnterChargeBoxCode;
