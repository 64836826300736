import { IonContent } from "@ionic/react";
import { useTranslation } from "react-i18next";
import PageToolbar from "../components/PageToolbar";
import PaymentForm from "../components/PaymentMethods/PaymentForm";
import { useHistory } from "react-router";

const AddNewPaymentMethodPage = () => {
  const { t } = useTranslation();
  const history = useHistory()
  const {from} = history?.location?.state ?? ""

  return (
    <IonContent className="no-padding">
      <PageToolbar
        title={t("add-payment-method")}
        leftButtonType="back"
        onClick={() => {
          if(from) {
            history.push(`/${from}`)
          }else{
            history.goBack()
          }
        }}
      />
      <div className="p-[16px]">
        <PaymentForm />
      </div>
    </IonContent>
  );
};
export default AddNewPaymentMethodPage;
