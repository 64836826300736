import { useState, memo } from "react"
import { IonInput, IonItem, IonLabel } from '@ionic/react';

const DTInput = (props) => {
    const { label, margin, textTransform, errorText, successText, className, textAlign, onChange, placeholder, register,...prop } = props;
    const [isFocus, setIsFocus] = useState(false);

    return (
      <>
      <IonItem lines="none" className={`DTInputWrapper${errorText ? " error" : ""}${successText ? " success" : ""}${className ? " " + className : ""}`} style={{ margin: margin ? margin : "" }}>
        <div className={`DTInputBorder ${isFocus && "focus"}`}>
          {placeholder ? <IonLabel className="ion-padding-end">{label}</IonLabel> : null}
          <IonInput
          {...prop}
          {...register}
          onIonChange={onChange && onChange}
          onIonFocus={() => {
            if(prop.onFocus){
              prop.onFocus()
            }else{
              setIsFocus(true)
            }
          }}
          onIonBlur={() => {
            if(props.onBlur){
              prop.onBlur()
            }else{
              setIsFocus(false)
            }
          }}
          placeholder={placeholder ? placeholder : label ? label : ""}
          style={{ textTransform: textTransform ? textTransform : "", textAlign: textAlign ? textAlign : ""}}
          />
        </div>
      </IonItem>
      {errorText ? <span className="DTInputWarnText">{errorText}</span> : successText ? <span className="DTInputWarnText">{successText}</span> : ""}
      </>
    )
}

export default memo(DTInput)
