import {IonModal} from "@ionic/react";
import Text from "../Text";
import Icon from "../Icon";
import Button from "../Button";
import {api} from "../../helper/api";
import {useDispatch} from "react-redux";
import PageToolbar from "../PageToolbar";
import {useTranslation} from "react-i18next";
import {Alert} from "../../helper/alert";
import {clearLoader, setLoading} from "../../redux/slices/globalSlice";

const CarModal = ({isCarModalOpen, setCarModalOpen, vehicle, currentPlate, saveHandler}) => {
	const dispatch = useDispatch()
	const {t}	= useTranslation()

	const addVehicle = () => {
		dispatch(setLoading({status: true, message: t("saving-your-car")+"..."}))

		api.addVehicle({options: {parameters: {plate: currentPlate, update_data: true}}}).then(({data}) => {
			if (data.success === true) {
				saveHandler(data.response)
			} else {
				Alert.error(data.message)
			}
		}).catch(() => {
			Alert.error(t("the-plate-you-submitted-didnt-match-any-records"))
		}).finally(() => {
			dispatch(clearLoader())
		})
	}

	return (
		<IonModal
			isOpen={isCarModalOpen}
			breakpoints={[0.7, 1]}
			initialBreakpoint={1}
			handle={false}
			onDidDismiss={() => setCarModalOpen(false)}
		>

			<div className="w-full h-full bg-primary">
				<div className="w-full bg-primary">
					<PageToolbar
						title={t("add-new-car")}
						titleColor="#fff"
						leftButtonType="back"
						whiteIcon="true"
						leftButtonOnClick={() => setCarModalOpen(false)}
						iosSafeArea={false}
					/>
				</div>

				<div className="bg-white h-full rounded-t-[28px] p-[16px]">
					<div className="CarModalTop">
						<div className="CarImage">
							<img
								src={vehicle?.info?.model_entry?.image_url}
								alt={vehicle?.info?.model_entry}
								draggable={false}
							/>
						</div>
					</div>

					<div className="CarModalInformation">
						<div className="CarLogo">
							<img
								src={vehicle?.info?.logo}
								alt={vehicle?.info?.response_brand + " " + vehicle?.info?.response_model}
							/>
						</div>
						<div className="CarModalName">
							<Text size="20" weight="600">
								{vehicle?.info?.response_brand}
							</Text>
							<Text size="16" weight="400">
								{vehicle?.info?.response_model}
							</Text>
						</div>
					</div>

					<div className="CarModalBottom">
						<ul className="CarModalList">
							{(vehicle?.owner || vehicle?.owner_info?.attributes?.name.legal_name)&&
							<li>
								<Icon
									name="document"
									color="var(--dt-primary-300)"
									height="16"
								/>
								{t("registered-to")}
								<div>
									<span>{vehicle?.owner?vehicle.owner:vehicle?.owner_info?.attributes?.name.legal_name}</span>
								</div>
							</li>
							}
							{(!vehicle?.info?.model_entry?.connection_type_name) && <li>
								<Icon
									name="charge-time"
									color="var(--dt-primary-300)"
									height="16"
								/>
								{t("model-year")}
								<div>
									<span>{vehicle?.info?.model_year}</span>
								</div>
							</li>}
							{(!vehicle?.info?.model_entry?.connection_type_name && vehicle?.inspection?.data?.meter) && <li>
								<Icon
									name="connector-type"
									color="var(--dt-primary-300)"
									height="16"
								/>
								{t("meter")}
								<div>
									<span>{vehicle?.inspection?.data?.meter}</span>
								</div>
							</li>}
							{(!vehicle?.info?.model_entry?.connection_type_name && vehicle?.info?.fuel_type_name) && <li>
								<Icon
									name="connector-type"
									color="var(--dt-primary-300)"
									height="16"
								/>
								{t("fuel-type")}
								<div>
									<span>{vehicle?.info?.fuel_type_name}</span>
								</div>
							</li>}
							{(vehicle?.info?.model_entry?.connection_type_name && vehicle?.info?.model_entry?.connection_type_name  !== "NA") && <li>
								<Icon
									name="connector-type"
									color="var(--dt-primary-300)"
									height="16"
								/>
								{t("connector-type")}
								<div>
									<span>{vehicle?.info?.model_entry?.connection_type_name}</span>
								</div>
							</li>}
							{(vehicle?.info?.model_entry?.battery && vehicle?.info?.model_entry?.battery !== "NA") && <li>
								<Icon
									name="battery-capacity"
									color="var(--dt-primary-300)"
									height="16"
								/>
								{t("battery-capacity")}
								<div>
									<span>{vehicle?.info?.model_entry?.battery} {t("kWh")}</span>
								</div>
							</li>}
							{(vehicle?.info?.model_entry?.charging_time_ac || vehicle?.info?.model_entry?.charging_time_dc) && <li>
								<Icon
									name="charge-time"
									color="var(--dt-primary-300)"
									height="16"
								/>
								{t("charge-time")}
								<div>
									{vehicle?.info?.model_entry?.charging_time_ac && <span>{t("charging-time", {type: "AC", time: vehicle?.info?.model_entry?.charging_time_ac})}</span>}
                          			{vehicle?.info?.model_entry?.charging_time_dc && <span>{t("charging-time", {type: "DC", time: vehicle?.info?.model_entry?.charging_time_dc})}</span>}
								</div>
							</li>}
						</ul>

						<Button
							theme="secondary"
							margin="5% auto 0"
							onClick={() => addVehicle()}
						>
							{t("save-my-car")}
						</Button>

						<Text
							margin="3% auto"
							size="12px"
							weight="500"
							color="var(--dt-primary-300)"
							align="center"
							onClick={() => setCarModalOpen(false)}
						>
							{t("this-is-not-my-car")}
						</Text>
					</div>
				</div>
			</div>
		</IonModal>
	)
}

export default CarModal;
