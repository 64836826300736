// Components
import BottomToolbarSlide from "./BottomToolbar/BottomToolbarSlide";

// Plugins
import styled from "styled-components";

const BottomToolbarContainer = styled("div")`
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 134px;
  width: 100vw;
  z-index: 200;

  opacity: 0;
  transform: translateY(200%);

  animation: toolbarSlideUp 0.15s ease-out forwards;
  animation-delay: 1s;

  .swiper-container {
    flex-shrink: 0;
    height: 100%;
    width: 100vw;
    padding-bottom: 50px;
    overflow: visible;
  }

  &,
  & * {
    transition: all 0.15s ease-out;
  }

  .BottomIconWrapper {
    box-sizing: border-box;
    width: 80px;
    margin: 0 8px;

    &:first-of-type {
      justify-content: flex-end;
    }
    &:last-of-type {
      justify-content: flex-start;
    }

    .BottomIcon {
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      border: 2px solid #fff;
      background-color: var(--dt-primary);
      border-radius: 50%;
      box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.25);

      &::after {
        content: "";
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(180deg);
        height: 100px;
        width: 100px;
        border-radius: 50%;
        background: conic-gradient(
          from 90deg at 50.08% 50%,
          rgba(182, 21, 150, 0) 0.04deg,
          rgba(182, 21, 150, 0) 0deg,
          #b61594 359.96deg,
          #b11d94 360deg
        );

        padding: 5px;
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;

        z-index: -2;
      }
    }

    &.BottomAllInOne .BottomIcon {
      background-color: #b11d94;
      .icon {
        margin-right: 3px;
      }
    }
    &.BottomParking .BottomIcon {
      background-color: #5e53e1;

      .icon {
        height: 15px;
        width: 12px;
      }

      &::after {
        background: conic-gradient(
          from 90deg at 50.08% 50%,
          rgba(182, 21, 150, 0) 0.04deg,
          rgba(182, 21, 150, 0) 0deg,
          #5e53e1 359.96deg,
          #554ad0 360deg
        );
      }
    }
    &.BottomCharging .BottomIcon {
      background-color: #34d298;

      .icon {
        height: 22px;
        width: 16px;
      }

      &::after {
        background: conic-gradient(
          from 90deg at 50.08% 50%,
          rgba(21, 182, 115, 0) 0.04deg,
          rgba(21, 182, 115, 0) 0deg,
          #34d298 359.96deg,
          #2ac089 360deg
        );
      }
    }

    &:not(.active):active {
      transform: scale(1.05);
    }

    &.swiper-slide-active .BottomIcon {
      height: 80px !important;
      width: 80px !important;
      border-width: 1px !important;
    }

    &.BottomAllInOne.swiper-slide-active .BottomIcon .icon {
      height: auto;
      width: 48px;
    }
    &.BottomCharging.swiper-slide-active .BottomIcon .icon {
      height: auto;
      width: 23px;
    }
    &.BottomParking.swiper-slide-active .BottomIcon .icon {
      height: auto;
      width: 24px;
    }
  }

  .BottomText {
    position: absolute;
    left: 50%;
    bottom: -30px;
    color: var(--dt-primary);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    transform: translate(-50%, 120px);
    transition: all 0.2s ease-out;
  }
  .BottomText.active {
    transform: translate(-50%, 0);
  }

  .BottomIcon::after {
    opacity: 0;
  }

  .swiper-slide-active.activeSession .BottomIcon::after {
    opacity: 1;
    animation: chargingSpin 2s linear infinite;
  }

  .activeText {
    text-transform: uppercase;
  }

  .activeTextPark {
    color: var(--dt-parking) !important;
  }
  .activeTextCharge {
    color: var(--dt-charging) !important;
  }
  .activeTextParkCharge {
    color: var(--dt-charging-and-parking) !important;
  }
`;

const BottomToolbar = ({
  bottomToolbarRef,
  setFilterMenu,
  mapFilter,
  setMapFilter,
  setMarkersInitType,
 stationsLoading
}) => {
  return (
    <BottomToolbarContainer>
      <BottomToolbarSlide
        mapFilter={mapFilter}
        setMapFilter={setMapFilter}
        setFilterMenu={setFilterMenu}
        bottomToolbarRef={bottomToolbarRef}
        setMarkersInitType={setMarkersInitType}
        stationsLoading={stationsLoading}
      />
    </BottomToolbarContainer>
  );
};

export default BottomToolbar;
