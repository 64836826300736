const ChargeStationTop = ({name, distance, zoneCode}) => {
    return (
        <div className="StationTop iosSafeAreaTop">
            <div className="flex justify-between">
            <div className="StationInfo">
             <div className='flex flex-row gap-2'>
              <p className="StationName">{name}</p>
              {zoneCode ? <p className="StationZoneCode">{zoneCode}</p> :null}
             </div>
              <span className="StationDistance">
                {distance}
              </span>
            </div>
            </div>
            {/* TODO: Comment in when label design ready */}
            {/* <div className="ContractedLabel">
              <ContractedLabel/>
            </div> */}
          </div>
    );
}

export default ChargeStationTop;
