// Ionic & React
import { useEffect, useState, memo } from "react"
import { IonContent, IonModal, useIonRouter } from "@ionic/react"
import "./Menu.css"

// Pages
import StatisticsModal from "./StatisticsModal"
import DrifterRewards from "./DrifterRewards"
import AboutModal from "./AboutModal"
import SettingsModal from "./SettingsModal"
import FAQModal from "./FAQModal"

// Components
import { MenuList, MenuTopButtons, MenuUser, VersionLabel } from "../components/Menu"
import {DrifterCardLearnMoreModal} from "../components/MyDrifterCard";

// Redux
import {useSelector, useDispatch}  from "react-redux";

//Helpers
import { api } from "../helper/api";
//TODO: Comment-in when my drifter card component is enabled
// import { getDrifterCardBalance } from "../helper/globalFunctions"
import { setMenuModal, setMyCarsModal, setMyProfileModal } from "../redux/slices/modalSlice"
import CloseSwipeableModalButton from "../components/CloseSessionModalButton"
import MenuModals from "../components/MenuModals"
import MenuSetUpProfileButton from "../components/MenuSetUpProfileButton"
import SupportModal from "./SupportModal"
import Icon from "../components/Icon"
import { useHistory } from "react-router"

const Menu = ({
  className,
  ...props
}) => {
  const {user, vehicles} = useSelector((state) => state.userSlice)
  const {menuModal} = useSelector((state) => state.modalSlice)

  const [isStatisticsOpen, setStatisticsOpen] = useState(false)
  const [isDrifterRewardsOpen, setDrifterRewardsOpen] = useState(false)
  const [isFAQModalOpen, setFAQModalOpen] = useState(false)
  const [isAboutModalOpen, setAboutModalOpen] = useState(false)
  const [isSettingsModalOpen, setSettingsModalOpen] = useState(false)
  const [isSupportModalOpen, setSupportModalOpen] = useState(false)

  const[ isUnreadMessage, setUnreadMessage ] = useState(null)
  const [isDrifterMoreModal, setDrifterMoreModal]=useState(false)

  const [breakpoint, setBreakPoint] = useState(0.9)

  const router = useIonRouter()

  const rewardsData = [
    {
      name: "Current Drifter Points",
      points: 3775,
      color: "#fff",
    },
    {
      name: "Remaining Drifter Points to next reward",
      points: 1225,
      color: "#353355",
    },
  ]

  useEffect(() => {
    if(menuModal.status){
      checkMessages()
    }
    // TODO: Will be enabled, when Drifter section added
    // getDrifterCardBalance()
  }, [menuModal.status])

  const goPath = async (path) => {
    await dispatch(setMenuModal(false))
    router.push(path)
  }

  const checkMessages = () => {
    api.getInbox().then((response) => {
      if(response?.status === 200) {
        let unread = response?.data?.data?.filter(message => message.read === 0)
        if(unread?.length > 0){
          setUnreadMessage(true)
        }else{
          setUnreadMessage(false)
        }
      }
    })
  }

  const MenuItems = [
    {
      label: "my-profile",
      itemOnClick: () => dispatch(setMyProfileModal(true))
    },
    {
      label: "my-cars",
      itemOnClick: () => dispatch(setMyCarsModal(true)),
      counter: vehicles?.length
    },
    {
      label: "my-business-account",
      itemOnClick: () => goPath("/my-business-accounts"),
    },
    {
      label: "tickets",
      itemOnClick: () => goPath("/tickets"),
    },
    {
      label: "my-site-codes",
      itemOnClick: () => goPath("/my-site-codes")
    },
    {
      label: "plan-and-payments",
      itemOnClick: () => goPath("/plan-and-payments")
    },
   /*
   TODO:: Hidden for live will be activated in the future
    {
      label: "statistics",
      itemOnClick: () => setStatisticsOpen(true)
    },
    */

    {
      label: "about",
      itemOnClick: () => setAboutModalOpen(true)
    },
    {
      label: "support",
      itemOnClick: () => setSupportModalOpen(true)
    }
  ]

  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <>
      <IonModal
      isOpen={menuModal.status}
      onDidDismiss={() => dispatch(setMenuModal(false))}
      breakpoints={[0, 0.9, 1]}
      initialBreakpoint={0.9}
      canDismiss={menuModal.canDismiss}
      className={`Menu${className ? " " + className : ""} ${breakpoint !== 1 ? "radiusModal" : "fullscreen"} transition-all`}
      onIonBreakpointDidChange={(e) => setBreakPoint(e.detail.breakpoint)}
      onIonModalWillPresent={() => setBreakPoint(0.9)}
      {...props}
    >
      <IonContent className={`no-padding `}>
        <div className={`h-full ${breakpoint === 1 ? "iosSafeAreaTop" : ""}`}>
        <CloseSwipeableModalButton
        breakpoint={breakpoint}
        onButtonClick={() => dispatch(setMenuModal(false))}
        cssClass={`DTMenuModalCloseButton ${breakpoint === 1 ? "iosSafeAreaTopMargin" : ""}`}/>
        <div className="MenuTop">
            {user.first_name && user.last_name && user.email ? (
              <div className="MenuUser">
                <MenuUser
                profilePic={user.profile_photo_url}
                label={`${user.first_name ?? ""} ${user.last_name ?? ""}`}
                />
                <button
                onClick={async () => {
                  await dispatch(setMenuModal(false))
                  history.push("/edit-profile", {from: "menu"})
                }}
                className="ountline-0 bg-purple w-[30px] h-[30px] rounded-[30px] flex justify-center items-center ml-[32px]">
                  <Icon
                    name={"editIconWhite"}
                    size={18}
                    fill="#b11d94"
                  />
                </button>
              </div>
            )
          : (
            <div onClick={()=>goPath("edit-profile")}>
              <MenuSetUpProfileButton  />
            </div>
          )
          } 
          <div className="MenuTopButtons">
            <MenuTopButtons
            goPath={goPath}
            isUnreadMessage={isUnreadMessage}
            setSettingsModalOpen={setSettingsModalOpen}
            />
          </div>
        </div>
        <div className="MenuMiddle">
          {
            /*TODO:: Hidden for live will be activated in the future
             <DrifterCardSummary setDrifterMore={setDrifterMoreModal} goPath={goPath}/>
             */
          }
          <div className="MenuBottom">
            <MenuList
            goPath={goPath}
            vehicles={vehicles}
            setStatisticsOpen={setStatisticsOpen}
            setAboutModalOpen={setAboutModalOpen}
            menuItems={MenuItems}
            />
            <VersionLabel />
          </div>
        </div>
        </div>
      </IonContent>

      <StatisticsModal
        isStatisticsOpen={isStatisticsOpen}
        setStatisticsOpen={setStatisticsOpen}
      />
      <DrifterRewards
        rewardsData={rewardsData}
        setFAQModalOpen={setFAQModalOpen}
        isDrifterRewardsOpen={isDrifterRewardsOpen}
        setDrifterRewardsOpen={setDrifterRewardsOpen}
      />
      <AboutModal
        isAboutModalOpen={isAboutModalOpen}
        setAboutModalOpen={setAboutModalOpen}
      />
      <SettingsModal
        isSettingsModalOpen={isSettingsModalOpen}
        setSettingsModalOpen={setSettingsModalOpen}
      />
      <FAQModal
        isFAQModalOpen={isFAQModalOpen}
        setFAQModalOpen={setFAQModalOpen}
      />
      <DrifterCardLearnMoreModal
      isOpen={isDrifterMoreModal}
      setOpen={setDrifterMoreModal}
      />
      <SupportModal
      isOpen={isSupportModalOpen}
      setOpen={setSupportModalOpen}
      setFAQModalOpen={setFAQModalOpen}
      />
    </IonModal>
    <MenuModals/>
    </>
  )
}

export default memo(Menu)
