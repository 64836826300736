//Ionic & React
import { IonContent, IonModal } from "@ionic/react";
import { useEffect } from "react";
//Helpers
import "./styles/PrivacyAndPolicy.scss";
import { api } from "../../helper/api";
import { store } from "../../redux/store";
import { setLoading } from "../../redux/slices/globalSlice";
import { useTranslation } from "react-i18next";
//Components
import PageToolbar from "../PageToolbar";
import Icon from "../Icon";

const PrivacyAndPolicy = ({ isOpen, setOpen, registered=true}) => {
  const {t} = useTranslation()
  useEffect(() => {
    if (isOpen) getPrivacyAndPolicy();
  }, [isOpen]);

  const getPrivacyAndPolicy = () => {
    api
      .getPages("privacy-and-policy")
      .then(({ data }) => {
        if (data.success) {
          document.getElementById("privacy-and-policy").innerHTML =
            data.response.content;
        }
      })
      .finally(() =>
        store.dispatch(setLoading({ status: false, message: "" }))
      );
  };

  if(!registered){
    return (
      <IonModal
        isOpen={isOpen}
        breakpoints={[0,1]}
        initialBreakpoint={1}
        canDismiss={true}
        cssClass="DTModal radiusModal"
        onDidDismiss={() => setOpen(false)}
        onIonBreakpointDidChange={e => {
          if(e.detail.breakpoint === 0){
            setOpen(false)
          }
        }}
      >
        <IonContent>
          <Icon 
          cssClass="absolute top-[32px] right-[32px]"
          name="close"
          size={18}
          color="var(--dt-primary)"
          onClick={() => setOpen(false)}
          />
          <div className="Wrapper" id="privacy-and-policy"></div>
        </IonContent>
      </IonModal>
    )

  }else{
    return (
      <IonModal
        isOpen={isOpen}
        canDismiss={true}
        className="modal-white-background"
        onDidDismiss={() => setOpen(false)}
      >
        <PageToolbar
          title={t("privacy-and-policy")}
          leftButtonType="back"
          onClick={() => setOpen(false)}
          backgroundColor="#fff"
        />
        <IonContent>
          <div className="Wrapper" id="privacy-and-policy"></div>
        </IonContent>
      </IonModal>
    );
  }
};

export default PrivacyAndPolicy;
