import {IonContent, useIonRouter} from "@ionic/react";
import {useEffect, useState} from "react";
import {InvoiceClearFiltersButton, InvoiceFilter, Invoices} from "../components/Invoice";
import {api} from "../helper/api";
import PageToolbar from "../components/PageToolbar";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setLoading} from "../redux/slices/globalSlice";

const Invoice = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const router = useIonRouter();

  const [invoices, setInvoices] = useState([]);
  const [filters, setFilters] = useState({category_id: "all", status_id: "all", type_id: "all"})
  const [invoiceFilters, setInvoiceFilters] = useState([])
  const [isFilterInvoicesModalOpen, setFilterInvoicesModalOpen] = useState(false)
  const[countOfFilters, setCountOfFilters] = useState(0)
  const [reloadInvoices, setReloadInvoices] = useState(true)
  const [loadingInvoices, setLoadingInvoices] = useState(false)

  const applyFilters = () => {
    dispatch(setLoading({status: true, message: ""}))
    GetInvoices(filters)
      .finally(() => dispatch(setLoading({status: false, message: ""})))
  }

  const clearFilter = (type) => {
    if (type === "status") {
      setFilters(prevState => ({...prevState, status_id: "all"}))
    } else if(type === "account"){
      setFilters(prevState => ({...prevState, type_id: "all"}))

    } else {
      setFilters(prevState => ({...prevState, category_id: "all"}))
    }
  }

  const clearFilters = () => {
    dispatch(setLoading({status: true, message: ""}))
    setFilters({category_id: "all", status_id: "all", type_id: "all"})
    setCountOfFilters(0)
    GetInvoices().finally(() => dispatch(setLoading({status: false, message: ""})))
  }

  const checkStatus = () => {
    if (invoices.find(invoice => invoice.status_id === 3)) {
      setTimeout(() => GetInvoices(), 5000)
    }
  }

  const GetInvoices = (filters) => {
    setLoadingInvoices(true)
    if(filters){
      const {category_id, status_id, type_id} = filters;
      const categoryId = category_id === 'all' ? '' : category_id;
      const statusId = status_id === 'all' ? '' : status_id;
      const typeId = type_id === 'all' ? '' : type_id;
      return api
        .getInvoices(categoryId, statusId, typeId)
        .then((response) => setInvoices(response?.data?.data ?? []))
        .finally(() => setLoadingInvoices(false));
    }
    return api
      .getInvoices()
      .then((response) => setInvoices(response?.data?.data ?? []))
      .finally(() => setLoadingInvoices(false));
  }

  const GetInvoiceFilters = async () => {
    await api.getInvoiceFilters().then((response) => {
      setInvoiceFilters(response?.data.data[0])
    })
  }

  async function getAll(){
    await GetInvoices()
    await GetInvoiceFilters()
  }
  useEffect(() => {
    dispatch(setLoading({status: true, message: ""}))
    getAll().finally(()=> dispatch(setLoading({status: false, message: ""})))
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(reloadInvoices){
      checkStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices]);

  useEffect(() => {
    if(filters.status_id === "all" && filters.category_id === "all" && filters.type_id === "all"){
      setReloadInvoices(true)
    }else{
      setReloadInvoices(false)
    }
  }, [filters])

  return (
    <>
    <PageToolbar
        title={t("invoices")}
        leftButtonType="back"
        rightButtonType={"header"}
        rightButtonIcon="filter-2"
        leftButtonOnClick={() => router.push('/plan-and-payments', 'back')}
        rightButtonOnClick={() => setFilterInvoicesModalOpen(true)}
        rightBadge={true}
        rightBadgeText={countOfFilters}
        rightBadgeBackgroundColor="#B11D94"
        rightBadgeTextColor="#fff"
        backgroundColor="#fff"
      />
      <IonContent className={"no-padding  InvoicesContent"}>
        <Invoices
        invoices={invoices}
        countOfFilters={countOfFilters}
        loadingInvoices={loadingInvoices}
        />

        {countOfFilters>0 && <InvoiceClearFiltersButton clearFilters={clearFilters}/>}

        <InvoiceFilter
          isFilterInvoicesModalOpen={isFilterInvoicesModalOpen}
          setFilterInvoicesModalOpen={setFilterInvoicesModalOpen}
          filters={filters}
          setFilters={setFilters}
          invoiceFilters={invoiceFilters}
          applyFilters={applyFilters}
          clearFilter={clearFilter}
          setCountOfFilters={setCountOfFilters}
        />


      </IonContent>
    </>
  )
}

export default Invoice;
