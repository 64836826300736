import React from "react";
import Marker from "./Marker";
import DrifterMapStyles from "../assets/theme/DrifterMapStyle";
import centerMarker from "../assets/images/marker.png";
import GoogleMap from "google-map-react";

const GoogleMapComponent = ({
    center,
    zoom,
    setZoom,
    getConfig,
    searchThisArea,
    handleApiLoaded,
    getCoords,
    pos,
    heading
}) => {
  return (
    <div className={`Map`}>
      <GoogleMap
        center={[center.lat, center.long]}
        zoom={zoom}
        onZoomAnimationEnd={(zoom) => setZoom(zoom)}
        options={{
          styles: DrifterMapStyles,
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          scaleControl: true,
          rotateControl: true,
        }}
        bootstrapURLKeys={{
          key: getConfig("google_map_token"),
        }}
        onDragEnd={(event) => {
          searchThisArea({ lat: event.center.lat(), long: event.center.lng() });
        }}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {getCoords && (
          <Marker lat={pos.lat} lng={pos.long} className="centerMarker">
            <img
              height="55"
              width="30"
              src={centerMarker}
              alt="Center Marker"
              style={{ transform: `rotate(${heading}deg)` }}
            />
          </Marker>
        )}
      </GoogleMap>
    </div>
  );
};

export default GoogleMapComponent;
