import { memo } from "react";
import Icon from "../../Icon";
const DefaultCard = ({ card4, expireDate, cardholderName, brand }) => {
  return (
    <div className="CardWrapper">
      <div className="DefaultCard">
        {brand === "mastercard" ? (
          <Icon
            name={"mastercard"}
            height={31}
            width={49}
            className="MastercardLogo"
          />
        ) : brand === "visa" ? (
          <Icon
            name={"visa"}
            fill={"#BFC1C3"}
            height={20}
            width={62}
            className="VisaLogo"
          />
        ) : (
          <></>
        )}
        <Icon name={"card-chip"} height={38} width={38} className="CardChip" />
        <div className="CardNumber">
          <span className="CardNumberText">****</span>
          <span className="CardNumberText">****</span>
          <span className="CardNumberText">****</span>
          <span className="CardNumberText">{card4}</span>
        </div>
        <div className="CardHolderName">
          <span className="CardHolderNameText">{cardholderName}</span>
        </div>
        <div className="CardExpireDate">
          <span className="CardExpireDateText">{expireDate.month}</span>
          <span className="CardExpireDateSlash">/</span>
          <span className="CardExpireDateText">
            {expireDate.year.toString().slice(2, 4)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(DefaultCard);
