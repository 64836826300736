import { useTranslation } from "react-i18next";
import WarningModal from "../../../pages/WarningModal";
import { useState } from "react";
import Text from "../../Text";
import { useIonAlert, useIonRouter } from "@ionic/react";
import { useDispatch } from "react-redux";
import { setPin } from "../../../redux/slices/userSlice";
import { setLoading } from "../../../redux/slices/globalSlice";
import { clearModals } from "../../../redux/slices/modalSlice";
import { deleteAccount, logout } from "../../../helper/globalFunctions";

const Actions = ({ isDisabled }) => {
  const { t } = useTranslation();
  const [showAlert] = useIonAlert();
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [softLogoutModalOpen, setSoftLogoutModalOpen] = useState(false);
  const dispatch = useDispatch();
  const router = useIonRouter();

  const softLogoutHandler = () => {
    dispatch(setLoading({ status: true, message: t("logging_out") }));
    dispatch(clearModals());

    setPinCode(document.getElementById("pin").value).finally(() => {
      dispatch(setLoading({ status: false, message: "" }));
    });
  };

  const deleteAccountHandler = () => {
    setConfirmDeleteModalOpen(false);
    deleteAccount();
  };

  const setPinCode = async (pin) => {
    dispatch(setPin(pin));
    setSoftLogoutModalOpen(false);
    router.push("/register-setup", "redirect");
  };

  const LogoutContent = () => {
    return (
      <>
        <Text size="24px" weight="600" margin="45px 15px 0">
          {t("delete-account-desc")}
        </Text>

        <Text size="14px" weight="400" margin="27px 0 0">
          {t("irreversibly-deleted")}
        </Text>

        <button
          className="DTButton"
          onClick={() => Logout("delete")}
          style={{ margin: "50px 0 0" }}
        >
          {t("confirm")}
        </button>
      </>
    );
  };

  const SoftLogoutContent = () => {
    return (
      <>
        <Text size="24px" weight="600" margin="45px 15px 0">
          {t("soft-logout-desc")}
        </Text>

        <form className="RegisterSetupBottom" onSubmit={softLogoutHandler}>
          <div className={`DTInput`}>
            <input type="number" id="pin" placeholder="Pin" />
            <label htmlFor="pin">{t("pin")}</label>
          </div>

          <button className="DTButton" style={{ margin: "50px 0 0" }}>
            {t("confirm")}
          </button>
        </form>
      </>
    );
  };

  const Logout = (type = "logout") => {
    if (type === "logout") {
      showAlert({
        message: t("logout-confirm"),
        buttons: [t("no"), { text: t("yes"), handler: () => logout() }],
      });
    } else if (type === "delete") {
      showAlert({
        message: t("confirm-delete-account"),
        buttons: [
          t("no"),
          { text: t("yes"), handler: () => deleteAccountHandler() },
        ],
      });
    }
  };

  return (
    <div className={`ProfileBottom${isDisabled ? "" : " borderTop"}`}>
      <div className="DTItems">
        <div className="DTItem" onClick={() => setSoftLogoutModalOpen(true)}>
          {t("logout")}
        </div>
        <div className="DTItem" onClick={() => Logout()}>
          {t("safe-logout")}
        </div>
        <div
          className="DTItem -danger"
          onClick={() => setConfirmDeleteModalOpen(true)}
        >
          {t("delete-my-account")}
        </div>
      </div>

      <WarningModal
        isOpen={confirmDeleteModalOpen}
        onDidDismiss={() => setConfirmDeleteModalOpen(false)}
        breakpoints={[0, 0.5]}
        canDismiss={true}
        initialBreakpoint={0.5}
        children={<LogoutContent />}
      />

      <WarningModal
        isOpen={softLogoutModalOpen}
        onDidDismiss={() => setSoftLogoutModalOpen(false)}
        breakpoints={[0, 0.5]}
        canDismiss={true}
        initialBreakpoint={0.5}
        children={<SoftLogoutContent />}
      />
    </div>
  );
};

export default Actions;
