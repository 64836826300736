import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import DTSelect from '../DTSelect';

const InvoiceStatusFilter = ({
    filters,
    setFilters,
    invoiceFilters,
    clearFilter,
    setCountOfFilters
}) => {
    const { t } = useTranslation()
    return (
        <DTSelect label={t("status")} margin="40px 0 0" clearable={filters.status_id !== "all"} clearOnclick={() => clearFilter('status')}>
          <IonSelect
            id="filter-invoice_time"
            className={'capitalize'}
            onIonChange={(e) => {
              setFilters( prevState => ({
                ...prevState,
                "status_id": e.detail.value
              }))
              if(e.detail.value !== 'all'){
                setCountOfFilters(prevState => prevState + 1)
              }else{
                setCountOfFilters(prevState => prevState - 1)
              }
          }}
            value={filters.status_id}
          >
            <IonSelectOption value="all" className={'capitalize'}>{t("all")}</IonSelectOption>

            {
              invoiceFilters.statuses?.map((statusFilter, idx) => {
                return (
                  <IonSelectOption className={'capitalize'} key={idx} value={statusFilter?.id}>{statusFilter?.name}</IonSelectOption>
                )
              })
            }
          </IonSelect>
        </DTSelect>
    );
}

export default InvoiceStatusFilter;
