import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import DTSelect from '../DTSelect';

const InvoiceCategoryFilter = ({
    filters,
    setFilters,
    invoiceFilters,
    clearFilter,
    setCountOfFilters
}) => {
    const { t } = useTranslation()
    return (
        <DTSelect label={t("category")} margin="40px 0 0" clearable={filters.category_id !== "all"} clearOnclick={() => clearFilter('category')}>
          <IonSelect
            id="filter-invoice_category"
            onIonChange={(e) => {
              setFilters( prevState => ({
              ...prevState,
              "category_id": e.detail.value
            }))
            if(e.detail.value !== 'all'){
              setCountOfFilters(prevState => prevState + 1)
            }else{
              setCountOfFilters(prevState => prevState - 1)
            }
          }}
            className={'capitalize'}
            value={filters.category_id}
          >
            <IonSelectOption value='all' className="capitalize">{t("all")}</IonSelectOption>
            {
              invoiceFilters.category?.map((categoryFilter, idx) => {
                return (
                  <IonSelectOption key={idx} value={categoryFilter.id} className="capitalize">{categoryFilter.name}</IonSelectOption>
                )
              })

            }
          </IonSelect>
        </DTSelect>
    );
}

export default InvoiceCategoryFilter;
