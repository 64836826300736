import { IonModal } from '@ionic/react'
import PageToolbar from '../PageToolbar'
import { useTranslation } from 'react-i18next'
import Text from '../Text'
import { Browser } from '@capacitor/browser'
import Icon from '../Icon'
import { LogoMono } from '../../assets/logo'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsDev } from '../../redux/slices/globalSlice'

const ContactModal = ({isOpen, setOpen}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [clickCount, setClickCount] = useState(0)
  const {isDev} = useSelector(state => state.globalSlice)
  useEffect(() => {
    if(clickCount >= 7) {
      setClickCount(0)
      dispatch(setIsDev(!isDev))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickCount])
  return (
    <IonModal
      isOpen={isOpen}
      canDismiss={true}
      className="modal-white-background"
      onDidDismiss={() => setOpen(false)}
    >
      <PageToolbar
        title={t("contact")}
        leftButtonType="back"
        onClick={() => setOpen(false)}
        backgroundColor="#fff"
      />
      <div className='px-4'>
        <div className='mt-3 mb-[40px]'>
            <LogoMono color='var(--dt-primary)' width={90} height={90}/>
        </div>
        <Text weight={500} size={16}>
            {t("if-you-have-question")}
        </Text><br/>
        <div
        onClick={() => setClickCount(prev => prev+=1)}
        className='absolute bottom-[30px] right-[16px] w-[190px] flex flex-col items-end'
        >
            <Text weight={600} size={18}>
            Drifter World AB
            </Text>
            <Text weight={600} size={17}>
            IMPORTGATAN 12A
            </Text>
            <Text weight={500} size={16}>
            422 46 Hisings Backa
            </Text>
            <Text weight={500} size={16}>
            Gothenburg/Sweden
            </Text>
            <div className='flex flex-row items-center gap-2'>
                <Text weight={500} size={16} transform="unset">
                +46 010-205 11 20
                </Text>
                <Icon name="telephone" fill={"var(--dt-primary)"} size={18}/>
            </div>
            <div className='flex flex-row items-center gap-2'>
                <Text weight={500} size={16} transform="unset">
                    info@drifterworld.se
                </Text>
                <Icon name="mail" fill={"var(--dt-primary)"} size={18}/>
            </div>
            <div className='flex flex-row items-center gap-2'>
                <Text weight={500} size={16} color="var(--dt-pink)" transform="unset" onClick={async () => await Browser.open({url: "https://drifterworld.se/"})}>
                www.drifterworld.se
                </Text>
                <Icon name="web" fill={"var(--dt-primary)"} size={18}/>
            </div>
        </div>
      </div>
    </IonModal>
  )
}

export default ContactModal