import {useDispatch, useSelector} from "react-redux";
import {api} from "../../helper/api";
import {getPerson} from "../../helper/globalFunctions";
import {useTranslation} from "react-i18next";
import {IonRippleEffect, useIonAlert, useIonRouter} from "@ionic/react";
import Text from "../Text";
import {useState} from "react";
import WarningModal from "../../pages/WarningModal";
import {setLoading} from "../../redux/slices/globalSlice";
import ConfirmMailContent from "../ConfirmMailModal";
import { setEditEmailModal, setMenuModal, setMyProfileModal } from "../../redux/slices/modalSlice";

const ProfileHeader = () => {
  const { t } = useTranslation()
  const [showAlert] = useIonAlert()
  const dispatch = useDispatch()
  const {user} = useSelector((store) => store.userSlice)

  const [isMailSentModalOpen, setMailSentModalOpen] = useState(false)

  const router = useIonRouter()

  const updatePP = async (e) => {
    dispatch(setLoading({status: true, message: t("updating-profile-picture")}))
    api.updateProfilePhoto(e.target.files).then(() => getPerson()).catch(() => {
      showAlert({message: t("error-message")})
    }).finally(() => {
      dispatch(setLoading({status: false, message: ""}))
    })
  }

  const IsVerified = () => {
    return (
      <div className="ProfileEmailVerification" style={{margin: "20px 0 0"}}>
        {t("verification-needed")}
        <span className="link" onClick={() => resendActivation()}>{t("resend-mail")}</span>
      </div>
    )
  }

  // Resend Activation
  const resendActivation = async () => {
    dispatch(setLoading({status: true, message: t("sending-mail")}))
    await api.resendActivation().then(() => setMailSentModalOpen(true)).catch(() => {
      showAlert({
        cssClass: "surePhoneNumber",
        message: t("error-message"),
      })
    }).finally(() => dispatch(setLoading({status: false, message: ""})))
  }

  const redirectToDisabledCard = async () => {
    if (user?.ssn){
      await dispatch(setMenuModal(false))
      await dispatch(setMyProfileModal(false))
      await router.push("/add-disable-permit", "forward")
    }else{
      showAlert({message: t("ssn-required-for-disabled-card")})
    }
  }

  return (
    <div className="MyProfileContent">
      <div className="ProfileHeader">
        <label className="ProfileImage" htmlFor="uploadProfileImage">
          {user && (
            <img
              id={"profile_photo"}
              src={user.profile_photo_url}
              alt={user.first_name + " " + user.last_name}
              draggable={false}
            />
          )}
          <label htmlFor="uploadProfileImage">
            <input
              onChange={(e) => updatePP(e)}
              type="file"
              accept="capture=camera,image/*"
              id="uploadProfileImage"
            />
          </label>
        </label>
        <div className="ProfileContent">
          <Text size={18} weight={600} color="var(--dt-primary)">
            {user.first_name ?? ""} {user.last_name ?? ""}
          </Text>
          <Text size="14px" weight="400" color="#635c5c" margin="10px 0 0">
            {user.email}
          </Text>
          {user.email_verify === 0 && <IsVerified/>}
          <button
            className="DTButtonOutlinedDark ion-activatable"
            style={{margin: "20px 0 0"}}
            onClick={() => dispatch(setEditEmailModal(true))}
          >
            {t("edit-my-email")}
            <IonRippleEffect/>
          </button>
          {!user.is_disabled && <button
            className="DTButtonOutlinedDark ion-activatable"
            style={{margin: "20px 0 0"}}
            onClick={redirectToDisabledCard}
          >
            {t("add-disabled-permit")}
            <IonRippleEffect/>
          </button>
          }
        </div>
        <WarningModal
          isOpen={isMailSentModalOpen}
          onDidDismiss={() => setMailSentModalOpen(false)}
          breakpoints={[0,0.65]}
          initialBreakpoint={0.65}
          canDismiss={true}
          children={<ConfirmMailContent mail={user.mail} modalOpen={setMailSentModalOpen}/>}
        />
      </div>
    </div>
  )
}

export default ProfileHeader;
