// Ionic & React
import { useState, memo } from "react";
import { IonModal } from "@ionic/react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { setAlert, setLoading } from "../redux/slices/globalSlice";
import { setSearchModal } from "../redux/slices/modalSlice";
// Components
import Text from "../components/Text";
import { ParkStationDetail } from "../components/StationDetail/ParkStationDetail";
import { StationDetailTop } from "../components/StationDetail";
import PageToolbar from "../components/PageToolbar";
import WarningModal from "./WarningModal";
import FirstExperience from "../components/FirstExperience";
import DTScrollable from "../components/DTScrollable";
//Translate
import { useTranslation } from "react-i18next";
//Api
import Api from "../helper/api";


const StationDetailModal = ({
  station,
  isStationDetailOpen,
  setStationDetailOpen,
  className,
  goRoute,
  setStartSessionModal,
  parkVipCode,
  setParkVipCode,
  openSessionModal,
  ...props
}) => {
  const [haveVipCode, sethaveVipCode] = useState(false);
  const [verify, setVerify] = useState(false);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [choosenImageUrl, setChoosenImageUrl] = useState("");
  const [noCarsModal, setNoCarsModal] = useState(false);
  const [isFirstExperienceModalOpen, setFirstExperienceModalOpen] = useState(false)
  const { vehicles: cars, config } = useSelector((store) => store.userSlice);

  const { t } = useTranslation();
  const api = new Api();
  const dispatch = useDispatch();

  const GetLogos = () => {
    let logo = station?.DETAIL?.operator_logo ;

    if (station.TYPE === "park_and_charge") {
      const operatorLogo = station?.DETAIL?.operator_logo;
      return (
        <div style={{ width: "60px" }}>
          <img src={logo} alt="" style={{ marginLeft: "10px" }} />
          <img src={operatorLogo} alt="" />
        </div>
      );
    } else {
      return <img src={logo} alt="" />;
    }
  };

  const addParkingReminder = async () => {
    await api
      .addParkingAvailabilityReminder({
        options: {
          parameters: {
            park_id: station.entry_id,
          },
        },
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch(
            setAlert({
              status: true,
              message: t(data.response),
              type: "success",
            })
          );
        } else {
          dispatch(
            setAlert({
              status: true,
              message: t("try-again"),
              type: "error",
            })
          );
        }
      })
      .catch(() =>
        dispatch(
          setAlert({
            status: true,
            message: t("try-again"),
            type: "error",
          })
        )
      );
  };

  const checkVipCode = () => {
    dispatch(setLoading({status: true, message:""}))
    api.checkParkVIPCode(parkVipCode, station.entry_id)
      .then(({data}) => {
        if(data.success){
          setVerify(true)
        }else{
          setParkVipCode("")
          dispatch(setAlert({
            status: true,
            message: data.message,
            type: "error"
          }))
        }
      })
      .finally(()=> dispatch(setLoading({status: false, message:""})))
  }

  const goAddNewCar = async () => {
    await closeModal();
    await dispatch(setSearchModal(false));
    await setNoCarsModal(false);
    await goRoute("/add-new-car", "forward");
  };

  const NoCarContent = () => {
    return (
      <>
        <Text
          size="24px"
          weight="600"
          margin="45px 0 0"
          dangerouslySetInnerHTML={{ __html: t("add-a-car") }}
        />
        <Text
          size="14px"
          weight="400"
          margin="27px 0 0"
          dangerouslySetInnerHTML={{ __html: t("add-car-desc") }}
        />
        <button
          className="DTButton"
          onClick={() => goAddNewCar()}
          style={{ margin: "50px 0 0" }}
        >
          {t("add-car")}
        </button>
      </>
    );
  };

  const closeModal = () => {
    setVerify(false)
    sethaveVipCode(false)
    setParkVipCode("")
    setStationDetailOpen(false);
  };

  return (
    <>
      <IonModal
        isOpen={isStationDetailOpen}
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        canDismiss={true}
        className={`StationDetailModal radiusModal ${station.TYPE}${
          className ? className : ""
        }`}
        onDidDismiss={() => {
          if(isStationDetailOpen===true){
            setStationDetailOpen(false);
          }
        }}
        {...props}
      >
        <StationDetailTop
          closeModal={closeModal}
          logo={<GetLogos />}
          name={station.NAME}
          distance={''}
          stationType={station.TYPE}
          isContracted={station.DETAIL?.is_contracted}
          station={station}
          zoneCode={station.ZONECODE}
        />
        <DTScrollable
        scrollY={true}
        cssClass={"StationDetailContent"}
        >
          <ParkStationDetail
          station={station}
          addParkingReminder={addParkingReminder}
          haveVipCode={haveVipCode}
          sethaveVipCode={sethaveVipCode}
          verify={verify}
          setVerify={setVerify}
          parkVipCode={parkVipCode}
          setParkVipCode={setParkVipCode}
          checkVipCode={checkVipCode}
          setImageModalOpen={setImageModalOpen}
          setChoosenImageUrl={setChoosenImageUrl}
          isContracted={station.DETAIL?.is_contracted}
          />
        </DTScrollable>
      </IonModal>
      <WarningModal
        isOpen={noCarsModal}
        onDidDismiss={() => setNoCarsModal(false)}
        breakpoints={[0,0.4]}
        canDismiss={true}
        initialBreakpoint={0.4}
        children={<NoCarContent />}
      />
      <IonModal
        isOpen={isImageModalOpen}
        initialBreakpoint={1}
        onDidDismiss={() => {
          setImageModalOpen(false);
          setChoosenImageUrl("");
        }}
      >
        <div className="w-full h-full bg-primary ">
          <div>
            <PageToolbar
              title={t("photo-gallery")}
              titleColor="#fff"
              leftButtonType="back"
              whiteIcon="true"
              leftButtonOnClick={() => {
                setImageModalOpen(false);
                setChoosenImageUrl("");
              }}
            />
          </div>
          <div className="w-full h-[calc(100%-55px)] flex justify-center items-center px-[16px]">
            <img src={choosenImageUrl} alt="StationImagePreview" />
          </div>
        </div>
      </IonModal>
      <IonModal
        isOpen={isFirstExperienceModalOpen}
        onDidDismiss={() => setFirstExperienceModalOpen(false)}
      >
        <FirstExperience
          isOpen={isFirstExperienceModalOpen}
          setOpen={setFirstExperienceModalOpen}
          openSessionStartModal={setStartSessionModal}
          closeStationDetailModal={closeModal}
        />
      </IonModal>
    </>
  );
};

export default memo(StationDetailModal);
