import { IonModal, IonContent } from '@ionic/react';
import {useState, useEffect} from 'react';
import { api } from '../../../helper/api';
import Text from '../../Text';
import Icon from '../../Icon';
import OperatorList from '../../Operators/OperatorList';

const Operators = ({choosenOperator, setChoosenOperator, title}) => {
  const [operators, setOperators] = useState([]);
  const [showOperatorListModal, setShowOperatorListModal] = useState(false);

    useEffect(() => {
        getOperators()
    },[])

    const getOperators = () => {
        return api.getParkChargeOperators().then(({data}) => {
            if(data.success){
                setOperators(data.response)
            }
        })
    }

    const handleClick = (operator) => {
      setChoosenOperator(operator)
      setShowOperatorListModal(false)
    }
    return (
        <>
            <div className="FilterRow">
                <div
                className='flex flex-row justify-between items-center w-full'
                onClick={() => setShowOperatorListModal(true)}
                >
                  <Text color="#353355" fontSize={16} weight={600}>{title}</Text>
                  
                  <div className='flex flex-row justify-between items-center gap-[8px]'>
                    {choosenOperator.id && <Text color="#353355" fontSize={16} weight={400}>{choosenOperator.title}</Text>}
                    <Icon
                    name={choosenOperator.id !== undefined ? "pink-thick" : "arrow-up"}
                    size={18}
                    color="var(--dt-primary)"
                    className={!choosenOperator ? "rotate-180" : ""}
                    />
                  </div>
                </div>
            </div>
            <IonModal
            isOpen={showOperatorListModal}
            breakPoints={[0.1, 0.85, 1]}
            initialBreakpoint={0.85}
            canDismiss={true}
            handle={true}
            onDidDismiss={() => setShowOperatorListModal(false)}
            >
                <IonContent scrollY={true}>
                  <OperatorList operators={operators} choosenOperator={choosenOperator} handleClick={handleClick}/>
                </IonContent>
            </IonModal>
        </>
    );
}

export default Operators;
