import {AndroidPermissions} from "@awesome-cordova-plugins/android-permissions";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic";

/**
 *
 * @param permission {string} - Android permission name
 * @returns {Promise<unknown>} - Promise with permission status
 */
const checkAndroidPermission = (permission) => {
  return new Promise((resolve) => {
    AndroidPermissions.checkPermission(permission).then(({hasPermission}) => {
      resolve(hasPermission)
    });
  });
}

/**
 *
 * @param permission {string} - IOS permission name
 * @returns {Promise<unknown>} - Promise with permission status
 */
 const checkIosPermission = (permission) => {
  return new Promise((resolve) => {
    switch (permission) {
      case "camera":
      Diagnostic.isCameraAuthorized().then(res => resolve(res))  
      break;
      case "location":
        Diagnostic.isLocationAuthorized().then(res => resolve(res))
        break;
      case 'notifications':
        Diagnostic.isRemoteNotificationsEnabled().then(res => resolve(res))
        break;
      default:
        break;
    }
  })
}

export const checkPermissionsByDevice =  {

  /**
   *
   * @param permissions {Array<Object>} - Array of permissions
   * @param setPermissions {Function} - Function to set permissions
   */
  android: (permissions, setPermissions) => {
    permissions.map((permission) => (
      checkAndroidPermission(permission.android).then((hasPermission) => {
        setPermissions(state => state.map((value) => {
          if (value.name === permission.name) {
            value.status = hasPermission;
          }
          return value;
        }))
      })
     ))
  },
  /**
   *
   * @param permissions {Array<Object>} - Array of permissions
   * @param setPermissions {Function} - Function to set permissions
   */
  web: (permissions, setPermissions) => {
    permissions.filter((permission) => (
      !permission.isNative && navigator.permissions.query({name: permission.web}).then(({state}) => {
        setPermissions(prevState => prevState.filter((value) => !value.isNative && value).map((value) => {
          if (value.name === permission.name) {
            value.status = state === 'granted';
          }
          return value;
        }))
      })
    ))
  },
  /**
   *
   * @param permissions {Array<Object>} - Array of permissions
   * @param setPermissions {Function} - Function to set permissions
   */
   ios: (permissions, setPermissions) => {
    permissions.map((permission) => (
      checkIosPermission(permission.ios).then((hasPermission) => {
        setPermissions(state => state.map((value) => {
          if (value.name === permission.name) {
            value.status = hasPermission;
          }
          return value;
        }))
      })
    ))
   }
}

export const togglePermission = {
  /**
   *
   * @param permission {string} - Android permission name
   * @returns {Promise<Boolean>} - Promise with permission status
   */
  android: (permission) => {
    return new Promise((resolve, reject) => {
      AndroidPermissions.checkPermission(permission).then(() => {
        AndroidPermissions.requestPermission(permission).then(({hasPermission}) => {
          resolve(hasPermission)
        }).catch(() => reject(false));
      }).catch(() => reject(false));
    })
  },
  /**
   *
   * @param permission {string} - Web permission name
   * @returns {Promise<unknown>} - Promise with permission status (granted or denied)
   */
  web: (permission) => {
    return new Promise((resolve, reject) => {
      navigator.permissions.query({name: permission}).then(({state}) => {
        console.log(state)
        resolve(state);
      }).catch(() => {
        reject(false)
      })
    })
  }
}
