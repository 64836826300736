import {useEffect, useState} from "react"
import {getCountryCallingCode, getCountries} from "react-phone-number-input"
import countryNames from "react-phone-number-input/locale/en.json"
import CountryList from "./CountryCodes/CountryList"

const CountryCodes = ({handleClick}) => {
    const [countries, setCountries] = useState([])

    useEffect(() => {
        const countryList = getCountries()

        setCountries(() => {
            return countryList.map(country => {
                return {
                    countryName: countryNames[country],
                    countryCode: getCountryCallingCode(country),
                    countryShortName: country
                }
            })
        })
    }, []);

    return (
        <CountryList countries={countries} onItemClick={handleClick}/>
    )
}

export default CountryCodes
