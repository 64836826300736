import { useEffect, useState } from "react";

const OperatorItem = ({operator, handleClick, choosenOperator}) => {
    const [active, setActive] = useState(false)
    useEffect(() => {
        if(choosenOperator.id === operator.id) setActive(true)
        else setActive(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [choosenOperator]);
    return (
        <div className='OperatorItem' onClick={() => {
            handleClick(operator)    
            setActive(prev => !prev)
            }}>
            <p className='text-primary'>{operator.title}</p>
            {active && <span className="active"/>}
        </div>
    );
}
export default OperatorItem;
