// Ionic & React
import { useState, useEffect } from "react"
import {
  IonPage,
  IonContent,
  useIonRouter,
  useIonAlert,
} from "@ionic/react"
import { Keyboard } from '@capacitor/keyboard';
// Redux
import {useDispatch, useSelector} from "react-redux"

// Plugins
import {
  isValidPhoneNumber
} from "react-phone-number-input"

// Components
import Text from "../components/Text"
import DTPhoneInput from "../components/DTPhoneInput"
import Button from "../components/Button"

import appLandingVideo from "../assets/images/app_landing_video.mp4"
import Logo from "../components/Logo"

// Helper
import Api from "./../helper/api"
import {useTranslation} from "react-i18next";
import {setPin} from "../redux/slices/userSlice";
import {clearLoader, setLoading} from "../redux/slices/globalSlice";
import { PrivacyAndPolicy, TermsAndConditions } from "../components/About"
import { Capacitor } from "@capacitor/core"
import md5 from "blueimp-md5";

const RegisterSetup = () => {
  const [border, setBorder] = useState("")
  const [isPhoneValid, setPhoneValid] = useState(false)
  const [isTaCModalOpen, setTaCModalOpen] = useState(false)
  const [isPPModalOpen, setPPModalOpen] = useState(false)
  const [loginType, setLoginType] = useState("pin")
  const [keyboard, setKeyboard] = useState(false)

  const router = useIonRouter()
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [showAlert] = useIonAlert()

  let { token, phone, pin } = useSelector((state) => state.userSlice)



  useEffect(() => {
    pin===null && token && router.push('/map', 'redirect')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      phone && phoneValidation()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone])

  const phoneValidation = () => {
    if(isValidPhoneNumber(phone)){
      setPhoneValid(true)
      setBorder("success")
    }
  }


  //Create Hash token with timestamp__formatted by UTC timezone and HashKey_from env file
  const createHash = () => {
    const HASH_KEY = process.env.REACT_APP_HASH_KEY
    let dateStamp = Date.now()/1000|0
    let str = `${HASH_KEY}-${dateStamp}`
    return md5(str);
  }

  const api = new Api()
  const registerPhone = (phone) => {
    dispatch(setLoading({status: true, message: t("sending")+"..."}))
    api.registerPhone({phone: phone,_token:createHash()}).then((response) => {
      if (response.data.success === true) {
        router.push("/enter-sms-code", "forward")
      } else {
        showAlert({
          message: t("invalid-phone-number"),
        })
      }
    }).catch(() => {
      showAlert({
        message: t("invalid-phone-number"),
      })
    }).finally(() => dispatch(clearLoader()))
  }

  const handleSubmitRegister = (e) => {
    e.preventDefault()

    if (isValidPhoneNumber(phone)) {
      registerPhone(phone)
    } else {
      dispatch(clearLoader())
      setBorder("")
      setTimeout(() => setBorder("shake"))
    }
  }

  const handleSubmitSoftLogin = (e) => {
    e.preventDefault()
    dispatch(setLoading({status: true, message: t("confirming")+"..."}))

    if (document.getElementById('pin').value === pin)
    {
      removePin().finally(()=>dispatch(clearLoader()))
    }else{
      dispatch(clearLoader())
      showAlert({
        message: t("invalid-pin-code"),
      })
    }
  }
  const removePin = async () => {
    dispatch(setPin(null))
    router.push('/map', 'redirect')
  }

  const openTermsAndConditions = (e) => {
    e.preventDefault();
    if (e.target.id === "privacy-policy") {
      setPPModalOpen(true)
    } else if (e.target.id === "terms-and-conditions-button") {
      setTaCModalOpen(true)
    }
  }

  const handleNativeKeyboard = keyboardHeight => {
    if(keyboardHeight !== 0){
      setKeyboard(true)
    }else{
      setKeyboard(false)
    }
  }

  useEffect(() => {
    if(Capacitor.isNativePlatform()){
      Keyboard.addListener('keyboardWillShow', info => handleNativeKeyboard(info.keyboardHeight))
      Keyboard.addListener('keyboardWillHide', () => handleNativeKeyboard(0))

        return () => {
            Keyboard.removeAllListeners();
        }
    }
  }, []);

  return (
    <IonPage className="RegisterSetup">
      <IonContent>
        <div className="RegisterSetupWrapper">
          <div className="RegisterSetupVideo">
            <div className="FilterLayer"></div>
            {/* style={{transform: keyboard ? "scale(.4)" : "scale(1)"}} */}
            <Logo type="LogoVertical" width={keyboard ? 80 : 436}/>
            <video src={appLandingVideo} autoPlay muted controls="" playsInline loop />
          </div>
          {loginType==="pin" && pin!==null ?
            <form className="RegisterSetupBottom" onSubmit={handleSubmitSoftLogin}>
            <Text select="none" cssClass="title-2" margin="0 10px 32px">
              {t("welcome-to-drifter")}
            </Text>

              <div className={`DTInput ${border}`}>
                <input
                    type="number"
                    id="pin"
                    placeholder="Pin"
                />
                <label htmlFor="pin">{t("pin")}</label>
              </div>

            <Button
                type="submit"
                theme="primary"
                margin="40px 0 0">
              {t("login")}
            </Button>

            <Text
                margin="30px 0 0"
                size="12px"
                weight="500"
                color="var(--dt-purple)"
                align="center"
                onClick={() => {setLoginType("phone");}}
                dangerouslySetInnerHTML={{
                  __html: t("forgot-pin"),
                }}
            >
            </Text>
          </form>:
          <form className="RegisterSetupBottom" onSubmit={handleSubmitRegister}>
            <Text select="none" cssClass="title-2" margin="0 10px 32px">
              {t("welcome-to-drifter")}
            </Text>

            <DTPhoneInput
              border={border}
              setBorder={setBorder}
              setPhoneValid={setPhoneValid}
            />

            <Button
              type="submit"
              theme="primary"
              margin="40px 0 0"
              disabled={!isPhoneValid}
            >
              {t("request-code")}
            </Button>


            { pin!==null &&
                <Text
                    margin="10px 0 0"
                    size="12px"
                    weight="500"
                    color="var(--dt-purple)"
                    align="center"
                    onClick={()=>setLoginType('pin')}
                    dangerouslySetInnerHTML={{
                      __html: t("pin_code_exist"),
                    }}
                >
                </Text>
            }



            <Text
              margin="10px 0 0"
              size="12px"
              weight="500"
              color="var(--dt-purple)"
              align="center"
              onClick={openTermsAndConditions}
              dangerouslySetInnerHTML={{
                __html: t("policy"),
              }}
            >
            </Text>


          </form>
          }
        </div>
      </IonContent>

      <TermsAndConditions
      isOpen={isTaCModalOpen}
      setOpen={setTaCModalOpen}
      registered={false}
      />

      <PrivacyAndPolicy
        isOpen={isPPModalOpen}
        setOpen={setPPModalOpen}
        registered={false}
      />


    </IonPage>
  )
}

export default RegisterSetup

