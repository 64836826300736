import { IonSkeletonText } from '@ionic/react'
const InvoiceSkeleton = () => {
    const SKELETON_STYLE = {
      height: 10,
      "--background": "rgb(82, 84, 86)",
      "--background-rgb": "82, 84, 86",
    };
    const Skeleton = () => (
        <div className='InvoiceSkeletonWrapper'>
          {[...Array(5).keys()].map(a => ( <IonSkeletonText key={a} animated={true} style={SKELETON_STYLE}/> ))}
        </div>
    )
  return (
    <div className='SettingsWrapper px-[16px]'>
        {[...Array(4).keys()].map(a => (<Skeleton key={a} />))}
    </div>
  )
}

export default InvoiceSkeleton