//React & Ionic
import { IonModal} from "@ionic/react"
import { useEffect, useState } from "react"
//Assets
import parkingLights from "../assets/images/vectors/parking_lights.svg"
import Car from "../assets/images/car.png"
import ParkIcon from "../assets/icons/white-park-icon.svg"
//Components
import { SwipeButton } from "./SwipeButton"
import Report from "./Report"
import AccountSelector from "./AccountSelector"
import Icon from "./Icon"
import Text from "./Text"
//Helpers
import { api } from "../helper/api"
import {useTranslation} from "react-i18next";
import { parkChargeFunctions } from "../helper/parkChargeFunctions"
import { useDispatch, useSelector } from "react-redux"
import VehicleSelector from "./VehicleSelector"
import { Alert } from "../helper/alert"
import {store} from "../redux/store";
import {setUsageAccount} from "../redux/slices/userSlice";
import SessionModalsToolbar from "./SessionModalsToolbar"
import { SelectTicketModal } from "./Tickets"
import {setSessionModal, setStationDetailModal} from "../redux/slices/modalSlice"
import { setLoading } from "../redux/slices/globalSlice"

const StartParkingOnlyModal = ({
    station,
    handleOpenModal,
    value,
    handleValue,
    vehicleInUseFromStartCharge,
    isOpen,
    parkVipCode,
    usageAccountFromStartCharge="",
    breakpoint
}) => {
  const dispatch = useDispatch()
  const [isReportModalOpen, setReportModalOpen] = useState(false)
  const [accounts, setAccounts] = useState([]);
  const [isStartSession, setIsStartSession] = useState(false);
  const [ticketsModalOpen, setTicketsModalOpen] = useState(false)
  const { t } = useTranslation();
  const {defaultVehicle, usageAccount, user} = useSelector((state) => state.userSlice);
  const [vehicleInUse, setVehicleInUse] = useState(null)
  const [resetSwipeButtonValue, setResetSwipeButtonValue] = useState(false)

  useEffect(() => {
    if (isOpen) {
      handleValue(0);
      getAccounts();
      if(vehicleInUseFromStartCharge){
        setVehicleInUse(vehicleInUseFromStartCharge)
      }
    }

    if(usageAccountFromStartCharge !== ""){
      store.dispatch(setUsageAccount(usageAccountFromStartCharge));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const getAccounts = async () => {
    const { data } = await api.getCompanyList();
    data.success && setAccounts(data.response);
  };

  useEffect(() => {
    isStartSession && startSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStartSession]);

  const vehicleId = vehicleInUse ?? defaultVehicle?.id

  const startSession = async () => {
    dispatch(setLoading({status: true, message:""}))
    await parkChargeFunctions
      .startPark(
        vehicleId,
        station.entry_id,
        station.DETAIL?.park_id,
        station.TYPE !== "parking",
        parkVipCode,
        usageAccount
        ).then(({data}) => {
        if(data.success) {
          handleModalsStartSession()
        }
      }).finally(() => {
        dispatch(setLoading({status: false, message:""}))
        setIsStartSession(false)
      });
  }

  const handleModalsStartSession = () => {
    dispatch(setStationDetailModal(false))
    setTicketsModalOpen(false)
    dispatch(setSessionModal(true))
    handleOpenModal(false);
  }

  const isPossibleToStartSession = async () => {
    if (!user.email_verify) {
      Alert.error(t("you-should-verification-your-email"));
      return false;
    }

    api.checkStartSessionIsPossible('park', station.entry_id, vehicleId)
    .then(({data}) =>{
      if(data.success){
        if(data.response.park){
          setTicketsModalOpen(true)
        }else{
          data.message && Alert.error(`${(data.message[0].toUpperCase()+data.message.slice(1,))}!`)
        }
      }else{
        data.message && Alert.error(`${(data.message[0].toUpperCase()+data.message.slice(1,))}!`)
        handleOpenModal(false)
      }
    })
    .catch(() => handleOpenModal(false))
  }

  const handleTicketModal = (status) => {
    setTicketsModalOpen(status)
    if(!status) {
      handleValue(0);
      setIsStartSession(false)
      setResetSwipeButtonValue(true)
    }
  }

  return (
    <>
    <div
      className={`bg-[#FCFCFF] flex flex-col justify-between h-full overflow-y-scroll`}
    >
      <SessionModalsToolbar
          title={station.NAME}
          stationLogo={station.DETAIL?.operator_logo }
          dismissModal={() => handleOpenModal(false)}
          breakpoint={breakpoint}
        />
      <div className={`p-2`}>
        <div className="mb-2">
          <div className="w-full flex justify-center items-center text-center">
            <div
              className="flex space-x-2 items-center"
              onClick={() => setReportModalOpen(true)}
            >
              <Icon
                width={23}
                height={20}
                name="photo"
                fill="var(--dt-primary)"
              />
              <Text weight={600} color="var(--dt-primary)" size={14}>
                {t("report-violation")}
              </Text>
            </div>
          </div>
        </div>

        <div
          className={" mt-4 flex justify-center items-center text-center"}
        >
          <div
            className={`carParkImage bg-blue h-[130px] w-[340px] rounded-3xl border-r-[7px] border-[#5E53E1] flex items-center justify-center text-center`}
          >
            <div className="rounded-3xl w-[355px] border-r-[1px] border-t-[1px] border-b-[1px] border-primary border-opacity-20 flex justify-center items-center text-center">
              <img
                src={parkingLights}
                className="parkingLights"
                style={{
                  marginLeft: "14px",
                  transform: `translate(${value - 180}px, -50%)`,
                }}
                alt=""
              />
              <img
                style={{
                  position: "relative",
                  left: `${value >= 210 ? 10 : value - 200}px`,
                }}
                src={Car}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={`h-[500px] mt-4 w-full bg-primary rounded-t-3xl text-white flex flex-col items-center justify-start text-center p-[16px] `}
      >
        <div className="flex flex-col items-center justify-center text-center mb-[5%]">
          <div className="flex space-x-2">
            <img src={ParkIcon} alt="" />
            <span className="text-[14px] font-normal">{t("park")}</span>
          </div>
          <div className="mt-2 ">
            <h3
              className="text-[22px] font-medium"
              dangerouslySetInnerHTML={{ __html: t("swipe-to-start-park") }}
            ></h3>
          </div>
          <div className="mt-2">
          <VehicleSelector setVehicle={id => setVehicleInUse(id)} vehicleInUse={vehicleInUse === null ? defaultVehicle.id : vehicleInUse}/>
          </div>
          <div className="w-full flex justify-center mt-3">

            <SwipeButton
              onEnding={() => isPossibleToStartSession()}
              value={value}
              changeValue={handleValue}
              isReset={resetSwipeButtonValue}
              setReset={setResetSwipeButtonValue}
              type="swipeRight"
            />

          </div>
        </div>
        <AccountSelector
          accounts={accounts}
          isActive={isOpen}
          usageAccount={usageAccount}
        />
      </div>
    </div>
    <IonModal
      isOpen={isReportModalOpen}
      onDidDismiss={() => setReportModalOpen(false)}
    >
      <Report isOpen={isReportModalOpen} setOpen={setReportModalOpen} />
    </IonModal>
    <SelectTicketModal
    isOpen={ticketsModalOpen}
    setOpen={status => handleTicketModal(status)}
    setStartSessionModalOpen={handleOpenModal}
    setIsStartSession={setIsStartSession}
    station={station}
    vehicle={vehicleId}
    />
  </>
  );
}

export default StartParkingOnlyModal
