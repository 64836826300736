import { useTranslation } from "react-i18next";
import DTInput from "../DTInput";
import Button from "../Button";
import { api } from "../../helper/api";
import { setAlert, setLoading } from "../../redux/slices/globalSlice";
import { useState } from "react";
import SiteCodeAgreement from "./SiteCodeAgreement";
import SiteCodeDetailBeforeCodeRegister from "./SiteCodeDetailBeforeCodeRegister";
import { useDispatch } from "react-redux";
import Text from "../Text";

const SiteCode = ({setRefreshCodes}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [siteCodeDetailModal, setSiteCodeDetailModal] = useState(false);
  const [siteCodeDetail, setSiteCodeDetail] = useState(null);
  const [siteCodeAgreementModal, setSiteCodeAgreementModal] = useState(false)
  const [siteCode, setSiteCode] = useState("")
  const [companyCode, setCompanyCode] = useState("")


    const getSiteCodeDetail = (code, companyCode) => {
        dispatch(setLoading({status: true, message: ""}));
        api.getSiteCodeDetail(code, companyCode).then((res) => {
            if (res?.data?.success) {
                setSiteCodeDetail(res.data.data)
                setSiteCodeDetailModal(true);
            } else {
                dispatch(
                    setAlert({status: true, message: t("not-found"), type: "error"})
                );
            }
        }).finally(() => dispatch(setLoading({status: false, message: ""})));
    };

    const submitHandler = (e) => {
        e.preventDefault();
        let code = document.getElementById("siteCode").value;
        let companyCode = document.getElementById("companycode").value;
        getSiteCodeDetail(code, companyCode);
    };

    return (
        <>
            <div className="SiteCode">
                <form onSubmit={(e) => submitHandler(e)}>
                    <div className="flex flex-row mt-[24px] mb-2 gap-2">
                        <p className="text-left m-0 text-primary font-medium">{t("enter-your-site-code")}</p>
                        <span className="m-0 text-[#a1a1a1] font-medium">{t("required")}</span>
                    </div>
                    <DTInput
                        id={"siteCode"}
                        autoFocus
                        required={true}
                        onChange={e => setSiteCode(e.detail.value)}
                    />
                    <div className="flex flex-row mt-[24px] mb-2 gap-2">
                        <p className="text-left m-0 text-primary font-medium">{t("enter-your-company-code")}</p>
                        <span className="m-0 text-[#a1a1a1] font-medium">{t("optional")}</span>
                    </div>
                    <DTInput
                        id={"companycode"}
                        onChange={e => setCompanyCode(e.detail.value)}
                    />
                    <Button disabled={!siteCode} type="submit" theme="primary" margin="32px 0 0">
                        {t("next")}
                    </Button>
                </form>
            </div>
            <SiteCodeDetailBeforeCodeRegister
                isOpen={siteCodeDetailModal}
                setOpen={setSiteCodeDetailModal}
                siteCodeDetail={{code_string: siteCode, detail: siteCodeDetail}}
                openAgreement={setSiteCodeAgreementModal}
                setRefreshCodes={setRefreshCodes}
                companyCode={companyCode}
            />

            <SiteCodeAgreement
                isOpen={siteCodeAgreementModal}
                setOpen={setSiteCodeAgreementModal}/>
        </>
    );
};

export default SiteCode;
