import Icon from "../Icon"
import Text from "../Text"
import { useHistory } from "react-router"

const TicketItem = ({ticket}) => {
    const history = useHistory()
    const goTicketDetail = (item) => {
        history.push(`/tickets/${item.id}`, {
            ticket: item,
            status: formatStatus(item)
        })
    }
    const formatStatus = (ticket) => {
        let date = new Date(ticket.ended_at)
        let today = new Date()
        return today<date
    }
  return (
    <button className='TicketItem' onClick={() => goTicketDetail(ticket)}>
        <div className="flex flex-row gap-[15px] items-center">
            <Icon name="ticket"size={35}/>
            <div className="flex flex-col justify-start items-start">
                <Text
                weight={400}
                size={18}
                >
                <strong>#</strong>{" " + ticket.id}
                </Text>
                <Text
                weight={400}
                size={15}
                >
                    <span className={`TicketAvailability ${formatStatus(ticket) ? "--available" : ""}`}/>
                    {formatStatus(ticket) ? 'Available' : 'Out of date'}
                </Text>
            </div>
        </div>
        <Icon width={6} height={13} name="arrow-right-basic"/>
    </button>
  )
}

export default TicketItem