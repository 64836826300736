import { IonContent } from "@ionic/react";

const SearchResults = ({
  searchResults,
  currentAddress,
  setAddress,
}) => {

  return (
    <div className="AddressList">
      <IonContent scrollY={true} className="no-pading">
      {searchResults.map((address, idx) => (
        <div
          className={`AddressBox ${
            currentAddress?.ssn === address?.ssn
                ? "border-purple"
                : ""
          }`}
          onClick={() => setAddress(address)}
          key={idx}
        >
          {address.firstName} {address.lastName}
          <span>
            {address.street} {address.zipCode} {address.city}
          </span>
        </div>
      ))}
      </IonContent>
    </div>
  );
};

export default SearchResults;
