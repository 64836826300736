import Text from "../Text";
import {useTranslation} from "react-i18next";
import DTInput from "../DTInput";
import {useState} from "react";
import Button from "../Button";
import {api} from "../../helper/api";
import {useDispatch} from "react-redux";
import {setLoading} from "../../redux/slices/globalSlice";
import CarModal from "./CarModal";
import {setVehicles} from "../../redux/slices/userSlice";
import AddNewCarModal from "../../pages/AddNewCarModal";

const CarForm = ({onSubmit}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [plateText, setPlateText] = useState("")
    const [vehicle, setVehicle] = useState({})
    const [vehicleInfoModal, setVehicleInfoModal] = useState(false)
    const [addManualVehicleModal, setAddManualVehicleModal] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        dispatch(setLoading({status: true, message: t("checking-your-license-plate") + "..."}))

        api.getVehicleByPlate(plateText).then((response) => {
            setVehicle(response)
            setVehicleInfoModal(true)
        }).catch(() => {
            setAddManualVehicleModal(true)
        }).finally(() => {
            dispatch(setLoading({status: false, message: ""}))
        });
    }

    const saveHandler = () => {
        setVehicleInfoModal(false)
        setAddManualVehicleModal(false)

        api.getVehicles().then(({data}) => dispatch(setVehicles(data.data ?? [])))
        onSubmit()
    }

    return (
        <div className="CarFormWrapper">
            <form className="SetUpProfileForm AddVehicle" onSubmit={handleSubmit}>
                <Text size="22px" weight="600" align="left" margin="0 14px 0">
                    <div dangerouslySetInnerHTML={{__html: `${t("enter-your-cars-registration-number")}`}}/>
                </Text>

                <DTInput
                    id={"plate"}
                    label={t("plate-number")}
                    margin="36px 0"
                    textTransform="uppercase"
                    onChange={e => setPlateText(e.target.value)}
                />

                <Button type="submit" theme="primary" margin="auto 0 56px"  disabled={!plateText}>
                    {t("find-my-car")}
                </Button>
            </form>

            <CarModal
                isCarModalOpen={vehicleInfoModal}
                setCarModalOpen={setVehicleInfoModal}
                vehicle={vehicle}
                setVehicle={setVehicle}
                currentPlate={plateText}
                saveHandler={saveHandler}
            />

            <AddNewCarModal
                isAddNewCarModalOpen={addManualVehicleModal}
                setAddNewCarModalOpen={setAddManualVehicleModal}
                newCurrentPlate={plateText}
                saveHandler={saveHandler}
            />
        </div>
    )
}

export default CarForm;
