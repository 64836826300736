import { createSlice } from "@reduxjs/toolkit"

export const propSlice = createSlice({
  name: "propSlice",
  initialState: {
    currentStation: {},
    redirectingSessionModalFrom: ""
  },
  reducers: {
    setCurrentStation: (state, action) => {
        state.currentStation = action.payload
    },
  },
})

export const {
    setCurrentStation
} = propSlice.actions
export default propSlice.reducer
