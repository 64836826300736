// Ionic & React
import { useState } from "react"
import {IonModal } from "@ionic/react"

// Components
import PageToolbar from "../components/PageToolbar"

import { useTranslation } from "react-i18next"
import { AboutApp, AboutDrifter, AboutModalContent, PrivacyAndPolicy, TermsAndConditions } from "../components/About"
import ContactModal from "../components/About/ContactModal"

const AboutModal = ({
  isAboutModalOpen,
  setAboutModalOpen,
  className,
  ...props
}) => {

  const { t } = useTranslation()

  const [isAboutAppOpen, setAboutAppOpen] = useState(false)
  const [isAboutDrifterOpen, setAboutDrifterOpen] = useState(false)
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false)
  const [isTermsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false)
  const [isContactModalOpen, setContactModalOpen] = useState(false)

  return (
    <IonModal
      isOpen={isAboutModalOpen}
      canDismiss={true}
      className={`AboutModal modal-white-background${
        className ? " " + className : ""
      }`}
      onDidDismiss={() => setAboutModalOpen(false)}
      {...props}
    >
      <PageToolbar
        title={t("about")}
        leftButtonType="back"
        onClick={() => setAboutModalOpen(false)}
        backgroundColor="#fff"
      />
      {/* Main Content */}
      <AboutModalContent
        setPrivacyPolicyOpen={setPrivacyPolicyOpen}
        setTermsAndConditionsOpen={setTermsAndConditionsOpen}
        setContactModal={setContactModalOpen}
      />
      {/* Modals */}
      <AboutApp
        isOpen={isAboutAppOpen}
        setOpen={setAboutAppOpen}
      />
      <AboutDrifter
        isOpen={isAboutDrifterOpen}
        setOpen={setAboutDrifterOpen}
      />
      <PrivacyAndPolicy
        isOpen={isPrivacyPolicyOpen}
        setOpen={setPrivacyPolicyOpen}
      />
      <TermsAndConditions
        isOpen={isTermsAndConditionsOpen}
        setOpen={setTermsAndConditionsOpen}
      />
      <ContactModal
      isOpen={isContactModalOpen}
      setOpen={setContactModalOpen}
      />
    </IonModal>
  );
}

export default AboutModal
