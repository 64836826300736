import React from 'react'

const ParkSession = ({
    slotNumber,
    plate,
    parkingLights,
    value,
    car
}) => {
    return (
        <div
            className={`flex justify-center 'items-center text-center`}
        >
            <div
                className={`carParkImage carParkImageParking bg-blue h-[130px] w-[340px] rounded-3xl border-r-[7px] border-[#5E53E1] flex items-center justify-center text-center`}
            >
                <div className="rounded-3xl w-[355px] border-r-[1px] border-t-[1px] border-b-[1px] border-primary border-opacity-20 flex justify-center items-center text-center">
                    <div className="flex flex-col gap-2">
                        {slotNumber && <div className="text-[12px] font-medium text-purple bg-white px-[7px] py-[3px] rounded-full">
                            {slotNumber}
                        </div>}
                        <div className="text-[12px] font-medium text-[gray] bg-white px-[7px] py-[3px] rounded-full uppercase">
                            {plate}
                        </div>
                    </div>
                    <img
                        src={parkingLights}
                        className={"parkingLights"}
                        style={{
                            marginLeft: "14px",
                            transform: `translate(${value - 180}px, -50%)`,
                        }}
                        alt=""
                    />
                    <img
                        style={{
                            position: "relative",
                            left: `${value >= 210 ? 10 : value - 200}px`,
                        }}
                        src={car}
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}

export default ParkSession