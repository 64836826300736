// Ionic & React
import { memo } from "react";
import { IonContent, IonModal } from "@ionic/react"

// Components
import PageToolbar from "../components/PageToolbar"
import {ManagePermissions} from "../components";

const ManagePermissionsModal = ({
  isManagePermissionsModalOpen,
  setManagePermissionsModalOpen,
  className,
  ...props
}) => {
  return (
    <IonModal
      isOpen={isManagePermissionsModalOpen}
      canDismiss={true}
      className={`ManagePermissionsModal modal-white-background${className ? " " + className : ""}`}
      onDidDismiss={() => setManagePermissionsModalOpen(false)}
      {...props}
    >
      <PageToolbar
        title="Permissions"
        leftButtonType="back"
        onClick={() => setManagePermissionsModalOpen(false)}
        backgroundColor="#fff"
      />

      <IonContent>
        <ManagePermissions />
      </IonContent>
    </IonModal>
  )
}

export default memo(ManagePermissionsModal)