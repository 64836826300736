const ParkingStationTitle = ({title}) => {
    return (
        <div className="flex flex-col text-center justify-center items-center mt-[22px]">
            <h3 className="font-semibold text-primary text-[22px]">
            {title}
            </h3>
        </div>
    )
}

export default ParkingStationTitle