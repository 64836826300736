import { createSlice } from "@reduxjs/toolkit"

export const filterStationSlice = createSlice({
  name: "filterStationSlice",
  initialState: {
    stationFilter: {
      is_parking: false,
      is_charging: false,
      is_park_and_charge: false,
      charge_speed: "",
      keyword: "",
      vehicle_id: null,
      only_available: false,
      outdoor: false,
      indoor: false,
      publicFilter: false,
      privateFilter: false,
      min_rate: ''
    },
    mapFilter:"MAP_PARKING"


  },
  reducers: {
    setStationFilter: (state, action) => {
      state.stationFilter = {
        ...state.stationFilter,
        ...action.payload
      }
    },
    setMapFilter: (state, action) => {
      state.mapFilter = action.payload
    }
  },
})

export const {
  setStationFilter,
  setMapFilter
} = filterStationSlice.actions
export default filterStationSlice.reducer
