// Ionic & React
import { memo, useState, useEffect } from "react"
import {IonButton} from "@ionic/react"

// Plugins
import styled from "styled-components"
import axios from "axios"

// Capacitor
import { Capacitor } from "@capacitor/core"

// Components
import Icon from "../components/Icon"
import Text from "../components/Text"
import Logo from "./Logo"
import { safeArea } from "../assets/theme/ios-safe-area"
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions"
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic"
import { LocationAccuracy } from "@awesome-cordova-plugins/location-accuracy"
import { t } from "i18next"
import {setLoading} from "../redux/slices/globalSlice";
import {parkChargeFunctions} from "../helper/parkChargeFunctions";
import {Alert} from "../helper/alert";
import {useDispatch,useSelector} from "react-redux";
import {BarcodeScanner} from "@awesome-cordova-plugins/barcode-scanner";
import { setMenuModal,setSearchModal, setFilterModal } from "../redux/slices/modalSlice"

const DTToolbar = styled("div")`
  pointer-events: none;
  position: fixed;
  inset: 0;
  width: 100vw;
  background: transparent;
  z-index: 200;

  opacity: 0;
  transform: translateY(-200%);

  animation: toolbarSlideDown 0.15s ease-out forwards;
  animation-delay: 1s;

  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 120px;
    width: 100vw;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 8%,
      rgba(255, 255, 255, 0.88) 70%
    );
  }

  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 120px;
    width: 100vw;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 8%,
      rgba(255, 255, 255, 0.88) 70%
    );
  }

  &.toolbar-hidden {
    transform: translateY(-200%);
  }

  .DTToolbarWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100vw;
    width: 100vw;
    padding: 12px 16px 0;
    background-color: transparent;

    ${safeArea}
  }

  .ToolbarSideButtons {
    position: absolute;
    right: 16px;
    bottom: 188px;
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    transition: all 0.1s ease-out;

    opacity: 0;
    transform: translateX(200%);

    animation: toolbarSlideLeft 0.15s ease-out forwards;
    animation-delay: 1.2s;
  }

  .Icon {
    pointer-events: all;
  }

  .ToolbarMiddle {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
  }

  .ToolbarMiddleText {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--dt-primary);
    white-space: nowrap;
  }

  .ToolbarMiddleWeather {
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dt-primary);
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    transition: all 0.1s ease-out;
  }
  .WeatherWrapper {
    opacity: 0;
    position: absolute;
    top: 21px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dt-primary);
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    transition: all 0.1s ease-out;
  }
  .ToolbarMiddleWeather .icon + span {
    margin-left: 8px;
  }

  .ToolbarMiddleLogo {
    opacity: 0;
    position: absolute;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.1s ease-out;
    z-index: 9;
  }

  .ToolbarMiddle.WEATHER .ToolbarMiddleText {
    opacity: 1;
    top: 0px;
    z-index: 9;
  }
  .ToolbarMiddle.WEATHER .ToolbarMiddleWeather {
    opacity: 1;
    top: 0px;
    z-index: 9;
  }
  .ToolbarMiddle.WEATHER .ToolbarMiddleWeather .WeatherWrapper {
    opacity: 1;
    top: 0px;
    z-index: 9;
  }
  .ToolbarMiddle.WEATHER .ToolbarMiddleWeather .WeatherWrapperWithText {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    top: 21px;
    z-index: 9;
  }
  .ToolbarMiddle.DRIFTER .ToolbarMiddleLogo {
    opacity: 1;
    top: unset;
    z-index: 9;
  }
`

const DTToolbarButton = styled(IonButton)`
  height: 48px;
  width: 48px;
  margin: 0;
  --background-activated: #ededed;
  --background-focused: unset;
  ::part(native) {
    --background: #fff;
    --box-shadow: 0 2px 17px 0 rgba(0, 0, 0, 0.1);
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --color: var(--dt-primary);

    pointer-events: all;
    height: 48px;
    width: 48px;
    margin: 0;
    border-radius: 14px;
  }

  svg {
    flex-shrink: 0;
  }
`

const Toolbar = ({
  setCenter,
  setPos,
  firstName,
  getCoords,
  setZoom,
  pos,
  setGetCoords,
  setLocationButton,
  setChargingModal,
  setParkChargeModalOpen
}) => {
  const [toolbarMiddleCurrent, setToolbarMiddleCurrent] = useState("WEATHER")
  const [weather, setWeather] = useState({ weatherIcon: "", weatherDegree: "" })
  const [intervalIndex, setIntervalIndex] = useState(0)
  const [initialIndex, setInitialIndex] = useState(true);
  const API_KEY = process.env.REACT_APP_WEATHER_API_KEY
  const intervalArray =["WEATHER","DRIFTER"]
  const { defaultVehicle, usageAccount } = useSelector((state) => state.userSlice);
  const dispatch = useDispatch();
  // TODO: Temp fix

  const askToTurnOnGPS = () => {
    LocationAccuracy.request(
      LocationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY
    ).then(() => {
      printCurrentPosition()
      // When GPS Turned ON call method to get Accurate location coordinates
    })
  }

  const checkGps = () => {
    let successCallback = (isAvailable, platform) => {
      if(platform) {
        if (!isAvailable) {
          AndroidPermissions.checkPermission(
            AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION
          ).then((result) => {
            if (result.hasPermission) {
              askToTurnOnGPS()
            }
          })
        } else {
          // dispatch(setLoading({ status: true, message: t("getting-location") }))
          printCurrentPosition()
        }
      }else {
        if(isAvailable){
          // dispatch(setLoading({ status: true, message: t("getting-location") }))
          printCurrentPosition()
        }else{
          askToTurnOnGPS()
        }
      }
    }
    let errorCallback = (err) => console.log(err)

    if(Capacitor.isNativePlatform()){
      Diagnostic.isLocationAvailable().then(res => successCallback(res)).catch(errorCallback)
    }else{
      navigator.permissions.query({ name: 'geolocation' }).then(res => successCallback(res.state === "granted", Capacitor.isNativePlatform())).catch(errorCallback)
    }
  }
  const printCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(handleCoordinates,(err)=>{console.log(err)},
        {
          maximumAge: 30000,
          timeout: 5000,
          enableHighAccuracy: false
        })
  }

  const handleCoordinates = (coords) => {
    //dispatch(clearLoader())
    const timeout = setTimeout(() => {

      let newCoords = coords.coords;

        setLocationButton(true);
        getWeather(newCoords)

        setPos({
          lat: newCoords.latitude,
          long: newCoords.longitude
        })

        setCenter({
          lat: newCoords.latitude,
          long: newCoords.longitude,
        })

      setZoom(15)
      setTimeout(()=>{
        setLocationButton(false)
      },500)
    }, 200)

    setGetCoords(true)
    return () => clearTimeout(timeout)
  }

  const getWeather = (coords) => {
    axios
      .get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${coords.latitude}&lon=${coords.longitude}&appid=${API_KEY}&units=metric`
      )
      .then((res) => {
        setWeather({
          weatherIcon: res.data.weather[0].description,
          weatherDegree: Math.floor(res.data.main.temp),
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    const interval = setInterval(() => {
        setIntervalIndex(previous => previous + 1)
        setInitialIndex(false);
    }, 6500)
    getWeather({latitude:pos.lat,longitude:pos.long});

    return (() => {
      clearInterval(interval)
      clearTimeout()
    })
    // eslint-disable-next-line
  }, [])

  useEffect(()=> {
    if(intervalIndex%3===0){
      setIntervalIndex(0)
      setToolbarMiddleCurrent(intervalArray[0])
    }else{
      setToolbarMiddleCurrent(intervalArray[1])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalIndex])


  const welcomeText = () => {
    var day = new Date(),
      h = day.getHours()

    if (h >= 5 && h < 12) return "good-morning"
    else if (h === 12) return "good-noon"
    else if (h >= 12 && h <= 17) return "good-afternoon"
    else return "good-evening"
  }
  const [isScanActive, setScanActive] = useState(false)
  const startScan = () => {
    if(!Capacitor.isNativePlatform()) return;
    setScanActive(true)
    BarcodeScanner.scan()
    .then((barcodeData) => {
      if (!barcodeData.cancelled) {
        startChargeSession(barcodeData.text)
      }
    }).finally(() => setScanActive(false))
  };

  
  const startChargeSession = async (codeId) => {
    if(!defaultVehicle) {
      Alert.error(t("add-vehicle-first"))
    }else{
      dispatch(setLoading({ status: true, message: t("starting-session") }))
      //TODO::Usage Account
      await parkChargeFunctions.startCharge('', defaultVehicle.id, false,codeId,usageAccount).then(({data}) => {
        if(data.success) {
          if (data.response.transaction.park_session_id)
          {
            setParkChargeModalOpen(true)
          }else{
            setChargingModal(true)
          }
        } else {
          Alert.error(
              `${(data.message[0].toUpperCase()+data.message.slice(1,))}!`
          );
        }
      })
      dispatch(setLoading({status: false, message: ""}))
    }
  }

  return (
    <DTToolbar
      className={`Toolbar`}
    >
      <div className="DTToolbarWrapper">
        <DTToolbarButton type="submit" onClick={() => dispatch(setSearchModal(true))}>
          <Icon name="search" size={20} fill="var(--dt-primary)" />
        </DTToolbarButton>

        <div className={`ToolbarMiddle ${toolbarMiddleCurrent}`}>
          <div className="ToolbarMiddleWeather">
            {firstName && initialIndex ? (
              <Text cssClass="ToolbarMiddleText" size="13px" weight="400">
                <span className="capitalize">{`${t(welcomeText())}, `}</span>
                <Text size="13px" weight="600">
                  {firstName}!
                </Text>
              </Text>
            ) : (
              ""
            )}
            {weather.weatherDegree ? (
              <div className={`${initialIndex?'WeatherWrapperWithText':'WeatherWrapper'}`}>
                <Icon
                  name={`weather-${weather.weatherIcon.replace(" ", "-")}`}
                  size={28}
                  fill="#fff"
                />
                <span>{weather.weatherDegree}° C</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <Logo
            className="ToolbarMiddleLogo"
            type="LogotypeDark"
            width={65}
            height={18}
          />
        </div>

        <DTToolbarButton id="toolbar-menu" onClick={() => dispatch(setMenuModal(true))}>
          <Icon name="menu" height={18} width={20} fill="var(--dt-primary)" />
        </DTToolbarButton>

        <div className="ToolbarSideButtons">
          <DTToolbarButton onClick={() => dispatch(setFilterModal(true))}>
            <Icon name="filter" size={20} fill="var(--dt-primary)" />
          </DTToolbarButton>

          <DTToolbarButton
            onClick={() => {
              checkGps();
              /*  console.log(pos.lat)
              console.log(pos.long)
              setPos({
                lat: pos.lat * 0.001,
                long: pos.long + 0.001,
              })
              setTimeout(() => {
                setPos({
                  lat: pos.lat,
                  long: pos.long,
                })
              }, 200)*/
            }}
          >
            <Icon
              name="scan"
              size={18}
              fill={getCoords ? "var(--dt-primary)" : "red"}
            />
          </DTToolbarButton>
          <DTToolbarButton onClick={() => !isScanActive ? startScan() : Alert.warning(t("scanner-active"))}>
            <Icon name="toolbar-scan" size={20} fill="var(--dt-primary)" />
          </DTToolbarButton>

        </div>
      </div>
    </DTToolbar>
  );
}

export default memo(Toolbar)
