//Ionic
import { IonSelect, IonSelectOption } from '@ionic/react';
//Helpers
import { t } from 'i18next';
//Components
import Text from './Text';
//Redux
import { useDispatch } from 'react-redux';
import {setUsageAccount} from "../redux/slices/userSlice";

const AccountSelector = ({ usageAccount, accounts }) => {
  const interfaceOptions = {
    header: t("select-usage-account"),
    cssClass: "AccountSelectorAlert"
  }
  const dispatch = useDispatch()
  return (
    <div className='AccountSelectorWrapper'>
      <Text color="#C0C0CB" weight={500} size={14} cssClass="capitalize">
        {t("usage")}:
      </Text>

      <IonSelect
      name="AccountSelector"
      className="AccountSelector"
      onIonChange={(e) => {
          dispatch(setUsageAccount(e.target.value));
      }}
      value={usageAccount}
      okText='OK'
      interfaceOptions={interfaceOptions}
      >
        <IonSelectOption value="personal">{t("personal")}</IonSelectOption>
        {
          !accounts || accounts.length === 0 ? (<IonSelectOption value="business">{t("business")}</IonSelectOption>) : (
            accounts?.map((ac) => (
              <IonSelectOption value={ac.company.id} key={ac.company.id}>
                {ac.company.company_name}
              </IonSelectOption>
            ))
          )
        }
        
      </IonSelect>
    </div>
  );
};

export default AccountSelector;
