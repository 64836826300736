import Icon from './Icon';

const CloseSessionModalButton = ({onButtonClick, cssClass, breakpoint}) => {
    return (
        <button className={cssClass} onClick={onButtonClick}>
            <Icon
            name={breakpoint === 1 ? "close-modal" : "close-modal-arrow"}
            size={32}
            />
        </button>
    );
}

export default CloseSessionModalButton;
