import { configureStore } from "@reduxjs/toolkit"
import CapacitorStorage from 'redux-persist-capacitor';
import {globalSlice, preferencesSlice, filterStationSlice, userSlice, modalSlice, propSlice} from "./slices";
import { persistCombineReducers, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";

const persistConfig = {
  key: 'root',
  storage: CapacitorStorage,
  stateReconciler: autoMergeLevel1,
  blacklist: ['globalSlice', 'modalSlice','filterStationSlice', 'propSlice']
};

const _persistedReducer = persistCombineReducers( persistConfig, {
  globalSlice,
  userSlice,
  preferencesSlice,
  filterStationSlice,
  modalSlice,
  propSlice
});

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH,
        REHYDRATE,
        PAUSE,
        PERSIST,
        PURGE,
        REGISTER
      ],
    },
  })
})

export const persistor = persistStore(store)
