// Ionic & React
import { useState, useEffect, useRef } from "react"
import { IonContent, IonModal, IonButton } from "@ionic/react"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { setPhone, setCountry } from "../redux/slices/userSlice"

// Plugins
import styled from "styled-components"

// React Phone Number Input
import "react-phone-number-input/style.css"
import Input from "react-phone-number-input/input"
import { isValidPhoneNumber } from "react-phone-number-input"

// Components
import CountryCodes from "./CountryCodes"
import Text from "./Text"
import {useTranslation} from "react-i18next";

const DTPhoneInputWrapper = styled("div")`
  position: relative;
  display: flex;
  flex-shrink: 0;
  height: 55px;
  width: 100%;
  border: 1px solid var(--dt-primary-300);
  border-radius: 12px;
  background-color: #fff;
  transition: all 0.1s ease-out;

  &.focus {
    border-color: var(--dt-pink);
  }

  & input:focus {
    color: var(--dt-primary);
  }

  &.success input {
    color: var(--dt-primary);
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5Z' fill='%2338BC8E'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.1515 8.07917C14.4944 8.43901 14.4807 9.00869 14.1209 9.35159L10.3845 12.9121C10.0137 13.2655 9.43074 13.2655 9.05993 12.9121L6.8791 10.8339C6.51926 10.491 6.50553 9.92136 6.84844 9.56152C7.19134 9.20169 7.76102 9.18796 8.12086 9.53086L9.7222 11.0568L12.8791 8.04851C13.2389 7.70561 13.8086 7.71933 14.1515 8.07917Z' fill='white'/%3E%3C/svg%3E%0A");
  }
  &.error input {
    color: var(--dt-error);
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 10.5C21 16.299 16.299 21 10.5 21C4.70101 21 0 16.299 0 10.5C0 4.70101 4.70101 0 10.5 0C16.299 0 21 4.70101 21 10.5Z' fill='%23E03232'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.88127 6.88123C7.2718 6.4907 7.90496 6.4907 8.29549 6.88123L10.5 9.08576L12.7045 6.88127C13.095 6.49075 13.7282 6.49075 14.1187 6.88127C14.5092 7.2718 14.5092 7.90496 14.1187 8.29549L11.9142 10.5L14.1187 12.7044C14.5092 13.0949 14.5092 13.7281 14.1187 14.1186C13.7281 14.5092 13.095 14.5092 12.7045 14.1186L10.5 11.9142L8.29553 14.1187C7.90501 14.5092 7.27184 14.5092 6.88132 14.1187C6.49079 13.7281 6.49079 13.095 6.88132 12.7045L9.0858 10.5L6.88127 8.29544C6.49075 7.90492 6.49075 7.27175 6.88127 6.88123Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  &.success{
    border-color: var(--dt-success);
  }
  &.error {
    border-color: var(--dt-error);
  }

  &.success input,
  &.error input {
    padding: 0 46px 0 20px;
    background-position: calc(100% - 20px) center;
    background-size: 22px;
    background-repeat: no-repeat;
  }
`

const DTToggleModal = styled(IonButton)`
  --ripple-color: transparent;
  --background-activated: #ededed;
  cursor: pointer;
  position: relative;
  height: 100%;
  border: none;
  border-right: 1px solid var(--dt-primary-300);
  width: fit-content;
  margin: 0;
  padding: 0 38px 0 14px;
  transition: all 0.1s ease-out;

  &::part(native) {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: var(--dt-primary);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: initial;
    text-transform: none;
    box-shadow: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    height: 6px;
    width: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L0.292894 1.70711C-0.0976312 1.31658 -0.0976312 0.683418 0.292894 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.58579L8.29289 0.292894C8.68342 -0.0976304 9.31658 -0.0976304 9.70711 0.292894C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L5.70711 5.70711Z' fill='%23353355'/%3E%3C/svg%3E%0A");
  }
`

const DTInput = styled(Input)`
  height: 100%;
  width: 100%;
  padding: 0 20px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--dt-primary);
  font-size: 16px;
  font-weight: 400;
`

const DTPhoneInput = ({ border, setBorder, setPhoneValid, ...props }) => {
  const [showModal, setShowModal] = useState(false)
  
  const { phone, country } = useSelector((state) => state.userSlice)

  const dispatch = useDispatch()

  const handleClick = async (country) => {
    dispatch(setPhone(""))
    dispatch(setCountry(country))
    setShowModal(false)
    phoneInputRef.current.focus()
  }

  const handleChange = (value) => {
    dispatch(setPhone(value))
    if (typeof value === "string" && isValidPhoneNumber(value)) {
      setPhoneValid(true)
    } else setPhoneValid(false)
  }

  const handleFocus = () => {
    setBorder("focus")
  }

  const handleBlur = () => {
    if (phone) {
      if (!isValidPhoneNumber(phone)) setBorder("")
    }
    checkValid()
  }

  const checkValid = () => {
    if (phone) {
      if (isValidPhoneNumber(phone)) setBorder("success")
      else setBorder("error")
    } else {
      setBorder("")
    }
  }

  const phoneInputRef = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      phoneInputRef.current?.focus()
    })
    // eslint-disable-next-line
  }, [])

  const {t} = useTranslation();

  return (
    <>
      <Text
        margin="0 auto 7px 10px"
        size="14px"
        weight="600"
        color="var(--dt-dark)"
      >
        {t("phone-number")}
      </Text>

      <DTPhoneInputWrapper className={`${border}`}>
        <DTToggleModal onClick={() => setShowModal(!showModal)}>
          <div className="flagPlaceholder">
            +{country.countryCode}
          </div>
        </DTToggleModal>

        <DTInput
          international={false}
          country={country.countryShortName}
          value={phone}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          ref={phoneInputRef}
          autoFocus
          placeholder="123 456 7890"
          {...props}
        />
      </DTPhoneInputWrapper>

      {/* Country Codes Modal */}
      <IonModal
        isOpen={showModal}
        breakPoints={[0.1, 0.85, 1]}
        initialBreakpoint={0.85}
        canDismiss={true}
        handle={true}
        onDidDismiss={() => setShowModal(false)}
      >
        <IonContent>
          <CountryCodes currentCountry={country} handleClick={handleClick} />
        </IonContent>
      </IonModal>
    </>
  )
}

export default DTPhoneInput
