import React, {useEffect, useState} from 'react';
import Menu from '../pages/Menu';
import BuyTicketModal from './Tickets/BuyTicketModal';
import EditEmailModal from './MyProfile/EditEmailModal';
import Receipts from "./ReceiptComponents";
import {ActiveSessions} from "./index";
import {useSelector} from "react-redux";

const Modals = () => {
  const [receipt, setReceipt] = useState(null);
  const {sessionModal} = useSelector((state) => state.modalSlice)
  const [render, setRender] = useState({session: false, receipt: false})

  const debounce = (callback) => {
    const timeout = setTimeout(callback, 500)
    return () => clearTimeout(timeout)
  }

  useEffect(() => {
    if (!sessionModal.status) {
      debounce(() =>
        setRender((prev) => ({ session: false, receipt: prev.receipt }))
      );
    } else {
      setRender((prev) => ({ session: true, receipt: prev.receipt }));
    }
  }, [sessionModal]);

  useEffect(() => {
    if (!receipt) {
      debounce(() =>
        setRender((prev) => ({ session: prev.session, receipt: false }))
      );
    } else {
      setRender((prev) => ({ session: prev.session, receipt: true }));
    }
  }, [receipt]);

  return (
    <>
      <Menu/>
      <BuyTicketModal/>
      <EditEmailModal/>

      {render.session && <ActiveSessions setReceipt={setReceipt} />}
      {render.receipt && <Receipts receipt={receipt} setReceipt={setReceipt}/>}
    </>
  )
}

export default Modals;
