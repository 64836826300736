//Ionic & React
import {useState} from "react";
import {IonContent, IonModal, useIonAlert} from "@ionic/react";
//Components
import DTInput from "../DTInput";
import PageToolbar from "../PageToolbar";
//Helpers
import {api} from "../../helper/api";
import {useTranslation} from "react-i18next";
import {getPerson} from "../../helper/globalFunctions";
//Redux
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../redux/slices/globalSlice";
import {setEditEmailModal} from "../../redux/slices/modalSlice";

const EditEmailModal = () => {

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [showAlert] = useIonAlert()
  const [newEmail, setNewEmail] = useState("");
  const {user} = useSelector(state => state.userSlice)
  const {editEmailModal} = useSelector(state => state.modalSlice)

  const updateEmail = (e) => {
    api.editPerson(
      {"options": {"parameters":
              {
                "first_name":user.first_name,
                "last_name":user.last_name,
                "email": e,
              }
      }}).then(() => {
        getPerson().then(() => dispatch(setEditEmailModal(false)));
      }).catch(() => {
        showAlert({
          message: t("error-message"),
        })
      }).finally(() => dispatch(setLoading({status: false, message: ""})))
  }

  const handleSubmit = () => {
    if (newEmail) {
      dispatch(setLoading({status: true, message: t("updating-email")}))
      updateEmail(newEmail)
    }
  }

  return (
    <IonModal
      className="modal-white-background"
      isOpen={editEmailModal.status}
      onDidDismiss={() => editEmailModal.status && dispatch(setEditEmailModal(false))}
    >
      <PageToolbar
        title="Edit e-mail"
        leftButtonType="back"
        leftButtonOnClick={() => dispatch(setEditEmailModal(false))}
        backgroundColor="#fff"
      />
      <IonContent className="">
          <p className="text-[14px] font-normal mb-[29px] text-primary">
            {t("verify-email-after-editing-desc")}
          </p>
          {/* TODO: Refactor */}
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 50px)",
            }}
          >
            <DTInput
            type="email"
            id="edit_email"
            onChange={e => setNewEmail(e.detail.value)}
            label="E-mail"
            />
            <button className="DTButton" type="button"
            onClick={() => handleSubmit()}
            style={{
              backgroundColor: "#353355",
              margin: "auto 0 0" 
            }}>
              {t("update")}
            </button>
          </form>
      </IonContent>
    </IonModal>
  );
}

export default EditEmailModal;
