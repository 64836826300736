import { memo } from "react"

const Marker = ({
  lat,
  lng,
  children,
  className,
}) => {
  return (
    <div lat={lat} lng={lng} animation={null} className={className}>
      {children}
    </div>
  )
}

export default memo(Marker)
