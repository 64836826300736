const SearchResultItem = ({
    onClick,
    title,
    distance,
    stationType,
    zoneCode
}) => {
    return (
        <>
            <div
            className="Result"
            onClick={() => onClick()}
            >
                <span className={`ResultIcon -${stationType}`} />
                <div>
                    <div className="flex flex-row justify-between">
                        <div className="ResultTitle">
                            {title}
                        </div>
                        {zoneCode ? <div className="ResultZoneCode">
                            {zoneCode}
                        </div> : null}
                    </div>
                    <div className="ResultDistance">
                        {distance.toFixed(2)} km
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchResultItem;
