import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const availableLanguages = ['en', 'tr', 'sv', 'de'];

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		detection: {
			checkWhitelist: true,
		},
		whitelist: availableLanguages,
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false
		}
	});


export default i18n;
