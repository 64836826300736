import {IonSlide, useIonAlert} from "@ionic/react";
import DTInput from "../DTInput";
import Button from "../Button";
import {useState, useEffect} from "react";
import {api} from "../../helper/api";
import Text from "../Text";
import {useDispatch, useSelector} from "react-redux";

import Loader from '../../assets/images/Loader-anim.png';
import {useTranslation} from "react-i18next";
import {clearLoader, setAlert, setLoading} from "../../redux/slices/globalSlice";
import {getPerson} from "../../helper/globalFunctions";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router";

const SetProfile = ({
                        setUpProfileRef,
                        setActiveIndex,
                        setAddresses,
                        setMailSentModalOpen,
                        isEdit = false,
                        setAddressesLoading,
                        onSubmitProfile
                    }) => {

    //TODO: refactor here

    const history = useHistory()
    const {
        reset,
        register,
        handleSubmit,
        formState: {errors}
    } = useForm();

    const {user} = useSelector((state) => state.userSlice);
    const [showAlert] = useIonAlert()
    const dispatch = useDispatch();
    const [isCustomLoading, setCustomLoading] = useState(false)
    const {t} = useTranslation();

    useEffect(() => {
        reset({
            first_name: user.first_name,
            last_name: user.last_name,
            ssn: user.ssn,
            email: user.email
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onSubmit = person => {
        if (!!onSubmitProfile) {
            onSubmitProfile(person)
            return;
        }

        api.userEdit(person).then(async ({data}) => {
            if (data.success) {
                if (isEdit) {
                    await getPerson();
                    history.push("/map", {
                        menuModal: true,
                        myProfileModal: true,
                    });
                    return;
                }
                getPerson()
                const addressList = await getUserInformationFromGovernment(person)
                setCustomLoading(true)
                if (addressList.success) {
                    setAddresses(addressList.response)
                    setUpProfileRef.current.swiper.slideTo(1)
                    setActiveIndex && setActiveIndex(1)
                } else {
                    setUpProfileRef?.current?.swiper.slideTo(2)
                    setActiveIndex && setActiveIndex(2)
                }
                setCustomLoading(true)
                setMailSentModalOpen(true)
            } else {
                // for "E-mail already exists!"
                dispatch(setAlert({status: true, message: `${data.message}!`, type: "error"}))
            }
        })
    }
    const updatePP = async (e) => {
        dispatch(setLoading({status: true, message: t("avatar-uploading") + "..."}))
        api.updateProfilePhoto(e.target.files).catch(() => {
            showAlert({
                message: t("an-error-occurred"),
            })
        }).finally(() => {
            dispatch(clearLoader())
        })
    }
    const getUserInformationFromGovernment = async (person) => {
        setAddressesLoading && setAddressesLoading(true)
        try {
            const response = await api.getUserInformationFromGovernment(person)
            return response.data
        } catch (error) {
            !isEdit && setUpProfileRef.current.swiper.slideTo(2)
        } finally {
            setAddressesLoading && setAddressesLoading(false)
        }
    }
    const Loading = () => (
        <div>
            <img src={Loader} alt="" className="animate-spin loader w-[78px] h-[78px]"/>
        </div>
    )

    if (isCustomLoading) return (<Loading/>);

    return (
        <IonSlide>
            <div className="SetUpProfileForm p-[10px] pt-[40px]">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label className="SetUpProfileImage" htmlFor="uploadProfileImage">
                        {user.profile_photo_url ? <img
                            id={"profile_photo"}
                            src={user.profile_photo_url}
                            alt="User Name"
                            draggable={false}
                        /> : null}
                        <label htmlFor="uploadProfileImage">
                            <input
                                onChange={(e) => updatePP(e)}
                                type="file"
                                accept="image"
                                id="uploadProfileImage"
                            />
                        </label>
                    </label>

                    {!isEdit &&
                        <>
                            <DTInput label={t("YYYYMMDDNNNN")} register={register("ssn")} margin="30px 0 0 0"/>
                            <Text>{t("or")}</Text>
                            <hr className={"hr-gray w-1/2  flex m-auto"}/>
                        </>
                    }


                    <DTInput
                        label={t("firstname")}
                        margin={"22px 0 0"}
                        register={register("first_name")}
                        autoFocus
                    />

                    <DTInput
                        label={t("lastname")}
                        register={register("last_name")}
                        margin="32px 0 0"
                    />

                    {!isEdit &&
                        <DTInput
                            label={t("email")}
                            type={"email"}
                            register={register("email", {
                                required: t("email-is-required"),
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: t("type_valid_email")
                                }
                            })}
                            margin="32px 0 0"
                            errorText={errors.email?.message}
                        />
                    }


                    <Button theme="primary" margin="32px 0 0" type={'submit'}>
                        {isEdit ? t("submit") : t("next")}
                    </Button>
                </form>
            </div>
        </IonSlide>

    )
}

export default SetProfile
