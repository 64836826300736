import {api} from "../../helper/api";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLoading} from "../../redux/slices/globalSlice";
import {IonContent, IonModal, useIonRouter} from "@ionic/react";
import Text from "../Text";
import {useTranslation} from "react-i18next";
import Button from "../Button";
import {setDefaultVehicle, setVehicles} from "../../redux/slices/userSlice";
import {Alert} from "../../helper/alert";
import CarForm from "../NewCar/CarForm";

const SetVehicle = ({activeIndex}) => {
    const dispatch = useDispatch();
    const router = useIonRouter()
    const {t} = useTranslation();
    const {user, vehicles} = useSelector((state) => state.userSlice);
    const [cars, setCars] = useState([]);
    const [selectedCars, setSelectedCars] = useState([])
    const [addCarModal, setAddCarModal] = useState(false)

    useEffect(() => {
        activeIndex === 3 && getVehicles();
    }, [activeIndex]);

    const getVehicles = () => {
        dispatch(setLoading({status: true, message: "Loading..."}));

        api.getVehiclesByApi().then(({data}) => {
            if (data.success && data.response[0].vehicles.data.length > 0) {
                setCars(data.response[0].vehicles.data);
                setAddCarModal(true);
            }
        }).finally(() => dispatch(setLoading({status: false, message: ""})));
    }

    const handleAddCarChange = (regno) => {
        if (selectedCars.includes(regno)) {
            setSelectedCars(selectedCars.filter((item) => item !== regno))
        } else {
            setSelectedCars([...selectedCars, regno])
        }
    }

    const addVehicle = (plates) => {
        dispatch(setLoading({status: true, message: ""}));

        api.addMultipleVehicle({options: {parameters: {plate_array: plates, update_data: true}}}).then(({data}) => {
            if (data.success) {
                dispatch(setVehicles([...vehicles, ...data.response.vehicles]))
                dispatch(setDefaultVehicle(data.response.vehicles.find(item => item.is_default === 1)))
                setAddCarModal(false);
                router.push("map", "forward")
            } else {
                Alert.error(data.message)
            }
        }).finally(() => dispatch(setLoading({status: false, message: ""})));
    }


    return (
        <>
            <CarForm isProfileRedirect={false} onSubmit={() => {
                router.push("map", "forward")
            }}/>

            <IonModal
                isOpen={addCarModal}
                breakpoints={[0, 0.7, 1]}
                initialBreakpoint={1}
                handle={false}
                cssClass="DTModal AddCarModal"
                onDidDismiss={() => setAddCarModal(false)}
            >
                <IonContent>
                    <button className="DTModalCloseButton" onClick={() => setAddCarModal(false)}/>

                    <Text size="24px" weight="600">
                        {t("add-your-cars")}
                        {" "}
                        <Text
                            weight="500"
                            color={`${selectedCars > 0 ? "" : "var(--dt-primary-300)"}`}
                        >
                            ({selectedCars.length})
                        </Text>
                    </Text>

                    <div className="AddCarModalTop">
                        {user.first_name} {user.last_name}
                        <span>
                          {user.street} {user.zipCode} {user.city}
                        </span>
                    </div>

                    <div className={"AddCarModalContent"}>
                        <div className="AddCarCheckboxGroup">
                            {
                                cars.map((car, index) => (
                                    <div className="AddCarCheckboxWrapper" key={index}>
                                        <input type="checkbox"
                                               checked={selectedCars.includes(car.attributes.regno)}
                                               onChange={() => handleAddCarChange(car.attributes.regno)}
                                        />

                                        <div className="AddCarCheckbox">
                                            <div className="CarLogo">
                                                <img
                                                    src={car.basic.data.logo}
                                                    alt={car.basic.data.brand_name}
                                                />
                                            </div>
                                            <div className="AddCarCheckboxContent">
                                                <Text size="20px" weight="700">
                                                    {car.basic.data.brand_name}
                                                </Text>
                                                <Text size="16px" weight="500" margin="6px 0 0">
                                                    {car.basic.data.response_model}
                                                </Text>
                                                <div className="AddCarCheckboxPlate">
                                                    {car.attributes.regno}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>

                        <Button
                            theme="primary"
                            margin="40px 0 0"
                            onClick={() => addVehicle(selectedCars)}
                            disabled={selectedCars.length === 0}
                        >
                            {t("save-selected-cars")}
                        </Button>

                        <Button
                            theme="primary"
                            margin="5px 0 0"
                            onClick={() => addVehicle(cars.map(item => item.attributes.regno))}
                        >
                            {t("save-all-cars")}
                        </Button>
                    </div>
                </IonContent>
            </IonModal>
        </>
    )
}

export default SetVehicle
