import { useTranslation } from "react-i18next";
import OperatorItem from "./OperatorItem";

const OperatorList = ({operators, handleClick, choosenOperator}) => {
    const {t} = useTranslation();
    const allOperator = {
        id: 0,
        title: t("all")
    }
    return (
      <div className="OperatorList">
        <OperatorItem
          operator={allOperator}
          handleClick={handleClick}
          choosenOperator={choosenOperator}
        />
        {operators &&
          operators.map((operator) => (
            <OperatorItem
              key={operator.id}
              operator={operator}
              handleClick={handleClick}
              choosenOperator={choosenOperator}
            />
          ))}
      </div>
    );
}

export default OperatorList;
