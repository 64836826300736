import { useTranslation } from "react-i18next";
import DisabledIcon from "../../../assets/images/disabled.png"
import Text from '../../Text';
const Disabled = () => {
    
    const {t} = useTranslation();

    // TODO: Design will change
    return (
        <div className='DisabledWrapper'>
            <div className='DisabledIconWrapper'>
                <img src={DisabledIcon} alt="DisabledIcon" />
            </div>
            <div className='DisabledInfo'>
                <Text color="var(--dt-primary)" weight={500}
                dangerouslySetInnerHTML={{ __html: t("disabled-badge-description") }}
                />
            </div>
        </div>
    );
}

export default Disabled;
