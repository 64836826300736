import {SetAddress, SetVehicle} from "../../SetupProfile";
import {IonSlide, IonSlides} from "@ionic/react";
import {useEffect, useRef, useState} from "react";
import {ProfileSlide} from "./Slides";
import SetCard from "../../SetupProfile/SetCard";

const RegisterProfileForm = ({activeSlide, setActiveSlide, sliderOptions}) => {
    const [addresses, setAddresses] = useState([])
    const sliderRef = useRef(null);

    useEffect(() => {
        sliderRef.current.slideTo(activeSlide)
    }, [activeSlide])

    const nextSlide = () => {
        setActiveSlide(prev => prev + 1)
    }

    return (
        <IonSlides className="SetUpProfileSlider" options={sliderOptions} ref={sliderRef}>
            <IonSlide>
                <ProfileSlide setAddresses={setAddresses} setActiveSlide={setActiveSlide}/>
            </IonSlide>

            <IonSlide>
                <SetAddress addresses={addresses} onSubmit={nextSlide} activeSlide={activeSlide}/>
            </IonSlide>

            <IonSlide>
                <SetCard onSuccess={nextSlide}/>
            </IonSlide>

            <IonSlide className={"px-5"}>
                <SetVehicle activeIndex={activeSlide}/>
            </IonSlide>
        </IonSlides>
    )
}

export default RegisterProfileForm
