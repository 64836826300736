import DTCar from "../../../../DTCar";

const ChargingTop = ({session, elapsedTime}) => {
    const transaction = session.charge_transaction;

    return (
        <div className={`flex justify-center items-center text-center flex-col px-[16px]`}>
            <DTCar slotNumber={transaction?.transaction_connection?.id} type="charge-session"/>
        </div>
    )
}

export default ChargingTop;
