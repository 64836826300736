//Helpers
import { useTranslation } from "react-i18next";
import { RemoveCard, SetDefaultCard } from "../../../helper/paymentFunctions";
//Components
import Text from "../../Text";
import Icon from "../../Icon";
import UseButton from "../../UseButton";
import DrifterCard from "../Cards/DrifterCard";
import DefaultCard from "../Cards/DefaultCard";
import { useIonRouter } from "@ionic/react";

const CardSliderItem = ({addSlide = false, addNewHandler, card}) => {
  const { t } = useTranslation();
  const formatMonth = (m) => {
    return m.length === 1 ? "0" + m : m;
  };
  const router = useIonRouter();

  return (
    <>
      <Text
        size={22}
        weight={600}
        color={addSlide ? "var(--dt-pink)" : "#353355"}
        margin={"0 0 40px"}
        className={"capitalize"}
      >
        {addSlide ? t("add-new") : card.card.brand}
      </Text>
      {!addSlide && (
        <button className="EditButton" onClick={() => RemoveCard(card.id)}>
          <Icon
            name={"trash"}
            size={24}
            fill={"var(--dt-pink)"}
            className="EditIcon"
          />
        </button>
      )}
      <div className="SlideWrapper">
        <div className="Slide">
          {addSlide ? (
            <DrifterCard
              onButtonClick={() =>
                  addNewHandler ? addNewHandler() : router.push("/add-payment-method", "forward")
              }
            />
          ) : (
            <DefaultCard
              brand={card.card.brand}
              card4={card.card.last4}
              expireDate={{
                month: card.card.exp_month ? formatMonth(card.card.exp_month.toString()) : '',
                year: card.card.exp_year ? card.card.exp_year : '',
              }}
              cardholderName={card?.holder_name}
            />
          )}
        </div>
      </div>
      {!addSlide && (
        <UseButton
          isInUse={card.default}
          setInUse={() => SetDefaultCard(card.id)}
        />
      )}
    </>
  );
};
export default CardSliderItem;
