import Text from "../Text";
const NoItems = ({ title, text, type }) => {
  
  if (type === "recent") {
    return (
      <div className="SearchResultsRow -recent-search">
        <div className="NoItemsWrapper">
          <div className="NoItems -no-recent-search" />
          <Text size="22px" weight="600" margin="12px 0 0">
            {title}
          </Text>
          <Text size="14px" weight="400" margin="12px 0 auto">
            {text}
          </Text>
        </div>
      </div>
    );
  } else if (type === "search") {
    return (
      <div className="SearchResultsRow">
        <div className="NoItemsWrapper">
          <Text size="22px" weight="600" margin="12px 0 0">
            {title}
          </Text>
          <Text size="14px" weight="400" margin="12px 0 auto">
            {text}
          </Text>
        </div>
      </div>
    );
  }
};
export default NoItems;
