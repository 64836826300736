//Ionic & React
import { useIonRouter } from "@ionic/react";
//Helpers
import { t } from "i18next";
import {setFirstExperienceDone, setUsageAccount} from "../redux/slices/userSlice";
import { store } from "../redux/store";
//Components
import ModalHandler from "./ModalHandler";
import Text from "./Text";
import { useEffect, useState } from "react";
import { api } from "../helper/api";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux/slices/globalSlice";

const FirstExperience = ({
  isOpen,
  setOpen,
  openSessionStartModal,
  closeStationDetailModal,
}) => {
  const router = useIonRouter();
  const dispatch = useDispatch()
  const [businessAccounts, setBusinessAccounts] = useState([])

  const getBusinessAccounts = async () => {
    dispatch(setLoading({message: "", status: true}))
    const { data } = await api.getCompanyList();
    if (data.success) {
      setBusinessAccounts(data.response);
    }
    dispatch(setLoading({message: "", status: false}))
  }

  useEffect(() => {
    if(isOpen){
      getBusinessAccounts()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const confirm = () => {
    store.dispatch(setFirstExperienceDone(true));
    let selected = document.querySelector('input[name="first_experience_radio"]:checked');
    store.dispatch(setUsageAccount(selected.value));
    setOpen(false);
    openSessionStartModal(true);
  };

  return (
    <div className="FirstExperience">
      <ModalHandler />
      <div className="FirstExperienceContent">
        <div className="mt-[20%]">
          <Text
            dangerouslySetInnerHTML={{ __html: t("welcome-first-experience") }}
            size={22}
            weight={500}
            color={"#F9F9F9"}
          />
        </div>
        <div className="mt-[8%]">
          <Text
            dangerouslySetInnerHTML={{ __html: t("choose-purpose") }}
            size={18}
            weight={400}
            color={"#F9F9F9"}
          />
        </div>
        <div className="mt-[4%]">
          <Text
            dangerouslySetInnerHTML={{ __html: t("choose-purpose-desc") }}
            size={16}
            weight={400}
            color={"#EAEAEE"}
          />
        </div>
        <div
        onChange={e => dispatch(setUsageAccount(e.target.value))}
        className={`FirstExperienceRadioButtons ${businessAccounts.length>1 ? "list" : ""}`}
        >
          <label className="FirstExperienceRadioWrapper" htmlFor="personal">
            <input
              type="radio"
              value="personal"
              id="personal"
              name="first_experience_radio"
              defaultChecked={true}
            />
            <span className="FirstExperienceRadio" />
            {t("personal")}
          </label>
          {businessAccounts.length ? (
            businessAccounts.map(b => (
            <label className={'FirstExperienceRadioWrapper'} htmlFor={b.company.id} key={b.company.id}>
              <input
                type="radio"
                id={b.company.id}
                value={b.company.id}
                name="first_experience_radio"
              />
              <span className="FirstExperienceRadio" />
              {b.company.company_name}
            </label>
            ))
          ) : (
          <label className={`FirstExperienceRadioWrapper ${businessAccounts.length ? null : "disabled"}`} htmlFor="business">
            <input
              type="radio"
              id="business"
              value="business"
              name="first_experience_radio"
              disabled={!businessAccounts.length}
            />
            <span className="FirstExperienceRadio" />
            {t("business")}
          </label>
          )}
        </div>
        <button className="FirstTimeExperienceConfirmButton" onClick={() => confirm()}>
          {t("confirm")}
        </button>
        <Text
          dangerouslySetInnerHTML={{ __html: t("add-your-business") }}
          size={12}
          weight={500}
          color={"#C0C0CB"}
          onClick={async () => {
            await setOpen(false);
            await closeStationDetailModal();
            await router.push("my-business-accounts", "forward");
          }}
        />
      </div>
    </div>
  );
};

export default FirstExperience;
