import {setAlert} from "../redux/slices/globalSlice";
import {store} from "../redux/store";

/**
 *
 * @param message - message to display
 * @param type {string | "success", "error", "warning"} - type of alert (success, error, warning)
 */
const showAlert = (message, type) => store.dispatch(setAlert({status: true, message, type}))

/**
 *
 * Close alert
 */
const closeAlert = () => store.dispatch(setAlert(false))

export const Alert = {
  /**
   * @param message {string} - Message to show in alert
   */
  success: (message) => showAlert(message, "success"),

  /**
   * @param message {string} - Message to show in alert
   */
  error: (message) => showAlert(message, "error"),

  /**
   * @param message {string} - Message to show in alert
   */
  warning: (message) => showAlert(message, "warning"),

  /**
   *
   * Close alert
   */
  close: () => closeAlert()
}
