import React from 'react';
import { IonCol, IonGrid, IonRow} from "@ionic/react";
import Button from '../../Button';
import { useTranslation } from 'react-i18next';

const AddCardForm = ({
    handleSubmit,
    number,
    expiry,
    cvc,
    name,
    handleInputChange,
    displayForm,
    error
}) => {
    const {t} = useTranslation()
    return (
        <form onSubmit={handleSubmit}>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <label
                    className="border-label"
                    htmlFor="number"
                    color="light"
                  >
                    {t("card-number")}
                  </label>
                  <input
                    id="number"
                    type="tel"
                    name="number"
                    className={`border-input creditCard`}
                    value={number}
                    placeholder="5555 5555 5555 4444"
                    pattern="[\d| ]{16,22}"
                    required
                    onChange={handleInputChange}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={displayForm}>
                <IonCol size="12">
                  <label className="border-label" htmlFor="name" color="light">
                    {t("card-holder-name")}
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    value={name}
                    className={`border-input ${error?.name}`}
                    placeholder=""
                    required
                    onChange={handleInputChange}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={displayForm}>
                <IonCol size="6" className={"inline-block"}>
                  <label
                    className="border-label"
                    htmlFor="expiry"
                    color="light"
                  >
                    {t("expiration-date")}
                  </label>
                  <input
                    id="expiry"
                    type="tel"
                    name="expiry"
                    value={expiry}
                    className={`border-input expiry`}
                    placeholder="**/**"
                    pattern="\d\d/\d\d"
                    required
                    onChange={handleInputChange}
                  />
                </IonCol>

                <IonCol size="6" className={"inline-block"}>
                  <label className="border-label" htmlFor="cvc" color="light">
                    {t("cvc")}
                  </label>
                  <input
                    id="cvc"
                    type="tel"
                    name="cvc"
                    value={cvc}
                    className={`border-input cvc`}
                    placeholder="***"
                    pattern="\d{3,4}"
                    required
                    onChange={handleInputChange}
                  />
                </IonCol>
              </IonRow>
              <input type="hidden" name="issuer" value={""} />

              <IonRow>
                <IonCol size="12">
                  <Button
                    type="submit"
                    className={`add-button`}
                    disabled={displayForm === "deactivateClass"}
                  >
                    {t("add-card")}
                  </Button>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
    );
}

export default AddCardForm;
