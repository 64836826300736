import { setActiveSession } from "../redux/slices/userSlice";
import { store } from "../redux/store";
import { fetch } from "./CapacitorFetch";
import { Alert } from "./alert";
import { api } from "./api";
import i18next from "i18next";

// TODO: already getting active session and updating state on the component,
// TODO: remove all active session updates and promises

export const parkChargeFunctions = {

  startPark: async (vehicle_id, station_id, park_id, is_park_charge, parkVipCode,usageAccount) => {
    let options = () => {
      if (is_park_charge) {
        return {
          options: {
            parameters: {
              vehicle_id: vehicle_id,
              park_id: park_id,
              is_park_charge: true,
              station_id: station_id,
              account_type: usageAccount
            },
          },
        };
      } else {
        return {
          options: {
            parameters: {
              vehicle_id: vehicle_id,
              park_id: station_id,
              is_park_charge: false,
              vip_code: parkVipCode,
              account_type: usageAccount
            },
          },
        };
      }
    };

    return await api.startParking(options());
  },
  stopPark: (session_id) => {
    return api.stopParking({
        options: {
          parameters: {
            park_session_id: session_id,
          },
        },
      })
  },
  startCharge: (connection_id, vehicle_id, is_park_charge,code_id,usageAccount,slot_id="") => {
    return api.startCharging({
          options: {
            parameters: {
              connection_id: connection_id,
              vehicle_id: vehicle_id,
              is_park_charge: is_park_charge,
              code_id:code_id,
              slot_number: slot_id,
              account_type:usageAccount
            },
          },
        })
  },
  stopCharge: (session_id) => {
    return api.stopCharging({
      options: {
        parameters: {
          "transaction_id": session_id
        },
      },
    })
  },
  getActiveSession: async (showAlert=true) => {

    const headers = {
      Locale: i18next.language
    }
    
    fetch("GET", "v2/active-session", [], headers)
      .then(res => {
        if (!res.ok) {
          const error = new Error(res.data.message);
          error.statusCode = res.status
          throw error
        }
        if (res?.status === 200 && res?.data?.success) {
          store.dispatch(setActiveSession(res.data.response));
          return res.data;
        }
      })
      .catch(err => {
        if(err.statusCode === 404 && showAlert) {
          Alert.error(err.message)
        }
      })
      
  },
};
