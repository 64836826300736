import { createSlice } from "@reduxjs/toolkit"

export const preferencesSlice = createSlice({
  name: "preferencesSlice",
  initialState: {
    mapFilter: "PARKING",
    filterBasedOnCar: true,
    parkingServiceType: "ANY",
    parkingOnlyAvailable: false,
    parkingConsiderDisabled: true,
    chargingType: "",
    chargingSpeed: "7KW",
    chargingOnlyAvailable: false,
  },
  reducers: {
    setMapFilter: (state, action) => {
      state.mapFilter = action.payload
    },
    setFilterBasedOnCar: (state, action) => {
      state.filterBasedOnCar = action.payload
    },
    setParkingServiceType: (state, action) => {
      state.parkingServiceType = action.payload
    },
    setParkingOnlyAvailable: (state, action) => {
      state.parkingOnlyAvailable = action.payload
    },
    setParkingConsiderDisabled: (state, action) => {
      state.parkingConsiderDisabled = action.payload
    },
    setChargingType: (state, action) => {
      state.chargingType = action.payload
    },
    setChargingSpeed: (state, action) => {
      state.chargingSpeed = action.payload
    },
    setChargingOnlyAvailable: (state, action) => {
      state.chargingOnlyAvailable = action.payload
    },
  },
})

export const {
  setMapFilter,
  setFilterBasedOnCar,
  setParkingServiceType,
  setParkingOnlyAvailable,
  setParkingConsiderDisabled,
  setChargingType,
  setChargingSpeed,
  setChargingOnlyAvailable,
} = preferencesSlice.actions
export default preferencesSlice.reducer
