import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    config:{},
    token: null,
    pin: null,
    activeSession: {
      type: null,
      park_session: null,
      charge_transaction: null,
      previous_status: false,
    },
    fullName: "",
    name: "",
    lastName: "",
    email: "",
    phone: "",
    country: {
      countryName: 'Sweden',
      countryShortName: 'SE',
      countryCode: '46'
    },
    currentPlate: "",
    editPlate: "",
    userCars: [],
    carSave:false,
    isProfileSet: false,
    user: {},
    vehicles: [],
    defaultVehicle: null,
    addNewCarModal: false,
    isFirstTime: true,
    isFirstExperienceDone: false,
    paymentMethods: [],
    language: "en",
    locations: [],
    drifterCardBalance: 0,
    codeId:"",
    rememberedPos:null,
    usageAccount:"personal",
    recentSearches: []
  },
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setPin: (state, action) => {
      state.pin = action.payload;
    },
    setActiveSession: (state, action) => {
      const previous_status = !!state.activeSession.type;

      state.activeSession = {...action.payload, previous_status};
    },
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.setEmail = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setCurrentPlate: (state, action) => {
      state.currentPlate = action.payload;
    },
    setEditPlate: (state, action) => {
      state.editPlate = action.payload;
    },
    setIsProfileSet: (state, action) => {
      state.isProfileSet = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setDefaultVehicle: (state, action) => {
      state.defaultVehicle = action.payload;
    },
    setAddNewCarModal: (state, action) => {
      state.addNewCarModal = action.payload;
    },
    setFirstTime: (state, action) => {
      state.isFirstTime = action.payload;
    },
    setFirstExperienceDone: (state, action) => {
      state.isFirstExperienceDone = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setLocations: (state, action) => {
        state.locations.push(action.payload);
    },
    clearLocations: (state) => {
      state.locations = [];
    },
    setCarSave: (state, action) => {
      state.carSave = action.payload;
    },
    setDrifterCardBalance: (state, action) => {
      state.drifterCardBalance = action.payload;
    },
    setCodeId: (state, action) => {
      state.codeId = action.payload;
      state.slotId = ""
    },
    setSlotId: (state, action) => {
      state.slotId = action.payload;
      state.codeId = ""
    },
    setRememberedPos: (state, action) => {
      state.rememberedPos = action.payload;
    },
    setUsageAccount: (state, action) => {
      state.usageAccount = action.payload;
    },
    setRecentSearches: (state,action) => {
      state.recentSearches.push(action.payload)
    },
    storeReset: (state) => {
      state.config={};
      state.token = null;
      state.activeSession = {
        type: null,
        park_session: null,
        charge_transaction: null,
      };
      state.fullName = "";
      state.name = "";
      state.lastName = "";
      state.email = "";
      state.phone = "";
      state.country = {
        countryName: "Sweden",
        countryCode: "46",
        countryShortName: "SE"
      };
      state.currentPlate = "";
      state.editPlate = "";
      state.userCars = [];
      state.isProfileSet = false;
      state.user = {};
      state.vehicles = [];
      state.defaultVehicle = null;
      state.addNewCarModal = false;
      state.paymentMethods = [];
      state.carSave = false;
      state.codeId="";
      state.slotId="";
      state.rememberedPos=null;
      state.usageAccount="personal"
    },
  },
});

export const {
  setConfig,
  setToken,
  setPin,
  setFullName,
  setName,
  setLastName,
  setEmail,
  setPhone,
  setCountry,
  setCurrentPlate,
  setEditPlate,
  setIsProfileSet,
  setActiveSession,
  setUser,
  setVehicles,
  setDefaultVehicle,
  setAddNewCarModal,
  setFirstTime,
  setFirstExperienceDone,
  setPaymentMethods,
  setLanguage,
  setLocations,
  storeReset,
  clearLocations,
  setCarSave,
  setDrifterCardBalance,
  setCodeId,
  setSlotId,
  setRememberedPos,
  setUsageAccount,
  setRecentSearches
} = userSlice.actions;
export default userSlice.reducer;
