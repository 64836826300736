import React, {memo} from 'react'
import ReactCountryFlag from 'react-country-flag'

const CountryItem = ({country, onItemClick, dialingCodeShown = false}) => {
  return (
    <div className='CountryItem' onClick={() => onItemClick(country)}>
        <ReactCountryFlag
            countryCode={country.countryShortName}
            style={{
                pointerEvents: "none",
                width: "40px",
                height: "auto",
                marginRight: "12px",
                objectFit: "contain",
                borderRadius: "3px",
            }}
            svg
        />
        <span
            style={{
                pointerEvents: "none",
                marginRight: "auto",
                textAlign: "left",
            }}
        >
            {dialingCodeShown && <span id='CountryCode'><strong>+{country.countryCode}</strong></span>} {"  "}
            {country.countryName}
        </span>
    </div>
  )
}

export default memo(CountryItem)
