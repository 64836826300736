import Text from "../../../Text";
import EditManuallyButton from "../../EditManuallyButton";
import { t } from "i18next";
const NoItems = ({
  isEdit,
  completeLater,
  personForm,
  setAddManualAddressModal,
}) => {
  const formCondition =
    !personForm.firstName && !personForm.lastName && !isEdit;
  return (
    <div className={`NoItemsWrapper ${isEdit ? "h-[calc(100%_-_150px)]" : ""}`}>
      <div className="NoItems -no-address" />
      <Text size="22px" weight="600" margin="12px 0 0">
        {t("oops")}...
      </Text>
      <Text size="14px" weight="400" margin="12px 0 auto">
        <div
          dangerouslySetInnerHTML={{
            __html: t("we-couldnt-find-an-address"),
          }}
        ></div>
      </Text>
      {!formCondition && (
        <>
          {isEdit ? (
            <EditManuallyButton
              setAddManualAddressModal={setAddManualAddressModal}
            />
          ) : (
            <Text
              margin="24px auto 10px"
              size="12px"
              weight="500"
              color="var(--dt-purple)"
              align="center"
            >
              <div
                onClick={completeLater}
                dangerouslySetInnerHTML={{
                  __html: t("complete-later"),
                }}
              ></div>
            </Text>
          )}
        </>
      )}
    </div>
  );
};

export default NoItems;
