import Icon from "./Icon";
import Text from "./Text";
import { useTranslation } from "react-i18next";
const ConfirmMailContent = ({mail, modalOpen}) => {
    const {t} = useTranslation()
  return (
    <>
      <div className="ModalIcon mt-[30px]">
        <Icon name="envelope" height={38} width={33} fill="#fff"/>
      </div>
      <Text size="24px" weight="600" margin="20px 0 0">
        {t("confirm-your-mail")}
      </Text>
      <Text
        size="14px"
        weight="400"
        margin="15px 0 0"
        dangerouslySetInnerHTML={{ __html: t("check-mailbox") }}
      />
      <Text size="16px" weight="400" margin="30px 0 0">
        {mail}
      </Text>
      <button
        className="DTButton"
        onClick={() => modalOpen(false)}
        style={{ margin: "44px 0 0" }}
      >
        {t("done")}
      </button>
    </>
  );
};
export default ConfirmMailContent;