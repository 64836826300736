//Ionic & React
import { useState, useEffect } from 'react';
//Components
import { BusinessAccounts, SetBusinessSlider } from '../components/MyBusinessAccount';
import AddBusinessAccountModal from '../components/MyBusinessAccount/AddBusinessAccountModal';
import PageToolbar from '../components/PageToolbar';
//Helpers
import { store } from '../redux/store';
import { api } from '../helper/api';
import { setLoading } from '../redux/slices/globalSlice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAddMyBusinsessAccountModal } from '../redux/slices/modalSlice';
import { useHistory } from 'react-router';


const MyBusinessAccount = () => {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const history = useHistory()
    const [businessAccounts, setBusinesAccounts] = useState()
    const [reloadBusinessAccounts, setReloadBusinessAccounts] = useState(true)
    const [currentBusinessAccount, setCurrentBusinesAccount] = useState({})
    const [isBusinessSliderModalOpen, setBusinessSliderModalOpen] = useState(false)
    
    const {addMyBusinessAccountModal} = useSelector(state => state.modalSlice)

    const getBusinessAccounts = async () => {
        store.dispatch(setLoading({status: true, message: ""}))
        const result = await api.getCompanyList()
        if(result?.data?.success) {
            setBusinesAccounts(result?.data?.response)
            setReloadBusinessAccounts(false)
        }
        store.dispatch(setLoading({status: false, message: ""}))
    }
    
    useEffect(() => reloadBusinessAccounts && getBusinessAccounts(), [reloadBusinessAccounts])

    useEffect(() => {
        if(businessAccounts && businessAccounts.length===0){
            dispatch(setAddMyBusinsessAccountModal(true))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessAccounts]);

    return (
        <div className='MyBusinessAccount'>
            <PageToolbar
            title={t("my-business-account")}
            leftButtonType="back"
            onClick={() => {
                history.push('/map', {
                    menuModal: true
                })
            }}
            />
            <BusinessAccounts
                accounts={businessAccounts}
                setAddBusinessAccountModalOpen={status => dispatch(setAddMyBusinsessAccountModal(status))}
            />
            <SetBusinessSlider
                isOpen={isBusinessSliderModalOpen}
                setOpen={setBusinessSliderModalOpen}
                currentBusinessAccount={currentBusinessAccount}
                getBusinessAccounts={getBusinessAccounts}
                comingFrom="my-accounts"
            />
            <AddBusinessAccountModal
                isOpen={addMyBusinessAccountModal.status}
                setOpen={status => dispatch(setAddMyBusinsessAccountModal(status))}
                setCurrentBusinesAccount={setCurrentBusinesAccount}
                currentBusinessAccount={currentBusinessAccount}
                setBusinessModal={setBusinessSliderModalOpen}
                businessAccounts={businessAccounts}
                setReloadBusinessAccounts={setReloadBusinessAccounts}
            />
        </div>
    );
}

export default MyBusinessAccount;
