//React
import {useEffect} from "react";
//Components
import PayWithCard from "./PayWithCard";
//Helpers
import {Alert} from "../../helper/alert";
import {Stripe} from "@capacitor-community/stripe";
import {getConfig} from "../../helper/globalFunctions";


const PaymentButtons = ({invoice, onSuccess}) => {
  const paymentInitialize = () => {
    Stripe.initialize({
      publishableKey: getConfig('stripe_publishable')
    }).catch((error) => Alert.error(`${error}`))
  }

  useEffect(() => {
    paymentInitialize()
  }, [])
  return (
    <div className={"w-full"}>
      <PayWithCard id={invoice.payment_id} onSuccess={() => onSuccess()} />
    </div>
  );
}

export default PaymentButtons;
