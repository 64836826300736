import {IonSlide} from "@ionic/react";
import {SetProfile} from "../../../SetupProfile";
import {setAlert, setLoading} from "../../../../redux/slices/globalSlice";
import {api} from "../../../../helper/api";
import {getPerson} from "../../../../helper/globalFunctions";
import {useDispatch} from "react-redux";

const ProfileSlide = ({setAddresses, setActiveSlide}) => {
    const dispatch = useDispatch();

    const onSubmitProfile = (person) => {
        dispatch(setLoading({status: true, message: ""}))

        api.userEdit(person).then(async ({data}) => {
            if (!data.success) {
                dispatch(setAlert({status: true, message: `${data.message}!`, type: "error"}))
            }

            getPerson().catch((error) => dispatch(setAlert({status: true, message: `${error}!`, type: "error"})))

            const addressList = await getAddressList(person)

            if (addressList.success) {
                setActiveSlide(1)
                setAddresses(addressList.response)
            } else {
                setActiveSlide(2)
            }


        }).catch((error) => dispatch(setAlert({status: true, message: `${error}!`, type: "error"}))).finally(() => {
            dispatch(setLoading({status: false, message: ""}))
        })
    }

    const getAddressList = async (person) => {
        try {
            const response = await api.getUserInformationFromGovernment(person)
            return response.data
        } catch (error) {
            dispatch(setAlert({status: true, message: `${error}!`, type: "error"}))
            return {success: false}
        }
    }

    return (
        <IonSlide>
            <SetProfile onSubmitProfile={onSubmitProfile}/>
        </IonSlide>
    )
}

export default ProfileSlide;
