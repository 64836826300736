import DTCar from "../../../DTCar";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Text from "../../../Text";
import {useTranslation} from "react-i18next";
import {Capacitor} from "@capacitor/core";
import {SwipeButton} from "../../../SwipeButton";
import {Alert} from "../../../../helper/alert";
import {setLoading} from "../../../../redux/slices/globalSlice";
import {parkChargeFunctions} from "../../../../helper/parkChargeFunctions";
import {setActiveSession} from "../../../../redux/slices/userSlice";
import {setSessionModal} from "../../../../redux/slices/modalSlice";
import {ParkInfo} from "./Partials";
import './ParkSession.css'
import {Swiper, SwiperSlide} from "swiper/react";
import {SemiCircleProgress} from "react-semicircle-progressbar";
import ElapsedTimer from "../../../ElapsedTimer";
import OverstayCountdownLabel from "../OverstayCountdownLabel";

const ParkSession = ({elapsedTime, setReceipt}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {activeSession, config} = useSelector(state => state.userSlice);
  const [value, setValue] = useState(250);
  const [isSwipeButtonReset, setResetSwipeButton] = useState(false);
  const [counter, setCounter] = useState(0);

  const isDigitalCodeActive = activeSession?.charge_transaction?.is_digital_code_active || activeSession?.park_session?.is_digital_code_active

  useEffect(() => {
    if (!activeSession.park_session) return;

    setCounter(activeSession.park_session?.countdown?.finish_second)
  }, [activeSession.park_session]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter => counter > 0 && counter - 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, []);

  const OverstayDescription = () => {
    if (activeSession.park_session?.countdown?.overstay) {
      return (
          <div className={"font-bold text-[30px] whitespace-nowrap"}>
            +{activeSession.park_session?.countdown?.cost} {activeSession.park_session?.countdown?.currency}
            <div className={'text-[14px] pb-8'}>
              <ElapsedTimer seconds={counter} />
            </div>
          </div>
      )
    }

    return (
        <>
          {t("time-remaining")}
          <div className={'font-bold text-[30px] pb-5'}>
            <ElapsedTimer seconds={counter} />
          </div>
        </>
    )
  }


  const stopSession = async () => {
    const session = activeSession.park_session;

    if (session?.auto_parking === 1) {
      Alert.error(t("auto-finish-is-require"))
      setResetSwipeButton(true)
      setValue(250)
      return true;
    }

    dispatch(setLoading({status: true, message: ""}))

    const {success, response} = await parkChargeFunctions.getActiveSession()

    if (success) {
      parkChargeFunctions.stopPark(response.park_session.id).then(({data: {success, response}}) => {
        if (success) {
          setReceipt(response)
          dispatch(setActiveSession({type: null, park_session: null, charge_transaction: null}))
          dispatch(setSessionModal({status: false}))
        }
      }).finally(() => {
        dispatch(setLoading({status: false, message: ""}))
      })
    }
  }

  return (
      <div className={`bg-[#fff] flex flex-col h-full pb-[330px]`}>
        <div className={`px-[16px]`}>
          <Swiper
              slidesPerView={1}
              spaceBetween={0}
              speed={300}
          >
            {
                !!activeSession.park_session?.countdown && (
                    <SwiperSlide>
                      <div className={"relative mx-auto w-[220px] h-[150px] translate-y-[-10%] overstayCounter"}>
                        {!activeSession.park_session?.countdown?.overstay ? <OverstayCountdownLabel label={activeSession.park_session?.countdown?.title}/> : null}
                        <SemiCircleProgress
                            percentage={activeSession.park_session?.countdown?.percentage}
                            size={{width: 200, height: 200}}
                            strokeWidth={4}
                            strokeColor={activeSession.park_session?.countdown?.color}
                            hasBackground={true}
                            bgStrokeColor={"#E1E1E8"}
                            showPercentValue={false}
                        />

                        <div
                            className={`absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] text-primary text-[14px] mt-[50px]`}>
                          <OverstayDescription/>
                        </div>
                      </div>
                    </SwiperSlide>
                )
            }

            <SwiperSlide>
              <DTCar
                  slotNumber={activeSession.park_session?.slot_number}
                  plate={activeSession.park_session?.plate_number}
                  value={value}
                  type="park-session"
              />
            </SwiperSlide>
          </Swiper>

          {!!isDigitalCodeActive && (
              <div
                  className="flex gap-1 flex-row justify-between items-center mx-[16px] mt-4 p-2 bg-white shadow-card rounded-[10px]">
                <Text size={15} weight={500}>
                  {t("digital-code-using")}
                </Text>
              </div>
          )}

          <ParkInfo elapsedTime={elapsedTime}/>

        </div>

        <div
            className={`absolute h-[200px] w-full iosSafeAreaBottom bg-primary rounded-t-3xl text-white flex flex-col items-center justify-start text-center p-[30px] activeSessionBottom !bottom-0 transition-all`}>
          <div className="mb-[12px] w-full">
            {!isDigitalCodeActive && (
                <div className="flex justify-between items-center">
                  <p className="text-[16px] font-medium">{t("total-cost")}</p>
                  <p className="text-[26px] font-bold">
                    {activeSession.park_session ? activeSession?.park_session?.details?.payment_amount : 0}
                    <span className="text-[16px] font-normal">{t("SEK")}</span>
                  </p>
                </div>
            )}
          </div>
        </div>
      </div>
  );
}

export default ParkSession;
